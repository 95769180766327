import { useEffect, useState } from "react";
import { VFC, APIResponse } from "types";
import CheckboxTree from "./CheckboxTree";
import { Form, Field } from "react-final-form";
import { _ } from "i18n";
import { parseRoleName } from "../functions";
import Icon from "components/Icon";
import { useToast } from "ui-kit";
import client from "libs/client";

interface RolesAndPermissionProps {
	activeMenu: string;
}

const RolesAndPermission: VFC<RolesAndPermissionProps> = ({ activeMenu }) => {

	const [checked, setChecked] = useState<number[]>([]);
	const [showEditRole, setShowEditRole] = useState<boolean>(false);
	const [rolesData, setRolesData] = useState<{ roles: number; }>();
	const [editRoleFormData, setEditRoleFormData] = useState<{ id: number | null, name: string | null }>();
	const [permissionTree, setPermissionTree] = useState([]);

	const { addSuccessToast, addErrorToast } = useToast();

	const [adminRoles, SetAdminRoles] = useState<{ id: number; name: string; }[]>();

	const onSetAdminRoles = (role: { id: number; name: string }[]) => {
		SetAdminRoles(role);
	};

	const getAllRoles = async () => {
		const response = await client.getRoles.query().then((res) => { return res?.data });
		SetAdminRoles(response);
		setRolesData({ roles: response.length > 0 ? response[0].id : null });
		setEditRoleFormData({
			id: response.length > 0 ? response[0].id : null,
			name: response.length > 0 ? parseRoleName(response[0].name) : null,
		})
		return response;
	};

	const fetchPermissionTree = async () => {
		const response = await client.getPermissionTree.query().then((res) => { return res; });
		if (response?.status === 200) {
			setPermissionTree(response.data);
		}
	};

	const addRoleFormData = {
		id: null,
		name: null,
	};

	const [name, setName] = useState<string>("");

	const onFormSubmit = async (values: any) => {
		const response = await client.addUpdateRole.mutation({ body: { role: values } });
		if (response?.data.status === APIResponse.SUCCESS) {
			if (response?.data.message) { addSuccessToast(response?.data.message) };
			onSetAdminRoles(response.data.data);
			setShowEditRole(false);
		}
	};

	const onAddUpdateRole = async (values: { id: number | null; name: string }) => {
		const response = await client.addUpdateRole.mutation({ body: { role: values } });
		if (response?.data.status === APIResponse.SUCCESS) {
			if (response?.data.message) { addSuccessToast(response?.data.message) };
			onSetAdminRoles(response.data.data);
			setName("");

			if (response.data.data.length > 0) {
				const role = { id: response.data.data[response.data.data.length - 1].id, name: parseRoleName(response.data.data[response.data.data.length - 1].name) };

				setEditRoleFormData(role);
				getRoleData(role.id);
				setRolesData({ roles: role.id });

				const roleResponse = await client.getPermissionByRole.query({ roleId: role.id }).then((res) => { return res?.data });
				setChecked(roleResponse.data);
			}
			if (values.id !== null) {
				setRolesData({ roles: values.id });
				setShowEditRole(false);
				setEditRoleFormData({ id: values.id, name: parseRoleName(values.name) });
			}
		} else {
			if (response?.data.message) { addErrorToast(response?.data.message) };
		}
	};

	const getRoleData = async (id: number) => {
		const role = adminRoles?.find((item) => item.id == id);
		if (role) {
			setEditRoleFormData({ ...role, name: parseRoleName(role.name) });
		}
		const response = await client.getPermissionByRole.query({ roleId: id }).then((res) => { return res?.data });
		setChecked(response.data);
	};

	const onSavePermission = async () => {
		if (!editRoleFormData?.id) { return; }

		const response = await client.assignPermissionToRole.mutation({
			roleId: editRoleFormData?.id,
			body: { permissionIds: checked }
		})

		if (response?.data.status === APIResponse.SUCCESS) {
			if (response?.data.message) { addSuccessToast(response?.data.message) };
		} else {
			if (response?.data.message) { addErrorToast(response?.data.message) };
		}
	};

	const onDeleteRole = async () => {
		if (!editRoleFormData) { return; }

		const response = await client.deleteRole.mutation({ roleId: editRoleFormData.id! });
		if (response?.data.status === APIResponse.SUCCESS) {
			if (response?.data.message) { addSuccessToast(response?.data.message) };
			if (response.data.data.length > 0) {
				onSetAdminRoles(response.data.data);
				setEditRoleFormData({ id: response.data.data[0].id, name: parseRoleName(response.data.data[0].name) });
				getRoleData(response.data.data[0].id);
			} else {
				onSetAdminRoles([]);
				setEditRoleFormData({ id: null, name: null });
				setChecked([]);
			}
		} else {
			if (response?.data.message) { addErrorToast(response?.data.message) };
		}
	};

	const syncRoles = async () => {
		const allRoles = await getAllRoles();
		if(allRoles.length > 0) {
			getRoleData(allRoles[0].id);
		}
	}

	useEffect(() => {
		if (activeMenu === "roles_permissions_management") {
			window.scrollTo({ top: 0, left: 0 });
			syncRoles();
			fetchPermissionTree();
		}
	}, [activeMenu]);

	return (
		<>
			<div className="product-management-heading-container">
				<div className="product-management-heading"> {_("product.management.admin.role.permission")} </div>
				<div className="top-form-container">
					<Form
						onSubmit={onAddUpdateRole}
						initialValues={addRoleFormData}
						render={({ handleSubmit, submitting }) => (
							<>
								<form onSubmit={handleSubmit}>
									<div className="top-search">
										<Field name="name" type="text" initialValue={name}>
											{({ input, meta }) => (
												<>
													<input {...input} placeholder={_("enter.role.name")} required={true}
														   onChange={(event) => setName(event.target.value)}
													/>
													{meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
												</>
											)}
										</Field>
									</div>
									<button type="submit" className="btn btn-light-blue">
										+ {_("label.role.add")}
									</button>
								</form>
							</>
						)}
					/>
				</div>
			</div>

			<div className="rolesandpremissions-table-container">
				<div>
					<Form
						onSubmit={onFormSubmit}
						initialValues={rolesData}
						render={({ handleSubmit, submitting }) => (
							<>
								<form onSubmit={handleSubmit}>
									<div className="form-row">
										<div className="field-group">
											<Field
												name="roles"
												parse={(value) => {
													getRoleData(parseInt(value));
													return value;
												}}
												component="select"
												className="role-select"
												required={true}
											>
												{adminRoles &&
													adminRoles.map((role) => {
														return (
															<option key={role.id} value={role.id}>
																{role.name}
															</option>
														);
													})}
											</Field>
										</div>
									</div>
								</form>
							</>
						)}
					/>
				</div>

				<div>
					<div className="permission-treeview">
						<div className="role-heading">
							{!showEditRole && <h2>{editRoleFormData?.name}</h2>}
							<Form
								onSubmit={onAddUpdateRole}
								initialValues={editRoleFormData}
								render={({ handleSubmit, submitting }) => (
									<>
										<form onSubmit={handleSubmit}>
											{showEditRole && (
												<Field name="name" type="text">
													{({ input, meta }) => (
														<>
															<input {...input} required={true} />
															{meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
														</>
													)}
												</Field>
											)}
											{!showEditRole && (
												<>
													<button
														className="plain-btn tableCellControls__button tableCellControls__button--svg"
														onClick={() => setShowEditRole(true)}
													>
														<Icon icon="crayon" size={16} color="white" />
													</button>

													<label
														className="plain-btn delete-btn tableCellControls__button tableCellControls__button--svg"
														onClick={onDeleteRole}
													>
														<Icon icon="trash-new" size={16} color="white" />
													</label>
												</>
											)}
											{showEditRole && (
												<button type="submit" className="plain-btn tableCellControls__button tableCellControls__button--svg">
													<Icon icon="check" />
												</button>
											)}
										</form>
									</>
								)}
							/>
						</div>
						{permissionTree && <CheckboxTree data={permissionTree} checked={checked} setChecked={setChecked} />}
						<div className="form-footer">
							<button className="btn btn-light-blue" onClick={onSavePermission}>
								{_("btn.save")}
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default RolesAndPermission;
