import "style/videoMetadataPage.css";
import { useEffect } from "react";
import { setScrollBehavior } from "features/scrollPageBehaviorProvider/scrollPageBehaviorProvider";
import { VideosInProcessingTable } from "features/videoUploads/tables/VideosInProcessingTable";

export function VideoUploadsInProcessingPage() {
	useEffect(() => {
		setScrollBehavior();
	}, []);

	return (
		<div className="videoMetadataList-container">
			<div className="listHeadline">In Processing videos:</div>
			<VideosInProcessingTable />
		</div>
	);
}
