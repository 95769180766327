import { _ } from "i18n";
import { Form, Field } from "react-final-form";
import { VFC } from "../types";
import { PoleManagementTableData } from "../types";
import { SetStateAction, useState } from "react";
import Select from "react-select";
import { useToast } from "ui-kit";

interface PoleModalProps {
	onClose: () => void;
	onUpdatePole: (values: PoleManagementTableData) => void;
	timeZoneList: { label?: string; value: string }[];
	row: PoleManagementTableData | null;
}

const PoleModal: VFC<PoleModalProps> = ({ onClose, onUpdatePole, timeZoneList, row }) => {
	const [timezone, setTimezone] = useState({ value: row?.timezone, label: row?.timezone });
	const { addErrorToast } = useToast();

	const formData = {
		oldId: row ? row.id : null,
		id: row ? row.id : null,
		location: row ? row.location : null,
		serialNumber: row ? row.serialNumber : null,
	};

	const onFormSubmit = (values: PoleManagementTableData) => {
		if (timezone.value === undefined) {
			addErrorToast(_("form.validation.please_select_timezone"));
			return;
		}

		values.timezone = timezone.value;

		if(values.oldId === undefined) {
			const {oldId, ...rest} = {...values, id: parseInt(values.id)};
			onUpdatePole(rest);
		}else{
			onUpdatePole({...values, id: parseInt(values.id)});
		}
	};
	const SelectAdapter = ({ input, ...rest }: { [x: string]: {}; input: {} }) => {
		return <Select {...input} {...rest} value={timezone} />;
	};

	return (
		<Form
			onSubmit={onFormSubmit}
			initialValues={formData}
			render={({ handleSubmit, submitting }) => (
				<>
					<form onSubmit={handleSubmit}>
						<div className="form-group flex-column">
							<label className="required">{_("label.user.id")}</label>
							<Field name="id" type="number">
								{({ input, meta }) => (
									<>
										<input {...input} className="form-control" required={true} />
										{meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
									</>
								)}
							</Field>
						</div>
						<div className="form-group flex-column">
							<label className="required"> {_("label.timezone")} </label>
							<Field
								name="timezone"
								className="react-select"
								component={SelectAdapter}
								// defaultValue={timezone}
								onChange={(e: SetStateAction<{ value: number | undefined; label: number | undefined }>) => {
									setTimezone(e);
								}}
								options={timeZoneList}
							/>
						</div>
						<div className="form-group flex-column">
							<label className="required"> {_("label.location")} </label>
							<Field name="location" type="text">
								{({ input, meta }) => (
									<>
										<input {...input} name="location" className="form-control" required={true} />
										{meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
									</>
								)}
							</Field>
						</div>
						<div className="form-group flex-column">
							<label className="required"> {_("label.serial_number")}</label>
							<Field name="serialNumber" type="text">
								{({ input, meta }) => (
									<>
										<input {...input} type="number" min={0} max={100} className="form-control" required={true} />
										{meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
									</>
								)}
							</Field>
						</div>

						<div className="form__footer">
							<button type="submit" disabled={submitting} className="btn-primary btn">
								{_("btn.save")}
							</button>
							<label className="btn--cancel" onClick={() => onClose()}>
								{_("btn.cancel")}
							</label>
						</div>
					</form>
				</>
			)}
		/>
	);
};

export default PoleModal;
