export const teamLevels = [
	{
		value: 1,
		label: "1",
	},
	{
		value: 2,
		label: "2",
	},
	{
		value: 3,
		label: "3",
	},
];

export const gameTeamAgeGroup = [
	"U6",
	"U7",
	"U8",
	"U9",
	"U10",
	"U11",
	"U12",
	"U13",
	"U14",
	"U15",
	"U16",
	"U17",
	"U18",
	"U19",
	"U20",
	"U21",
	"U23",
	"SENIOR",
	"N2",
	"N3",
].map((value) => ({
	value: value,
	label: value,
}));
