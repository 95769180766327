// import type { MouseEvent } from "react";
// import { secondsToTime } from "./functions";
import type { TimelineValues } from "./types";
import type { MediaPlayerInstance } from "@vidstack/react";


interface TimelineProps {
	periods: TimelineValues;
	duration: number;
	currentTime: number;
	player: MediaPlayerInstance | null;
}

function getLeft(time: number, duration: number): number {
	return (time / duration) * 100;
}

function getDimensions(start: number, end: number, duration: number): [number, number] {
	const left = getLeft(start, duration);
	const width = ((end - start) / duration) * 100;

	return [left, width];
}

export function Timeline({ periods, currentTime, duration }: TimelineProps) {
	const cursorLeft = getLeft(currentTime, duration);

	// const onClick = (e: MouseEvent<HTMLDivElement>) => {
	// 	console.log(e);

	// 	const left = (e.clientX - e.currentTarget.offsetLeft) / e.currentTarget.clientWidth
	// 	const seconds = duration * left
	// 	console.log({
	// 		clientX: e.clientX,
	// 		offsetLeft: e.currentTarget.offsetLeft,
	// 		width: e.currentTarget.clientWidth,
	// 		left: `${left*100}%`,
	// 		time: secondsToTime(seconds)
	// 	});
	// }

	return (
		<div className="Video-timeline">
			<div
				className="timeline-track-view"
				//onClick={onClick}
			>
				{Object.entries(periods).map(([period, { start, end }]) => {
					const [left, width] = getDimensions(start, end, duration);
					return (
						<div className={`timeline-track ${period}`} style={{ left: `${left}%`, width: `${width}%` }} key={period}>
							{period}
						</div>
					);
				})}
				<div className="timeline-cursor" style={{ left: `${cursorLeft}%` }} />
			</div>
		</div>
	);
}
