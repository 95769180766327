import { useIframeStore } from "app/iframeStore";
import Iframe from "components/iframe";
import { useConstants } from "features/constantsProvider/context";
import { useEffect, useState } from "react";
import { Link, isRouteErrorResponse, useLocation, useNavigate, useRouteError } from "react-router-dom";
import errorImg from "assets/images/errors/500.png";
import "./errorPage.css";
import { _ } from "i18n";

interface RouteError {
	message: string;
	status?: number;
}

export default function ErrorPage() {
	const error = useRouteError() as RouteError;
	console.error(error);
	const location = useLocation();
	const { apiURL } = useConstants();

	const [code, setCode] = useState(isRouteErrorResponse(error) ? 404 : 500);

	const navigate = useNavigate();

	const {
		actions: { startIframeLoading, stopIframeLoading },
	} = useIframeStore();

	const messageListener = (event: MessageEvent<{ event: string; url: string; code: number }>) => {
		const url = event.data.url?.replace(apiURL, "");
		switch (event.data.event) {
			case "click":
				startIframeLoading();
				navigate(url);
				break;

			case "error":
				if (code !== event.data.code) {
					setCode(event.data.code);
				}
				break;
		}
	};

	useEffect(() => {
		localStorage.clear();
		stopIframeLoading();

		// if ("serviceWorker" in navigator) {
		// 	window.addEventListener("load", () => {
		// 		navigator.serviceWorker
		// 			.register("/service-worker.js")
		// 			.then((registration) => {
		// 				registration.onupdatefound = () => {
		// 					const installingWorker = registration.installing;
		// 					if (installingWorker) {
		// 						installingWorker.onstatechange = () => {
		// 							if (installingWorker.state === "installed") {
		// 								if (navigator.serviceWorker.controller) {
		// 									// New content is available; refresh the page.
		// 									console.log("New content is available; please refresh.");
		// 								} else {
		// 									// This is the initial service worker registration; refresh the page.
		// 									console.log("Service worker registered successfully.");
		// 								}
		// 							}
		// 						};
		// 					}
		// 				};
		// 			})
		// 			.catch((error) => {
		// 				console.error("Service worker registration failed:", error);
		// 			});
		// 	});
		// }

		window.addEventListener("message", messageListener);

		return () => {
			window.removeEventListener("message", messageListener);
		};
	}, []);

	if (isRouteErrorResponse(error)) {
		if (error.status === 404) {
			return <Iframe url={location.pathname} />;
		}

		return (
			<div>
				<h1>Oops!</h1>
				<h2>{error.status}</h2>
				<p>{error.statusText}</p>
				{error.data?.message && <p>{error.data.message}</p>}
			</div>
		);
	}

	if (error.status === 401) {
		return <></>;
	}

	return (
		<div id="errorPage" className="errorPage-container">
			<div className="content">
				<img src={errorImg} className="errorImg" />
				<div className="error-text">{_("message.500page")}</div>
				<div className="error-text">{_("message.500page.info")}</div>
				<button className="backToHomeButton" onClick={() => window.location.reload()}>
					{_("label.refresh")}
				</button>
				<Link className="backToHomeButton" to="/">
					{_("label.button.backToHome")}
				</Link>
				<div className="ripple">
					<div className="circle" />
				</div>
			</div>
		</div>
	);
}
