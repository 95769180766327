import React from "react";
import { _ } from "i18n";
import { Continent, CountryData, SingleCountryData, UserCountrySelection } from "./types";
import { CountryFlag, CountryCode } from "../flags";

interface SelectedCountryProps {
	onClick: () => void;
	selection: UserCountrySelection;
	countryData: CountryData;
}

export function SelectedCountry({ selection, countryData, onClick }: SelectedCountryProps) {
	const entries = Object.entries(selection.countries);
	const selectedCountriesCount = Object.values(selection.countries).reduce((acc, cur) => acc + cur.length, 0);

	const getCountryName = (country: string, continent: string) => {
		let data = "";
		countryData[continent as Continent]?.map((countryDetails: SingleCountryData) => {
			if (country === countryDetails.code) data = countryDetails.name;
		});
		return data;
	};

	return (
		<div className="selected-country" onClick={onClick}>
			<button type="button" className="selected-country-btn">
				<div className="header-flag-icon-block">
					<span className="icon icon-flag-new" />
				</div>
				{/* {selectedCountriesCount > 0 && <span> {selectedCountriesCount > 5 ? "5+" : selectedCountriesCount} </span>} */}
				<span>{selectedCountriesCount}</span>
			</button>
			{selectedCountriesCount > 0 && (
				<div className="selected-country-dropdown">
					<span>{_("country.selection.selected.countries")}</span>
					<ul>
						{entries.map(([continent, countries]) => {
							return (
								<React.Fragment key={continent}>
									{countries.map((country: string) => {
										return (
											<li key={country}>
												<a>
													<CountryFlag code={country as CountryCode} /> {getCountryName(country, continent)}
												</a>
											</li>
										);
									})}
								</React.Fragment>
							);
						})}
					</ul>
				</div>
			)}
		</div>
	);
}
