import type { PartialPlayerProfileResponse, PartialPlayerUserResponse } from "libs/apiClient"
import { ContentCell } from "./ContentCell";
import { formatDate } from "pages/PlayerUserDetailPage";
import { PlayerPosition } from "types";
import { _ } from "i18n";
import { gameTeamAgeGroup } from "libs/constants";

interface PlayerUserDetailProps {
    userData?: PartialPlayerUserResponse | null;
    profileData?: PartialPlayerProfileResponse | null;
    nationalities?: {
        label: string;
        value: string | number;
    }[];
}

export function PlayerUserDetail({ userData, profileData, nationalities }: PlayerUserDetailProps) {

    const locales = [
        { value: "en", label: "English" },
        { value: "fr", label: "French" },
        { value: "de", label: "German" },
        { value: "es", label: "Spanish" },
        { value: "it", label: "Italian" }
    ]

    const playerPositions = [
        { value: PlayerPosition.G, label: _("enum.position.G.key") },
        { value: PlayerPosition.DD, label: _("enum.position.DD.key") },
        { value: PlayerPosition.DC, label: _("enum.position.DC.key") },
        { value: PlayerPosition.DG, label: _("enum.position.DG.key") },
        { value: PlayerPosition.MDC, label: _("enum.position.MDC.key") },
        { value: PlayerPosition.MC, label: _("enum.position.MC.key") },
        { value: PlayerPosition.MOC, label: _("enum.position.MOC.key") },
        { value: PlayerPosition.MG, label: _("enum.position.MG.key") },
        { value: PlayerPosition.MD, label: _("enum.position.MD.key") },
        { value: PlayerPosition.ATT, label: _("enum.position.ATT.key") },
        { value: PlayerPosition.AG, label: _("enum.position.AG.key") },
        { value: PlayerPosition.AD, label: _("enum.position.AD.key") },
    ];

    const strongFoot = [
        { value: "left", label: "Left" },
        { value: "right", label: "Right" }
    ]

    const renderUserContent = <K extends keyof PartialPlayerUserResponse>(
        key: K,
        value: PartialPlayerUserResponse[K]
    ) => {

        switch (key) {
            case "id":
                return <ContentCell key={key} title="User Id" content={(typeof value !== "object" && value) ? value : "-"} />
            case "isActive":
                return <ContentCell key={key} title={"Is Active"} content={value ? "Yes" : "No"} boolean booleanValue={typeof value === "boolean" ? value : undefined} />
            case "club":
                return <ContentCell key={key} title="Club Id" content={(typeof value === "object") && (value?.id ?? "-")} id={userData?.id} patchMethod={"user"} field={"clubId"} number />
            case "player":
                return <ContentCell key={key} title="Linked eb_player.id" content={(typeof value === "object") && (value?.id ?? "-")} id={userData?.id} patchMethod={"user"} field={"playerId"} number />
            case "email":
                return <ContentCell key={key} title="Email" content={(typeof value !== "object" && value) ? value : "-"} id={userData?.id} patchMethod={"user"} field={key} />
            case "firstName":
                return <ContentCell key={key} title="First Name" content={(typeof value !== "object" && value) ? value : "-"} id={userData?.id} patchMethod={"user"} field={key} />
            case "lastName":
                return <ContentCell key={key} title="Last Name" content={(typeof value !== "object" && value) ? value : "-"} id={userData?.id} patchMethod={"user"} field={key} />
            case "phoneNumber":
                return <ContentCell key={key} title="PhoneNumber" content={(typeof value !== "object" && value) ? value : "-"} id={userData?.id} patchMethod={"user"} field={key} phoneNumber />
            case "locale":
                return <ContentCell key={key} title="Locale" content={(typeof value !== "object" && value) ? value : "-"} id={userData?.id} field={key} patchMethod={"user"} selectItems={locales} />
            case "acceptedTermsOfUse":
                return <ContentCell key={key} title="Accepted Terms Of Use" content={value ? "Accepted" : "Not Accepted"} boolean booleanValue={typeof value === "boolean" ? value : undefined} />
            case "guardianApprovalRequired":
                return <ContentCell key={key} title="Guardian Approval Required" content={value ? "Yes" : "No"} boolean booleanValue={typeof value === "boolean" ? value : undefined} />
            case "hasNewsletterSubscription":
                return <ContentCell key={key} title="Has Newsletter Subscription" content={value ? "Yes" : "No"} boolean booleanValue={typeof value === "boolean" ? value : undefined} />
            case "pendingRemoval":
                return <ContentCell key={key} title="Pending Removal" content={value ? "Yes" : "No"} boolean booleanValue={typeof value === "boolean" ? value : undefined} />
            case "createdAt":
                return <ContentCell key={key} title="Created At" content={formatDate(typeof value === "string" ? value : null)} />
            case "deletedAt":
                return <ContentCell key={key} title="Deleted At" content={formatDate(typeof value === "string" ? value : null)} />
            case "acceptedTermsOfUseAt":
                return <ContentCell key={key} title="Accepted Terms Of Use At" content={formatDate(typeof value === "string" ? value : null)} />
            case "updatedAt":
                return <ContentCell key={key} title="Updated At" content={formatDate(typeof value === "string" ? value : null)} />
            case "pendingRemovalAt":
                return <ContentCell key={key} title="Pending Removal At" content={formatDate(typeof value === "string" ? value : null)} />
            case "resetPasswordTokenRequestedAt":
                return <ContentCell key={key} title="Reset Password Token Requested At" content={formatDate(typeof value === "string" ? value : null)} />
            case "photo":
                return <ContentCell key={key} title="Photo" content={(typeof value !== "object" && value) && value} isImage />
            case "passwordMigrated":
                return null
            default: {
                const fieldLabel = key
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, (str) => str.toUpperCase());
                return <ContentCell key={key} title={fieldLabel} content={(typeof value !== "object" && value) ? value : "-"} />
            }
        }
    };

    const renderProfileContent = <K extends keyof PartialPlayerProfileResponse>(
        key: K,
        value: PartialPlayerProfileResponse[K]
    ) => {
        switch (key) {
            case "height":
                return <ContentCell key={key} title="Height (cm)" content={(typeof value !== "object" && value) ? value : "-"} number field={key} id={profileData?.id} patchMethod={"profile"} />
            case "weight":
                return <ContentCell key={key} title="Weight (kg)" content={(typeof value !== "object" && value) ? value : "-"} number field={key} id={profileData?.id} patchMethod={"profile"} />
            case "position":
                return <ContentCell key={key} title="Position" content={(typeof value !== "object" && value) ? value : "-"} id={profileData?.id} patchMethod={"profile"} field={key} selectItems={playerPositions} />
            case "position2":
                return <ContentCell key={key} title="Position 2" content={(typeof value !== "object" && value) ? value : "-"} id={profileData?.id} patchMethod={"profile"} field={key} selectItems={playerPositions} />
            case "ageGroup":
                return <ContentCell key={key} title="Age Group" content={(typeof value !== "object" && value) ? value : "-"} id={profileData?.id} patchMethod={"profile"} field={key} selectItems={gameTeamAgeGroup} />
            case "strongestFoot":
                return <ContentCell key={key} title="Strongest Foot" content={(typeof value !== "object" && value) ? value : "-"} id={profileData?.id} patchMethod={"profile"} field={key} selectItems={strongFoot} />
            case "nationality":
                return <ContentCell key={key} title="Nationality" content={(typeof value !== "object" && value) ? value : "-"} id={profileData?.id} patchMethod={"profile"} field={key} selectItems={nationalities} />
            case "nationality2":
                return <ContentCell key={key} title="Nationality 2" content={(typeof value !== "object" && value) ? value : "-"} id={profileData?.id} patchMethod={"profile"} field={key} selectItems={nationalities} />
            case "birthday":
                return <ContentCell key={key} title="Date of Birth" date content={formatDate(typeof value === "string" ? value : null, true)} id={profileData?.id} patchMethod={"profile"} field={key} />
            case "introVideo":
                return <ContentCell key={key} title="Intro Video" content={(typeof value !== "object" && value) ? value : "-"} field={key} id={profileData?.id} patchMethod={"profile"} />
            case "lineUpVideo":
                return <ContentCell key={key} title="Line-up Video" content={(typeof value !== "object" && value) ? value : "-"} field={key} id={profileData?.id} patchMethod={"profile"} />
            case "id":
                return null
        }
    };
    return (
        <>
            {nationalities && <>
                <div className="detailCategory">Player User:</div>
                <div className="userDetails-content">
                    {userData ? Object.entries(userData).map(([key, value]) => {
                        const typedKey = key as keyof PartialPlayerUserResponse;
                        return renderUserContent(typedKey, value);
                    }) : "no user data avialble"}
                </div>
                <div className="categoryDivider" />
                <div className="detailCategory">Player User Profile:</div>
                <div className="userDetails-content">
                    {profileData ? Object.entries(profileData).map(([key, value]) => {
                        const typedKey = key as keyof PartialPlayerProfileResponse;
                        return renderProfileContent(typedKey, value);
                    }) : "no player profile data avialble"}
                </div>
            </>}
        </>
    )
}
