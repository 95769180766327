import { _, _any } from "i18n";
import { Continent, ContinentData, CountrySelection } from "./types";
import { CountryFlag, CountryCode } from "../flags";

interface ContinentContainerProps {
	continent: Continent;
	data?: ContinentData;
	selection: CountrySelection;
	onSelect: (continent: Continent, countries: string[]) => void;
	onDeselect: (continent: Continent, countries: string[]) => void;
	onSelectAll: (continent: Continent) => void;
	onDeselectAll: (continent: Continent) => void;
}

const ContinentContainer = ({ continent, data, selection, onSelect, onDeselect, onSelectAll, onDeselectAll }: ContinentContainerProps) => {
	const isAllCountrySelected = data && Object.values(data).filter((value) => value.enabled)?.length === selection[continent]?.length;

	return (
		<div className="countrylist-section">
			<div className="continent-container-new">
				<div className="countries-heading">
					<a
						onClick={() => {
							if (isAllCountrySelected) {
								onDeselectAll(continent);
							} else {
								onSelectAll(continent);
							}
						}}
					>
						<h3 className={isAllCountrySelected ? "selected" : ""}>{_any(`continents.${continent}`)}</h3>
					</a>
					<span> {isAllCountrySelected ? _("label.unselect_all_countries") : _("label.select_all_countries")} </span>
				</div>
				<ul>
					{data?.map(({ code, enabled, tooltip, name }) => {
						const isSelected = selection[continent]?.includes(code);
						return (
							<li key={code} className={`country-thumb${!enabled ? " country-thumb-disabled" : ""}`}>
								<a
									className={isSelected ? "active" : ""}
									onClick={() => {
										if (enabled) {
											if (isSelected) {
												onDeselect(continent, [code]);
											} else {
												onSelect(continent, [code]);
											}
										}
									}}
								>
									<CountryFlag code={code as CountryCode} />
									<span>{name}</span>
									{tooltip != null && (
										<div className={enabled ? "tooltip" : "tooltip tooltip-disabled"}>
											<div
												// rome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
												dangerouslySetInnerHTML={{
													__html: _any(`${tooltip}`),
												}}
											/>
										</div>
									)}
								</a>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

export default ContinentContainer;
