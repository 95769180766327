import Translator from "bazinga-translator";

window.Translator = Translator;

export const _ = (id: string, count: number | Record<string, string> = {}, params = {}): string => {
	if (Number.isInteger(count)) {
		return Translator.transChoice(id, count as number, params);
	}

	return Translator.trans(id, params);
};
