import { ReactElement } from "react";
import Logo from "assets/images/logo-eyeball.svg";

interface HeaderProps {
	logo: (logo: typeof Logo) => ReactElement;
	mainItems: ReactElement[];
	rightItems: ReactElement[];
}

export function Header({ mainItems, rightItems, logo }: HeaderProps) {
	return (
		<header className="header">
			<div className="inner">
				<div className="header__content">
					<div className="header__logo">{logo(Logo)}</div>
					<nav className="header__nav">
						<nav className="header__nav">{mainItems}</nav>
					</nav>
					<div className="header__right">{rightItems}</div>
				</div>
			</div>
		</header>
	);
}
