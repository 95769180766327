import { _ } from "i18n";
import { Form, Field } from "react-final-form";
import { continentCountries, SubscriptionProduct } from "../types";
import { SubscriptionManagementTableColumns, GameOptions } from "../types";
import { Continents } from "../types";
import { FC, useRef, useState } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { useToast } from "ui-kit";
import client from "libs/client";
import CreatableSelect from "react-select/creatable";

interface SubscriptionModalProps {
	onClose: () => void;
	onUpdateSubscription: (values: any) => void;
	row: SubscriptionManagementTableColumns | null;
	ownershipOptions?: {value?: number | undefined;label?: string | undefined;}[];
	countriesWithContinents: continentCountries[];
	subscriptionProducts?: { value: string; label: string }[];
}

const SubscriptionModal: FC<SubscriptionModalProps> = ({ onUpdateSubscription, row, ownershipOptions, countriesWithContinents, subscriptionProducts }) => {

	const existingGameOptions = useRef<string[]>([]);

	const gameOptionsNames: { [key: string]: string } = {
		[GameOptions.AI]: _("label.AI"),
		[GameOptions.STATISTICS_RATING]: _("label.statistics_rating"),
		[GameOptions.STATISTICS_PLAYERS]: _("label.statistics_player"),
		[GameOptions.STATISTICS_TEAMS]: _("label.statistics_team"),
		[GameOptions.EVENTS]: _("label.events"),
		[GameOptions.DOWNLOADABLE]: _("label.download"),
		[GameOptions.CLIPS]: _("label.clips"),
		[GameOptions.DOWNLOADABLE_GAMETAGS]: _("label.downloadable_gametags"),
		[GameOptions.PLAYER_STATS_SEARCH]: _("label.player_stats_search"),
	};

	const { addSuccessToast, addErrorToast } = useToast();

	if (row?.gameOptions) {
		Object.entries(row?.gameOptions).forEach((value) => {
			if (value[1]) {
				existingGameOptions.current.push(value[0]);
			}
		});
	}

	const formData = {
		id: row ? row.id : null,
		comment: row ? row.comment : null,
		product: row ? { value: row.product, label: row.product } : null,
		maxAccounts: row?.maxAccounts || 0,
		startDate: row ? row.startDate : null,
		expirationDate: row ? row.expirationDate : null,
		countries: row ? row.countries : null,
		gameOptions: row ? existingGameOptions.current : null,
		proClub: row ? {value: row.proClubId, label: row.proClubName} : {value: "", label: ""},
		ownership: row?.id ? (row.ownerships!.length > 0 ? row.ownerships : []) : (ownershipOptions && ownershipOptions.length > 0) ? [ownershipOptions[0]] : []
	};

	const SelectAdapter = ({ input, ...rest }: { [x: string]: any; input: any }) => {
		return <Select {...input} {...rest} />;
	};

	const AsyncSelectAdapter = ({ input, ...rest }: { [x: string]: any; input: any }) => {
		return <AsyncSelect {...input} {...rest} />;
	};

	const CreatableSelectAdapter = ({ input, ...rest }: { [x: string]: any; input: any }) => {
		return (
			<CreatableSelect
				value={input.value || null}
				onChange={(value) => input.onChange(value)}
				{...rest}
				className="react-select"
				classNamePrefix="react-select"
			/>
		);
	};

	const clubSearch = async (term: string)=> {
		if (term !== "") {
			const data = await client.clubProSearch.query({ search: term }).then((res) => { return res?.data });
			if (data.length > 0) {
				return data;
			}
		}
		return [];
	};

	const onFormSubmit = (values: any) => {
		const countries: { [key: string]: string | any } = {
			[Continents.AFRICA]: [],
			[Continents.ASIA]: [],
			[Continents.NORTH_AMERICA]: [],
			[Continents.SOUTH_AMERICA]: [],
			[Continents.OCEANIA]: [],
			[Continents.EUROPE]: [],
		};

		const gameOptions: { [key: string]: boolean } = {
			[GameOptions.AI]: false,
			[GameOptions.STATISTICS_RATING]: false,
			[GameOptions.STATISTICS_PLAYERS]: false,
			[GameOptions.STATISTICS_TEAMS]: false,
			[GameOptions.EVENTS]: false,
			[GameOptions.DOWNLOADABLE]: false,
			[GameOptions.CLIPS]: false,
			[GameOptions.DOWNLOADABLE_GAMETAGS]: false,
			[GameOptions.PLAYER_STATS_SEARCH]: false,
		};

		const formattedValues = { ...values, maxAccounts: values.maxAccounts ? parseInt(values.maxAccounts): 0 };

		if (values.product?.value) {
			formattedValues.product = values.product.value;
		} else {
			addErrorToast(_("message.select_product"));
			return;
		}

		const isNewsfeed = formattedValues.product === SubscriptionProduct.NEWSFEED;

		if (values.countries) {
			values.countries.forEach((value: { continent: string; value: string }) => {
				countries[value.continent].push(value.value);
			});

		}
		formattedValues.countries = countries;

		if (values.gameOptions) {
			values.gameOptions.forEach((value: string) => {
				gameOptions[value] = true;
			});

			formattedValues.gameOptions = gameOptions;
		}

		if (values.id === undefined) {
			delete formattedValues.id;
		}

		if (values.proClub?.value === undefined || values.proClub?.value === null) {
			addErrorToast(_("app.player.register.club.select"));
			return;
		} else {
			formattedValues.proClub = values?.proClub?.value;
		}

		if (!isNewsfeed && (values.countries === undefined || values.countries.length === 0)) {
			addErrorToast(_("message.select_country"));
			return;
		}

		if (!isNewsfeed && (values.gameOptions === undefined || values.gameOptions.length === 0)) {
			addErrorToast(_("message.select_game_options"));
			return;
		}

		onUpdateSubscription(formattedValues);
	};
	
	return (
		<div className="add-subscription">
			<Form
				keepDirtyOnReinitialize
				onSubmit={onFormSubmit}
				initialValues={formData}
				render={({ handleSubmit, submitting, values }) => {
					const isNewsfeed = values.product.value === SubscriptionProduct.NEWSFEED;
					return (
					<>
						<form onSubmit={handleSubmit}>
							<div className="form-row">
								<div className="field-group w-100">
									<label className="required"> {_("label.product")} </label>
									<Field name="product" component={CreatableSelectAdapter}
										   options={subscriptionProducts}/>
								</div>
								<div className="field-group">
									<label className="required"> {_("label.form.start_date")} </label>
									<Field name="startDate" type="date">
										{({input, meta}) => (
											<>
												<input {...input} maxLength={96} className="form-control"
													   required={true}/>
												{meta.error && meta.touched &&
													<span className="form-error">{meta.error}</span>}
											</>
										)}
									</Field>
								</div>
								<div className="field-group">
									<label className="required"> {_("label.form.expiration_date")} </label>
									<Field name="expirationDate" type="date">
										{({input, meta}) => (
											<>
												<input {...input} min={0} className="form-control" required={true}/>
												{meta.error && meta.touched &&
													<span className="form-error">{meta.error}</span>}
											</>
										)}
									</Field>
								</div>
								{ !isNewsfeed &&
									<>
										<div className="field-group w-100">
											<label className="required">{_("videos.table.th.country")}</label>
											<Field
												name="countries"
												className="react-select"
												component={SelectAdapter}
												isMulti={true}
												options={countriesWithContinents}
											/>
										</div>
										<div className="field-group">
											<label className="required">{_("label.max_accounts")}</label>
											<Field name="maxAccounts" type="number">
												{({input, meta}) => (
													<>
														<input {...input} min={0} className="form-control" required={values.product.value !== SubscriptionProduct.NEWSFEED}/>
														{meta.error && meta.touched &&
															<span className="form-error">{meta.error}</span>}
													</>
												)}
											</Field>
										</div>
									</>
								}
								<div className={`field-group ${isNewsfeed ? 'w-100' : ''}`}>
									<label className="required">{_("label.pro_club")}</label>
									<Field
										cacheOptions
										name="proClub"
										className="react-select"
										loadOptions={clubSearch}
										component={AsyncSelectAdapter}
										isClearable={true}
										placeholder={_("label.search")}
									/>
								</div>
								{ !isNewsfeed &&
									<div className="field-group w-100">
										<label>{_("label.ownership")}</label>
										<Field
											name="ownership"
											className="react-select"
											component={SelectAdapter}
											isMulti={true}
											options={ownershipOptions}
										/>
									</div>
								}
								<div className="field-group w-100">
									<label> {_("label.subscription.comment")} </label>
									<Field name="comment">
										{({input}) => (
											<div className="form-group">
												<textarea {...input} maxLength={256} className="form-control textarea"/>
											</div>
										)}
									</Field>
								</div>
								{ !isNewsfeed &&
									<div className="field-group w-100">
										<div className="game-option ">
											<label className="required">{_("label.game_options")}</label>
											<ul>
												{Object.entries(gameOptionsNames).map((value, index) => {
													return (
														<li key={index}>
															<Field
																name="gameOptions"
																component="input"
																type="checkbox"
																value={value[0]}
																className="custom-control-input"
																id={value[0]}
															/>
															<span>{value[1]}</span>
														</li>
													);
												})}
											</ul>
										</div>
									</div>
								}
							</div>

							<div className="form-footer text-center">
								<button className="btn btn-light-blue"> {_("btn.save")}</button>
							</div>
						</form>
					</>
				)}}
			/>
		</div>
	);
};

export default SubscriptionModal;
