import { _ } from "i18n";
import "./errorPage.css";
import notFoundImg from "assets/images/errors/404-new.png";
import { Link } from "react-router-dom";

export default function NotFoundPage() {
	return (
		<div className="errorPage-container">
			<div className="content">
				<img src={notFoundImg} className="notFoundImg" />
				<div className="error-title">{_("label.pageNotFound")}</div>
				<div className="error-text">{_("label.pageNotFound.text")}</div>
				<Link className="backToHomeButton" to="/">
					{_("label.button.backToHome")}
				</Link>
			</div>
		</div>
	);
}
