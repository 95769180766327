// @ts-ignore
import UserPlaceholder from "assets/images/user-placeholder.png";
import { _ } from "i18n";
import { MenuTrigger, Button, Popover, Menu, MenuItem, Section, Header } from "react-aria-components";
import "./accountOptions.scss";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

interface AccountOptionsProps {
	apiUrl?: string;
	navItems?: { label: string; link: string; icon?: ReactElement }[];
	subMenuOptions?: {
		subMenuName?: string;
		options: { label: string; id?: string; link: string; icon?: ReactElement }[];
	};
	user: { name: string; email: string; photo?: string | null };
	onLogout: () => void;
}

export default function AccountOptions({ apiUrl, navItems, subMenuOptions, user, onLogout }: AccountOptionsProps) {
	const navigate = useNavigate();
	return (
		<div className="accountOptions">
			<MenuTrigger>
				<Button className="accountOptions__button header-user-thumbnail" aria-label="Account options">
					<img src={user.photo ? (user.photo?.includes("http") ? user.photo : `${apiUrl}/${user.photo}`) : UserPlaceholder} alt="Profile picture Empty" loading="lazy" />
				</Button>
				<Popover className="accountOptions__popin active" placement="bottom end">
					<Menu
						className="accountOptions__bottom"
						onAction={(id) => {
							const option = navItems?.find((option) => option.label === id);
							const subOption = subMenuOptions?.options.find((subOption) => subOption.label === id);
							if (option) {
								navigate(option.link);
							} else if (id === "logoff") {
								onLogout();
							}
							if (subOption) {
								navigate(subOption.link, { state: { activeMenu:subOption?.id}});
							}
						}}
					>
						<Section>
							<Header className="accountMenu-header">
								<div>
									<h5>{user.name}</h5>
									<p>{user.email}</p>
								</div>
							</Header>
							{navItems?.map((option) => (
								<MenuItem className="accountMenu-option" key={`navItem-${option.label}`} id={option.label}>
									{option?.icon}
									<div>{option.label.toUpperCase()}</div>
								</MenuItem>
							))}
						</Section>

						{subMenuOptions && (
							<Section>
								<Header className="accountMenu-header">
									<div>
										<h5>{subMenuOptions.subMenuName?.toUpperCase()}</h5>
									</div>
								</Header>
								{subMenuOptions?.options.map((option) => (
									<MenuItem className="accountMenu-option" key={`subMenuItem-${option.label}`} id={option.label}>
										{option.icon}
										<div>{option.label.toUpperCase()}</div>
									</MenuItem>
								))}
							</Section>
						)}
						<MenuItem className="btn btn--white-blue-dark-border" id="logoff">
							{_("header.logout").toUpperCase()}
						</MenuItem>
					</Menu>
				</Popover>
			</MenuTrigger>
		</div>
	);
}
