import { Form, Field } from "react-final-form";
import { useActions, User } from "app/store";
import { _, _any } from "i18n";
import { useNavigate } from "react-router-dom";
import client from "libs/client";
import { toApiError } from "libs/apiClient";
import { useToast } from "ui-kit";

interface LoginFormData {
	email: string;
	password: string;
}

interface LoginFormProps {
	redirect?: string | null;
}

export default function LoginFrom({ redirect = null }: LoginFormProps) {
	const forgot_password_path = "/forgotten-password";
	const target_path = "";
	const { login } = useActions();
	const navigate = useNavigate();
	const { addErrorToast } = useToast();

	const onSubmit = async (values: LoginFormData) => {
		try {
			const data = await client.http
				.url("/admin/json_login")
				.post({
					email: values.email,
					password: values.password,
				})
				.fetchError((err, request) => {				
				})
				.json<User>();
			login(data);
			if (redirect) {
				navigate(redirect);
			} else {
				navigate("/");
			}
		} catch (err) {
			const e = toApiError(err);
			addErrorToast(_any(e.json.message));
		}
	};

	return (
		<Form
			onSubmit={onSubmit}
			render={({ handleSubmit }) => (
				<form className="form_validation" onSubmit={handleSubmit}>
					<div className="formfilled-row">
						<label htmlFor=""> {_("label.email_address")}</label>
						<Field name="email" component="input" type="text" className="grey-form-field" required />
					</div>
					<div className="formfilled-row">
						<label> {_("admin.login.password.placeholder")}</label>
						<Field name="password" component="input" type="password" className="grey-form-field" autoComplete="on" required />
					</div>
					<div className="forgot-password-container">
						<div>
							<Field name="_remember_me" component="input" type="checkbox" id="remember_me" />
							<label htmlFor="remember_me"> {_("admin.login.remember_me")}</label>
						</div>
						<div>
							<a href={forgot_password_path}> {_("admin.login.forgot_password")} </a>
						</div>
					</div>
					{/* <Field
            name="_csrf_token"
            component="input"
            type="hidden"
            value={csrf_token}
          /> */}
					<Field name="_target_path" component="input" type="hidden" value={target_path} />

					<div className="formfilled-row text-center">
						<button className="big-lightblue-btn"> {_("label.login_to_eyeball")}</button>
					</div>
				</form>
			)}
		/>
	);
}
