import { VideoUploadsSubheader } from "features/videoUploads/VideoUploadsSubheader";
import { Outlet } from "react-router-dom";
import "style/videoMetadataPage.css";

export function VideoUploadsLayoutPage() {
	return (
		<>
			<div className="videoMetadata-container">
				<VideoUploadsSubheader />
				<Outlet />
			</div>
		</>
	);
}
