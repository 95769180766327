import { Menu, MenuItem } from "react-aria-components";

import "./contextMenu.css";
import { useOnClickOutside } from "usehooks-ts";
import { useRef } from "react";

export interface ContextMenuPosition {
	x: number;
	y: number;
}

export interface ContextMenuItem {
	id: string;
	label: string;
	action: () => void;
}

interface ContextMenuProps {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	items: ContextMenuItem[];
	position: ContextMenuPosition;
}

export default function ContextMenu({ isOpen, setIsOpen, items, position }: ContextMenuProps) {
	const ref = useRef<HTMLInputElement>(null);

	useOnClickOutside(ref, () => {
		setIsOpen(false);
	});

	return (
		<div
			onClick={() => console.log("Left click detected!")}
			ref={ref}
			className={`context-menu ${isOpen ? "open" : ""}`}
			style={{ top: position.y, left: position.x }}
		>
			<Menu
				className="context-menu__menu"
				onAction={(id) => {
					const item = items.find((item) => item.id === id);
					item?.action();
					setIsOpen(false);
				}}
			>
				{items.map((item) => {
					return (
						<MenuItem className="context-menu__item" key={item.id} id={item.id}>
							<span className={`icon-new icon-25 icon icon-${item.id}`} /> {item.label}
						</MenuItem>
					);
				})}
			</Menu>
		</div>
	);
}
