//@ts-ignore
import loader from "assets-new/loaders/loader3.gif";
import "./iframeLoader.css";
import { _ } from "i18n";

export default function IFrameLoader() {
	return (
		<div className="iframe-loader">
			<div>
				<h5>{_("iframeLoader.text")}</h5>
				<img src={loader} />
			</div>
		</div>
	);
}
