import { redirect } from "react-router-dom";
import { getRedirect } from "app/redirects";

export function loader({ request }: { request: Request }) {
	const newUrl = getRedirect(request);
	if (newUrl) {
		return redirect(newUrl, 301);
	}

	return;
}

export default function RedirectPage() {
	return null;
}
