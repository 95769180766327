import type { PropsWithChildren } from "react";

interface VideoDetailProps {
	label: string;
	value: string;
}
export function VideoFact({ label, value }: VideoDetailProps) {
	return (
		<div className="VideometaDetails">
			<span>{label}</span>
			{value}
		</div>
	);
}

export function VideoDetails({ children }: PropsWithChildren) {
	return <div className="VideometaDetails-container">{children}</div>;
}
