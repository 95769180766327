import { MediaPlayer, MediaProvider, Poster } from "@vidstack/react";
import { defaultLayoutIcons, DefaultVideoLayout } from "@vidstack/react/player/layouts/default";

interface VidstackPlayerProps {
	baseURL?: string;
}

export function VidstackPlayer({ baseURL }: VidstackPlayerProps) {
	let videoUrl: URL | string = "";
	let thumbUrl: URL | string = "";
	let posterUrl: URL | string = "";

	if (baseURL) {
		videoUrl = new URL(`${baseURL}/stream/stream.m3u8`);
		thumbUrl = new URL(`${baseURL}/thumbs.vtt`);
		posterUrl = new URL(`${baseURL}/poster.webp`);
	}

	return (
		<div className="videoApp-playerContainer">
			<MediaPlayer src={videoUrl?.toString()}>
				<MediaProvider>
					<Poster className="vds-poster" src={posterUrl?.toString()} />
				</MediaProvider>
				<DefaultVideoLayout thumbnails={thumbUrl?.toString()} icons={defaultLayoutIcons} />
			</MediaPlayer>
		</div>
	);
}
