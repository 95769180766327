import type { ReactNode } from "react";
import { Button, type ButtonProps, Dialog, DialogTrigger, OverlayArrow, Popover, type PopoverProps } from "react-aria-components";
import "./DialogPopover.css";

export interface DialogPopoverProps {
	triggerSlot: ReactNode;
	content: ReactNode;
	icon?: ReactNode;
	popoverOptions?: Omit<PopoverProps, "isOpen">;
	buttonOptions?: Omit<ButtonProps, "onPress">;
}

export function DialogPopover({ triggerSlot, content, icon, popoverOptions, buttonOptions }: DialogPopoverProps) {
	return (
		<DialogTrigger>
			<Button {...buttonOptions}>{triggerSlot}</Button>
			<Popover {...popoverOptions}>
				{icon ? <OverlayArrow>{icon}</OverlayArrow> : null}
				<Dialog>{content}</Dialog>
			</Popover>
		</DialogTrigger>
	);
}
