import { useRightClickMenuStore } from "app/rightClickMenuStore";
import { useI18n } from "features/translation/context";
import { useEffect, useState } from "react";
import ContextMenu, { ContextMenuItem } from "ui-kit/src/ContextMenu";
import { useCopyToClipboard } from "usehooks-ts";

function downloadFile(url: string, fileName: string) {
	fetch(url, { method: "get", mode: "no-cors", referrerPolicy: "no-referrer" })
		.then((res) => res.blob())
		.then((res) => {
			const aElement = document.createElement("a");
			aElement.setAttribute("download", fileName);
			const href = URL.createObjectURL(res);
			aElement.href = href;
			aElement.setAttribute("target", "_blank");
			aElement.click();
			URL.revokeObjectURL(href);
		});
}

async function saveFile(url: string) {
	fetch(url, { method: "get", mode: "no-cors", referrerPolicy: "no-referrer" })
		.then((res) => res.blob())
		.then(async (blob) => {
			//@ts-ignore
			if (window.showSaveFilePicker) {
				const opts = {
					types: [
						{
							description: "MYfile",
							accept: { "text/html": [".html"] },
						},
					],
					suggestedName: "Eyeball Portal",
				};
				//@ts-ignore
				const handle = await window.showSaveFilePicker(opts);
				const writable = await handle.createWritable();
				await writable.write(blob);
				writable.close();
			} else {
				const aElement = document.createElement("a");
				aElement.setAttribute("download", "Eyeball Portal.html");
				const href = URL.createObjectURL(blob);
				aElement.href = href;
				aElement.setAttribute("target", "_blank");
				aElement.click();
				URL.revokeObjectURL(href);
			}
		});
}

export default function RightClickMenu() {
	const [isOpen, setIsOpen] = useState(true);
	const {
		url,
		position,
		actions: { closeRightClickMenu },
	} = useRightClickMenuStore();
	const { _ } = useI18n();
	const [_value, copy] = useCopyToClipboard();

	useEffect(() => {
		setIsOpen(true);
	}, [position]);

	const items: ContextMenuItem[] = [
		{
			id: "new-tab",
			label: _("contextMenu.openInNewTab"),
			action: () => {
				window.open(url, "_blank");
				closeRightClickMenu();
			},
		},
		// {
		// 	id: "new-window",
		// 	label: _("contextMenu.openInNewWindow"),
		// 	action: () => {
		// 		window.open(url, "_blank", "noopener,popup");
		// 		closeRightClickMenu();
		// 	},
		// },
		{
			id: "copy-link",
			label: _("contextMenu.copyLink"),
			action: () => {
				if (url) {
					copy(url);
				}
				closeRightClickMenu();
			},
		},
		// {
		// 	id: "save-as",
		// 	label: _("contextMenu.saveAs"),
		// 	action: () => {
		// 		if (url) {
		// 			saveFile(url);
		// 		}
		// 		closeRightClickMenu();
		// 	},
		// },

		//cant be done in js
		// {
		// 	id: "new-window",
		// 	label: "open in new window",
		// 	action: () => {
		// 		window.open(url, '_blank')
		// 	},
		// },
	];

	if (navigator.share) {
		items.push({
			id: "share",
			label: _("contextMenu.share"),
			action: () => {
				if (url) {
					//copy(url);
					navigator.share({
						url,
					});
				}
			},
		});
	}

	return <ContextMenu isOpen={isOpen} setIsOpen={setIsOpen} items={items} position={position} />;
}
