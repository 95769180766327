import SkeletonBox from "ui-kit/src/Skeleton/SkeletonBox";
import "./squadsPageSkeleton.scss"

export default function SquadTeamsSkeleton() {
	const teams = [];
	for (let i = 0; i < 10; i++) {
		teams.push(<SkeletonBox key={`row${i}`} className="squadTeams-team" />);
	}

	return <div className="squadTeams-container">{teams}</div>;
}
