import { useIframeStore } from "app/iframeStore";
import { useI18n } from "features/translation/context";
import { useCountriesStore } from "app/store";
import type { UserCountrySelection } from "ui-kit";
import type { LoaderFunctionArgs } from "react-router-dom";
import IFrameLoader from "ui-kit/src/IFrameLoader";
import { useConstants } from "features/constantsProvider/context";

interface IframeProps {
	url: string;
}

function getSelectionKey(selection: UserCountrySelection): string {
	return Object.entries(selection.countries)
		.flatMap((continent) => {
			return continent[1];
		})
		.join("-");
}

export const iframeLoader = ({ request }: LoaderFunctionArgs) => {
	if (request.url !== window.location.toString()) {
		useIframeStore.getState().actions.startIframeLoading();
	}
	return null;
};

export default function Iframe({ url }: IframeProps) {
	const i18n = useI18n();
	const { loading } = useIframeStore();
	const { selection } = useCountriesStore();

	const { apiURL } = useConstants();

	const frameUri = `${apiURL}${`/${url}`.replaceAll("//", "/")}`;
	const frameUrl = new URL(frameUri);
	frameUrl.searchParams.set("_locale", i18n.locale);

	const key = frameUrl.toString() + getSelectionKey(selection);

	return (
		<>
			<iframe
				key={key}
				src={frameUrl.toString()}
				className={frameUrl.toString().includes("my-eyeball") ? "my-account" : ""}
				width="100%"
				style={{ height: "100vh", visibility: loading ? "hidden" : "visible" }}
				allowFullScreen
				allow="clipboard-read; clipboard-write"
			/>
			{loading ? <IFrameLoader /> : null}
		</>
	);
}
