import { FC } from "react";
import { MigrationType, NonPartnerPlayerData } from "../../types";
import clubPlaceholder from "assets/images/logo_fixtures.png";
import user from "assets/images/user-placeholder.png";
import Icon from "components/Icon";
import { Menu } from "@headlessui/react";
import { CountryCode, CountryFlag } from "ui-kit/src/flags";
import {_ } from "i18n";

interface nonPartnerPlayerCardProps {
	player: NonPartnerPlayerData;
	countriesList: { [key: string]: string };
	onDeleteActionClick: (player: NonPartnerPlayerData) => void;
	onEditActionClick: (player: NonPartnerPlayerData) => void;
	onMigrateActionClick: (player: NonPartnerPlayerData) => void;
}

const nonPartnerPlayerCard: FC<nonPartnerPlayerCardProps> = ({ player, countriesList, onDeleteActionClick, onEditActionClick, onMigrateActionClick }) => {
	const publicUrl = new URL(`public`, import.meta.url);

	return (
		<>
			<div className="club-card">
				<div className="card-row1">
					<div className="card-logo club-players">
						<img src={player.picture ? (player.picture.includes("http") ? player.picture : `${publicUrl}/${player.picture}`) : user} />
					</div>
					<div className="card-details">
						<h6>
							{player.firstName} {player.lastName}
						</h6>
						{player.npClubCountry && (
							<div className="playerCardBlock__geo">
								<CountryFlag code={player.npClubCountry as CountryCode} />
								<span>{countriesList[player.npClubCountry]}</span>
							</div>
						)}
					</div>
				</div>
				<div className="playerCardBlock__infoClub">
					<div className="playerCardBlock__clubLogo">
						<div className="playerCardBlock__clubLogo-cropper">
							<img
								src={
									player.npClubLogo
										? player.npClubLogo.includes("http")
											? player.npClubLogo
											: `${publicUrl}/${player.npClubLogo}`
										: clubPlaceholder
								}
							/>
						</div>
					</div>
					<div className="">
						<div className="playerCardBlock__clubName">{player.npClubName}</div>
						<div className="playerCardBlock__geo">{countriesList[player.npClubCountry as keyof {}]}</div>
					</div>
				</div>
				<div className="card-row2">
					<button
						className="link"
						onClick={() => {
							onEditActionClick(player);
						}}
					>
						<Icon icon="crayon" size={12} color="dark-grey"/>
						{_("permission.edit")}
					</button>
					<button
						className="link"
						onClick={() => {
							onDeleteActionClick(player);
						}}
					>
						<Icon icon="trash-new" size={16}/> {_("permission.delete")}
					</button>
					<button
						className="link"
						onClick={() => {
							onMigrateActionClick(player);
						}}
					>
						<Icon icon="migrate-new" /> {_("label.migrate")}
					</button>
				</div>
			</div>
		</>
	);
};

export default nonPartnerPlayerCard;
