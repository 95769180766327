import { create, type StateCreator } from "zustand";
import { devtools /*persist*/ } from "zustand/middleware";
import { type CountryData, type CountrySelection, emptySelection, type UserCountrySelection } from "ui-kit";
export interface User {
	[x: string]: unknown;
}

interface AuthState {
	user: User;
}

interface Actions {
	login: (user: User) => void;
	setCountryData: (countries: CountryData) => void;
	setSelectedCountries: (countries: UserCountrySelection) => void;
	updateSelectedCountries: (countries: CountrySelection) => void;
	openCountrySelect: () => void;
	closeCountrySelect: () => void;
}

interface State {
	auth: AuthState;
	countries: {
		countryData: CountryData;
		selection: UserCountrySelection;
		showModal: boolean;
	};

	actions: Actions;
}

type Store = StateCreator<State>;

const store: Store = (set) => ({
	auth: {
		user: null as unknown as User,
	},
	countries: {
		countryData: {} as CountryData,
		selection: {
			countries: emptySelection,
			count: 0,
			showModal: false,
		},
		showModal: false,
	},
	actions: {
		login: (user) => {
			set((state) => {
				return { auth: { ...state.auth, user } };
			});
		},
		setCountryData: (countryData: CountryData) => {
			set((state) => {
				return { countries: { ...state.countries, countryData } };
			});
		},
		setSelectedCountries: (selection: UserCountrySelection) => {
			set((state) => {
				return { countries: { ...state.countries, selection } };
			});
		},
		updateSelectedCountries: (newSelection: CountrySelection) => {
			set((state) => {
				const updatedSelection = {
					...state.countries.selection,
					countries: newSelection,
				};
				return { countries: { ...state.countries, selection: updatedSelection } };
			});
		},
		openCountrySelect: () => {
			set((state) => {
				return { countries: { ...state.countries, showModal: true } };
			});
		},
		closeCountrySelect: () => {
			set((state) => {
				return { countries: { ...state.countries, showModal: false } };
			});
		},
	},
});

export const useStore = create<State>()(
	devtools(
		// persist(store, {
		// 	name: "admin-storage",
		// }),
		store,
		{
			enabled: import.meta.env.DEV,
			name: "main"
		},
	),
);

export const useAuthStore = () => useStore((state) => state.auth);
export const useCountriesStore = () => useStore((state) => state.countries);

export function useIsUserCM() {
	const auth = useAuthStore();

	console.log({ auth });
}

export const useActions = () =>
	useStore((state) => {
		return state.actions;
	});
