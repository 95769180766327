import { _ } from "i18n";
import { Form, Field } from "react-final-form";
import { ClubType, Status, type NonPartnerPlayerData } from "../../types";
import { useDropzone } from "react-dropzone";
import { type FC, type MutableRefObject, useCallback, useState } from "react";
import { APIResponse } from "types";
import userPlaceholder from "assets/images/user-placeholder.png";
import Icon from "components/Icon";
import Select from "react-select";
import client from "libs/client";
import { useToast } from "ui-kit";
import { getBase64 } from "../../functions";

interface PlayerModalProps {
	onClose: () => void;
	onUpdatePlayer: (values: any) => Promise<void>;
	player: NonPartnerPlayerData | null;
	isSubmitted: MutableRefObject<boolean>;
	clubs: {
		value: string;
		label: string;
	}[];
	countriesList: { [key: string]: string };
	nationality: {
		value: string;
		label: string;
	}[];
	position: {
		value: string;
		label: string;
	}[];
}

const PlayerModal: FC<PlayerModalProps> = ({ onClose, onUpdatePlayer, player, clubs, countriesList, nationality, position, isSubmitted }) => {
	const [playerPicture, SetPlayerPicture] = useState(player?.picture ? player.picture : "");
	const publicUrl = new URL(`public`, import.meta.url);

	const { addSuccessToast, addErrorToast } = useToast();

	const strongestFoot = [
		{ value: "left", label: _("label.player.strongest_foot.left") },
		{ value: "right", label: _("label.player.strongest_foot.right") },
	];

	const formData = {
		id: player ? player.id : null,
		firstName: player ? player.firstName : null,
		playedInNationalTeam: player ? player.playedInNationalTeam : null,
		lastName: player ? player.lastName : null,
		clubNonpartnerId: player ? (player?.clubNonpartnerId ? { value: player?.clubNonpartnerId, label: player?.npClubName } : null) : null,
		nationality: player
			? player?.nationality
				? { value: player?.nationality, label: countriesList[player?.nationality as keyof {}] }
				: null
			: null,
		nationality2: player
			? player?.nationality2
				? { value: player?.nationality2, label: countriesList[player?.nationality2 as keyof {}] }
				: null
			: null,
		position: player
			? player?.position
				? { value: player?.position, label: position.find((element) => element.value == player?.position)?.label }
				: null
			: null,
		strongestFoot: player
			? player?.strongestFoot
				? { value: player?.strongestFoot, label: strongestFoot.find((element) => element.value == player?.strongestFoot)?.label }
				: null
			: null,
		birthday: player ? player.birthday : null,
		tranLink: player ? player.tranLink : null,
		federationLink: player ? player.federationLink : null,
	};

	const onFormSubmit = (values: any) => {
		let data: any = {};
		data.id = values.id;
		data.firstName = values.firstName;
		data.lastName = values.lastName;
		data.playedInNationalTeam = values.playedInNationalTeam;
		if (playerPicture !== "") {
			data.picture = playerPicture;
		}

		if (values.nationality) {
			data.nationality = values.nationality.value;
		}

		if (values.nationality2) {
			data.nationality2 = values.nationality2.value;
		}

		if (values.birthday) {
			data.birthday = values.birthday;
		}

		if (values.position) {
			data.position = values.position.value;
		}

		if (values.strongestFoot) {
			data.strongestFoot = values.strongestFoot.value;
		}

		if (values.tranLink) {
			data.tranLink = values.tranLink;
		}

		if (values.federationLink) {
			data.federationLink = values.federationLink;
		}

		if (values.clubNonpartnerId) {
			data.clubNonpartnerId = values.clubNonpartnerId.value;
		} else {
			addErrorToast("Please Select Club");
			return;
		}
		onUpdatePlayer(data);
	};

	const { data: lastUpdates } = client.getPlayerLatestLogEntries.useQuery(
		{
			id: player?.id!,
			type: ClubType.NON_PARTNER,
		},
		{
			enabled: !!player?.id,
		},
	);

	const onDrop = useCallback(async (acceptedFiles: File[]) => {
		const id = player ? player.id : null;
		const file = await getBase64(acceptedFiles[0]) as string;

		const response = await client.uploadNPPlayerPicture.mutation({ body: { id, file } });
		if (response?.data.status === APIResponse.SUCCESS) {
			if (response?.data.message) { addSuccessToast(response?.data.message) };
			if(response?.data.url) { SetPlayerPicture(response.data.url); }
		} else {
			if (response?.data.message) { addErrorToast(response?.data.message) };
		}
	}, []);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: { "image/jpeg": [".jpg", ".jpeg"], "image/png": [".png"] },
		multiple: false,
		maxFiles: 1,
	});

	const SelectAdapter = ({ input, ...rest }: { [x: string]: any; input: any }) => {
		return <Select {...input} {...rest} />;
	};

	return (
		<>
			<div className="modal open">
				<span className="modal__backdrop"></span>
				<div className="modal__container modal__container--sm">
					<span className="modal__close" onClick={() => onClose()}>
						<Icon icon="cross" />
					</span>
					<div className="modal__title">{_("club.non_partner_player")}</div>
					<div className="modal__body">
						<div className="admin-creation-container">
							<div className="admin-creation-form">
								<>
								<div className="user-row">
									<div className="admin-user-pic">
										<img
											src={
												playerPicture !== ""
													? playerPicture.includes("http")
														? playerPicture
														: `${publicUrl}/${playerPicture}`
													: userPlaceholder
											}
										/>
										<label className="tableCellControls__button tableCellControls__button--svg">
											<Icon icon="crayon" color="white" />
											<input
												id="player_team_photoFile"
												name="player_team[photoFile]"
												className="js-input-file js-disable-tab custom-file-input"
												{...getInputProps()}
											/>
										</label>
									</div>
								</div>
									{player && (
										<div className="text-center form-field-info">
											{`${_("player.info.last.updated")} ${lastUpdates?.data.photo ?? Status.NOT_AVAILABLE}`}
										</div>
									)}
								</>
								<Form
									onSubmit={onFormSubmit}
									initialValues={formData}
									render={({ handleSubmit, submitting, values }) => (
										<>
											<form onSubmit={handleSubmit}>
												<div className="form-row">
													<div className="field-group w-100">
														<label
															className="required"> {_("label.user.first_name")} </label>
														<Field name="firstName" type="text">
															{({input, meta}) => (
																<div className="form-group">
																	<input {...input} className="form-control"
																		   required={true}/>
																	{meta.error && meta.touched && <span
																		className="form-error">{meta.error}</span>}
																</div>
															)}
														</Field>
													</div>

													<div className="field-group w-100">
														<label
															className="required"> {_("label.user.last_name")} </label>
														<Field name="lastName" type="text">
															{({input, meta}) => (
																<div className="form-group">
																	<input {...input} className="form-control"
																		   required={true}/>
																	{meta.error && meta.touched && <span
																		className="form-error">{meta.error}</span>}
																</div>
															)}
														</Field>
													</div>

													<div className="field-group w-100">
														<label
															className="required"> {_("videos.table.th.club")} </label>
														<Field
															name="clubNonpartnerId"
															className="react-select"
															component={SelectAdapter}
															options={clubs}
															required="true"
														/>
													</div>

													<div className="field-group w-100">
														<label> {_("label.player.birthday")} </label>
														<Field name="birthday" className="react-select" type="date"
															   placeholder="-">
															{({input, meta}) => (
																<div>
																	<input {...input} className="form-control"/>
																</div>
															)}
														</Field>
													</div>

													<div className="field-group w-100">
														<label> {_("label.player.nationality")} </label>
														<Field
															name="nationality"
															className="react-select"
															component={SelectAdapter}
															isClearable
															options={[
																...nationality.filter(
																	(nationalities) => nationalities.value !== values.nationality2?.value
																),
															]}
														/>
													</div>

													<div className="field-group w-100">
														<label> {_("label.player.nationality2")} </label>
														<Field
															name="nationality2"
															className="react-select"
															component={SelectAdapter}
															isClearable
															options={[
																...nationality.filter(
																	(nationalities) => nationalities.value !== values.nationality?.value
																),
															]}
														/>
													</div>

													<div className="field-group w-100">
														<label> {_("label.player.position")} </label>
														<Field
															name="position"
															className="react-select"
															component={SelectAdapter}
															options={position}
														/>
													</div>

													<div className="field-group w-100">
														<label> {_("label.player.strongest_foot")} </label>
														<Field
															name="strongestFoot"
															className="react-select"
															component={SelectAdapter}
															options={strongestFoot}
														/>
													</div>

													<div className="field-group-checkbox w-100">
														<Field name="playedInNationalTeam" component="input"
															   type="checkbox"/>
														<label>{_("player.playedInNationalTeam")}</label>
													</div>

													<div className="field-group w-100">
														<label> {_("label.user.tran_link")} </label>
														<Field name="tranLink" type="text">
															{({input, meta}) => (
																<div className="form-group">
																	<input {...input} className="form-control"
																		   required={false}/>
																	{meta.error && meta.touched && <span
																		className="form-error">{meta.error}</span>}
																</div>
															)}
														</Field>
													</div>

													<div className="field-group w-100">
														<label> {_("label.user.federation_link")} </label>
														<Field name="federationLink" type="text">
															{({input, meta}) => (
																<div className="form-group">
																	<input {...input} className="form-control"
																		   required={false}/>
																	{meta.error && meta.touched && <span
																		className="form-error">{meta.error}</span>}
																</div>
															)}
														</Field>
													</div>
												</div>

												<div className="form-footer">
													<button type="submit"
															className={`btn btn-light-blue ${isSubmitted.current ? "disabled" : ""}`}
															disabled={isSubmitted.current}>
														{_("btn.save")}
													</button>
												</div>
											</form>
										</>
									)}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PlayerModal;
