import client from "libs/client";
import { useParams, useSearchParams } from "react-router-dom";
import NotFoundPage from "./errorPages/NotFoundPage";
import Loader from "components/Loader";
import { useToast } from "ui-kit";
import { _ } from "i18n";
import apiURL from "libs/apiUrl";
import "../style/userPage.css";

type Tab = "sessions" | "signins";

export function UserPage() {
	const { addSuccessToast, addErrorToast } = useToast();

	const params = useParams() as { id: string };
	const id = Number.parseInt(params.id);

	const [searchParams, setSearchParams] = useSearchParams();
	const tab = (searchParams.get("tab") || "sessions") as Tab;

	const { data, isFetching } = client.getUserDetail.useQuery({ id }, {});

	const user = data?.data;

	const { data: sessions, refetch: refetchSessions } = client.getUserSessions.useQuery(
		{ id },
		{
			enabled: tab === "sessions",
		},
	);

	const {
		data: signins,
		refetch: refetchSignins,
		isFetching: isFetchingSignIns,
	} = client.getUserSignIns.useQuery(
		{ id },
		{
			enabled: false,
			cacheTime: 300 * 1000,
		},
	);

	const revokeSessionsMutation = client.revokeSessions.useMutation({
		onSuccess(data, variables, context) {
			addSuccessToast(
				_("revokeSessions.success", {
					sessionsDeleted: data?.data.sessionsDeleted?.length,
				}),
			);
		},
		onError: () => {
			addErrorToast(_("revokeSessions.fail"));
		},
		onSettled: () => {
			refetchSessions();
		},
	});

	const revokeSessions = () => {
		if (confirm(_("revokeSessions.confirmation"))) {
			revokeSessionsMutation.mutate({ id });
		}
	};

	const changeTab = (tab: Tab) => {
		setSearchParams({
			tab,
		});
	};

	if (isFetching) {
		return <Loader />;
	}

	if (!user) {
		return <NotFoundPage />;
	}

	return (
		<div className="inner revoke-access-container">
			<div className="revoke-user">
				{user.photo ? (
					<div className="user-profilepic">
						<img src={user.photo?.includes("http") ? user.photo : `${apiURL}/${user.photo}`} />
					</div>
				) : null}

				<div className="revokeuser-name">
					{user.firstName} {user.lastName}{" "}
				</div>

				{/* <div className="revoke-user-country">
					{" "}
					<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANBAMAAABbflNtAAAAD1BMVEX///8AI5XtKTlUa7jzcHuHLUnIAAAAFUlEQVQI12MQFBQ0YGBgUVJSGgxMAONKCmr3ili7AAAAAElFTkSuQmCC" />
					France - Occitanie
				</div> */}

				<ul className="revok-tab">
					<li className={tab === "sessions" ? "active" : ""} onClick={changeTab.bind(null, "sessions")}>
						Sessions
					</li>
					<li className={tab === "signins" ? "active" : ""} onClick={changeTab.bind(null, "signins")}>
						Sign ins
					</li>
				</ul>

				{/* -------------Tab 1 start---------------- */}

				{tab === "sessions" ? (
					<div className="tab1">
						<button className="revoke-btn btn" onClick={revokeSessions} disabled={revokeSessionsMutation.isLoading}>
							{revokeSessionsMutation.isLoading ? "Revoking..." : "Revoke sessions"}
						</button>

						<table>
							<thead>
								<tr>
									<th>id</th>
									<th>created at</th>
								</tr>
							</thead>
							<tbody>
								{sessions?.data.map((session) => {
									return (
										<tr key={session.sessionId}>
											<td>{session.sessionId}</td>
											<td>{session.createdAt ? new Date(session.createdAt).toLocaleString() : ""}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				) : null}

				{tab === "signins" ? (
					<div className="tab2">
						<button className="revoke-btn btn" onClick={() => refetchSignins()} disabled={isFetchingSignIns}>
							{isFetchingSignIns ? "Loading..." : "Load sign ins"}
						</button>

						<table>
							<thead>
								<tr>
									<th>location</th>
									<th>device</th>
									<th>created at</th>
								</tr>
							</thead>
							<tbody>
								{(signins?.data ?? []).map((signin) => {
									return (
										<tr key={signin.id}>
											<td>
												{signin.location?.city}, {signin.location?.state}, {signin.location?.countryOrRegion}
											</td>
											<td>
												{signin.device?.browser}, {signin.device?.operatingSystem}
											</td>
											<td>{signin.createdDateTime ? new Date(signin.createdDateTime).toLocaleString() : ""}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				) : null}
			</div>
		</div>
	);
}
