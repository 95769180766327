import { CircularProgressBar } from "components/CircularProgressBar";
import Icon from "components/Icon";
import { _ } from "i18n";
import { NonPartnerMigrationPlayer } from "pages/productManagement/types";
import { FC } from "react";
import PlayerMigrationItem from "./PlayerMigrationItem";
import { GroupBase } from "react-select";

interface PlayersMigrationModalProps {
	player: NonPartnerMigrationPlayer;
	setSourcePlayers: React.Dispatch<React.SetStateAction<NonPartnerMigrationPlayer[]>>;
	migrationType: string;
	teamList: ({ value: string; label: string } | GroupBase<{ value: string; label: string }>)[];
	index: number;
	countriesList: { [key: string]: string };
}

const PlayersMigrationModal: FC<PlayersMigrationModalProps> = ({ countriesList, index, player, migrationType, teamList, setSourcePlayers }) => {
	return (
		<>
			<PlayerMigrationItem
				migrationType={migrationType}
				teamList={teamList}
				player={player}
				index={index}
				setSourcePlayers={setSourcePlayers}
				countriesList={countriesList}
			/>
			{player.percentage && player.percentage !== 100 && (
				<div className="migration-status" aria-label=" ">
					<CircularProgressBar strokeWidth={5} percentage={player.percentage} squareSize={50} />
				</div>
			)}
			{player.percentage === 100 && player.status === "skipped" && (
				<div className="migration-status tooltip left" aria-label={_("label.migration.player_skipped")}>
					<Icon icon="migrate-skip-new" size={50} color="danger" className="migration-status" />
				</div>
			)}
			{player.percentage === 100 && player.status === "merged" && (
				<div className="migration-status tooltip left" aria-label={_("label.migration.player_skipped")}>
					<Icon icon="migrate-merge-new" size={50} color="orange" className="migration-status" />
				</div>
			)}
			{player.percentage === 100 && player.status === "retained" && (
				<div className="migration-status tooltip left" aria-label={_("label.migration.player_skipped")}>
					<Icon icon="tick-new" size={50} color="success" className="migration-status" />
				</div>
			)}
			{player.percentage === 100 && player.status === "migrated" && (
				<div className="migration-status">
					<Icon icon="migrate-success" className="migration-status" />
				</div>
			)}
		</>
	);
};
export default PlayersMigrationModal;
