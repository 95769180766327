import { Fragment, useMemo, useState, VFC } from "react";
import { useTable, useSortBy, TableOptions, Column, useFilters, useGlobalFilter, usePagination, useExpanded } from "react-table";
import { CellRenderer, HeaderRenderer } from ".";
import { FilterRenderers, HeaderRenderers, Renderers, TableSortableColumns } from "./types";
import { _ } from "../../translator";
import Loader from "../Loader";
import Icon from "../../components/Icon";
import { Form, Field } from "react-final-form";
import { handlePageCount } from "./functions";
import NoDataFound from "assets/images/no-data-found.png";

interface DatagridProps<T extends object> {
	columns: {
		[K in keyof T]: string;
	};
	data: T[];
	cellRenderers?: Renderers<T>;
	headerRenderers?: HeaderRenderers<T>;
	filterRenderers?: FilterRenderers<T>;
	hiddenColumns?: string[];
	className?: string;
	override: boolean;
	headerClassName?: string;
	onRowClick?: (row: T, index: number) => void;
	preselectedRow?: number;
	defaultRenderers?: { Header: HeaderRenderer<T>; Cell: CellRenderer<T> };
	onPaginate?: (pageSize: number, nextPageIndex: number) => void;
	totalRecords?: number;
	disableSoryBy?: boolean;
	sortableColumns?: string[];
	lengthFilterEnabled?: boolean;
	manualPaginationEnabled?: boolean;
	showLoader?: boolean;
	searchEnabled?: boolean;
	batchUpdateEnabled?: boolean;
	onSearch?: (values: any) => void;
	onBatchUpdate?: () => void;
	defaultLength?: number;
	renderRowSubComponent?: (row: any) => JSX.Element;
}

function makeDefaultRenderers<T extends object>(): { Header: HeaderRenderer<T>; Cell: CellRenderer<T> } {
	return {
		Header: ({ column }, headerName) => {
			return (
				<>
					{headerName}
					<span>
						{column.isSorted ? column.isSortedDesc ? <Icon icon="icon-zoom-arrow-top" /> : <Icon icon="icon-zoom-arrow-bottom" /> : ""}
					</span>
					{/* @ts-ignore */}
					<span className="tooltiptext">{column.tooltip}</span>
				</>
			);
		},
		Cell: ({ cell }) => (cell.value === 0 ? <span className="zero-value">{cell.value}</span> : cell.value),
	};
}

export const Datagrid = <T extends Record<string, any>>({
	columns: columnsDefintion,
	data,
	className,
	override,
	cellRenderers,
	headerRenderers,
	filterRenderers,
	hiddenColumns = [],
	defaultRenderers = makeDefaultRenderers<T>(),
	onPaginate,
	totalRecords,
	disableSoryBy,
	sortableColumns = [],
	lengthFilterEnabled,
	manualPaginationEnabled,
	showLoader,
	searchEnabled,
	batchUpdateEnabled,
	onSearch,
	onBatchUpdate,
	defaultLength,
	renderRowSubComponent,
}: DatagridProps<T>): ReturnType<VFC<DatagridProps<T>>> => {
	let columns: TableOptions<T>["columns"];
	const [currentPage, setCurrentPage] = useState<number>(0);
	const [currentPageSize, setCurrentPageSize] = useState<number>(defaultLength ? defaultLength : 10);

	columns = useMemo(
		() =>
			Object.entries(columnsDefintion).map(([accessor, Header]) => {
				return { accessor, Header };
			}),
		[columnsDefintion]
	);

	if (override) {
		columns = useMemo(
			() =>
				Object.entries(columnsDefintion).map(([accessor, headerName]) => {
					return {
						accessor,
						Header: (props) => {
							let renderer = undefined;
							if (headerRenderers) {
								renderer = headerRenderers[accessor];
							}

							return renderer !== undefined ? renderer(props, headerName) : defaultRenderers.Header(props, headerName);
						},

						sortDescFirst: true,

						Cell: (props: any) => {
							let renderer = undefined;
							if (cellRenderers) {
								renderer = cellRenderers[accessor];
							}

							return renderer !== undefined ? renderer(props) : defaultRenderers.Cell(props);
						},

						tooltip: _(`game.stats.table.${accessor}`),
						Filter: () => {
							let renderer = undefined;
							if (filterRenderers) {
								renderer = filterRenderers[accessor];
							}
							return renderer;
						},
						disableSortBy: sortableColumns.includes(TableSortableColumns.SORT_ALL_COLUMNS)
							? false
							: sortableColumns.includes(accessor)
							? false
							: true,
					} as Column<T>;
				}),
			[columnsDefintion]
		);
	}

	const tableInstance = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: hiddenColumns,
				pageIndex: currentPage,
				pageSize: currentPageSize,
			},
			manualPagination: true,
			pageCount: totalRecords && handlePageCount(totalRecords, currentPageSize),
			disableSortBy: disableSoryBy,
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		useExpanded,
		usePagination
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		footerGroups,
		page,
		prepareRow,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		visibleColumns,
		state: { expanded, pageIndex, pageSize },
	} = tableInstance;

	const onSearchSubmit = (values: any) => {
		onSearch!(values);
	};

	return (
		<>
			{(lengthFilterEnabled || searchEnabled) && (
				<div className="datatable-top">
					<div>
						<select
							value={pageSize}
							onChange={(e) => {
								setPageSize(Number(e.target.value));
								setCurrentPageSize(Number(e.target.value));
								onPaginate!(Number(e.target.value), 0);
							}}
						>
							{[10, 20, 30, 40, 50].map((length) => (
								<option key={length} value={length}>
									{_("label.pagination.show")} {length}
								</option>
							))}
						</select>
					</div>
					<div className="batchupdate">
						{batchUpdateEnabled && (
							<button className="btn tableSection__head__controls__button" onClick={onBatchUpdate}>
								<span className="icon-new icon-16 icon-batch-update"> </span> {_("label.batch.update")}
							</button>
						)}
						{searchEnabled && (
							<Form
								onSubmit={onSearchSubmit}
								render={({ handleSubmit, submitting }) => (
									<>
										<form onSubmit={handleSubmit}>
											<div className="form-row">
												<Field name="search" type="text">
													{({ input, meta }) => (
														<>
															<input {...input} placeholder={_("label.search")} className="search" />
															{meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
														</>
													)}
												</Field>
											</div>
										</form>
									</>
								)}
							/>
						)}
					</div>
				</div>
			)}

			<table className={className} {...getTableProps()}>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th {...column.getHeaderProps(column.getSortByToggleProps())} title="">
									{column.render("Header")}
									{column.canFilter ? column.render("Filter") : null}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.length === 0 && showLoader === false ? (
						<tr>
							<td colSpan={columns.length} className="text-center nodata-found">
								<p>
									<img src={NoDataFound} />
								</p>
								{_("datatables.empty_table")}
							</td>
						</tr>
					) : (
						<></>
					)}
					{showLoader ? (
						<tr>
							<td colSpan={visibleColumns.length}>
								<Loader />
							</td>
						</tr>
					) : (
						page.map((row) => {
							prepareRow(row);
							return (
								<Fragment key={row.id}>
									<tr {...row.getRowProps()}>
										{row.cells.map((cell) => {
											return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
										})}
									</tr>
									{row.isExpanded ? (
										<tr>
											<td></td>
											<td colSpan={visibleColumns.length - 1}>{renderRowSubComponent && renderRowSubComponent({ row })}</td>
										</tr>
									) : null}
								</Fragment>
							);
						})
					)}
				</tbody>
				<tfoot>
					<tr>
						<td colSpan={visibleColumns.length}></td>
					</tr>
				</tfoot>
			</table>

			{manualPaginationEnabled && (totalRecords ? totalRecords > pageSize : false) && (
				<div className="datagrid-pagination">
					<div className="pagination-left">
						<button
							onClick={() => {
								gotoPage(0);
								setCurrentPage(0);
								onPaginate!(pageSize, 0);
							}}
							disabled={!canPreviousPage}
						>
							{
								<>
									<Icon icon="icon-arrow-left" />
									<Icon icon="icon-arrow-left" />
								</>
							}
						</button>
						<button
							onClick={() => {
								previousPage();
								const navPageIndex = pageIndex - 1;
								setCurrentPage(navPageIndex);
								onPaginate!(pageSize, navPageIndex);
							}}
							disabled={!canPreviousPage}
						>
							{
								<>
									<Icon icon="icon-arrow-left" />
								</>
							}
						</button>
						<button
							onClick={() => {
								nextPage();
								const navPageIndex = pageIndex + 1;
								setCurrentPage(navPageIndex);
								onPaginate!(pageSize, navPageIndex);
							}}
							disabled={!canNextPage}
						>
							{
								<>
									<Icon icon="icon-arrow-right" />
								</>
							}
						</button>
						<button
							onClick={() => {
								gotoPage(pageCount - 1);
								const navPageIndex = pageCount - 1;
								setCurrentPage(navPageIndex);
								onPaginate!(pageSize, navPageIndex);
							}}
							disabled={!canNextPage}
						>
							{
								<>
									<Icon icon="icon-arrow-right" />
									<Icon icon="icon-arrow-right" />
								</>
							}
						</button>
					</div>

					<span>
						{_("label.pagination.page")} {pageIndex + 1} {_("label.pagination.of")} {pageOptions.length} |
						{_("label.pagination.go.to.page")}
					</span>
					<input
						type="number"
						defaultValue={pageIndex + 1}
						onKeyDown={(e) => {
							// Added e, E to avoid exponential value. Eg: eˣ
							if (["e", "E", "+", "-", "."].includes(e.key)) {
								e.preventDefault();
							}
						}}
						onChange={(e) => {
							const page = e.target.value ? Number(e.target.value) - 1 : 0;
							gotoPage(page);
							onPaginate!(pageSize, page);
						}}
						style={{ width: "100px" }}
					/>
				</div>
			)}
		</>
	);
};

export default Datagrid;
