import { useEffect, useRef, useState } from "react";
import type { TableData, continentCountries } from "../types";
import { type VFC, APIResponse } from "types";
import { Datagrid, type Renderers, type HeaderRenderers } from "../../../components/Datagrid";
import type { SubscriptionManagementTableColumns } from "../types";
import DeleteConfirmationModal from "../components/DeleteConfirmationModal";
import SubscriptionModal from "./SubscriptionModal";
import AssignSubscriptionModal from "./AssignSubscriptionModal";
import Loader from "components/Loader";
import { _ } from "i18n";
import Icon from "components/Icon";
import * as R from "ramda";
import DatePicker from "react-datepicker";
import type { MultiValue } from "react-select";
import ColumnSortIcon from "../components/ColumnSortIcon";
import UnAssignSubscriptionModal from "./UnAssignSubscriptionModal";
import Modal from "ui-kit/src/Modal";
import Select from "react-select";
import client from "libs/client";
import { useToast } from "ui-kit";
import type { Keys } from "i18n/locales/types";
import ErrorFallback from "ui-kit/src/FallbackComponents/ErrorFallback";
import type { getSubscriptionFilterDataResponse200 } from "libs/apiClient";


export enum TableColumns {
	TIME_ZONE = "Time Zone",
	LOCATION = "Location",
	CURRENT_BATTERY = "Current Battery",
	ASSIGNED_CLUB = "Assigned Club",
	ACTION = "Action",
	SYNC = "Sync",
	ASSIGN = "Assign",
	SERIAL_NUMBER = "serialNumber",
	EXPIRES_IN = "expiresIn",
}
interface SubscriptionManagementProps {
	activeMenu: string;
	countriesWithContinents: continentCountries[];
	subscriptionProducts: { label: string; value: string }[];
}

const SubscriptionManagement: VFC<SubscriptionManagementProps> = ({ activeMenu, countriesWithContinents, subscriptionProducts }) => {
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
	const [showAssignSubscriptionModal, setShowAssignSubscriptionModal] = useState(false);
	const [showUnAssignSubscriptionModal, setShowUnAssignSubscriptionModal] = useState(false);
	const [error, setError] = useState(null);
	const [ownershipOptions, setOwnershipOptions] = useState<{ value?: number; label?: string }[]>();

	const [filterData, setFilterData] = useState<getSubscriptionFilterDataResponse200>();
	const [filterDataLoaded, setFilterDataLoaded] = useState(true);

	const selectedProducts = useRef<MultiValue<{ value: string | number; label: string | number }>>();
	const selectedClubs = useRef<MultiValue<{ value: string | number; label: string | number }>>();

	const [selectedRow, setSelectedRow] = useState<SubscriptionManagementTableColumns | null>(null);
	const [tableData, setTableData] = useState<TableData>({ columns: {}, data: [] });

	const { addSuccessToast, addErrorToast } = useToast();

	const start = useRef(0);
	const totalRecords = useRef(0);
	const search = useRef("");

	const filters = useRef<any>({
		fromStartDate: null,
		toStartDate: null,
		fromExpirationDate: null,
		toExpirationDate: null,
	});
	const startDate = useRef<[Date | null, Date | null]>([null, null]);
	const expirationDate = useRef<[Date | null, Date | null]>([null, null]);

	const activeStatus = useRef<string>("");

	const getSubscriptionFilters = async () => {
		const response = await client.getSubscriptionFilterData.query().then((res) => { return res?.data });
		setFilterData(response);
		setFilterDataLoaded(false);
	};

	const loadGameOwnerShipsValue = async () => {
		const response = await client.loadOwnerships.query().then((res) => { return res?.data });
		if(response.data){
			setOwnershipOptions(response.data);
		}
	};

	const onDeleteRow = async () => {
		if (!selectedRow?.id) { return; }

		const response = await client.deleteSubscription.mutation({ id: selectedRow?.id });
		if (response?.data.status === APIResponse.SUCCESS) {
			if (response?.data.message) { addSuccessToast(response?.data.message) };
			start.current = 0;
			getSubscriptionFilters();
			fetchData();
		} else {
			if (response?.data.message) { addErrorToast(response?.data.message) };
		}
	};

	let tableColumns: { [key: string]: number | string | null } = {};
	const sortableColumns: string[] = ["countriesCount", "maxAccounts", "startDate", "expirationDate", "expiresIn"];
	const ignoreColumns: string[] = ["id", "countries", "gameOptions", "proClubId", "comment", "ownerships"];
	const preferredOrder = {
		product: null,
		proClubName: null,
		countriesCount: null,
		maxAccounts: null,
		active: null,
		startDate: null,
		expirationDate: null,
		expiresIn: null,
		id: null,
		proClubId: null,
	};

	const { isLoading, mutateAsync: loadSubscriptionDataMutate} = client.loadSubscriptionData.useMutation();

	const fetchData = async () => {
		const params = {
			start: error === null ? start.current : 0,
			length: 10,
			search: search.current,
			fromStartDate: filters.current.fromStartDate ? filters.current.fromStartDate : null,
			toStartDate: filters.current.toStartDate ? filters.current.toStartDate : null,
			fromExpirationDate: filters.current.fromExpirationDate ? filters.current.fromExpirationDate : null,
			toExpirationDate: filters.current.toExpirationDate ? filters.current.toExpirationDate : null,
			product: (filters.current.product && filters.current.product.length > 0) ? filters.current.product : null,
			club: (filters.current.club && filters.current.club.length > 0) ? filters.current.club : null,
			active: filters.current.active === "true" ? 1 : filters.current.active === "false" ? 0 : null,
		};

		loadSubscriptionDataMutate({ body: params })
			.then((response) => {
				if(!response){ return;	}

				response.data.data?.forEach((row: { [s: string]: unknown } | ArrayLike<unknown>) => {
					Object.keys(row).forEach((key) => {
						if (!ignoreColumns.includes(key as never)) {
							tableColumns[key] = key;
						}
					});
				});

				tableColumns = { ...preferredOrder, ...tableColumns };
				if (response.data.recordsFiltered) {
					totalRecords.current = response.data.recordsFiltered;
				}
				setError(null)

				setTableData((prevData) => {
					if(response.data.data){
						if (start.current === 0) {
							return { columns: tableColumns, data: response.data.data } as TableData;
						}
						if (R.equals(prevData.data, response.data.data) === false ) {
							const newData = [...prevData.data, ...response.data.data];
							return { columns: tableColumns, data: newData } as TableData;
						}
						return { columns: tableColumns, data: response.data.data }  as TableData;
					}
					return prevData;
				});
			})
			.catch((error) => {
				setError(error);
			})
	};

	const onUpdateSubscription = async (values: any) => {
		const response = await client.addUpdateSubscription.mutation({ body: { subscription: values } });

		if (response?.data.status === APIResponse.SUCCESS) {
			if (response?.data.message) { addSuccessToast(response?.data.message) };

			setShowSubscriptionModal(false);
			start.current = 0;
			getSubscriptionFilters();
			fetchData();
		} else {
			if (response?.data.message) { addErrorToast(response?.data.message) };
		}
	};

	const onAssignSubscription = async (values: any) => {
		if (!selectedRow?.id) { return; }

		const params = {
			clubId: values.value,
			subscriptionId: selectedRow?.id,
		}

		const response = await client.assignSubscriptionToClub.mutation(params);
		if (response?.data.status === APIResponse.SUCCESS) {
			if (response?.data.message) { addSuccessToast(response?.data.message) };
			setShowAssignSubscriptionModal(false);
			start.current = 0;
			getSubscriptionFilters();
			fetchData();
		} else {
			if (response?.data.message) { addErrorToast(response?.data.message) };
		}
	};

	const onUnAssignSubscription = async () => {
		if (!selectedRow?.proClubId && !selectedRow?.id) { return; }

		const params = {
			clubId: parseInt(selectedRow?.proClubId!),
			subscriptionId: selectedRow?.id!,
			unAssign: 1
		}

		const response = await client.assignSubscriptionToClub.mutation(params);

		if (response?.data.status === APIResponse.SUCCESS) {
			if (response?.data.message) { addSuccessToast(response?.data.message) };
			setShowUnAssignSubscriptionModal(false);
			start.current = 0;
			getSubscriptionFilters();
			fetchData();

		} else {
			if (response?.data.message) { addErrorToast(response?.data.message) };
		}
	};

	const updateFilter = (value: string | number | (string | number)[], id: string) => {
		start.current = 0;
		if (value) {
			filters.current[id] = value;
			fetchData();
		} else {
			filters.current[id] = null;
			fetchData();
		}
	};

	const handleLoadMore = () =>{
		start.current += 10;
		fetchData();
	}

	useEffect(() => {
		if (activeMenu === "subscriptions") {
			window.scrollTo({ top: 0, left: 0 });
			loadGameOwnerShipsValue();
			getSubscriptionFilters();
            fetchData();
		}
	}, [activeMenu]);

	const tristateRef = useRef<HTMLButtonElement>(null);

	const onActiveTristateChange = () => {
		if (tristateRef.current) {
			const element: HTMLElement = tristateRef.current;
			const elementStatus = element?.lastElementChild?.lastElementChild?.lastElementChild;

			if (element.dataset.state === "") {
				elementStatus?.classList.add("status__circle--ok");
				activeStatus.current = "1";
				updateFilter("true", "active");
			} else if (element.dataset.state === "1") {
				elementStatus?.classList.add("status__circle--nook");
				activeStatus.current = "0";
				updateFilter("false", "active");
			} else {
				elementStatus?.classList.add("status__circle");
				activeStatus.current = "";
				updateFilter("", "active");
			}
		}
	};

	const onChangeProduct = (e: MultiValue<{ value: string | number; label: string | number }>) => {
		let productsIds:(string | number)[]= [];
		if (e) {
			selectedProducts.current = e;
			e.forEach((club) => {
				productsIds.push(club.value);
			});
			updateFilter(productsIds, "product");
		} else {
			updateFilter("", "product");
		}
	};

	const onChangeClub = (e: MultiValue<{ value: string | number; label: string | number }>) => {
		let clubIds: number[] = [];
		if (e) {
			selectedClubs.current = e;
			e.forEach((club) => {
				clubIds.push(typeof club.value === 'number' ? club.value : parseInt(club.value));
			});
			updateFilter(clubIds, "club");
		} else {
			updateFilter("", "club");
		}
	};

	const headerRenderers: HeaderRenderers<SubscriptionManagementTableColumns> = {
		product: () => {
			return (
				<div className="subscription-name">
					<Select
						isMulti
						isLoading={filterDataLoaded}
						options={filterData?.products}
						value={selectedProducts.current}
						isClearable={true}
						className="react-select"
						placeholder={_("label.placeholder.products")}
						onChange={onChangeProduct}
					/>
				</div>
			);
		},
		proClubName: () => {
			return (
				<div className="assigned-club">
					<Select
						isMulti
						isLoading={filterDataLoaded}
						options={filterData?.clubs}
						className="react-select"
						value={selectedClubs.current}
						isClearable={true}
						placeholder={_("my_eyeball.title.clubs")}
						onChange={onChangeClub}
					/>
				</div>
			);
		},
		countriesCount: ({ column }) => {
			return (
				<>
					<div>
						{_("label.countries")} <ColumnSortIcon column={column} />
					</div>
				</>
			);
		},
		maxAccounts: ({ column }) => {
			return (
				<>
					<div>
						{_("label.max_accounts")} <ColumnSortIcon column={column} />
					</div>
				</>
			);
		},
		active: () => {
			return (
				<>
					<button
						ref={tristateRef}
						data-state={activeStatus.current}
						className="tableFilter__btn video-js-th-toggleFilter-btn"
						onClick={() => onActiveTristateChange()}
					>
						<span className="tableFilter__btn-content">
							{_("form_add_club_pro.label.is_active.true")}
							<span
								className={`status__circle ${activeStatus.current === "1" ? "status__circle--ok" : activeStatus.current === "0" ? "status__circle--nook" : ""
									}`}
							></span>
						</span>
					</button>
				</>
			);
		},
		startDate: () => {
			return (
				<DatePicker
					onChange={(dates) => {
						if (startDate.current) {
							startDate.current = dates;
						}
						if (dates[0] !== null) {
							filters.current.fromStartDate = new Date(dates[0]?.getTime()! - dates[0]?.getTimezoneOffset()! * 60 * 1000)
								.toISOString()
								.split("T")[0];
						} else {
							delete filters.current.fromStartDate;
						}

						if (dates[1] !== null) {
							filters.current.toStartDate = new Date(dates[1]?.getTime()! - dates[1]?.getTimezoneOffset()! * 60 * 1000)
								.toISOString()
								.split("T")[0];
						} else {
							delete filters.current.toStartDate;
						}

						if ((filters.current.fromStartDate && filters.current.toStartDate) || (dates[0] === null && dates[1] === null)) {
							start.current = 0;
							fetchData();
						}
					}}
					placeholderText={_("label.start_date")}
					startDate={startDate.current[0]}
					dateFormat="d-MM-yyyy"
					endDate={startDate.current[1]}
					selectsRange
					isClearable={true}
				/>
			);
		},
		expirationDate: () => {
			return (
				<DatePicker
					onChange={(dates) => {
						if (expirationDate.current) {
							expirationDate.current = dates;
						}
						if (dates[0] !== null) {
							filters.current.fromExpirationDate = new Date(dates[0]?.getTime()! - dates[0]?.getTimezoneOffset()! * 60 * 1000)
								.toISOString()
								.split("T")[0];
						} else {
							delete filters.current.fromExpirationDate;
						}

						if (dates[1] !== null) {
							filters.current.toExpirationDate = new Date(dates[1]?.getTime()! - dates[1]?.getTimezoneOffset()! * 60 * 1000)
								.toISOString()
								.split("T")[0];
						} else {
							delete filters.current.toExpirationDate;
						}

						if ((filters.current.fromExpirationDate && filters.current.toExpirationDate) || (dates[0] === null && dates[1] === null)) {
							start.current = 0;
							fetchData();
						}
					}}
					placeholderText={_("label.expiration_date")}
					startDate={expirationDate.current[0]}
					dateFormat="d-MM-yyyy"
					endDate={expirationDate.current[1]}
					selectsRange
					isClearable={true}
				/>
			);
		},
		expiresIn: ({ column }) => {
			return (
				<>
					<div>
						{_("label.expires_in")} <ColumnSortIcon column={column} />
					</div>
				</>
			);
		},
		id: () => _("label.action"),
		proClubId: () => _("label.assign"),
	};

	const cellRenderers: Renderers<SubscriptionManagementTableColumns> = {
		proClubName: ({ cell }) => cell.value,
		active: ({ cell }) => {
			return (
				<>
					{cell.value ? "Active" : "Inactive"}{" "}
					<span className={`status__circle ${cell.value ? "status__circle--ok" : "status__circle--nook"}`}></span>
				</>
			);
		},
		expirationDate: ({ cell }) => {
			return (
				<>
					<span>{cell.value}</span>
				</>
			);
		},
		startDate: ({ cell }) => {
			return (
				<>
					<span>{cell.value}</span>
				</>
			);
		},
		id: ({ cell }) => {
			return (
				<>
					<div className="action-icons">
						<button
							className="tableCellControls__button tableCellControls__button--svg"
							onClick={() => {
								setSelectedRow(cell.row.original);
								setShowSubscriptionModal(true);
							}}
						>
							<Icon icon="crayon" size={16} color="dark-grey" />
						</button>
						<button
							className="tableCellControls__button tableCellControls__button--svg"
							onClick={() => {
								setSelectedRow(cell.row.original);
								setShowDeleteModal(true);
							}}
						>
							<Icon icon="trash-new" size={16} color="dark-grey" />
						</button>
					</div>
				</>
			);
		},
		proClubId: ({ cell }) => {
			return (
				<button
					className={cell.row.original.proClubId !== null ? "assign-btn assigned-btn" : "assign-btn"}
					onClick={() => {
						setSelectedRow(cell.row.original);
						if (cell.row.original.proClubId !== null) {
							setShowUnAssignSubscriptionModal(true);
						} else {
							setShowAssignSubscriptionModal(true);
						}
					}}
				>
					{cell.row.original.proClubId !== null ? _("label.unassign") : _("label.assign")}
				</button>
			);
		},
	};


	/*useEffect(() => {
		fetchData();
	}, []);
*/
	return (
		<>
			<div className="product-management-heading-container">
				<div className="product-management-heading">{_("label.subscriptions_management")} </div>
				<button
					className="btn btn-light-blue"
					onClick={() => {
						setSelectedRow({});
						setShowSubscriptionModal(true);
					}}
				>
					+ {_("label.add.subscription")}
				</button>
			</div>
			<div className="datatable-container">
				{error !== null
					? <ErrorFallback error={error} resetErrorBoundary={fetchData} />
					: <Datagrid
						columns={tableData.columns}
						data={tableData.data}
						className="table"
						override={true}
						cellRenderers={cellRenderers}
						headerRenderers={headerRenderers}
						sortableColumns={sortableColumns}
						lengthFilterEnabled={false}
						manualPaginationEnabled={false}
					/>
				}
				<div>{isLoading && <Loader />}</div>
				{tableData.data.length < totalRecords.current && !isLoading && 
					<div className="text-center">
						<button
							className="btn btn-light-blue"
							onClick={handleLoadMore}
						>
								{_("btn.load_more")}
						</button>
					</div>
				}
			</div>
			<Modal
				show={showDeleteModal}
				title={null}
				size={"sm"}
				onClose={() => {
					setShowDeleteModal(false);
					setSelectedRow({});
				}}
			>
				<DeleteConfirmationModal
					title={`${selectedRow?.product}  ${selectedRow?.proClubName == null ? "" : `- ${selectedRow?.proClubName}`}`}
					onClose={() => {
						setShowDeleteModal(false);
						setSelectedRow({});
					}}
					onDelete={() => {
						onDeleteRow();
						setShowDeleteModal(false);
					}}
					show={showDeleteModal} />
			</Modal>
			<Modal
				show={showSubscriptionModal}
				title={_("label.subscription")}
				size={"lg"}
				onClose={() => {
					setShowSubscriptionModal(false);
					setSelectedRow({});
				}}
			>
				<SubscriptionModal
					onClose={() => {
						setShowSubscriptionModal(false);
						setSelectedRow({});
					}}
					onUpdateSubscription={onUpdateSubscription}
					row={selectedRow}
					ownershipOptions={ownershipOptions}
					countriesWithContinents={countriesWithContinents}
					subscriptionProducts={subscriptionProducts}
				/>
			</Modal>
			<Modal
				show={showAssignSubscriptionModal}
				title={_("label.assign.subscription.club")}
				size={"sm"}
				onClose={() => {
					setShowAssignSubscriptionModal(false);
					setSelectedRow({});
				}}
			>
				<AssignSubscriptionModal
					onClose={() => {
						setShowAssignSubscriptionModal(false);
						setSelectedRow({});
					}}
					onAssignSubscription={onAssignSubscription}
				/>
			</Modal>
			<Modal
				show={showUnAssignSubscriptionModal}
				title={`${_('label.unassign' as keyof Keys)} ${selectedRow?.proClubName}`}
				size={"sm"}
				onClose={() => {
					setShowUnAssignSubscriptionModal(false);
					setSelectedRow({});
				}}
			>
				<UnAssignSubscriptionModal
					onClose={() => {
						setShowUnAssignSubscriptionModal(false);
						setSelectedRow({});
					}}
					onUnAssign={() => {
						onUnAssignSubscription();
						setShowDeleteModal(false);
					}}
				/>
			</Modal>
		</>
	);
};


export default SubscriptionManagement;
