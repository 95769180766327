import { useEffect, useRef, useState } from "react";
import { ClubData, NonPartnerClubData, continentCountries } from "../../types";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import { VFC, APIResponse } from "types";
import { handleIntersectionObserver } from "../../functions";
import Loader from "components/Loader";
import * as R from "ramda";
import { Form, Field } from "react-final-form";
import Select, { SingleValue } from "react-select";
import NonPartnerClubCard from "./nonPartnerClubCard";
import Modal from "ui-kit/src/Modal";
import Migrate from "../migrate/Migrate";
import ClubModal from "./ClubModal";
import TristateButton from "components/TristateButton/TristateButton";
import { _ } from "i18n";
import client from "libs/client";
import { useToast } from "ui-kit";

interface NonPartnerClubManagementProps {
	activeMenu: string;
	onPlayerCountClick: (club: any) => void;
	countries: { [key: string]: string };
	countriesWithContinents: continentCountries[];
}

const NonPartnerClubManagement: VFC<NonPartnerClubManagementProps> = ({ onPlayerCountClick, activeMenu, countries, countriesWithContinents }) => {
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showClubModal, setShowClubModal] = useState(false);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [showLoader, setShowLoader] = useState(false);
	const isSubmitted = useRef(false);

	const [countrieslist, setCountriesList] = useState<{ value: string; label: string }[]>([]);

	const [selectedClub, setSelectedClub] = useState<NonPartnerClubData | null>(null);
	const [selectedMigrationType, setSelectedMigrationType] = useState<string>("");
	const [geographicalAreaList, setGeographicalAreaList] = useState<{ value: string; label: string }[]>([]);

	const [clubData, setClubData] = useState<NonPartnerClubData[]>([]);

	const [showMigrate, setShowMigrate] = useState(false);

	const { addSuccessToast, addErrorToast } = useToast();

	const start = useRef(0);
	const totalRecords = useRef(0);
	const search = useRef("");

	const [defaultCountry, setDefaultCountry] = useState<{ value: string; label: string } | null>(null);
	const isDefaultCountryEmpty = useRef(true);

	const filters = useRef<any>({
		country: null,
	});

	const onDeleteActionClick = (club: any) => {
		setSelectedClub(club);
		setShowDeleteModal(true);
	};

	const onEditActionClick = (club: any) => {
		setSelectedClub(club);
		setShowClubModal(true);
	};

	const onMigrateActionClick = (club: any, type: string) => {
		setSelectedClub(club);
		setSelectedMigrationType(type);
		setShowMigrate(true);
	};

	const onDelete = async () => {
		if (!selectedClub) return;

		const response = await client.deleteNPClub.mutation({ clubId: selectedClub?.id });

		if (response?.data.status === APIResponse.SUCCESS) {
			if (response?.data.message) { addSuccessToast(response?.data.message) };
			start.current = 0;
			fetchData();
		} else {
			if (response?.data.message) { addErrorToast(response?.data.message) };
		}
	};

	const onUpdateClub = async (values: NonPartnerClubData) => {
		isSubmitted.current = true;
		const response = await client.addUpdateNPClub.mutation({ body: { nonPartnerClub: values } });

		if (response?.data.status === APIResponse.SUCCESS) {
			if (response?.data.message) { addSuccessToast(response?.data.message) };
			if (values.id) {
				setClubData((prevData: NonPartnerClubData[]) => {
					return prevData.map((data) => {
						if (data.id === values.id) {
							return { ...values, playersCount: data.playersCount, gamesCount: data.gamesCount };
						}
						return data;
					});
				});
			} else {
				start.current = 0;
				fetchData();
			}
			setShowClubModal(false);
		} else {
			if (response?.data.message) { addErrorToast(response?.data.message) };
		}
		isSubmitted.current = false;
	};

	const onChangeCountry = (e: SingleValue<{ value: string; label: string }>) => {
		if (e?.value) {
			updateFilter(e.value, "country");
			setDefaultCountry(e);
		} else {
			updateFilter("", "country");
			setDefaultCountry(null);
		}
	};

	const getNonPartnerCountriesData = async () => {
		return client.getCountries.query().then((res) => {
			if (res) {
				const countriesList = res.data;
				if (countriesList.length > 0) {
					setCountriesList(countriesList);
					setDefaultCountry(countriesList[0]);
					return countriesList;
				}
			}
		});
	};

	const getGeographicalAreas = async () => {
		const response = await client.getGeographicalAreaList.query().then((res) => { return res });
		if (response?.status === 200) {
			setGeographicalAreaList(response?.data);
		}
	};

	const fetchData = async (loadMore: boolean = false) => {

		if (isDefaultCountryEmpty.current) {
			const countriesList = await getNonPartnerCountriesData();
			if (countriesList.length > 0) {
				filters.current.country = countriesList[0].value;
			}
			start.current = 0;
			isDefaultCountryEmpty.current = false;
		}

		const param = {
			start: start.current,
			length: 10,
			search: search.current,
			country: filters.current.country ? filters.current.country : "",
			showcase: filters.current.showcase ? parseInt(filters.current.showcase) : -1,
		};

		client.getNPClubs.query(param).then((res) => {
			if (res) {
				const response = res.data;
				if (response.status === APIResponse.SUCCESS) {
					totalRecords.current = response.recordsFiltered;
					setShowLoader(false);
					setClubData((prevData) => {
						if (search.current && start.current === 0) {
							return response.data;
						}
						if (R.equals(prevData, response.data) === false && loadMore) {
							const newData = [...new Set([...prevData, ...response.data])];
							return newData;
						}
						return response.data;
					});
					setHasMoreData(false);
				}
			}
		});
	};

	const updateFilter = (value: string | number, id: string) => {
		setShowLoader(true);
		start.current = 0;
		if (value) {
			filters.current[id] = value;
		} else {
			filters.current[id] = null;
		}
	};

	const onSearchSubmit = (values: { search: string }) => {
		setShowLoader(true);
		search.current = values.search;
		start.current = 0;
		fetchData();
	};

	useEffect(() => {
		if (showLoader) {
			fetchData();
		}
	}, [showLoader]);

	const containerRef = useRef(null);
	const options = { root: null, rootMargin: "0px", threshold: 1.0 };

	useEffect(() => {
		if (activeMenu === "non_partner_club") {
			window.scrollTo({ top: 0, left: 0 });
			getGeographicalAreas();
		}
	}, [activeMenu]);

	useEffect(() => {
		const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
			handleIntersectionObserver(entries, start, totalRecords, fetchData, setHasMoreData);
		}, options);

		if (containerRef.current) observer.observe(containerRef.current);

		return () => {
			if (containerRef.current) observer.unobserve(containerRef.current);
		};
	}, [containerRef, options]);

	return (
		<>
			{!showMigrate && (
				<div className="nonpartner-clubs-container">
					<div className="product-management-heading-container">
						<div className="product-management-heading"> {_("resource.non_partner_clubs")} </div>
						<div className="top-form-container">
							<button
								className="btn btn-light-blue"
								onClick={() => {
									setShowClubModal(true);
									setSelectedClub(null);
								}}
							>
								{_("filter.add_club")}
							</button>
						</div>
					</div>
					<div className="form-filter-container">
						<div className="filter-field">
							<label>{_("videos.table.th.country")}</label>
							<Select className="react-select" isClearable options={countrieslist} onChange={onChangeCountry} value={defaultCountry} />
						</div>

						<div className="filter-field">
							<label> {_("label.search")}</label>
							<Form
								onSubmit={onSearchSubmit}
								render={({ handleSubmit, submitting }) => (
									<>
										<form onSubmit={handleSubmit}>
											<Field name="search" type="text">
												{({ input, meta }) => (
													<>
														<input {...input} placeholder={_("search.club")} />
														{meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
													</>
												)}
											</Field>
										</form>
									</>
								)}
							/>
						</div>
						<div className="filter-field">
							<label> {_("label.showcase")}</label>
							<TristateButton label={_("label.showcase")} updateFilter={updateFilter} />
						</div>
					</div>
					<div className="club-grid-container">
						{clubData ? (
							clubData.map((club) => {
								return (
									<NonPartnerClubCard
										key={club.id}
										club={club}
										countriesList={countries}
										onDeleteActionClick={onDeleteActionClick}
										onEditActionClick={onEditActionClick}
										onMigrateActionClick={onMigrateActionClick}
										onPlayerCountClick={onPlayerCountClick}
									/>
								);
							})
						) : (
							<h6 className="modal-msg">{_("info.nodata_available")}</h6>
						)}
					</div>
					<div ref={containerRef}>{hasMoreData && <Loader />}</div>
				</div>
			)}
			{showMigrate && (
				<Migrate
					club={selectedClub as unknown as ClubData}
					player={null}
					type={selectedMigrationType}
					countries={countries}
					onClose={() => {
						setShowMigrate(false);
						fetchData();
					}}
				/>
			)}

			<Modal
				show={showDeleteModal}
				title={null}
				size={"sm"}
				onClose={() => {
					setShowDeleteModal(false);
					setSelectedClub(null);
				}}
			>
				<DeleteConfirmationModal
					show={true}
					title={`${selectedClub?.name}`}
					onClose={() => {
						setShowDeleteModal(false);
						setSelectedClub(null);
					}}
					onDelete={() => {
						onDelete();
						setShowDeleteModal(false);
					}}
				/>
			</Modal>
			{showClubModal && (
				<ClubModal
					onClose={() => {
						setShowClubModal(false);
						setSelectedClub(null);
					}}
					onUpdateClub={onUpdateClub}
					club={selectedClub}
					isSubmitted={isSubmitted}
					geographicalAreaList={geographicalAreaList}
					countries={countries}
					countriesWithContinents={countriesWithContinents}
				/>
			)}
		</>
	);
};

export default NonPartnerClubManagement;
