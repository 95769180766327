import { _ } from "i18n";
import { Form, Field } from "react-final-form";
import { VFC } from "../types";
import { useEffect, useRef, useState } from "react";
import Select, { MultiValue } from "react-select";
import AsyncSelect from "react-select/async";
import LanguageList from "./LanguageList";
import { NotificationManagementData } from "../types";
import { useToast } from "ui-kit";
import client from "libs/client";
import Icon from "components/Icon";

interface NotificationModalProps {
	onClose: () => void;
	handleNotication: (values: NotificationManagementData, isTest: boolean) => void;
	row?: NotificationManagementData;
}

export enum RecieverOptions {
	INDIVIDUAL_USER_SELECT = "individualUserSelect",
	AMATEUR_CLUB_SELECT = "amateurClubSelect",
	PRO_CLUB_SELECT = "proClubSelect",
	EMAIL = "Email",
	SMS = "SMS",
	FIREBASE = "Firebase",
}

const NotificationModal: VFC<NotificationModalProps> = ({ onClose, handleNotication, row }) => {

	const userLocale = useRef<string>("");
	const loggedUserId = useRef<number>();

	const [users, setIndividualUsers] = useState<MultiValue<{ value?: number; label?: string }>>(row?.receivers?.users ? row.receivers.users : []);
	const [amateurClubs, setAmateurClubs] = useState<MultiValue<{ value?: number; label?: string }>>(
		row?.receivers?.amateurClubs ? row.receivers.amateurClubs : []
	);
	const [proClubs, setProClubs] = useState<MultiValue<{ value?: number; label?: string }>>(row?.receivers?.proClubs ? row.receivers.proClubs : []);

	const [languageOptions, setLanguageOptions] = useState<{ value?: string; label?: string }[]>([]);
	const [selectedLanguage, setSelectedLanguage] = useState(row?.messages?.length! > 0 ? row?.messages?.[0].language : userLocale);
	const [messages, setMessages] = useState<
		{
			fullLanguage?: string;
			language?: string;
			title?: string;
			body?: string;
		}[]
	>(row?.messages?.length! > 0 ? row?.messages! : [{ language: userLocale.current, title: "", body: "" }]);
	const [languageList, setLanguageList] = useState<{ label: string; value: string }[]>([]);
	const selectedMethods = useRef<string[]>([]);
	const isTestNotification = useRef<boolean>(false);


	const [disableFormActions, setDisableFormActions] = useState(false);
	const { addErrorToast } = useToast();


	const formData = {
		title: row ? row.title : null,
	};

	const onChangeLanguage = (e: any) => {
		const filteredLanguage = languageOptions?.filter((language: { value?: string }) => language.value != e?.value);
		setLanguageList((prev: { label?: string; value?: string }[]) => [...prev, e]);
		setLanguageOptions(filteredLanguage);
		setSelectedLanguage(e.value);
		const message = messages.find((message) => message.language === e.value);

		if (message === undefined) {
			setMessages((prev) => {
				return [...prev, { language: e.value, title: "", body: "" }];
			});
		}
	};
	const onDeleteLanguage = (e: { value?: string; label?: string }) => {
		const filteredLanguage = languageList.filter((language) => language.value != e?.value);
		setLanguageOptions((prev: { value?: string; label?: string }[]) => [...prev, e]);
		setLanguageList(filteredLanguage);

		setMessages((prev) => {
			return prev.map((message: { language?: string }) => {
				return message.language === e.value ? { ...message, title: "", body: "" } : message;
			});
		});

		if (filteredLanguage.length > 0) {
			setSelectedLanguage(filteredLanguage[0].value);
		}
	};
	const onFormSubmit = async (values: NotificationManagementData) => {
		values.receivers = {
			users: users ? users : null,
			amateurClubs: amateurClubs ? amateurClubs : null,
			proClubs: proClubs ? proClubs : null,
		};

		if (values.receivers?.amateurClubs?.length == 0 && values.receivers.proClubs?.length == 0 && values.receivers.users?.length == 0 && isTestNotification.current === false) {
			addErrorToast(_("message.please_select_receivers"));
			return;
		}
		if (selectedMethods.current.length === 0) {
			addErrorToast(_("message.please_select_method"));
			return;
		} else {
			values.methods = selectedMethods.current;
		}

		if (messages.length > 0) {
			const selectedLanguages = languageList.map((language) => language.value);

			let messagesValues: { language?: string; title?: string; body?: string }[] = [];

			messages.forEach(
				(message: {
					allLanguage?: string;
					language?: string;
					title?: string;
					body?: string;
				}) => {
					if (selectedLanguages.includes(message.language!)) {
						if (message.title === "" || message.body === "") {
							addErrorToast(
								`${languageList.find((language: { value: string }) => language?.value === message?.language)?.label}-${_(
									"message.all_message_fields_required"
								)}`
							);
							return;
						}

						messagesValues.push(message);
					}
				}
			);

			values.messages = messagesValues;
		}

		if (values?.messages?.length === 0) {
			addErrorToast(_("message.message_content_required"));
			return;
		}

		values.authorId = loggedUserId.current;

		if (selectedMethods.current.includes(RecieverOptions.SMS)) {
			for (let key in values.messages) {
				var arr = values.messages[key as any];

				if (arr?.title?.length! > 64 || arr?.body?.length! > 96) {
					let currentLanguage = languageList.find((a) => a.value == arr.language);

					return addErrorToast(
						`${_("label.as_your_selected")} ${currentLanguage?.label} ${_("label.message_title _reached_maximum_length")}}`
					);
				}
			}
		}
		setDisableFormActions(true);

		if (isTestNotification.current) {
			isTestNotification.current = false;
			await handleNotication(values, true);
			setDisableFormActions(false);
		} else {
			await handleNotication(values, false);
			setDisableFormActions(false);
		}
	};

	const loadReceiverOptions = async (term: string, receiverType: string) => {
		if (term !== "") {
			if (receiverType === RecieverOptions.INDIVIDUAL_USER_SELECT) {
				return await client.userSearch.query({ search: term }).then((res) => { return res?.data });
			} else if (receiverType === RecieverOptions.AMATEUR_CLUB_SELECT) {
				return await client.clubAmateurSearch.query({ search: term, checkUserExists: 1 }).then((res) => { return res?.data });
			} else if (receiverType === RecieverOptions.PRO_CLUB_SELECT) {
				return await client.clubProSearch.query({ search: term, checkUserExists: 1 }).then((res) => { return res?.data });
			}

			return [];
		}
	};

	const AsyncSelectAdapter = ({ input, ...rest }: { input: {} }) => {
		return <AsyncSelect {...input} {...rest} />;
	};

	const toggleMethod = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, method: string) => {
		const element = e.currentTarget as HTMLElement;
		if (element.classList.contains("active")) {
			if (selectedMethods.current.length > 0) {
				selectedMethods.current = selectedMethods.current.filter((p) => p !== method);
			}
		} else {
			selectedMethods.current.push(method);
		}
		element.classList.toggle("active");
	};

	const getLanguages = async () => {
		const userData = await client.getCurrentUser.query().then((res) => {
			return res?.data as {
				id: number;
				email: string;
				firstName: string;
				lastName: string;
				roles: string[];
				role: string;
				helpHash: string;
				isVip: boolean;
				position: string;
				clubName: string;
				photo: string | null;
				locale: string;
				hasAcceptedTermsOfUse: boolean;
				productManagementOptions?: any;
			}
		});
		if (userData) {
			userLocale.current = userData.locale;
			loggedUserId.current = userData.id;
		}

		const response = await client.getAllLanguages.query().then((res) => { return res?.data });
		if (response && response.length > 0) {
			setLanguageOptions(response);
		}
	}

	useEffect(() => {
		getLanguages();
	}, []);

	useEffect(() => {
		if (row?.messages?.length! > 0) {
			const selectedLanguages = messages.map((message) => {
				return { label: message.fullLanguage!, value: message.language! };
			});

			setLanguageList(selectedLanguages);
		} else {
			const userLanguage = languageList?.filter((languages: { value: string }) => languages.value === userLocale.current);
			setLanguageList(userLanguage!);
		}

		const filterLanguage = languageOptions?.filter((language: { value?: string }) => language.value != userLocale.current);
		setLanguageOptions(filterLanguage);
	}, [userLocale]);

	return (
		<>
			<div className="add-Notification">
				<Form
					onSubmit={onFormSubmit}
					initialValues={formData}
					render={({ handleSubmit, form, submitting }) => (
						<>
							<form onSubmit={handleSubmit}>
								<div className="form-row">
									<div className="field-group w-100">
										<label htmlFor=""> {_("form_add_club_amateur.label.contactPosition")}</label>

										<Field name="title" type="text">
											{({ input, meta }) => (
												<>
													<input
														{...input}
														maxLength={32}
														className="form-control"
														required={true}
														disabled={row?.title === undefined ? false : true}
													/>
													{meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
												</>
											)}
										</Field>
									</div>
									<div className="field-group w-100">
										<label> {_("label.receivers")}</label>
										<div className={`${row?.receivers ? "receivers-row disabled" : "receivers-row"}`}>
											<label className="receivers-label">{_("label.individual_user")}</label>
											<div className="w-100">
												<AsyncSelect
													name="individualUserSelect"
													className="react-select"
													cacheOptions
													loadOptions={async (term: string) => {
														return (await loadReceiverOptions(term, "individualUserSelect")) as any;
													}}
													isClearable={true}
													isMulti={true}
													defaultOptions
													value={users}
													onChange={(e: MultiValue<{ value?: number; label?: string }>) => {
														setIndividualUsers(e);
													}}
												/>
											</div>
										</div>
										<div className={`${row?.receivers ? "receivers-row disabled" : "receivers-row"}`}>
											<label className="receivers-label">{_("resource.clubs_amateur")}</label>
											<div className="w-100">
												<AsyncSelect
													name="amateurClubSelect"
													className="react-select"
													cacheOptions
													loadOptions={async (term: string) => {
														return (await loadReceiverOptions(term, "amateurClubSelect")) as any;
													}}
													isClearable={true}
													isMulti={true}
													defaultOptions
													value={amateurClubs}
													onChange={(e: MultiValue<{ value?: number; label?: string }>) => {
														setAmateurClubs(e);
													}}
												/>
											</div>
										</div>
										<div className={`${row?.receivers ? "receivers-row disabled" : "receivers-row"}`}>
											<label className="receivers-label">{_("label.pro_club")}</label>
											<div className="w-100">
												<AsyncSelect
													name="proClubSelect"
													className="react-select"
													cacheOptions
													loadOptions={async (term: string) => {
														return (await loadReceiverOptions(term, "proClubSelect")) as any;
													}}
													isClearable={true}
													isMulti={true}
													defaultOptions
													value={proClubs}
													onChange={(e: MultiValue<{ value?: number; label?: string }>) => {
														setProClubs(e);
													}}
												/>
											</div>
										</div>
										<div className={`${row?.receivers ? "receivers-row disabled" : "receivers-row"}`}>
											<label className="receivers-label hide">{_("club.non_partner.label.country")}</label>
											<div className="w-100 hide">
												<Field
													name="CountrySelect"
													className="react-select"
													cacheOptions
													loadOptions={async (term: string) => {
														return await loadReceiverOptions(term, "proClubSelect");
													}}
													component={AsyncSelectAdapter}
													isClearable={true}
													isMulti={true}
													defaultOptions
												/>
											</div>
										</div>
										<div className={`${row?.receivers ? "receivers-row disabled" : "receivers-row"}`}>
											<label className="receivers-label hide">{_("label.accounts")}</label>
											<div className="w-100 hide">
												<Field
													name="AccountsSelect"
													className="react-select"
													cacheOptions
													loadOptions={async (term: string) => {
														return await loadReceiverOptions(term, "proClubSelect");
													}}
													component={AsyncSelectAdapter}
													isClearable={true}
													isMulti={true}
													defaultOptions
												/>
											</div>
										</div>
									</div>
									<div className="field-group w-100">
										<label>{_("label.methods")}</label>
										<div className={`${row?.methods ? "methods-list disabled" : "methods-list"}`}>
											<ul>
												<li
													onClick={(e) => toggleMethod(e, RecieverOptions.SMS)}
													className={row?.methods?.includes(RecieverOptions.SMS) ? "active" : ""}
												>
													<Icon icon="sms-new" size={20} color="dark-grey" />SMS
												</li>

												<li
													onClick={(e) => toggleMethod(e, RecieverOptions.EMAIL)}
													className={row?.methods?.includes(RecieverOptions.EMAIL) ? "active" : ""}
												>
													<Icon icon="mail-new" size={20} color="dark-grey" />Email
												</li>
												<li
													onClick={(e) => toggleMethod(e, RecieverOptions.FIREBASE)}
													className={row?.methods?.includes(RecieverOptions.FIREBASE) ? "active hide" : "hide"}
												>
													<Icon icon="firebase-new" size={20} color="dark-grey" />Firebase
												</li>
											</ul>
										</div>
									</div>
									<div className={`${row?.messages ? "field-group w-100 disabled" : "field-group w-100"}`}>
										<label> {_("label.notofications.message")}</label>
										<Select
											className="react-select"
											options={languageOptions}
											placeholder={"Select Language"}
											onChange={(e) => onChangeLanguage(e)}
										/>
									</div>
									{languageList.length > 0 && (
										<>
											<div className="methods-list msg-list">
												<ul>
													{languageList.map((language: { value?: string; label?: string }, index) => {
														return (
															<LanguageList
																key={index}
																language={language}
																selectedLanguage={selectedLanguage as string}
																row={row}
																setSelectedLanguage={setSelectedLanguage}
																onDeleteLanguage={onDeleteLanguage}
															/>
														);
													})}
												</ul>
											</div>

											<div className={`${row?.messages ? "field-group w-100 disabled" : "field-group w-100"}`}>
												<label> {_("form_add_club_amateur.label.contactPosition")}</label>
												<Field name="messageTitle" type="text">
													{({ input, meta }) => (
														<>
															<input
																{...input}
																className="form-control"
																required={true}
																onChange={(e) => {
																	setMessages((prev) => {
																		const message = messages.find(
																			(message) => message.language === selectedLanguage
																		);

																		if (message !== undefined) {
																			const filteredMessage = messages.filter(
																				(message) => message.language !== selectedLanguage
																			);
																			message.title = e.target.value;
																			message.language = selectedLanguage as string;

																			if (filteredMessage.length > 0) {
																				return [...filteredMessage, message];
																			} else {
																				return [message];
																			}
																		} else {
																			return [...prev, { language: selectedLanguage, title: e.target.value }] as {
																				fullLanguage?: string | undefined;
																				language?: string | undefined;
																				title?: string | undefined;
																				body?: string | undefined;
																			}[];
																		}
																	});
																}}
																value={
																	messages.length > 0
																		? messages.find((message) => message.language === selectedLanguage)?.title
																		: ""
																}
															/>
															{meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
														</>
													)}
												</Field>
											</div>
											<div className={`${row?.methods ? "field-group w-100 disabled" : "field-group w-100"}`}>
												<label> {_("label.notofications.body")}</label>

												<Field name="body" type="textarea">
													{({ input, meta }) => (
														<div className="form-group">
															<textarea
																{...input}
																className="form-control textarea"
																required={true}
																onChange={(e) => {
																	setMessages((prev) => {
																		const message = messages.find(
																			(message) => message.language === selectedLanguage
																		);

																		if (message !== undefined) {
																			const filteredMessage = messages.filter(
																				(message) => message.language !== selectedLanguage
																			);
																			message.body = e.target.value;
																			message.language = selectedLanguage as string;

																			if (filteredMessage.length > 0) {
																				return [...filteredMessage, message];
																			} else {
																				return [message];
																			}
																		} else {
																			return [...prev, { language: selectedLanguage, body: e.target.value }] as {
																				fullLanguage?: string | undefined;
																				language?: string | undefined;
																				title?: string | undefined;
																				body?: string | undefined;
																			}[];
																		}
																	});
																}}
																value={
																	messages.length > 0
																		? messages.find((message) => message.language === selectedLanguage)?.body
																		: ""
																}
															/>
															{meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
														</div>
													)}
												</Field>
											</div>
										</>
									)}
									{!row?.messages && (
										<div className={`field-group w-100 text-center space-around ${disableFormActions ? "disabled" : ""}`}>
											<a className="btn btn-light-blu btn--grey" onClick={onClose}>{_("btn.cancel")}</a>
											<a
												className=" btn btn-light-blue"
												onClick={() => {
													isTestNotification.current = true;
													form.submit();
												}}
											>
												{_("label.notification_modal.test")}
											</a>
											<a className="btn btn-light-blue btn-dark-blue" onClick={() => {
													isTestNotification.current = false;
													form.submit();
											}}>{_("forgot_password.sent")}</a>
										</div>
									)}
								</div>
							</form>
						</>
					)}
				/>
			</div>
		</>
	);
};

export default NotificationModal;
