let apiURL = import.meta.env.VITE_API_URL;
if (import.meta.env.DEV) {
	if (window.location.hostname.endsWith(".localhost")) {
		apiURL = "http://eyeball.localhost";
	} else {
		apiURL = "http://eyeball.test";
	}
}

export default apiURL;
