import { useEffect, useRef, useState } from "react";
import type { NonPartnerPlayerData, PlayerData } from "../../types";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import { type VFC, APIResponse } from "types";
import { handleIntersectionObserver } from "../../functions";
import Loader from "components/Loader";
import * as R from "ramda";
import { Form, Field } from "react-final-form";
import Select, { type SingleValue } from "react-select";
import NonPartnerPlayerCard from "./nonPartnerPlayerCard";
import PlayerModal from "./PlayerModal";
import Migrate from "../migrate/Migrate";
import { _ } from "i18n";
import client from "libs/client";
import { useToast } from "ui-kit";

interface nonPartnerPlayerManagementProps {
	activeMenu: string;
	activeClub: any;
	countries: { [key: string]: string };
}

const nonPartnerPlayerManagement: VFC<nonPartnerPlayerManagementProps> = ({ activeMenu, activeClub, countries }) => {
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showPlayerModal, setShowPlayerModal] = useState(false);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [showLoader, setShowLoader] = useState(false);
	const isSubmitted = useRef(false);

	const [countrieslist, setCountriesList] = useState<{ value: string; label: string }[]>([]);
	const [clubNonpartnerList, setClubNonpartnerList] = useState<{ value: string; label: string; logo: string|null; country: string|null }[]>([]);
	const [nationalityList, setNationalityList] = useState<{ value: string; label: string }[]>([]);
	const [positionList, setPositionList] = useState<{ value: string; label: string }[]>([]);
	const [selectedPlayer, setSelectedPlayer] = useState<NonPartnerPlayerData | null>(null);
	const [selectedMigrationType, setSelectedMigrationType] = useState<string>("");

	const [clubData, setClubData] = useState<NonPartnerPlayerData[]>([]);

	const [showMigrate, setShowMigrate] = useState(false);

	const [clubValue, setClubValue] = useState<{ value: any; label: any } | null>(null);
	const start = useRef(0);
	const totalRecords = useRef(0);
	const search = useRef("");
	const [defaultCountry, setDefaultCountry] = useState<{ value: string; label: string } | null>(null);
	const isDefaultCountryEmpty = useRef(true);

	const { addSuccessToast, addErrorToast } = useToast();

	const filters = useRef<any>({
		country: null,
	});

	const onDeleteActionClick = (player: any) => {
		setSelectedPlayer(player);
		setShowDeleteModal(true);
	};

	const onEditActionClick = (player: any) => {
		setSelectedPlayer(player);
		setShowPlayerModal(true);
	};

	const onMigrateActionClick = (player: any, type: string) => {
		setSelectedPlayer(player);
		setSelectedMigrationType(type);
		setShowMigrate(true);
	};

	const onDelete = async () => {
		if (!selectedPlayer) { return; }

		const response = await client.deleteNPPlayer.mutation({ playerId: selectedPlayer?.id });
		if (response?.data.status === APIResponse.SUCCESS) {
			if (response?.data.message) { addSuccessToast(response?.data.message) };
			start.current = 0;
			fetchData();
		} else {
			if (response?.data.message) { addErrorToast(response?.data.message) };
		}
	};

	const onUpdatePlayer = async (values: NonPartnerPlayerData) => {
		isSubmitted.current = true;
		const response = await client.addUpdateNPPlayer.mutation({ body: { nonPartnerPlayer: values } });
		if (response?.data.status === APIResponse.SUCCESS) {
			if (response?.data.message) { addSuccessToast(response?.data.message) };
			if (values.id) {
				setClubData((prevData: NonPartnerPlayerData[]) => {
					return prevData.map((data) => {
						if (data.id === values.id) {
							const club = clubNonpartnerList.find(club => club.value == values.clubNonpartnerId);
							return {
								...values,
								npClubName: club.label,
								npClubLogo: club.logo,
								npClubCountry: club.country
							};
						}
						return data;
					});
				});
			} else {
				start.current = 0;
				fetchData();
			}
			setShowPlayerModal(false);
		} else {
			if (response?.data.message) { addErrorToast(response?.data.message) };
		}
		isSubmitted.current = false;
	};

	const onChangeCountry = (e: SingleValue<{ value: string; label: string }>) => {
		if (e?.value) {
			updateFilter(e.value, "country");
			setDefaultCountry(e);
		} else {
			updateFilter("", "country");
			setDefaultCountry(null);
		}
	};

	const fetchData = async (loadMore: boolean = false) => {

		if (isDefaultCountryEmpty.current) {
			const countriesList = await getNonPartnerCountriesData();
			if (countriesList.length > 0 && !activeClub) {
				filters.current.country = countriesList[0].value;
			}
			start.current = 0;
			isDefaultCountryEmpty.current = false;
		}

		const param = {
			start: start.current,
			length: 10,
			search: search.current,
			country: filters.current.country ? filters.current.country : "",
			clubNonpartnerId: filters.current.clubNonpartnerId ? `${filters.current.clubNonpartnerId}` : "",
		};

		client.getNPPlayers.query(param).then((res) => {
			if (res) {
				const response = res.data;
				if (response.status === APIResponse.SUCCESS) {
					totalRecords.current = response.recordsFiltered;
					setShowLoader(false);
					setClubData((prevData) => {
						if (search.current && start.current === 0) {
							return response.data;
						}
						if (R.equals(prevData, response.data) === false && loadMore) {
							const newData = [...new Set([...prevData, ...response.data])];
							return newData;
						}
						return response.data;
					});
				}
				setHasMoreData(false);
			}
		});
	};

	const updateFilter = (value: string, id: string) => {
		setShowLoader(true);
		start.current = 0;
		if (value) {
			filters.current[id] = value;
			fetchData();
		} else {
			filters.current[id] = null;
			fetchData();
		}
	};

	const getCountriesList = async () => {
		const response = await client.getCountries.query().then((res) => { return res?.data });
		if (response) {
			setCountriesList(response);
		}
	};

	const getClubNonpartnerList = async () => {
		const response = await client.getAllNPClubs.query().then((res) => { return res?.data });
		if (response) {
			setClubNonpartnerList(response);
		}
	};

	const getNationalityList = async () => {
		const response = await client.getNationalityList.query().then((res) => { return res?.data });
		if (response) {
			setNationalityList(response);
		}
	};

	const getPositionList = async () => {
		const response = await client.getPositionList.query().then((res) => { return res?.data });
		if (response) {
			setPositionList(response);
		}
	};

	const onSearchSubmit = (values: any) => {
		setShowLoader(true);
		search.current = values.search;
		start.current = 0;
		fetchData();
	};

	const getNonPartnerCountriesData = async () => {
		return client.getCountries.query().then((res) => {
			if (res) {
				const countriesList = res.data;
				if (countriesList.length > 0) {
					setCountriesList(countriesList);
					if (!activeClub) {
						setDefaultCountry(countriesList[0]);
					}
					return countriesList;
				}
			}
		});
	};

	useEffect(() => {
		if (activeMenu === "non_partner_player") {
			window.scrollTo({ top: 0, left: 0 });
			getCountriesList();
			getClubNonpartnerList();
			getNationalityList();
			getPositionList();
		}
	}, [activeMenu]);

	useEffect(() => {
		if (activeClub) {
			setShowLoader(true);
			filters.current.clubNonpartnerId = activeClub?.id;
			setClubValue({ value: activeClub?.id, label: activeClub?.name });
			fetchData();
		}
	}, [activeClub]);

	const containerRef = useRef(null);
	const options = { root: null, rootMargin: "0px", threshold: 1.0 };

	useEffect(() => {
		const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
			handleIntersectionObserver(entries, start, totalRecords, fetchData, setHasMoreData);
		}, options);

		if (containerRef.current) observer.observe(containerRef.current);

		return () => {
			if (containerRef.current) observer.unobserve(containerRef.current);
		};
	}, [containerRef, options]);

	return (
		<>
			{!showMigrate && (
				<div className="nonpartner-players-container">
					<div className="product-management-heading-container">
						<div className="product-management-heading"> {_("product.management.nonpartner.players")}</div>
						<div className="top-form-container">
							<button
								className="btn btn-light-blue"
								onClick={() => {
									setShowPlayerModal(true);
									setSelectedPlayer(null);
								}}
							>
								{_("filter.add_player")}
							</button>
						</div>
					</div>
					<div className="form-filter-container">
						<div className="filter-field">
							<label>{_("videos.table.th.country")}</label>
							<Select className="react-select" isClearable options={countrieslist} onChange={onChangeCountry} value={defaultCountry} />
						</div>
						<div className="filter-field">
							<label>{_("videos.table.th.club")}</label>
							<Select
								className="react-select"
								isClearable
								options={clubNonpartnerList}
								value={clubValue}
								onChange={(e) => {
									setClubValue(e);
									updateFilter(e?.value, "clubNonpartnerId");
								}}
							/>
						</div>
						<div className="filter-field">
							<label> {_("label.search")}</label>
							<Form
								onSubmit={onSearchSubmit}
								render={({ handleSubmit, submitting }) => (
									<>
										<form onSubmit={handleSubmit}>
											<Field name="search" type="text">
												{({ input, meta }) => (
													<>
														<input {...input} placeholder={_("search.name")} />
														{meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
													</>
												)}
											</Field>
										</form>
									</>
								)}
							/>
						</div>
					</div>
					<div className="club-grid-container">
						{clubData ? (
							clubData.map((player) => {
								return (
									<NonPartnerPlayerCard
										key={player.id}
										player={player}
										countriesList={countries}
										onDeleteActionClick={onDeleteActionClick}
										onEditActionClick={onEditActionClick}
										onMigrateActionClick={onMigrateActionClick}
									/>
								);
							})
						) : (
							<h6 className="modal-msg">{_("info.nodata_available")}</h6>
						)}
					</div>
					<div ref={containerRef}>{hasMoreData && <Loader />}</div>
				</div>
			)}
			{showMigrate && (
				<Migrate
					club={null}
					player={selectedPlayer as unknown as PlayerData}
					countries={countries}
					type={selectedMigrationType}
					onClose={() => {
						setShowMigrate(false);
						fetchData();
					}}
				/>
			)}
			{showDeleteModal && (
				<DeleteConfirmationModal
					show={showDeleteModal}
					title={`${selectedPlayer?.firstName} ${selectedPlayer?.lastName}`}
					onClose={() => {
						setShowDeleteModal(false);
						setSelectedPlayer(null);
					}}
					onDelete={() => {
						onDelete();
						setShowDeleteModal(false);
					}}
				/>
			)}
			{showPlayerModal && (
				<PlayerModal
					onClose={() => {
						setShowPlayerModal(false);
						setSelectedPlayer(null);
					}}
					onUpdatePlayer={onUpdatePlayer}
					player={selectedPlayer}
					clubs={clubNonpartnerList}
					countriesList={countries}
					nationality={nationalityList}
					position={positionList}
					isSubmitted={isSubmitted}
				/>
			)}
		</>
	);
};

export default nonPartnerPlayerManagement;
