import { _ } from "i18n";
import { FC } from "react";
import userPlaceholder from "assets/images/user-placeholder.png";
import { NonPartnerMigrationPlayer } from "pages/productManagement/types";
import { CountryCode, CountryFlag } from "ui-kit/src/flags";
interface PlayerListProps {
	players: NonPartnerMigrationPlayer[];
	disabled?: boolean;
	countriesList: { [key: string]: string };
	onPlayerSelect: (player: NonPartnerMigrationPlayer, index: number) => void;
}

const PlayerList: FC<PlayerListProps> = ({ players, disabled, countriesList, onPlayerSelect }) => {
	const publicUrl = new URL(`public`, import.meta.url);

	return (
		<div className="migration-playerslist">
			<ul className={`${disabled ? "disabled" : ""}`}>
				{players.map((player, index) => {
					return (
						<li key={player.id} className={player.selected ? "selected" : ""} onClick={() => onPlayerSelect(player, index)}>
							<div className="card-row1">
								<div className="card-logo club-players">
									<img
										src={
											player.picture !== null
												? player.picture.includes("http")
													? player.picture
													: `${publicUrl}/${player.picture}`
												: userPlaceholder
										}
									/>
								</div>
								<div className="card-details">
									<h6>
										{player.firstName} {player.lastName}
									</h6>
									{player.clubCountry && (
										<>
											<div className="playerCardBlock__geo" data-re={JSON.stringify(player)}>
												<CountryFlag code={player.clubCountry as CountryCode} />
												<span> {countriesList[player?.clubCountry as keyof {}]}</span>
											</div>
											<div className="playerCardBlock__geo">
												<span>
													{player.teamCategory &&
														player.teamName &&
														`${_("label.player.team")} : ${player.teamCategory} ${player.teamName} | `}
													{`${_("label.user.id")} : ${player.id}`}
												</span>
											</div>
										</>
									)}
								</div>
							</div>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default PlayerList;
