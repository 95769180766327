import { _, _any } from "i18n";
import "../style/squadsPage.scss";
import SelectComponent from "ui-kit/src/SelectComponent/SelectComponent";
import { useEffect, useState } from "react";
import type { SelectValue } from "ui-kit/src/MultipleSelect/types";
import PlayersTable from "components/SquadsPage/PlayersTable";
import Modal from "ui-kit/src/Modal";
import client from "libs/client";
import SquadCard from "components/SquadsPage/SquadCard";
import AddEditPlayerModal from "components/SquadsPage/AddEditPlayerModal";
import { PlayerPosition } from "types";
import { setScrollBehavior } from "features/scrollPageBehaviorProvider/scrollPageBehaviorProvider";
import { AddEditTeamModal } from "components/SquadsPage/AddEditTeamModal";
import SquadTeamsSkeleton from "components/Skeleton/SquadsPage/SquadTeamsSkeleton";
import PageControls from "components/PageControls/PageControls";
import { Link, useParams } from "react-router-dom";
import SkeletonBox from "ui-kit/src/Skeleton/SkeletonBox";

export default function TeamsPage() {
	const { id } = useParams();
	const clubId = Number(id);
	const [selectedCoach, setSelectedCoach] = useState<SelectValue>();
	const [showAddPlayerModal, setShowAddPlayerModal] = useState(false);
	const [showAddTeamModal, setShowAddTeamModal] = useState(false);
	const [showOldPlayers, setShowOldPlayers] = useState(false);
	const [pagination, setPagination] = useState({ page: 1, limit: 10 });
	const { data: clubData, isLoading: isClubLoading } = client.getClubDetail.useQuery({ clubId });

	useEffect(() => {
		setScrollBehavior();
	}, []);

	const onTeamSelect = (value: SelectValue) => {
		setSelectedTeam(value);
	};

	const onCoachSelect = (value: SelectValue) => {
		setSelectedCoach(value);
	};

	const playerPositions = [
		{ value: PlayerPosition.G, label: _any("enum.position.G.key") },
		{ value: PlayerPosition.DD, label: _any("enum.position.DD.key") },
		{ value: PlayerPosition.DC, label: _any("enum.position.DC.key") },
		{ value: PlayerPosition.DG, label: _any("enum.position.DG.key") },
		{ value: PlayerPosition.MDC, label: _any("enum.position.MDC.key") },
		{ value: PlayerPosition.MC, label: _any("enum.position.MC.key") },
		{ value: PlayerPosition.MOC, label: _any("enum.position.MOC.key") },
		{ value: PlayerPosition.MG, label: _any("enum.position.MG.key") },
		{ value: PlayerPosition.MD, label: _any("enum.position.MD.key") },
		{ value: PlayerPosition.ATT, label: _any("enum.position.ATT.key") },
		{ value: PlayerPosition.AG, label: _any("enum.position.AG.key") },
		{ value: PlayerPosition.AD, label: _any("enum.position.AD.key") },
	];

	const { data: teamList } = client.getClubTeamsList.useQuery({ id: clubId }, { queryKey: ["squadTeams"] });

	const findTeam = () => {
		const team = teamList?.data.find((team) => team.value === Number(window.location.hash.substring(1)));
		if (team) {
			return team;
		}
		return null;
	};

	const [selectedTeam, setSelectedTeam] = useState<SelectValue>(findTeam());

	const { data: teams, isLoading: isTeamsLoading } = client.getClubAllTeams.useQuery({ ...pagination, id: clubId }, { queryKey: ["squadTeams"] });
	const { data: nationalities } = client.getNationalities.useQuery();
	const { data: filteredTeams, isLoading: isFilteredTeamsLoading } = client.getTeamByIdAndCoachId.useQuery(
		{
			clubId: clubId,
			id: selectedTeam?.value ? Number(selectedTeam?.value) : undefined,
			coachId: typeof selectedCoach?.value === "number" ? selectedCoach?.value : undefined,
		},
		{ enabled: !!selectedTeam || !!selectedCoach, queryKey: ["squadTeams"] },
	);
	const { data: coaches } = client.clubCoaches.useQuery({ id: clubId });

	const changePage = (newPage: number) => {
		setPagination({ ...pagination, page: newPage });
	};

	return (
		<div className="squadsPage-container">
			<div className="squadsPage-navigation">
				<Link className="navigation-item navigationLink" to="/admin/clubs-amateur">
					{_("breadcrumb.search_club")}
				</Link>
				{">"}
				{isClubLoading ? (
					<SkeletonBox style={{ margin: "0 10px 0 10px", width: "100px", height: "12px" }} />
				) : (
					<Link className="navigation-item navigationLink" to={`/admin/club-amateur/${clubData.data.id}`}>
						{clubData.data.name}
					</Link>
				)}
				{">"}
				<div className="navigation-item">{_("app.eyeball.filter.teams")}</div>
			</div>
			<div className="clubTeams">
				<div className="teamFilters">
					<div className="filterLeft">
						<div className="filterLeft-name">{_("btn.club_teams")}</div>
						<div className="filterLeft-selects">
							<div className="filterLeft-select">
								<div className="select-label">{_("btn.filter_by_team")}</div>
								<SelectComponent items={teamList?.data} value={selectedTeam} handleChange={onTeamSelect} />
							</div>
							<div className="filterLeft-select">
								<div className="select-label">{_("btn.filter_by_coach")}</div>
								<SelectComponent items={coaches?.data} value={selectedCoach} handleChange={onCoachSelect} />
							</div>
						</div>
					</div>
					<div className="filterRight">
						<div className="filterRight-addButtons">
							<div className="addButton first" onClick={() => setShowAddTeamModal(true)}>
								+ {_("btn.add_team")}
							</div>
							<div className="addButton" onClick={() => setShowAddPlayerModal(true)}>
								+ {_("filter.add_player")}
							</div>
						</div>
						<div className="oldPlayersButton" onClick={() => setShowOldPlayers(!showOldPlayers)}>
							<span className="icon-new icon-group icon-blue" />
							{_("club.former_players")}
						</div>
					</div>
				</div>
			</div>
			{showOldPlayers && (
				<div className="squadsPage-playerTable">
					<PlayersTable
						clubId={clubId}
						playerPositions={playerPositions}
						oldPlayers={true}
						nationalities={nationalities?.data}
						teams={teamList?.data}
					/>
				</div>
			)}
			{!selectedTeam &&
				!selectedCoach &&
				(isTeamsLoading ? (
					<SquadTeamsSkeleton />
				) : (
					teams?.data.data.map((team) => (
						<SquadCard
							clubId={clubId}
							playerPositions={playerPositions}
							coaches={coaches?.data}
							nationalities={nationalities?.data}
							key={team.id}
							teamData={team}
							teams={teamList?.data}
						/>
					))
				))}
			{(selectedTeam || selectedCoach) &&
				(isFilteredTeamsLoading ? (
					<SquadTeamsSkeleton />
				) : (
					filteredTeams?.data.data?.map((team) => (
						<SquadCard
							clubId={clubId}
							playerPositions={playerPositions}
							coaches={coaches?.data}
							nationalities={nationalities?.data}
							key={team.id}
							teamData={team}
							teams={teamList?.data}
						/>
					))
				))}
			{!filteredTeams && teams?.data.pages !== undefined && teams?.data.page !== undefined && teams?.data.pages > 0 && (
				<PageControls totalPages={teams?.data.pages} currentPage={teams?.data.page} changePage={changePage} />
			)}
			<Modal show={showAddPlayerModal} title={_("filter.add_player").toUpperCase()} size="lg" onClose={() => setShowAddPlayerModal(false)}>
				<AddEditPlayerModal
					clubId={clubId}
					nationalities={nationalities?.data}
					positions={playerPositions}
					teams={teamList?.data}
					close={() => setShowAddPlayerModal(false)}
				/>
			</Modal>
			<Modal show={showAddTeamModal} title={_("filter.add_team").toUpperCase()} size="sm" onClose={() => setShowAddTeamModal(false)}>
				<AddEditTeamModal clubId={clubId} close={() => setShowAddTeamModal(false)} />
			</Modal>
		</div>
	);
}
