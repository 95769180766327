import { useEffect, useRef, useState } from "react";
import { Button, CalendarCell, CalendarGrid, CalendarGridBody, CalendarGridHeader, CalendarHeaderCell, RangeCalendar } from "react-aria-components";
import { parseDate, type CalendarDate } from "@internationalized/date";
import "./dateTimeRangeCalendar.css";
import { I18nProvider, useDateFormatter } from "react-aria";
import { _, _any, type Locale } from "i18n";
import { useOnClickOutside } from "usehooks-ts";
import { ArrowLeft, ArrowRight } from "ui-kit/src/icons";

interface BaseRangeCalendarProps {
	start: string;
	end: string;
	startTime?: string;
	endTime?: string;
	onChange: (start: string, end: string) => void;
	placeholder?: string;
	apiFormat?: boolean;
	iconArrow?: boolean;
}

interface DateRangeCalendarProps extends BaseRangeCalendarProps {
	locale: Locale;
	placeholder?: string;
}

interface RangeValue {
	start: CalendarDate;
	end: CalendarDate;
}

function parseDateTime(dateTimeStr: string) {
	const [dateStr, timeStrWithTz] = dateTimeStr.split("T");
	const date = parseDate(dateStr);
	const [timeStr] = timeStrWithTz ? timeStrWithTz.split("Z") : ["00:00:00"];
	return { date, time: timeStr };
}

function ensureTimeFormat(time: string) {
	const [hours, minutes, seconds] = time.split(":");
	return `${hours || "00"}:${minutes || "00"}:${seconds || "00"}`;
}

function BaseRangeTimeCalendar({ start, end, onChange, placeholder, apiFormat = true, iconArrow = false }: BaseRangeCalendarProps) {
	const dateFormatter = useDateFormatter({
		month: "long",
		year: "numeric",
	});

	const currentDate = new Date().toISOString().split("T")[0];
	const containerRef = useRef<HTMLDivElement>(null);
	const [show, setShow] = useState(false);

	const [range, setRange] = useState({
		start: parseDate(currentDate),
		end: parseDate(currentDate),
		startTime: "00:00:00",
		endTime: "23:59:59",
	});

	useEffect(() => {
		if (start && end) {
			const parsedStart = parseDateTime(start);
			const parsedEnd = parseDateTime(end);

			setRange({
				start: parsedStart.date,
				end: parsedEnd.date,
				startTime: ensureTimeFormat(parsedStart.time || "00:00:00"),
				endTime: ensureTimeFormat(parsedEnd.time || "23:59:59"),
			});
		} else {
			setRange({
				start: parseDate(currentDate),
				end: parseDate(currentDate),
				startTime: "00:00:00",
				endTime: "23:59:59",
			});
		}
	}, [start, end, currentDate]);

	useOnClickOutside(containerRef, () => {
		setShow(false);
	});

	const dayStrings: Record<string, string> = {
		mon: "datepicker.days_short.monday",
		tue: "datepicker.days_short.tuesday",
		wed: "datepicker.days_short.wednesday",
		thu: "datepicker.days_short.thursday",
		fri: "datepicker.days_short.friday",
		sat: "datepicker.days_short.saturday",
		sun: "datepicker.days_short.sunday",
	};

	const getDayString = (day: string) => {
		return dayStrings[day.toLowerCase()] || day;
	};

	const formatDateTime = (date: CalendarDate, time: string) => {
		return time
			? `${date.year}-${String(date.month).padStart(2, "0")}-${String(date.day).padStart(2, "0")}T${ensureTimeFormat(time)}Z`
			: `${date.year}-${String(date.month).padStart(2, "0")}-${String(date.day).padStart(2, "0")}`;
	};

	const formatOutputDateTime = (date: CalendarDate, time: string) => {
		const formattedTime = ensureTimeFormat(time || (date === range.start ? "00:00:00" : "23:59:59"));
		return apiFormat
			? `${date.year}-${String(date.month).padStart(2, "0")}-${String(date.day).padStart(2, "0")}T${formattedTime}Z`
			: formatDateTime(date, formattedTime);
	};

	const handleApply = () => {
		const formattedStart = formatOutputDateTime(range.start, range.startTime);
		const formattedEnd = formatOutputDateTime(range.end, range.endTime);
		onChange(formattedStart, formattedEnd);
		setShow(false);
	};

	const handleCancel = () => {
		onChange("", "");
		setRange({
			start: parseDate(currentDate),
			end: parseDate(currentDate),
			startTime: "00:00:00",
			endTime: "23:59:59",
		});
		setShow(false);
	};

	const handleTimeChange = (type: "startTime" | "endTime", time: string) => {
		setRange((prevRange) => ({
			...prevRange,
			[type]: ensureTimeFormat(time),
		}));
	};

	const handleDateChange = (value: RangeValue) => {
		setRange((prevRange) => ({
			...prevRange,
			start: value.start,
			end: value.end,
		}));
	};

	function formatDateTimeForDisplay(date: CalendarDate, time: string) {
		const formattedDate = `${String(date.day).padStart(2, "0")}/${String(date.month).padStart(2, "0")}/${date.year}`;
		const formattedTime = ensureTimeFormat(time);
		return `${formattedDate} ${formattedTime}`;
	}

	return (
		<div ref={containerRef}>
			<div
				className="calendarInput-container"
				onClick={() => {
					setShow(!show);
				}}
			>
				{iconArrow && !(start && end) && <span className="icon-new icon-select-arrow-down" />}
				<input
					readOnly
					placeholder={placeholder}
					value={
						start && `${formatDateTimeForDisplay(range.start, range.startTime)} - ${formatDateTimeForDisplay(range.end, range.endTime)}}`
					}
					className={`calendarInput ${start && end ? "hasValue" : ""}`}
				/>
				{start && end && <span onClick={handleCancel} className="icon-new icon-cross icon-white" />}
			</div>
			{show && (
				<div className="calendarContainer">
					<RangeCalendar value={{ start: range.start, end: range.end }} onChange={handleDateChange} visibleDuration={{ months: 2 }}>
						{({ state: { visibleRange, timeZone } }) => (
							<>
								<header className="calendarHeading">
									<Button slot="previous">
										<ArrowLeft />
									</Button>
									{dateFormatter.formatRange(visibleRange.start.toDate(timeZone), visibleRange.end.toDate(timeZone))}
									<Button slot="next">
										<ArrowRight />
									</Button>
								</header>
								<div style={{ display: "flex", gap: 30, overflow: "auto", flexWrap: "wrap", justifyContent: "center" }}>
									<CalendarGrid weekdayStyle="short">
										<CalendarGridHeader>
											{(day) => (
												<CalendarHeaderCell style={{ color: "var(--blue)" }}>{_any(getDayString(day))}</CalendarHeaderCell>
											)}
										</CalendarGridHeader>
										<CalendarGridBody>{(date) => <CalendarCell date={date} />}</CalendarGridBody>
									</CalendarGrid>
									<CalendarGrid weekdayStyle="short" offset={{ months: 1 }}>
										<CalendarGridHeader>
											{(day) => (
												<CalendarHeaderCell style={{ color: "var(--blue)" }}>{_any(getDayString(day))}</CalendarHeaderCell>
											)}
										</CalendarGridHeader>
										<CalendarGridBody>{(date) => <CalendarCell date={date} />}</CalendarGridBody>
									</CalendarGrid>
								</div>
							</>
						)}
					</RangeCalendar>
					<div className="timePickers">
						<label>
							Start Time:
							<input type="time" value={range.startTime} onChange={(e) => handleTimeChange("startTime", e.target.value)} step="1" />
						</label>
						<label>
							End Time:
							<input type="time" value={range.endTime} onChange={(e) => handleTimeChange("endTime", e.target.value)} step="1" />
						</label>
					</div>
					<div className="calendarBottom">
						<span>{`${formatDateTimeForDisplay(range.start, range.startTime)} - ${formatDateTimeForDisplay(range.end, range.endTime)}`}</span>
						<Button className="calendarButton-cancel" onPress={handleCancel}>
							{_("btn.cancel")}
						</Button>
						<Button className="calendarButton-apply" onPress={handleApply}>
							{_("daterange.applyLabel")}
						</Button>
					</div>
				</div>
			)}
		</div>
	);
}

export function DateTimeRangeCalendar({ placeholder, locale, start, end, onChange, ...props }: DateRangeCalendarProps) {
	return (
		<I18nProvider locale={locale}>
			<BaseRangeTimeCalendar placeholder={placeholder} start={start} end={end} onChange={onChange} {...props} />
		</I18nProvider>
	);
}
