import { VFC } from "types";

const Loader: VFC = () => {
	return (
		<div className="loading-type-product_management">
			<svg className="icon icon-loading"/>
		</div>
	);
};

export default Loader;
