import { _ } from "i18n";
import ContinentContainer from "./ContinentContainer";
import { Continent, CountryData, CountrySelection, UserCountrySelection } from "./types";
import { useState } from "react";
import ContinentSelector from "./ContinentSelector";
import Modal from "../Modal";

interface CountrySelectionModalProps {
	countryData: CountryData;
	selection: UserCountrySelection["countries"];
	onSave: (selection: CountrySelection) => void;
	onClose: () => void;
}

function getSelectedContinentsInitialValue(selectedCountries: CountrySelection): Continent[] {
	return Object.entries(selectedCountries)
		.map(([continent, selectedCountries]) => {
			if (selectedCountries.length > 0) {
				return continent;
			}
		})
		.filter(Boolean) as Continent[];
}

export function CountrySelectionModal({ countryData, selection, onSave, onClose }: CountrySelectionModalProps) {
	const [selectedContinents, setSelectedContinents] = useState<Continent[]>(getSelectedContinentsInitialValue(selection));
	const [userSelectedCountries, setUserSelectedCountries] = useState(selection);

	const getEnabledCountries = (continent: Continent) => {
		const countries: string[] = [];
		const continentData = countryData[continent];

		if (continentData) {
			for (const country in continentData) {
				const data = continentData[country];
				if (data.enabled) {
					countries.push(data.code);
				}
			}
		}
		return countries;
	};

	const handleContinentSelect = (continent: Continent) => {
		let continents: Continent[] = [];
		if (!selectedContinents.includes(continent)) {
			continents = [...selectedContinents, continent];
		}

		setSelectedContinents(continents);
	};

	const handleContinentDeselect = (continent: Continent) => {
		let continents: Continent[] = [];
		if (selectedContinents.includes(continent)) {
			continents = selectedContinents.filter((value: string) => value !== continent);
		}

		setSelectedContinents(continents);
	};

	const handleSelectCountries = (continent: Continent, countries: string[]) => {
		const newSelection = userSelectedCountries;
		newSelection[continent] = [...newSelection[continent], ...countries];

		setUserSelectedCountries({ ...newSelection });
	};

	const handleDeselectCountries = (continent: Continent, countries: string[]) => {
		const newSelection = userSelectedCountries;
		newSelection[continent] = [...newSelection[continent].filter((countryCode) => !countries.includes(countryCode))];

		setUserSelectedCountries({ ...newSelection });
	};

	const handleSelectAllCountries = (continent: Continent) => {
		const newSelection = userSelectedCountries;

		newSelection[continent] = getEnabledCountries(continent);
		setUserSelectedCountries({ ...newSelection });
	};

	const handleDeselectAllCountries = (continent: Continent) => {
		const newSelection = userSelectedCountries;
		newSelection[continent] = [];

		setUserSelectedCountries({ ...newSelection });
	};

	const continents = Object.keys(countryData).sort() as Continent[];

	return (
		<Modal size="xl" show={true} onClose={onClose} title={_("country.selection.choose.country")}>
			<div className="countrySelection-containerNew">
				<div className="continent-list">
					{continents.map((continent) => {
						return (
							<ContinentSelector
								key={continent}
								continent={continent}
								isSelected={selectedContinents.includes(continent)}
								onSelect={handleContinentSelect}
								onDeselect={handleContinentDeselect}
							/>
						);
					})}
				</div>
				{continents.map((continent) => {
					if (selectedContinents.includes(continent)) {
						return (
							<ContinentContainer
								key={continent}
								continent={continent}
								data={countryData[continent]}
								selection={userSelectedCountries}
								onSelect={handleSelectCountries}
								onDeselect={handleDeselectCountries}
								onSelectAll={handleSelectAllCountries}
								onDeselectAll={handleDeselectAllCountries}
							/>
						);
					}
				})}
				<div className="modal__footer">
					<button
						className="footer-action btn"
						onClick={() => {
							onSave(userSelectedCountries);
						}}
					>
						{_("country.selection.save.btn")}
					</button>
				</div>
			</div>
		</Modal>
	);
}
