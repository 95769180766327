import { _ } from "i18n";
import LoginForm from "features/auth/LoginForm";
import { useI18n } from "features/translation/context";
//import video from "assets/videos/EyeballLandingPage_Background.mp4";
import EyeballLogo from "assets/images/logo-eyeball-transparent.png";
import "../style/login.css";
import LangSwitch from "ui-kit/src/Header/LangSwitch/LangSwitch";
import { useSearchParams } from "react-router-dom";

export default function LoginPage() {
	const { changeLocale, locale } = useI18n();
	const [params] = useSearchParams();

	return (
		<>
			<div className="login-video-container">
				{/* <video src={video} autoPlay muted playsInline loop /> */}
				<div className="top-gradient"> </div>
			</div>
			<div className="container medium login-form-outer-container">
				<img className="adminLogin-logo" src={EyeballLogo} />
				<div className="adminLogin-headerRight">
					<LangSwitch loginPage={true} onChange={changeLocale} selectedLocale={locale} />
				</div>
				<div className="login-form-container">
					<h1> {_("label.loginToEyeballAdmin")} </h1>
					<LoginForm redirect={params.get("next")} />
				</div>
			</div>
		</>
	);
}
