import "style/videoMetadataPage.css";
import { useEffect } from "react";
import { setScrollBehavior } from "features/scrollPageBehaviorProvider/scrollPageBehaviorProvider";
import { VideosAllTable } from "features/videoUploads/tables/VideosAllTable";

export function VideoUploadsAllPage() {
	useEffect(() => {
		setScrollBehavior();
	}, []);

	return (
		<div className="videoMetadataList-container">
			<div className="listHeadline">All Videos:</div>
			<VideosAllTable />
		</div>
	);
}
