import { _ } from "i18n";
import { VFC } from "types";
import { useState } from "react";
import AsyncSelect from "react-select/async";
import client from "libs/client";
interface AssignSubscriptionModalProps {
	onClose: () => void;
	onAssignSubscription: (values: any) => void;
}

const AssignSubscriptionModal: VFC<AssignSubscriptionModalProps> = ({ onClose, onAssignSubscription }) => {
	const [selectedClub, setSelectedClub] = useState<{ value: string | undefined; label: string | undefined }>();

	const onAssign = () => {
		onAssignSubscription(selectedClub);
	};

	const clubSearch = async (term: string): Promise<{ label: string | number; value: string | number; }[] | []> => {
		if (term === "") return [];
		return await client.clubProSearch.query({search : term}).then((res) => { return res?.data }) as { label: string | number; value: string | number; }[];
	};

	return (
		<>
			<div className="form-group flex-column">
				<label className="required"> {_("label.assign_into")} </label>
				<AsyncSelect
					cacheOptions
					loadOptions={clubSearch}
					value={selectedClub}
					isClearable={true}
					placeholder={_("label.search")}
					className={"react-select"}
					onChange={(e: any) => {
						setSelectedClub(e);
					}}
				/>
			</div>
			<div className="form__footer">
				<button className="btn-primary btn" onClick={() => onAssign()}>
					{_("label.assign")}
				</button>
				<button className="btn--cancel" onClick={() => onClose()}>
					{_("btn.cancel")}
				</button>
			</div>
		</>
	);
};

export default AssignSubscriptionModal;
