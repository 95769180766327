import type { ContextMenuPosition } from "ui-kit";
import type { StateCreator } from "zustand";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface Actions {
	openRightClickMenu: (url: string, position: ContextMenuPosition) => void;
	closeRightClickMenu: () => void;
}

interface State {
	isOpen: boolean;
	actions: Actions;
	url?: string;
	position: ContextMenuPosition;
}

type Store = StateCreator<State>;

const rightClickMenuStore: Store = (set) => ({
	isOpen: false,
	position: {
		x: 0,
		y: 0,
	},
	actions: {
		openRightClickMenu: (url, position) => {
			set((state) => {
				return { isOpen: true, url, position };
			});
		},
		closeRightClickMenu: () => {
			set((state) => {
				return { isOpen: false, url: undefined, position: undefined };
			});
		},
	},
});

export const useRightClickMenuStore = create<State>()(
	devtools(
		// persist(store, {
		// 	name: "admin-storage",
		// }),
		rightClickMenuStore,
		{
			enabled: import.meta.env.DEV,
			name: "rightClick",
		},
	),
);
