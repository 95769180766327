import { setScrollBehavior } from "features/scrollPageBehaviorProvider/scrollPageBehaviorProvider";
import { useUserData } from "features/userData/context";
import { useEffect, useState } from "react";
import { MenuLink } from "types";
import Section from "./Section";
import {Sidebar} from "./Sidebar";
import { getMenuItems } from "./functions";

export function ProductManagement() {
	const [activeMenu, setActiveMenu] = useState<string | null>(null);
	const { productManagementOptions, isSuperAdmin } = useUserData();


	useEffect(() => {
		setScrollBehavior();
	}, []);

	useEffect(() => {
		if (productManagementOptions && Object.values(productManagementOptions).length) {
			if (isSuperAdmin) {
				setActiveMenu(MenuLink.NON_PARTNER_CLUB);
				return;
			}
			const menu = Object.values(productManagementOptions)[0];

			const active = menu?.subMenuItems ? Object.values(menu?.subMenuItems)[0]?.id : menu.id;
			if (active) {
				setActiveMenu(active);
			}
		}
	}, [isSuperAdmin, productManagementOptions]);

	const onMenuClick = (id: string) => {
		setActiveMenu(id);
	};

	const menuItems = getMenuItems(productManagementOptions)

	return (
		<>
			<div className="product-management-container">
				<Sidebar onMenuClick={onMenuClick} activeMenu={activeMenu} menuItems={menuItems} />
				<Section activeMenu={activeMenu} onMenuClick={onMenuClick} />
			</div>
		</>
	);
}
