import { useState, useEffect } from "react";
import DatagridV2 from "ui-kit/src/Datagrid/DatagridV2";
import UserPlaceholder from "assets/images/user-placeholder.png";
import Modal from "ui-kit/src/Modal/Modal";
import { _, _any } from "i18n";
import SelectComponent from "ui-kit/src/SelectComponent/SelectComponent";
import type { SelectValue } from "ui-kit/src/MultipleSelect/types";
import client from "libs/client";
import type { SimpleColumn } from "ui-kit/src/Datagrid/DatagridV2";
import type { ClubPlayersInfo, getNationalitiesResponse200 } from "libs/apiClient";
import AddEditPlayerModal from "./AddEditPlayerModal";
import { useToast } from "ui-kit";
import type { PlayerPosition } from "types";
import GenerateMobileLinkModal from "./GenerateMobileLinkModal";
import TableSkeleton from "ui-kit/src/Skeleton/TableSkeleton";
import BatchUpdateModal from "./BatchUpdateModal";
import DeletePlayerModal from "./DeletePlayerModal";

export interface Player extends ClubPlayersInfo {
	actions?: boolean;
	select?: boolean;
}

interface PlayersTableProps {
	clubId: number;
	oldPlayers?: boolean;
	teamId?: number;
	nationalities?: getNationalitiesResponse200;
	teams?: { value: string | number; label: string }[];
	playerPositions: {
		value: PlayerPosition;
		label: string;
	}[];
}

type SortConfig = {
	name: string | null;
	sort: "asc" | "desc" | null;
};

export default function PlayersTable({ oldPlayers, teamId, nationalities, teams, playerPositions, clubId }: PlayersTableProps) {
	const { addSuccessToast, addErrorToast } = useToast();
	const [showEditModal, setShowEditModal] = useState(false);
	const [showBatchModal, setShowBatchModal] = useState(false);
	const [showAppLinkModal, setShowAppLinkModal] = useState(false);
	const [showDeletePlayerModal, setShowDeletePlayerModal] = useState(false);
	const [deletePlayerInfo, setDeletePlayerInfo] = useState<Player>();
	const [position, setPosition] = useState<SelectValue>();
	const [strongFoot, setStrongFoot] = useState<SelectValue>();
	const [nationality, setNationality] = useState<SelectValue>();
	const [pagination, setPagination] = useState<{ pageIndex: number; pageSize: number }>({ pageIndex: 0, pageSize: 30 });
	const [search, setSearch] = useState("");
	const [sortConfig, setSortConfig] = useState<SortConfig>({ name: null, sort: null });
	const [modalPlayerData, setModalPlayerData] = useState<ClubPlayersInfo>();
	const [selectedPlayers, setSelectedPlayers] = useState<Player[]>([]);
	const [queryData, setQueryData] = useState({
		page: pagination.pageIndex + 1,
		limit: pagination.pageSize,
		nationality: nationality?.value as string,
		strongestFoot: strongFoot?.value as string,
		position: position?.value as string,
		search: search,
		order: sortConfig.name ? { name: sortConfig.name, sort: sortConfig.sort } : undefined,
	});

	const { data: oldPlayersList, isInitialLoading: oldPlayersLoading } = client.formerPlayers.useQuery(
		{ ...queryData, clubId: clubId },
		{
			enabled: oldPlayers,
			queryKey: ["squadPlayers"],
		},
	);
	const { data: teamPlayers, isInitialLoading: teamPlayersLoading } = client.teamPlayers.useQuery(
		{ ...queryData, id: teamId ?? 0, clubId: clubId },
		{ enabled: !!teamId, queryKey: ["squadPlayers"] },
	);
	const deleteTeamMutation = client.deleteTeam.useMutation({
		onSuccess: (res) => {
			addSuccessToast(res?.data.message ?? "");
			client.queryClient.invalidateQueries({
				predicate: (query) => query.queryKey.includes("squadTeams"),
			});
		},
		onError: (res) => {
			if (res.status !== 403) {
				addErrorToast(res.message);
			}
		},
	});

	const setVipMutation = client.updateVip.useMutation({
		onSuccess: (res) => {
			addSuccessToast(res?.data.message ?? "");
			client.queryClient.invalidateQueries({
				predicate: (query) => query.queryKey.includes("squadPlayers"),
			});
		},
		onError: (res) => {
			if (res.status !== 403) {
				addErrorToast(res.message);
			}
		},
	});

	const strongFootOptions = [
		{ value: "left", label: _("label.player.strongest_foot.left") },
		{ value: "right", label: _("label.player.strongest_foot.right") },
	];

	useEffect(() => {
		setQueryData({
			page: pagination.pageIndex + 1,
			limit: pagination.pageSize,
			nationality: nationality?.value as string,
			strongestFoot: strongFoot?.value as string,
			position: position?.value as string,
			search: search,
			order: sortConfig.name ? { name: sortConfig.name, sort: sortConfig.sort } : undefined,
		});
	}, [pagination, position, strongFoot, nationality, search, sortConfig]);

	const headerSort = (name: string, label: string) => {
		const toggleSortOrder = () => {
			let newOrder: "asc" | "desc" | null = "asc";
			if (sortConfig.name === name) {
				if (sortConfig.sort === "asc") {
					newOrder = "desc";
				} else if (sortConfig.sort === "desc") {
					newOrder = null;
				}
			}
			setSortConfig({ name: newOrder ? name : null, sort: newOrder });
		};

		return (
			<div onClick={toggleSortOrder} style={{ cursor: "pointer" }}>
				{_any(label)}
				{sortConfig.name === name && sortConfig.sort ? (sortConfig.sort === "asc" ? "↑" : "↓") : ""}
			</div>
		);
	};

	const getCountryName = (code: string | null) => {
		if (code) {
			return nationalities?.find((nationality) => nationality.value === code)?.label;
		}
		return "-";
	};

	const handleSelectPlayer = (player: Player) => {
		setSelectedPlayers((prevSelected) =>
			prevSelected.find((p) => p.id === player.id) ? prevSelected.filter((p) => p.id !== player.id) : [...prevSelected, player],
		);
	};

	const handleSelectAllPlayers = () => {
		const allPlayers = getPlayerData();
		const allSelected = selectedPlayers.length === allPlayers.length;
		setSelectedPlayers(allSelected ? [] : allPlayers);
	};

	const playerColumns: SimpleColumn<Player>[] = [
		{
			id: "select",
			key: "select",
			header: () => (
				<input
					className="squadTable-checkbox"
					type="checkbox"
					checked={selectedPlayers.length === getPlayerData().length}
					onChange={handleSelectAllPlayers}
				/>
			),
			cell: (value, row) => (
				<input type="checkbox" checked={selectedPlayers.some((player) => player.id === row.id)} onChange={() => handleSelectPlayer(row)} />
			),
		},
		{
			id: "photo",
			key: "photo",
			header: () => <span className="icon-new icon-person icon-12" />,
			cell: (value, row) => (
				<div className="squadTable-photo">
					<img src={row.photo ? row.photo : UserPlaceholder} />
				</div>
			),
		},
		{ key: "firstName", header: () => headerSort("firstName", "app.coach.squad.add_player_first_name_title") },
		{ key: "lastName", header: () => headerSort("lastName", "app.coach.squad.add_player_last_name_title") },
		{
			id: "position",
			key: "position",
			header: () => (
				<SelectComponent
					menuPortalTarget
					placeholder={_("game.stats.table.position")}
					items={playerPositions}
					value={position}
					handleChange={setPosition}
				/>
			),
			cell: (value, row) =>
				`${row.position ? _any(`enum.position.${row.position}.key`) : "-"}${
					row.position2 ? `/${_any(`enum.position.${row.position2}.key`)}` : ""
				}`,
		},

		{
			key: "strongestFoot",
			header: () => (
				<SelectComponent
					menuPortalTarget
					placeholder={_("label.player.strongest_foot")}
					items={strongFootOptions}
					value={strongFoot}
					handleChange={setStrongFoot}
				/>
			),
			cell: (value, row) => <div className="squadTable-strongFoot">{value ?? "-"}</div>,
		},
		{
			id: "height",
			key: "height",
			header: () => headerSort("height", "app.player.home.info.height"),
			cell: (value, row) => `${value ? `${value} cm` : "-"}`,
		},
		{
			id: "weight",
			key: "weight",
			header: () => headerSort("weight", "app.player.home.info.weight"),
			cell: (value, row) => `${value ? `${value} kg` : "-"}`,
		},
		{
			id: "birthday",
			key: "birthday",
			header: () => headerSort("birthday", "label.player.birthday"),
			cell: (value, row) => `${value}`,
		},
		{
			id: "nationality",
			key: "nationality",
			header: () => (
				<SelectComponent
					menuPortalTarget
					placeholder={_("player.nationality")}
					items={nationalities}
					value={nationality}
					handleChange={setNationality}
				/>
			),
			cell: (value, row) =>
				`${row.nationality2 ? `${getCountryName(row.nationality)}/${getCountryName(row.nationality2)}` : getCountryName(row.nationality)}`,
		},
		{
			id: "vip",
			key: "vip",
			header: () => headerSort("vip", "label.player.vip"),
			cell: (value, row) => (
				<input type="checkbox" checked={row.vip} onChange={(e) => setVipMutation.mutate({ id: row.id, vip: e.target.checked })} />
			),
		},
		{
			key: "actions",
			header: _("label.action"),
			cell: (value, row) => (
				<div className="squadTable-actions">
					<div
						className="squadTable-actionButton"
						onClick={() => {
							setModalPlayerData(row);
							setShowEditModal(true);
						}}
					>
						<span className="icon-crayon icon-new icon-grey icon-16" />
					</div>
					<div
						className="squadTable-actionButton"
						onClick={() => {
							setDeletePlayerInfo(row);
							setShowDeletePlayerModal(true);
						}}
					>
						<span className="icon-trash-new icon-new icon-grey icon-16" />
					</div>
					<div
						onClick={() => {
							setModalPlayerData(row);
							setShowAppLinkModal(true);
						}}
						className="squadTable-actionButton"
					>
						<span className="icon-generate-link icon-new icon-grey icon-18" />
					</div>
				</div>
			),
		},
	];

	const resultCount = [
		{ value: 10, label: _("label.show_table_results", { numOfResults: "10" }) },
		{ value: 20, label: _("label.show_table_results", { numOfResults: "20" }) },
		{ value: 30, label: _("label.show_table_results", { numOfResults: "30" }) },
		{ value: 40, label: _("label.show_table_results", { numOfResults: "40" }) },
		{ value: 50, label: _("label.show_table_results", { numOfResults: "50" }) },
	];

	const getPlayerData = (): Player[] => {
		if (oldPlayers && oldPlayersList) {
			return oldPlayersList?.data.data;
		}
		if (teamId && teamPlayers) {
			return teamPlayers?.data.data;
		}
		return [];
	};

	const deleteTeam = () => {
		deleteTeamMutation.mutate({ id: teamId ?? 0 });
	};

	const pageCount = oldPlayers && oldPlayersList?.data.pages ? oldPlayersList?.data.pages : teamPlayers ? teamPlayers?.data.pages : 1;

	// biome-ignore lint/correctness/useExhaustiveDependencies: exhaustive dependencies not desirable
	useEffect(() => {
		if (teamPlayers?.data.pages !== 0 && oldPlayersList?.data.pages !== 0) {
			if (oldPlayersList && oldPlayers && pagination.pageIndex + 1 > oldPlayersList?.data.pages) {
				setPagination({ ...pagination, pageIndex: oldPlayersList?.data.pages - 1 });
			}
			if (teamPlayers && pagination.pageIndex + 1 > teamPlayers.data.pages) {
				setPagination({ ...pagination, pageIndex: teamPlayers?.data.pages - 1 });
			}
		}
	}, [teamPlayers?.data.pages, oldPlayersList?.data.pages]);

	return (
		<div>
			<div className="squadTable-topControls">
				<div className="topControls-select">
					<SelectComponent
						menuPortalTarget
						items={resultCount}
						value={{ value: pagination.pageSize, label: _("label.show_table_results", { numOfResults: pagination.pageSize }) }}
						handleChange={(value) => setPagination({ pageIndex: 0, pageSize: Number(value?.value) })}
						isCleareable={false}
					/>
				</div>

				<div className="controls-right">
					{selectedPlayers.length > 0 && (
						<div className="batchUpdate-button" onClick={() => setShowBatchModal(true)}>
							<span className="icon-new icon-16 icon-batch-update" />
							{_("label.batch.update")}
						</div>
					)}
					<input
						className="topControls-search"
						placeholder={_("label.search")}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								const target = e.target as HTMLInputElement;
								setSearch(target.value);
							}
						}}
					/>
				</div>
			</div>
			{!(oldPlayersLoading || teamPlayersLoading) &&
			!(teamPlayers?.data.countWithoutFilter || (oldPlayers && oldPlayersList?.data.countWithoutFilter)) ? (
				<div className="squadTable-noPlayers">
					{_("message.no_players_in_team")}
					{!oldPlayers && !search && (
						<div className="deleteTeamButton" onClick={() => deleteTeam()}>
							{_("filter.delete_team")}
						</div>
					)}
				</div>
			) : oldPlayersLoading || teamPlayersLoading ? (
				<TableSkeleton />
			) : (
				<>
					<DatagridV2<Player>
						noResults={_("search_no_result.players")}
						data={getPlayerData()}
						simpleColumns={playerColumns}
						pagination={pagination}
						setPagination={setPagination}
						pageCount={pageCount}
					/>
					<Modal
						show={showEditModal}
						title={_("app.coach.squad.edit_player_title").toUpperCase()}
						size="lg"
						onClose={() => setShowEditModal(false)}
					>
						<AddEditPlayerModal
							clubId={clubId}
							positions={playerPositions}
							nationalities={nationalities}
							playerData={modalPlayerData}
							teams={teams}
							close={() => setShowEditModal(false)}
						/>
					</Modal>
					<Modal show={showBatchModal} title={_("label.batch.update").toUpperCase()} size="lg" onClose={() => setShowBatchModal(false)}>
						<BatchUpdateModal
							selectedPlayers={selectedPlayers}
							clearSelecterdPlayers={() => setSelectedPlayers([])}
							clubId={clubId}
							positions={playerPositions}
							nationalities={nationalities}
							teams={teams}
							close={() => setShowBatchModal(false)}
						/>
					</Modal>
					<Modal title="" show={showAppLinkModal} size="sm" onClose={() => setShowAppLinkModal(false)}>
						<GenerateMobileLinkModal playerData={modalPlayerData} close={() => setShowAppLinkModal(false)} />
					</Modal>
					<Modal title="" show={showDeletePlayerModal} size="sm" onClose={() => setShowDeletePlayerModal(false)}>
						<DeletePlayerModal deletePlayerInfo={deletePlayerInfo} close={() => setShowDeletePlayerModal(false)} />
					</Modal>
				</>
			)}
		</div>
	);
}
