import { useRef, useState } from "react";
import { VFC } from "types";

interface TristateButtonProps {
	label: string;
	updateFilter: (value: string, id: string) => void;
}

const TristateButton: VFC<TristateButtonProps> = ({ label, updateFilter }) => {
	const [status, setStatus] = useState<number | null>(null);

	const tristateRef = useRef<HTMLButtonElement>(null);

	const onShowcaseTristateChange = () => {
		if (tristateRef.current) {
			const element: HTMLElement = tristateRef.current;
			const elementStatus = element?.lastElementChild?.lastElementChild?.lastElementChild;

			if (element.dataset.state === "") {
				element.dataset.state = "1";
				elementStatus?.classList.add("status__circle--ok");
				setStatus(1);
				updateFilter("1", "showcase");
			} else if (element.dataset.state === "1") {
				element.dataset.state = "0";
				elementStatus?.classList.add("status__circle--nook");
				setStatus(0);
				updateFilter("0", "showcase");
			} else {
				element.dataset.state = "";
				elementStatus?.classList.add("status__circle");
				setStatus(null);
				updateFilter("", "showcase");
			}
		}
	};

	return (
		<button
			ref={tristateRef}
			data-state={""}
			className="tableFilter__btn video-js-th-toggleFilter-btn"
			onClick={() => onShowcaseTristateChange()}
		>
			<span className="tableFilter__btn-content">
				{label}
				<span className={`status__circle ${status === 1 ? "status__circle--ok" : status === 0 ? "status__circle--nook" : ""}`}></span>
			</span>
		</button>
	);
};

export default TristateButton;
