import type { PartialGuardianApprovalResponse } from "libs/apiClient"
import { ContentCell } from "./ContentCell";
import { formatDate } from "pages/PlayerUserDetailPage";

interface GuardianApprovalDetailProps {
    approvalData: PartialGuardianApprovalResponse[]
}

export function GuardianApprovalDetail({ approvalData }: GuardianApprovalDetailProps) {
    const renderContent = <K extends keyof PartialGuardianApprovalResponse>(
        key: K,
        value: PartialGuardianApprovalResponse[K]
    ) => {
        switch (key) {
            case "player":
                return <ContentCell key={key} title="Player Id" content={(typeof value === "object" && value?.id) ? value.id : "-"} />
            case "approvedAt":
                return <ContentCell key={key} title="Approved At" content={formatDate(typeof value === "string" ? value : null)} />
            case "createdAt":
                return <ContentCell key={key} title="Created At" content={formatDate(typeof value === "string" ? value : null)} />
            case "updatedAt":
                return <ContentCell key={key} title="Updated At" content={formatDate(typeof value === "string" ? value : null)} />
            default: {
                const fieldLabel = key
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, (str) => str.toUpperCase());
                return <ContentCell key={key} title={fieldLabel} content={(typeof value !== "object" && value) ? value : "-"} />
            }
        }
    }

    return (
        <div className="userDetails-content">
            {approvalData.map((approval, index) => (
                <div className="guardianApproval" key={approval.id}>
                    <div className="approvalHeader"> Guardian Approval Request - #{index + 1}</div>
                    <div className="approvalContent">
                        {Object.entries(approval).map(([key, value]) => {
                            const typedKey = key as keyof PartialGuardianApprovalResponse;
                            return renderContent(typedKey, value);
                        })}
                    </div>
                </div>
            ))}
        </div>
    )
}