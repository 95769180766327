import { Form, FormSpy } from "react-final-form";
import type { MediaPlayerInstance } from "@vidstack/react";
import type { FormValues, DefaultFormValues, TimelineValues } from "./types";
import { TimeInput } from "./timeField";
import { omit } from "remeda";
import { convertFormValuesToTimelineValues, validate } from "./functions";

interface MetadataFormProps {
	player: MediaPlayerInstance | null;
	onSubmit: (values: FormValues) => void;
	defaults: DefaultFormValues;
	onChange: (values: TimelineValues) => void;
}

export function MetadataForm({ player, defaults, onChange, onSubmit }: MetadataFormProps) {
	return (
		<Form<FormValues>
			validate={validate}
			onSubmit={onSubmit}
			initialValues={defaults}
			render={({ handleSubmit, values }) => (
				<form onSubmit={handleSubmit}>
					<FormSpy
						subscription={{ valid: true, values: true, touched: true, pristine: true }}
						onChange={({ values, valid, pristine }: { values: Partial<FormValues> | undefined; valid: boolean; pristine: boolean }) => {
							if (values && valid && !pristine) {
								const convertedValues = convertFormValuesToTimelineValues(omit(values, ["posterTime"]));
								if (convertedValues) {
									onChange(convertedValues);
								}
							}
						}}
					/>
					<TimeInput name="posterTime" label="Poster time:" player={player} />

					<div className="videoMetadataPage-formPeriod">
						<TimeInput name="firstHalfStart" label="1st half start*" player={player} />
						<TimeInput name="firstHalfEnd" label="1st half end*" player={player} />
					</div>

					<div className="videoMetadataPage-formPeriod">
						<TimeInput
							name="secondHalfStart"
							label="2nd half start"
							player={player}
							disabled={!(values.firstHalfStart && values.firstHalfEnd)}
						/>
						<TimeInput
							name="secondHalfEnd"
							label="2nd half end"
							player={player}
							disabled={!(values.firstHalfStart && values.firstHalfEnd)}
						/>
					</div>
					<div className="videoMetadataPage-formPeriod">
						<TimeInput
							name="firstOvertimeStart"
							label="1st overtime start"
							player={player}
							disabled={!(values.secondHalfStart && values.secondHalfEnd)}
						/>
						<TimeInput
							name="firstOvertimeEnd"
							label="1st overtime end"
							player={player}
							disabled={!(values.secondHalfStart && values.secondHalfEnd)}
						/>
					</div>
					<div className="videoMetadataPage-formPeriod">
						<TimeInput
							name="secondOvertimeStart"
							label="2nd overtime start"
							player={player}
							disabled={!(values.firstOvertimeStart && values.firstOvertimeEnd)}
						/>
						<TimeInput
							name="secondOvertimeEnd"
							label="2nd overtime end"
							player={player}
							disabled={!(values.firstOvertimeStart && values.firstOvertimeEnd)}
						/>
					</div>
					<div className="videoMetadataPage-formPeriod">
						<TimeInput
							name="penaltyShootoutStart"
							label="penalty shootout start"
							player={player}
							disabled={!(values.secondOvertimeStart && values.secondOvertimeEnd)}
						/>
						<TimeInput
							name="penaltyShootoutEnd"
							label="penalty shootout end"
							player={player}
							disabled={!(values.secondOvertimeStart && values.secondOvertimeEnd)}
						/>
					</div>
					<button type="submit" className="videoMetadataEditor-submitButton">
						Submit
					</button>
				</form>
			)}
		/>
	);
}
