import { ElementType, ComponentProps, PropsWithChildren } from "react";
import "./skeleton.scss";

interface SkeletonBoxProps extends PropsWithChildren<ComponentProps<"div">> {
	as?: ElementType;
}

export default function SkeletonBox({ as: Tag = "div", children, className, ...props }: SkeletonBoxProps) {
	return (
		<Tag {...props} className={`skeleton-box${className ? ` ${className}` : ""}`}>
			{children}
		</Tag>
	);
}
