import { ColumnInstance } from "react-table";
import { VFC } from "types";

interface ColumnSortIconProps {
	column: ColumnInstance;
}

const ColumnSortIcon: VFC<ColumnSortIconProps> = ({ column }) => {
	return (
		<span>
			{column.isSorted ? (
				column.isSortedDesc ? (
					<span className="icon-new icon-12 icon icon-zoom-arrow-top" />
				) : (
					<span className="icon-new icon-12 icon icon-zoom-arrow-bottom" />
				)
			) : (
				""
			)}
		</span>
	);
};

export default ColumnSortIcon;
