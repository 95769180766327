import { Field } from "react-final-form";
import type { MediaPlayerInstance } from "@vidstack/react";
import { useRef } from "react";
import { parseTime, secondsToTime, timeToSeconds } from "./functions";

interface TimeFieldProps {
	name: string;
	label: string;
	player: MediaPlayerInstance | null;
	disabled?: boolean;
}

export function TimeInput({ name, label, player, disabled = false }: TimeFieldProps) {
	const inputRef = useRef<HTMLInputElement>(null);

	return (
		<Field name={name}>
			{({ input, meta }) => (
				<div className="Timingform"> 
				<div className="Timinglable">{label}</div>
				<div className="Timingfileds"> 	<button
						type="button" className="btn add"
						onClick={() => {
							if (inputRef.current && player) {
								const time = player.currentTime;
								input.onChange(secondsToTime(time));
							}
						}}
					>
						<span className="icon-new icon-plus-white icon-24"/>
					</button>

					<button
						type="button" className="btn go"
						onClick={() => {
							if (inputRef.current && player) {
								const seconds = timeToSeconds(parseTime(input.value));
								player.currentTime = seconds;
							}
						}}
					>
						Go
					</button>
					
					<input placeholder="HH:MM:SS" type="text" {...input} ref={inputRef} disabled={disabled} />
					{meta.error && meta.touched && <div className="valitation-msg">{meta.error}</div>}</div>
				
				</div>
			)}
		</Field>
	);
}
