import "./ErrorFallback.scss";
import errorImage from "../../assets/error-image.svg";

interface ErrorFallbackProps {
	error: Error;
	resetErrorBoundary: () => void;
}
export default function ErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
	return (
		<div className="error_boundary">
			<img src={errorImage} loading="lazy" alt="error" className="error-img" />
			<p className="errorTitle"> Something went wrong</p>
			<p className="errorMsg"> {error.message} </p>
			<button onClick={() => resetErrorBoundary()} type="button" className="tryAgain_btn btn rounded">
				Try again
			</button>
		</div>
	);
}
