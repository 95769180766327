import SkeletonBox from "./SkeletonBox";

export default function StatTableSkeleton() {
	const rows = [];
	for (let i = 0; i < 14; i++) {
		rows.push(<SkeletonBox key={`row${i}`} className="tableSkeleton-tableRow" />);
	}

	return <div className="tableSkeleton-container">{rows}</div>;
}
