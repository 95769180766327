import { _ } from "i18n";
import { VFC } from "types";
import { MigrationType, NonPartnerMigrationPlayer } from "../../types";
import Icon from "components/Icon";
import { useEffect, useState } from "react";
import PlayersMigrationModal from "./PlayersMigrationModal";
import Select, { SingleValue } from "react-select";
import client from "libs/client";

interface MigrationModalProps {
	onClose: () => void;
	sourcePlayers: NonPartnerMigrationPlayer[];
	setSourcePlayers: React.Dispatch<React.SetStateAction<NonPartnerMigrationPlayer[]>>;
	targetClub: SingleValue<{
		value: string;
		label: string;
		country: string;
		logo: string;
		type: string;
	}>;
	migrationType: string;
	countriesList: { [key: string]: string };
}

const MigrationModal: VFC<MigrationModalProps> = ({ onClose, sourcePlayers, setSourcePlayers, targetClub, migrationType, countriesList }) => {
	const [teamList, setTeamList] = useState<{ label: string | number; value: string | number; }[]>([]);
	const [isloading, setIsloading] = useState(false);

	const getTargetClubTeams = async () => {
		if (targetClub) {
			const response = await client.getTeamsByTargetClub.query({ clubId: parseInt(targetClub.value) }).then((res) => { return res?.data });
			setTeamList(response);
		}
	};

	useEffect(() => {
		if (targetClub?.type === MigrationType.PARTNER) {
			getTargetClubTeams();
		}
	}, [migrationType]);

	return (
		<>
			<div className="modal open">
				<span className="modal__backdrop" />
				<div className="modal__container modal__container--lg">
					<span className="modal__close" onClick={() => onClose()}>
						<Icon icon="cross" />
					</span>
					<div className="modal__title"> {_("migration.players_migration_process")} </div>
					<div className="modal__body">
						<div className="migration-list-container">
							{targetClub?.type === MigrationType.PARTNER && (
								<>
									<div className="card-details">
										<h6>{_("migration.label.select_team_for_all_players")}</h6>
									</div>
									<div className="overall-team-selection">
										<div className="migration-options">
											<Select
												className="react-select"
												options={teamList}
												placeholder={_("migration.placeholder.team")}
												isClearable={true}
												onChange={(e) => {
													setSourcePlayers((prev) => {
														return prev.map((player) => {
															return { ...player, selectedTeam: e };
														});
													});
													return e;
												}}
											/>
											<button
												className={"btn btn-light-blue"}
												onClick={() => {
													const elements = document.getElementsByClassName(
														"continue"
													) as HTMLCollectionOf<HTMLButtonElement>;
													for (let element of elements) {
														element.click();
													}
												}}
											>
												{_("btn.continue_all")}
											</button>
										</div>
									</div>
								</>
							)}
							<ul>
								{sourcePlayers
									? sourcePlayers.map((player, index) => {
										if (player.selected) {
											return (
												<li key={player.id}>
													<PlayersMigrationModal
														player={player}
														index={index}
														migrationType={migrationType}
														teamList={teamList}
														setSourcePlayers={setSourcePlayers}
														countriesList={countriesList}
													/>
												</li>
											);
										}
									})
									: null}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default MigrationModal;
