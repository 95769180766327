import type { Key, Keys } from "./locales/types";
import { Locale } from "./locales/types";
import IntlMessageFormat from "intl-messageformat";
import type { MessageFormatElement } from "@formatjs/icu-messageformat-parser";
import I18nContext from "./context";
//function _<K extends Key>(id: K, ...args: Keys[K] extends never ? never : [Keys[K]]): string {
//@ts-ignore
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
function _<K extends Key>(id: K, ...args: any | never): string {
	return translator.translate(id, ...args);
}

function _any(id: string, args: Record<string, string> = {}): string {
	return translator.translateAny(id, args);
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
function _styled<K extends Key>(id: K, ...args: any | never) {
	return translator.translateStyled(id, ...args);
}

const locales = Object.values(Locale);

function isValidLang(lang: string): boolean {
	return locales.includes(lang as Locale);
}

class Translator {
	private locale: Locale;

	private translations: Map<Locale, Map<Key, string | MessageFormatElement[]>>;

	constructor(defaultLocale: Locale = locales[0]) {
		this.locale = defaultLocale;
		this.translations = new Map();
	}

	add(locale: Locale, translations: Record<Key, string | { type: number; value: string }[]>) {
		this.translations.set(locale, new Map(Object.entries(translations) as [Key, string][]));
	}

	translateAny(id: string, args: Record<string, string> = {}): string {
		const data = args;
		const translation = this.translations.get(this.locale)?.get(id as keyof Keys);

		if (!translation) {
			return id;
		}

		const msg = new IntlMessageFormat(translation, this.locale);
		return msg.format(data).toString();
	}

	translate<K extends Key>(id: K, ...args: Keys[K] extends never ? never : [Keys[K]]): string {
		const data = args[0];
		const translation = this.translations.get(this.locale)?.get(id);

		if (!translation) {
			return id;
		}

		const msg = new IntlMessageFormat(translation, this.locale);

		return msg.format(data).toString();
	}

	translateStyled<K extends Key>(id: K, ...args: Keys[K] extends never ? never : [Keys[K]]) {
		const data = args[0];
		const translation = this.translations.get(this.locale)?.get(id);

		if (!translation) {
			return id;
		}

		const msg = new IntlMessageFormat(translation, this.locale);

		return msg.format(data);
	}

	changeLocale(locale: Locale) {
		this.locale = locale;
	}

	hasLocale(locale: Locale): boolean {
		return this.translations.has(locale);
	}
}

const translator = new Translator();
export type TranslationFunction = typeof _;
export { Locale, Translator, translator, _, _any, _styled, isValidLang, I18nContext };
