import { Locale } from "i18n";
import { MenuTrigger, Button, Popover, Menu, MenuItem } from "react-aria-components";
import "./langSwitch.scss";

interface LangSwitchProps {
	selectedLocale: Locale | string;
	onChange: (locale: Locale) => void;
	loginPage?: boolean;
}

export default function LangSwitch({ loginPage, selectedLocale, onChange }: LangSwitchProps) {
	const locales = Object.keys(Locale)
		.filter((locale) => {
			if (locale.toLowerCase() !== selectedLocale.toLowerCase()) {
				return true;
			}
		})
		.map((locale) => locale.toLowerCase());

	return (
		<div className="langSwitch">
			<MenuTrigger>
				<Button className={`langSwitch__button${loginPage ? " loginPage" : ""}`} aria-label="Menu">
					{selectedLocale.toUpperCase()}
				</Button>
				<Popover>
					<Menu
						className="langSwitch__collapse"
						onAction={(locale) => {
							onChange(locale as Locale);
						}}
					>
						{locales.map((locale) => {
							return (
								<MenuItem className="langSwitch__switchLangue" key={locale} id={locale}>
									{locale.toUpperCase()}
								</MenuItem>
							);
						})}
					</Menu>
				</Popover>
			</MenuTrigger>
		</div>
	);
}
