import client from "libs/client";
import "style/videoMetadataPage.css";
import { StatusPageSkeleton } from "components/Skeleton/MetadataPages/StatusPageSkeleton";
import { MetadataLogTable } from "features/videoUploads/MetadataLogTable";
import { MetadataStatusInfo } from "features/videoUploads/MetadataStatusInfo";
import { type VideoProgressUpdate, type VideoUpdate, VideoUpdateMessageSchema } from "features/videoUploads/tables/schemas";
import { mercure } from "libs/mercure";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export function VideoUploadStatusPage() {
	const params = useParams();

	const id = Number(params.id);
	const { data, isLoading, refetch } = client.getVideo.useQuery({ id }, { queryKey: ["metadataVideos"], enabled: id !== undefined });
	const video = { ...data?.data };

	const [progress, setProgress] = useState<(VideoProgressUpdate["progress"] & { stage: string }) | undefined>(undefined);

	const handleUpdate = (update: VideoUpdate) => {
		if (update.id === id) {
			if (update.status === "PROCESSING_PROGRESS") {
				setProgress({ stage: update.stage, ...update.progress });
			} else {
				refetch();
			}
		}
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: including all dependencies is undesirable
	useEffect(() => {
		const es = mercure({
			topics: ["video-processing"],
			onMessage: (ev) => {
				const parsed = VideoUpdateMessageSchema.safeParse(JSON.parse(ev.data));
				if (parsed.success) {
					const update = parsed.data;
					handleUpdate(update);
				} else {
					console.error(parsed.error);
				}
			},
		});

		return () => {
			es.close();
		};
	}, []);

	return (
		<div className="videoStatusPage-container">
			{isLoading ? (
				<StatusPageSkeleton />
			) : (
				<>
					<MetadataStatusInfo video={video} progress={progress} />
					<MetadataLogTable logs={video.logs} />
				</>
			)}
		</div>
	);
}
