import type { VideoProgressUpdate } from "features/videoUploads/tables/schemas";
import type { getVideoUploadsNotificationsResponse200 } from "libs/apiClient";
import { type StateCreator, create } from "zustand";
import { devtools, persist } from "zustand/middleware";

type Notifications = NonNullable<getVideoUploadsNotificationsResponse200>;
export type Notification = Notifications[number] | VideoProgressUpdate;

interface Actions {
	addMessage: (notification: Notification) => void;
	setNotifications: (notifications: Notifications) => void;
}

interface State {
	notifications: Notification[];
	actions: Actions;
}

type Store = StateCreator<State>;

const store: Store = (set) => ({
	notifications: [],
	actions: {
		addMessage: (notification: Notification) => {
			set(({ notifications }) => {
				const index = notifications.findIndex(({ name }) => {
					return name === notification.name;
				});

				if (index === -1) {
					notifications.unshift(notification);
				} else {
					notifications[index] = notification;
				}

				const updatedNotifications = notifications.slice(0, 10).sort((a, b) => {
					return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
				});

				return { notifications: updatedNotifications };
			});
		},
		setNotifications: (notifications: Notifications) => {
			set((state) => {
				return { ...state, notifications };
			});
		},
	},
});

export const useNotificationsStore = create<State>()(
	devtools(
		persist(store, {
			name: "admin-notifications-storage",
			partialize: ({ actions, ...rest }: State) => rest,
		}),
		//store,
		{
			enabled: import.meta.env.DEV,
			name: "notifications",
		},
	),
);

export const useVideoNotifications = () => useNotificationsStore((state) => state.notifications);
export const useVideoNotificationsActions = () => useNotificationsStore((state) => state.actions);
