import SkeletonBox from "ui-kit/src/Skeleton/SkeletonBox";
import TableSkeleton from "ui-kit/src/Skeleton/TableSkeleton";
import "./metadataPages.css";

export function StatusPageSkeleton() {
	return (
		<>
			<div className="videoEditorSkeleton-heading">
				<SkeletonBox className="headingItem" />
				<SkeletonBox className="headingItem" />
			</div>
			<SkeletonBox className="videoEditorSkeleton-statusInfo" />
			<TableSkeleton />
		</>
	);
}
