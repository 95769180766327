/**
 * Sets the Scroll Behavior for the Page based on whether the loaded page is iFrame or Native SPA
 *
 * Provides a manual override function to skip the default logic
 */
export const setScrollBehavior = (manualValue: boolean | null = null) => {
	let addClass = "";

	window.scrollTo(0, 0);

	if (manualValue === null) {
		const container = document.querySelector(".container");
		if (container) {
			const iFrame = container.querySelector("iframe");
			if (iFrame) {
				addClass = "scrollable-y-false";
			} else {
				addClass = "scrollable-y";
			}
		}
	} else {
		addClass = manualValue ? "scrollable-y" : "scrollable-y-false";
	}

	if (addClass !== "") {
		const bodyClasses = document.body.className.replace(" scrollable-y-false", "").replace(" scrollable-y", "");

		document.body.className = bodyClasses + " " + addClass;
	}
};
