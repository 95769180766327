import SkeletonBox from "ui-kit/src/Skeleton/SkeletonBox";
import "./metadataPages.css";

export function VideoEditorSkeleton() {
	return (
		<>
			<div className="videoEditorSkeleton-heading">
				<SkeletonBox className="headingItem" />
				<SkeletonBox className="headingItem" />
			</div>
			<SkeletonBox className="videoEditorSkeleton-video" />
			<SkeletonBox className="videoEditorSkeleton-timeline" />
			<div className="videoEditorSkeleton-infoContainer">
				<SkeletonBox className="infoTile" />
				<SkeletonBox className="infoTile" />
				<SkeletonBox className="infoTile" />
			</div>
			<div className="videoEditorSkeleton-editContainer">
				<SkeletonBox className="gamePicker" />
				<div className="timeFields">
					<SkeletonBox className="field" />
					<div className="fieldGroup">
						<SkeletonBox className="field" />
						<SkeletonBox className="field" />
					</div>
					<div className="fieldGroup">
						<SkeletonBox className="field" />
						<SkeletonBox className="field" />
					</div>
					<div className="fieldGroup">
						<SkeletonBox className="field" />
						<SkeletonBox className="field" />
					</div>
					<div className="fieldGroup">
						<SkeletonBox className="field" />
						<SkeletonBox className="field" />
					</div>
					<div className="fieldGroup">
						<SkeletonBox className="field" />
						<SkeletonBox className="field" />
					</div>
					<div className="buttons">
						<SkeletonBox className="button" />
						<SkeletonBox className="button" />
					</div>
				</div>

			</div>
		</>
	);
}
