import { NavLink } from "react-router-dom";

export function VideoUploadsSubheader() {
	return (
		<div className="videoMetadataSubheader">
			<div className="subheader-inner">
				<div className="subheader-container">
					<div className="subheader-options">
						<NavLink to="/video-uploads/approval" className="tab">
							Approvals
						</NavLink>

						<NavLink to="/video-uploads/in-processing" className="tab">
							In processing
						</NavLink>

						<NavLink to="/video-uploads/finished" className="tab">
							Finished
						</NavLink>

						<NavLink to="/video-uploads/errored" className="tab">
							Errored
						</NavLink>

						<NavLink to="/video-uploads/all" className="tab">
							All
						</NavLink>
					</div>
				</div>
			</div>
		</div>
	);
}
