import { StateCreator, create } from "zustand";
import { devtools } from "zustand/middleware";

interface Actions {
	startIframeLoading: () => void;
	stopIframeLoading: () => void;
}

interface State {
	loading: boolean;
	actions: Actions;
}

type Store = StateCreator<State>;

const iframeStore: Store = (set) => ({
	loading: true,
	actions: {
		startIframeLoading: () => {
			set((state) => {
				return { loading: true };
			});
		},
		stopIframeLoading: () => {
			set((state) => {
				return { loading: false };
			});
		},
	},
});

export const useIframeStore = create<State>()(
	devtools(
		// persist(store, {
		// 	name: "admin-storage",
		// }),
		iframeStore,
		{
			enabled: import.meta.env.DEV,
			name: "iframes",
		},
	),
);
