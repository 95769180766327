import { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "ui-kit/src/Modal";
import { ApprovalModal } from "../ApprovalModal";
import { BaseTable } from "./BaseTable";

export function VideosWithoutApprovalTable() {
	const [videoToApprove, setVideoToApprove] = useState<number>();
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<BaseTable
				preset="waiting-for-approval"
				actions={{
					id: "actions",
					key: "actions",
					header: () => "Actions",
					cell: (value, row) => (
						<div className="actionsCell">
							<Link className="actionsButton" to={`/video-uploads/${row.id}/video`}>
								open
							</Link>
							<div
								className="actionsButton"
								onClick={() => {
									setVideoToApprove(row?.id);
									setShowModal(true);
								}}
							>
								Approve
							</div>
						</div>
					),
				}}
			/>
			<Modal show={showModal} onClose={() => setShowModal(false)} size="sm" title="Approve VideoUpload">
				<ApprovalModal videoId={videoToApprove ?? 0} approved={false} close={() => setShowModal(false)} />
			</Modal>
		</>
	);
}
