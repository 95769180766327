import { useI18n } from "features/translation/context";
import { eventsDropdownOptions, statusDropdownOptions } from "features/videoUploads/metadata/functions";
import type { VideoTable } from "features/videoUploads/metadata/types";
import { _, _any } from "i18n";
import client from "libs/client";
import { useState } from "react";
import { Link } from "react-router-dom";
import DatagridV2, { type SimpleColumn } from "ui-kit/src/Datagrid/DatagridV2";
import { DateTimeRangeCalendar } from "ui-kit/src/DateTimeRangeCalendar/DateTimeRangeCalendar";
import type { SelectValue } from "ui-kit/src/MultipleSelect/types";
import SelectComponent from "ui-kit/src/SelectComponent/SelectComponent";
import TableSkeleton from "ui-kit/src/Skeleton/TableSkeleton";
import { formatDateTime, formatDuration, getStatusClass, roundSizeString } from "../functions";

type SortConfig = {
	name: string | undefined;
	sort: "asc" | "desc" | undefined;
};

interface BaseTableProps {
	actions?: SimpleColumn<VideoTable>;
	canFilterStatus?: boolean;
	preset?: "waiting-for-approval" | "approved" | "in-processing" | "errored" | "finished" | "";
}

export function BaseTable({ actions, preset = "", canFilterStatus = false }: BaseTableProps) {
	const { locale } = useI18n();
	const [pagination, setPagination] = useState<{ pageIndex: number; pageSize: number }>({ pageIndex: 0, pageSize: 10 });
	const [dateTimeRange, setDateTimeRange] = useState({ start: "", end: "" });
	const [status, setStatus] = useState<SelectValue>();
	const [lastEvent, setLastEvent] = useState<SelectValue>();
	const [sortConfig, setSortConfig] = useState<SortConfig>({ name: undefined, sort: undefined });

	const { data, isLoading, error, refetch } = client.getVideoUploads.useQuery(
		{
			preset,
			page: pagination.pageIndex + 1,
			limit: pagination.pageSize,
			order: { name: sortConfig.name, sort: sortConfig.sort },
			filters: [
				...(status?.value ? [{
					field: "status",
					type: "string",
					value: status.value,
				}] : []),
				...(lastEvent?.value ? [{
					field: "lastEvent",
					type: "string",
					value: lastEvent.value,
				}] : []),
				...((dateTimeRange.start || dateTimeRange.end) ? [{
					field: "createdAt",
					type: "datetimerange",
					value: {
						start: dateTimeRange.start === "" ? undefined : dateTimeRange.start,
						end: dateTimeRange.end === "" ? undefined : dateTimeRange.end,
					},
				}] : []),
			]
		},
		{ queryKey: ["metadataVideos"] },
	);

	const headerSort = (name: string, label: string) => {
		const toggleSortOrder = () => {
			let newOrder: "asc" | "desc" | undefined = "asc";
			if (sortConfig.name === name) {
				if (sortConfig.sort === "asc") {
					newOrder = "desc";
				} else if (sortConfig.sort === "desc") {
					newOrder = undefined;
				}
			}
			setSortConfig({ name: newOrder ? name : undefined, sort: newOrder });
		};

		return (
			<div onClick={toggleSortOrder} style={{ cursor: "pointer" }}>
				{_any(label)}
				{sortConfig.name === name && sortConfig.sort ? (sortConfig.sort === "asc" ? "↑" : "↓") : ""}
			</div>
		);
	};

	const onDateTimeChange = (start: string, end: string) => {
		setDateTimeRange({ start: start, end: end });
	};

	const columns: SimpleColumn<VideoTable>[] = [
		{
			id: "name",
			key: "name",
			header: () => headerSort("name", "Name"),
			cell: (value, row) => row.name ?? "-",
		},
		{
			id: "createdAt",
			key: "createdAt",
			header: () => (
				<DateTimeRangeCalendar
					placeholder="Uploaded"
					apiFormat
					onChange={onDateTimeChange}
					start={dateTimeRange.start}
					end={dateTimeRange.end}
					locale={locale}
				/>
			),
			cell: (value, row) => <div className="dateTimeCell">{formatDateTime(row.createdAt) ?? "-"}</div>,
		},
		{
			id: "duration",
			key: "duration",
			header: () => headerSort("duration", "Duration"),
			cell: (value, row) => <div className="dateTimeCell">{formatDuration(row.duration) ?? "-"}</div>,
		},
		{
			id: "size",
			key: "size",
			header: () => headerSort("size", "File Size"),
			cell: (value, row) => <div className="dateTimeCell">{roundSizeString(row.size) ?? "-"}</div>,
		},
		{
			id: "status",
			key: "status",
			header: () =>
				canFilterStatus ? (
					<SelectComponent menuPortalTarget placeholder="status" items={statusDropdownOptions} value={status} handleChange={setStatus} />
				) : null,
			cell: (value, row) => <div className={getStatusClass(row.status)}>{row?.status?.replace(/_/g, " ") ?? "-"}</div>,
		},
		{
			id: "lastEvent",
			key: "lastEvent",
			header: () => (
				<SelectComponent menuPortalTarget placeholder="last event" items={eventsDropdownOptions} value={lastEvent} handleChange={setLastEvent} />
			),
			cell: (value, row) => <div className="eventCell">{row?.lastEvent?.replace(/_/g, " ") ?? "-"}</div>,
		},
		actions
			? actions
			: {
				id: "actions",
				key: "actions",
				header: () => "Actions",
				cell: (value, row) => (
					<div className="actionsCell">
						<Link className="actionsButton" to={`/video-uploads/${row.id}/status`}>
							Status
						</Link>
					</div>
				),
			},
	];

	const pageCount = data?.data.pages ?? 1;

	// TODO: move to datagrid component
	const resultCount = [
		{ value: 10, label: _("label.show_table_results", { numOfResults: "10" }) },
		{ value: 20, label: _("label.show_table_results", { numOfResults: "20" }) },
		{ value: 30, label: _("label.show_table_results", { numOfResults: "30" }) },
		{ value: 40, label: _("label.show_table_results", { numOfResults: "40" }) },
		{ value: 50, label: _("label.show_table_results", { numOfResults: "50" }) },
	];

	if (error && error.status === 503) {
		return <div>Video Servers are currently unavailable - please try again later, if issue persists contact IT</div>
	}

	return (
		<>
			{isLoading ? (
				<TableSkeleton />
			) : (
				<>
					<div className="topControls">
						<div className="topControls-select">
							<SelectComponent
								menuPortalTarget
								items={resultCount}
								value={{ value: pagination.pageSize, label: _("label.show_table_results", { numOfResults: pagination.pageSize }) }}
								handleChange={(value) => setPagination({ pageIndex: 0, pageSize: Number(value?.value) })}
								isCleareable={false}
							/>
						</div>
						<div className="refetchButton" onClick={() => refetch()}>
							<span className="icon-new icon-admin-syc icon-white icon-24" />
							<div className="buttonText">{_("label.refresh")}</div>
						</div>
					</div>
					<DatagridV2
						data={data?.data.videos ?? []}
						simpleColumns={columns}
						noResults="no results"
						pageCount={pageCount}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</>
			)}
		</>
	);
}
