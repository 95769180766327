import { AdminApiClient, toApiError } from "./apiClient";
import { navigate } from "app/router";
import queryClient from "libs/queryClient";
import apiURL from "libs/apiUrl";
import toastQueue from "./toastQueue";
import { APIResponse } from "types";

const client = new AdminApiClient(
	apiURL,
	queryClient,
	{
		credentials: "include",
		mode: "cors",
	},
	(wretch) => {
		const w = wretch
			.catcher(401, (err) => {
				if (window.location.pathname !== "/" && window.location.pathname !== "/login") {
					window.parent.location.replace(`/login?next=${window.location.pathname}`);
				} else {
					window.parent.location.replace("/login");
				}
				return {
					json: () => {
						throw err;
					},
					status: 401,
				};
			})
			.catcher(403, (err) => {
				const error = toApiError(err);
				toastQueue.add({ type: APIResponse.ERROR, message: error.json.message ?? error.message ?? "" }, { timeout: 2500 });
				return {
					json: () => {
						throw err;
					},
					status: 403,
				};
			});
		return w;
	},
);

export type Client = typeof client;

export default client;
