import { _any } from "i18n";
import { type FieldRenderProps, useField } from "react-final-form";
import Select, { type GroupHeadingProps } from "react-select";
import "admin/src/style/filters.module.scss";

interface SelectAdapterProps extends FieldRenderProps<{ value: string; label: string }, HTMLElement> {
	label: string;
}

export const SelectAdapter = ({ input, label, ...rest }: SelectAdapterProps) => {
	const CustomGroupLabel = (props: GroupHeadingProps<{ value: string; label: string }>) => {
		return <div className="customGroupLabel">{props.children}</div>;
	};

	const customComponents = {
		GroupHeading: CustomGroupLabel,
	};

	return (
		<>
			<label className="selectLabel">{label}</label>
			<Select {...input} {...rest} className="react-select" classNamePrefix="react-select" components={customComponents} />
			{rest.meta.touched && rest.meta.error && <span className="form-error">{rest.meta.error}</span>}
		</>
	);
};

export const InputField = ({ name, type }: { name: string; type: string }) => {
	const { input, meta } = useField(name, { type });
	return (
		<>
			<input {...input} className="form-control" />
			{meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
		</>
	);
};
