import { useCallback, useEffect, useRef, useState } from "react";
import { VFC, APIResponse } from "types";
import { Datagrid, Renderers, HeaderRenderers } from "components/Datagrid";
import { JobDetailTableColumns, NotificationManagementTableColumns, TableColumns } from "../types";
import { fetchNotificationInstants } from "../api";
import { _ } from "i18n";
import { destinationFinder } from "../functions";
import { useToast } from "ui-kit";
import client from "libs/client";
import Icon from "components/Icon";

interface JobDetailProps {
	data: { row: { original: { id: number } } };
}

const JobDetail: VFC<JobDetailProps> = ({ data }) => {
	const [showLoader, setShowLoader] = useState(false);

	const [tableData, setTableData] = useState<{
		columns: {
			[K in keyof NotificationManagementTableColumns]: string;
		};
		data: [];
	}>({ columns: {}, data: [] });

	const start = useRef(0);
	const length = useRef(10);
	const totalRecords = useRef(0);

	const { addSuccessToast } = useToast();

	const getNotificationInstants = "";

	let tableColumns: { [key: string]: number | string | null } = {};
	const ignoreColumns: string[] = [TableColumns.LAST_NAME, TableColumns.EXCEPTION, TableColumns.PHONE_NUMBER];
	const preferredOrder = {
		id: null,
		firstName: null,
		email: null,
		locale: null,
		sentDateTime: null,
		method: null,
		delivered: null,
	};

	const fetchData = async () => {
		const params = {
			jobId: data.row.original.id,
			start: start.current,
			length: length.current
		}
		const response = await client.getNotificationInstants.query(params).then((res) => { return res?.data });
		if (response.status === APIResponse.SUCCESS) {
			response.data.forEach((row: { [s: string]: unknown } | ArrayLike<unknown>) => {
				Object.keys(row).forEach((key) => {
					if (!ignoreColumns.includes(key as never)) {
						tableColumns[key] = key;
					}
				});
			});

			tableColumns = { ...preferredOrder, ...tableColumns };
			totalRecords.current = response.recordsFiltered;
			setShowLoader(false);
			setTableData({ columns: tableColumns, data: response.data });
		}
	};

	const headerRenderers: HeaderRenderers<JobDetailTableColumns> = {
		id: () => _("label.user.id"),
		firstName: () => _("label.user"),
		email: () => _("label.destination"),
		locale: () => _("label.language"),
		sentDateTime: () => _("label.date/time"),
		method: () => _("label.method"),
		delivered: () => _("label.delivered"),
	};

	const cellRenderers: Renderers<JobDetailTableColumns> = {
		id: ({ cell }) => cell.value,
		firstName: ({ cell }) => `${cell.value} ${cell.row.original.lastName}`,
		email: ({ cell }) => destinationFinder(cell.row.original),
		locale: ({ cell }) => cell.value,
		sentDateTime: ({ cell }) => {
			return (
				<div>
					{cell.value.split(" ")[0]} <br />
					<span>{cell.value.split(" ")[1]}</span>
				</div>
			);
		},
		method: ({ cell }) => cell.value,
		delivered: ({ cell }) => (
			<>
				{cell.value ? (
					<span className="squre-icon">
						<Icon icon="tick-new" size={22} color="white"/>
					</span>
				) : (
					<>
						<div
							className={cell.value == false && cell.row.original.exception != null ? "tooltip left" : ""}
							aria-label={cell.value == false && cell.row.original.exception != null ? cell.row.original.exception : ""}
						>
							<span className="squre-icon red">
								<Icon icon="close-w-new" size={22} color="white"/>
							</span>
						</div>
						{cell.value == false && cell.row.original.exception != null && (
							<span className="clipboard">
								<a
									href="#"
									onClick={() => {
										navigator.clipboard.writeText(cell.row.original.exception!);
										addSuccessToast(_("message.message_copied"));
									}}
								>
									<Icon icon="copy-new" size={26} color="dark-grey"/>
								</a>
							</span>
						)}
					</>
				)}
			</>
		),
	};

	const onPaginate = useCallback((pageSize: number, navPageIndex: number) => {
		start.current = pageSize * navPageIndex;
		length.current = pageSize;
		fetchData();
	}, []);

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<>
			<div className="table-container">
				<Datagrid
					columns={tableData.columns}
					data={tableData.data}
					className="table"
					override={true}
					cellRenderers={cellRenderers}
					headerRenderers={headerRenderers}
					onPaginate={onPaginate}
					totalRecords={totalRecords.current}
					lengthFilterEnabled={true}
					manualPaginationEnabled={true}
					showLoader={showLoader}
				/>
			</div>
		</>
	);
};

export default JobDetail;
