import { useEffect, useRef, useState } from "react";
import { VFC, APIResponse } from "types";
import { Datagrid, Renderers, HeaderRenderers } from "components/Datagrid";
import { PoleManagementTableData, PoleManagementTableColumns, AssignPoleData } from "../types";
import DeleteConfirmationModal from "../components/DeleteConfirmationModal";
import PoleModal from "./PoleModal";
import AssignPoleModal from "./AssignPoleModal";
import { formatSelectValues, handleIntersectionObserver } from "../functions";
import { Form, Field } from "react-final-form";
import Loader from "components/Loader";
import { _ } from "i18n";
import Icon from "components/Icon";
import * as R from "ramda";
import UnAssignPoleModal from "./UnAssignPoleModal";
import Modal from "ui-kit/src/Modal";
import ColumnSortIcon from "../components/ColumnSortIcon";
import client from "libs/client";
import { useToast } from "ui-kit";
import { Keys } from "i18n/locales/types";

export enum TableColumns {
	SERIAL_NUMBER = "serialNumber",
	ID = "id",
}
interface PoleManagementProps {
	activeMenu: string;
}

const PoleManagement: VFC<PoleManagementProps> = ({ activeMenu }) => {
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showPoleModal, setShowPoleModal] = useState(false);
	const [showAssignPoleModal, setShowAssignPoleModal] = useState(false);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [timeZoneList, setTimezoneList] = useState<{ label?: string; value: string }[]>([]);
	const [clubList, setClubList] = useState<{ label?: string; value: string }[]>([]);
	const [showUnAssignPoleModal, setShowUnAssignPoleModal] = useState(false);

	const start = useRef(0);
	const totalRecords = useRef(0);
	const search = useRef("");

	const [selectedRow, setSelectedRow] = useState<PoleManagementTableData | null>(null);
	const [selectedClub, setSelectedClub] = useState<{ id: number; name: string } | null>(null);
	const [tableData, setTableData] = useState<{
		columns: {
			[K in keyof PoleManagementTableData]: string;
		};
		data: PoleManagementTableData[];
	}>({ columns: {}, data: [] });

	const { addSuccessToast, addErrorToast } = useToast();

	const onDeleteRow = async () => {
		if (!selectedRow?.id) { return; }

		const response = await client.deletePole.mutation({ id: selectedRow?.id });
		if (response?.data.status === APIResponse.SUCCESS) {
			if (response?.data.message) { addSuccessToast(response?.data.message) };
			setTableData((prevData) => {
				const rowData = prevData.data.filter((data: PoleManagementTableData) => data?.id != selectedRow?.id);
				return { ...prevData, data: [...rowData] };
			});
		} else {
			if (response?.data.message) { addErrorToast(response?.data.message) };
		}
	};

	let tableColumns: { [key: string]: number | string | null } = {};
	const sortableColumns: string[] = ["id"];
	const ignoreColumns: string[] = [TableColumns.SERIAL_NUMBER];
	const preferredOrder = {
		id: null,
		timezone: null,
		location: null,
		currentBattery: null,
		assignedClub: null,
		action: null,
		sync: null,
		assignedClubCount: null,
	};

	const fetchData = async (loadMore: boolean = false) => {
		const params = {
			start: start.current,
			length: 10,
			search: search.current
		}

		const response = await client.getAllPoles.query(params).then((res) => { return res?.data });
		if (response.status === APIResponse.SUCCESS) {
			response.data.forEach((row: { [s: string]: unknown } | ArrayLike<unknown>) => {
				Object.keys(row).forEach((key) => {
					if (!ignoreColumns.includes(key as never)) {
						tableColumns[key] = key;
					}
				});
			});
			tableColumns = { ...preferredOrder, ...tableColumns };
			totalRecords.current = response.recordsFiltered;

			setTableData((prevData) => {
				const Data = prevData.data.filter(function (entry) {
					return response.data.some(function (value: { id: number }) {
						return entry.id === value.id;
					});
				});
				const values = prevData.data.filter(function (entry) {
					return Data.every(function (value) {
						return entry.id != value.id;
					});
				});

				prevData.data = values;
				if (search.current && start.current === 0) {
					return { columns: tableColumns, data: response.data };
				}
				if (R.equals(prevData.data, response.data) === false && loadMore) {
					const newData = [...new Set([...prevData.data, ...response.data])];
					return { columns: tableColumns, data: newData };
				}
				return { columns: tableColumns, data: response.data };
			});
		}
	};

	const onUpdatePole = async (values: PoleManagementTableData) => {
		const response = await client.addUpdatePole.mutation({ body: { pole: values } });
		if (response?.data.status === APIResponse.SUCCESS) {
			if (response?.data.message) { addSuccessToast(response?.data.message) };
			setShowPoleModal(false);
			if (values.oldId === undefined) {
				setTableData((prevData: { columns: {}; data: PoleManagementTableData[] }) => {
					return { ...prevData, data: [response.data.data, ...prevData.data] };
				});
			} else {
				setTableData((prevData: { columns: {}; data: PoleManagementTableData[] }) => {
					const rowData = prevData.data.map((data) => {
						if (data.id === values.oldId) {
							return response.data.data;
						}
						return data;
					});
					return { ...prevData, data: rowData };
				});
			}
		} else {
			if (response?.data.message) { addErrorToast(response?.data.message) };
		}
	};

	const onAssignPole = async (values: AssignPoleData) => {
		if (!values.club_id && values.pole_id) { return; }

		const response = await client.assignPole.mutation({
			id: values.pole_id,
			body: {
				assign_pole: {
					club_id: values.club_id!,
					assign_to: `${values.assign_to}`
				}
			}
		});

		if (response?.data.status === APIResponse.SUCCESS) {
			if (response?.data.message) { addSuccessToast(response?.data.message) };
			setShowAssignPoleModal(false);
			setTableData((prevData: { columns: {}; data: PoleManagementTableData[] }) => {
				const rowData = prevData.data.map((data) => {
					if (data.id === response.data.data.id) {
						return response.data.data;
					}
					return data;
				});
				return { ...prevData, data: rowData };
			});
		} else {
			if (response?.data.message) { addErrorToast(response?.data.message) };
		}
	};

	const onUnAssignPole = async () => {
		if (selectedRow && selectedClub) {
			const params = {
				id: selectedRow.id!,
				clubId: selectedClub.id
			}
			const response = await client.unAssignPole.mutation(params);
			if (response?.data.status === APIResponse.SUCCESS) {
				if (response?.data.message) { addSuccessToast(response?.data.message) };
				setShowUnAssignPoleModal(false);
				setTableData((prevData: { columns: {}; data: PoleManagementTableData[] }) => {
					const rowData = prevData.data.map((data) => {
						if (data.id === response.data.data.id) {
							return response.data.data;
						}
						return data;
					});
					return { ...prevData, data: rowData };
				});
			} else {
				if (response?.data.message) { addErrorToast(response?.data.message) };
			}

		}
	};

	const onSyncPole = async (id: number) => {
		const response = await client.syncPole.mutation({ id: id });
		if (response?.data.status === APIResponse.SUCCESS && response?.data.message) {
			addSuccessToast(response?.data.message);
		}
	};

	const getTimezoneList = async () => {
		const response = await client.getTimezones.query().then((res) => { return res?.data });
		if (response) {
			const list = formatSelectValues(response as any as string[]);
			setTimezoneList(list);
		}

	};

	const getClubList = async () => {
		const response = await client.getClubList.query().then((res) => { return res?.data });
		setClubList(response);
	};

	const onSearchSubmit = (values: { search: string }) => {
		search.current = values.search;
		start.current = 0;
		fetchData();
	};

	useEffect(() => {
		if (activeMenu === "poles") {
			window.scrollTo({ top: 0, left: 0 });
			getTimezoneList();
			getClubList();
		}
	}, [activeMenu]);

	const headerRenderers: HeaderRenderers<PoleManagementTableData> = {
		id: ({ column }) => {
			return (
				<>
					<div>
						{_("label.user.id")} <ColumnSortIcon column={column} />
					</div>
				</>
			);
		},
		timezone: () => _("label.timezone"),
		location: () => _("label.location"),
		currentBattery: () => _("label.current_battery"),
		assignedClub: () => _("label.assigned_club"),
		action: () => _("label.action"),
		sync: () => _("label.sync"),
		assignedClubCount: () => _("label.assign"),
	};

	const cellRenderers: Renderers<PoleManagementTableData> = {
		assignedClub: ({ cell }) => {
			return (
				<div className="assigned-clubs">
					{cell.value.length > 0 &&
						cell.value.map((club: { id: number; name: string }, index: number) => {
							return (
								<span className="badge-dark" key={index}>
									{club.name}
									<a
										href="#"
										onClick={() => {
											setSelectedRow(cell.row.original);
											setSelectedClub(club);
											setShowUnAssignPoleModal(true);
										}}
									>
										x
									</a>
								</span>
							);
						})}
				</div>
			);
		},
		action: ({ cell }) => {
			return (
				<>
					<div className="action-icons">
						<button
							className="tableCellControls__button tableCellControls__button--svg"
							onClick={() => {
								setSelectedRow(cell.row.original);
								setShowPoleModal(true);
							}}
						>
							<Icon icon="crayon" size={16} color="dark-grey" />
						</button>
						<button
							className="tableCellControls__button tableCellControls__button--svg"
							onClick={() => {
								setSelectedRow(cell.row.original);
								setShowDeleteModal(true);
							}}
						>
							<Icon icon="trash-new" size={16} color="dark-grey" />
						</button>
					</div>
				</>
			);
		},
		sync: ({ cell }) => {
			return (
				<button
					className="tableCellControls__button tableCellControls__button--svg syc-icon"
					onClick={() => {
						onSyncPole(cell.row.original.id!);
					}}
				>
					<Icon icon="admin-syc-new" size={16} color="light-blue" />
				</button>
			);
		},
		assignedClubCount: ({ cell }) => {
			return (
				<button
					className={cell.value === 3 ? "assign-btn assigned-btn" : "assign-btn"}
					onClick={() => {
						setSelectedRow(cell.row.original);
						setShowAssignPoleModal(true);
					}}
				>
					{cell.value === 3 ? _("label.assigned") : _("label.assign")}
				</button>
			);
		},
	};

	const containerRef = useRef(null);
	const options = { root: null, rootMargin: "0px", threshold: 1.0 };

	useEffect(() => {
		const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
			handleIntersectionObserver(entries, start, totalRecords, fetchData, setHasMoreData);
		}, options);

		if (containerRef.current) observer.observe(containerRef.current);

		return () => {
			if (containerRef.current) observer.unobserve(containerRef.current);
		};
	}, [containerRef, options]);

	return (
		<>
			<div className="product-management-heading-container">
				<div className="product-management-heading">{_("label.pole_management")}</div>

				<div className="top-form-container">
					<div className="top-search">
						<Form
							onSubmit={onSearchSubmit}
							render={({ handleSubmit, submitting }) => (
								<>
									<form onSubmit={handleSubmit}>
										<div className="form-row">
											<Field name="search" type="text">
												{({ input, meta }) => (
													<>
														<input {...input} placeholder={_("label.search")} />
														{meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
													</>
												)}
											</Field>
										</div>
									</form>
								</>
							)}
						/>
					</div>
					<button
						className="btn btn-light-blue"
						onClick={() => {
							setSelectedRow({});
							setShowPoleModal(true);
						}}
					>
						+ {_("label.add.pole")}
					</button>
				</div>
			</div>
			<div className="pole-table-container">
				<Datagrid
					columns={tableData.columns}
					data={tableData.data}
					className="table"
					override={true}
					cellRenderers={cellRenderers}
					headerRenderers={headerRenderers}
					sortableColumns={sortableColumns}
					lengthFilterEnabled={false}
					manualPaginationEnabled={false}
				/>
				<div ref={containerRef}>{hasMoreData && <Loader />}</div>
			</div>
			<Modal
				show={showDeleteModal}
				title={null}
				size={"sm"}
				onClose={() => {
					setShowDeleteModal(false);
					setSelectedRow({});
				}}
			>
				<DeleteConfirmationModal
					title={`${selectedRow?.location}`}
					show={showDeleteModal}
					onClose={() => {
						setShowDeleteModal(false);
						setSelectedRow({});
					}}
					onDelete={() => {
						onDeleteRow();
						setShowDeleteModal(false);
					}}
				/>
			</Modal>
			<Modal
				show={showPoleModal}
				title={_("clubInfos.pole")}
				size={"sm"}
				onClose={() => {
					setShowPoleModal(false);
					setSelectedRow({});
				}}
			>
				<PoleModal
					onClose={() => {
						setShowPoleModal(false);
						setSelectedRow({});
					}}
					onUpdatePole={onUpdatePole}
					timeZoneList={timeZoneList}
					row={selectedRow}
				/>
			</Modal>
			<Modal
				show={showAssignPoleModal}
				title={_("label.assign.pole.club")}
				size={"sm"}
				onClose={() => {
					setShowAssignPoleModal(false);
					setSelectedRow({});
				}}
			>
				<AssignPoleModal
					onClose={() => {
						setShowAssignPoleModal(false);
						setSelectedRow({});
					}}
					clubList={clubList}
					onAssignPole={onAssignPole}
					row={selectedRow}
				/>
			</Modal>
			<Modal
				show={showUnAssignPoleModal}
				title={`${_('label.unassign' as keyof Keys)} ${selectedClub?.name}`}
				size={"sm"}
				onClose={() => {
					setShowUnAssignPoleModal(false);
					setSelectedRow({});
				}}
			>
				<UnAssignPoleModal
					onClose={() => {
						setShowUnAssignPoleModal(false);
						setSelectedRow({});
					}}
					onUnAssign={() => {
						onUnAssignPole();
						setShowUnAssignPoleModal(false);
					}}
				/>
			</Modal>
		</>
	);
};

export default PoleManagement;
