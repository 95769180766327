interface MercureOptions {
	topics: string[];
	onMessage: (ev: MessageEvent<string>) => void;
}

export function mercure({ topics, onMessage }: MercureOptions) {
	const params = topics.map((topic) => {
		return ["topic", topic];
	});
	const url = new URL(`${import.meta.env.VITE_SSE_URL}?${new URLSearchParams(params).toString()}`);
	const es = new EventSource(url, { withCredentials: true });

	es.onmessage = onMessage;

	return es;
}
