import { VFC } from "../types";

interface IconProps {
	icon: string;
	className?: string;
}

const Icon: VFC<IconProps> = ({ icon, className }) => {
	return (
		<span className={`${className ? className : ""}`}>
			<span className={`icon-new icon-blue ${icon}`}></span>
		</span>
	);
};

export default Icon;
