import { useEffect, useRef } from "react";
import Plyr from "plyr";
import Hls from "hls.js";
import "plyr/dist/plyr.css";

interface PlyrPlayerProps {
	baseURL?: string;
}

export function PlyrPlayer({ baseURL }: PlyrPlayerProps) {
	const videoRef = useRef(null);

	let videoUrl: URL | string = "";
	let thumbUrl: URL | string = "";
	let posterUrl: URL | string = "";

	if (baseURL) {
		videoUrl = new URL(`${baseURL}/stream/stream.m3u8`);
		thumbUrl = new URL(`${baseURL}/thumbs.vtt`);
		posterUrl = new URL(`${baseURL}/poster.webp`);
	}

	let plyrInstance: Plyr;

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		const setupPlayer = () => {
			if (!videoRef.current) {
				return;
			}

			if (Hls.isSupported()) {
				const hls = new Hls();
				hls.loadSource(videoUrl.toString());
				hls.attachMedia(videoRef.current);
			}
			plyrInstance = new Plyr(videoRef.current, {
				controls: ["play", "progress", "current-time", "mute", "volume", "fullscreen"],
				captions: { active: true, update: true, language: "en" },
				autoplay: false,
				...(thumbUrl.toString() && {
					previewThumbnails: {
						enabled: true,
						src: thumbUrl.toString(),
					},
				}),
			});

			return () => {
				if (plyrInstance) {
					plyrInstance.destroy();
				}
			};
		};

		setupPlayer();
	}, [baseURL]);

	return (
		<div className="videoApp-playerContainer">
			<video ref={videoRef} controls playsInline crossOrigin="anonymous" poster={posterUrl.toString()} />
		</div>
	);
}
