import { StrictMode, useEffect } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { QueryClientProvider } from "@tanstack/react-query";
import queryClient from "libs/queryClient";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "assets/scss/styles.scss";
import "./style/admin.css";
import "assets-new/icons.css";
import { Locale, translator, isValidLang, _ } from "i18n";
import { SymfonyDebugBar } from "components/symfonyDebugBar/symfonyDebugBar";
import LogRocket from "logrocket";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import setupLogRocketReact from "logrocket-react";
import * as Sentry from "@sentry/react";
import ConstantsProvider from "features/constantsProvider/context";
import apiURL from "libs/apiUrl";

const enableSentry = parseInt(import.meta.env.VITE_ENABLE_SENTRY) === 1;
const enableLogrocket = parseInt(import.meta.env.VITE_ENABLE_LOGROCKET) === 1;

if (import.meta.env.VITE_APP_ENV === "review") {
	console.table({
		VITE_APP_ENV: import.meta.env.VITE_APP_ENV,
		VITE_APP_NAME: import.meta.env.VITE_APP_ENV,
		//@ts-ignore defined in pipeline
		// biome-ignore lint/correctness/noUndeclaredVariables: <explanation>
		appVersion: APP_VERSION,
		enableSentry,
		enableLogrocket,
	});
}

if (enableLogrocket) {
	LogRocket.init(import.meta.env.VITE_LOGROCKET_APP_ID);
	setupLogRocketReact(LogRocket);
}

if (enableSentry) {
	Sentry.init({
		dsn: import.meta.env.VITE_SENTRY_DSN,
		environment: import.meta.env.VITE_APP_NAME,
		//@ts-ignore defined in pipeline
		// biome-ignore lint/correctness/noUndeclaredVariables: <explanation>
		release: APP_VERSION,
		integrations: [
			new Sentry.BrowserTracing({
				// See docs for support of different versions of variation of react router
				// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
				routingInstrumentation: Sentry.reactRouterV6Instrumentation(
					useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes,
				),
				tracePropagationTargets: [/^https:\/\/.*eyeball\.club/],
			}),
			new Sentry.Replay(),
		],
		// Performance Monitoring
		tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
		beforeSend: (event) => {
			if (enableLogrocket) {
				const logRocketSession = LogRocket.sessionURL;
				if (logRocketSession !== null) {
					if (!event.extra) {
						event.extra = {};
					}
					event.extra.LogRocket = logRocketSession;
					return event;
				}
				return event;
			}
			return event;
		},
	});
}

const browserLang = navigator.language.substring(0, 2);

const lang = isValidLang(browserLang) ? (browserLang as Locale) : Locale.EN;

const readLang = (lang: string) => import(`../../i18n/locales/${lang}-intl.ts`);

const changeLocale = async (lang: Locale): Promise<void> => {
	if (!translator.hasLocale(lang)) {
		const value = await readLang(lang);
		const translations = value.default;
		translator.add(lang, translations);
		translator.changeLocale(lang);
	} else {
		return new Promise((resolve, _reject) => {
			translator.changeLocale(lang);
			resolve(undefined);
		});
	}
};

readLang(lang).then((value) => {
	const translations = value.default;
	translator.add(lang, translations);
	translator.changeLocale(lang);

	createRoot(document.getElementById("root") as HTMLElement).render(
		<StrictMode>
			<ConstantsProvider apiURL={apiURL}>
				<QueryClientProvider client={queryClient}>
					<App _={_} changeLocale={changeLocale} locale={lang} translator={translator} />
					<ReactQueryDevtools initialIsOpen={false} position="top-left" />
					{import.meta.env.DEV ? <SymfonyDebugBar /> : null}
				</QueryClientProvider>
			</ConstantsProvider>
		</StrictMode>,
	);
});
