import { CountryCode, CountryFlag } from "ui-kit/src/flags";
import Modal from "ui-kit/src/Modal";
import * as R from "ramda";
import { useEffect, useRef, useState } from "react";
import { _ } from "i18n";
import { continentCountries } from "../types";
import { Datagrid, Renderers, HeaderRenderers } from "components/Datagrid";
import { VFC, APIResponse } from "types";
import { CountryExpansionTableColumns, CountryExpansionTableColumnsData } from "../types";
import AddCountryModal from "./AddCountryModal";
import DeleteConfirmationModal from "../components/DeleteConfirmationModal";
import { handleIntersectionObserver } from "../functions";
import Loader from "components/Loader";
import { Field, Form } from "react-final-form";
import Select, { SingleValue } from "react-select";
import client from "libs/client";
import { useToast } from "ui-kit";
import Icon from "components/Icon";

interface CountryExpansionProps {
	activeMenu: string;
	countries: { value: string; label: string; }[];
	countriesWithContinents: continentCountries[];
}

const CountryExpansion: VFC<CountryExpansionProps> = ({ activeMenu, countries, countriesWithContinents }) => {

	const [showAddCountryModal, setShowAddCountryModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [selectedRow, setSelectedRow] = useState<CountryExpansionTableColumns | null>();
	const [hasMoreData, setHasMoreData] = useState(true);
	const [showLoader, setShowLoader] = useState(false);

	const [country, setCountry] = useState<SingleValue<{ value: string; label: string }> | null>();
	const [status, setStatus] = useState<SingleValue<{ value: number; label: string }> | null>();

	const [tableData, setTableData] = useState<{
		columns: {
			[K in keyof CountryExpansionTableColumns]: string;
		};
		data: [];
	}>({ columns: {}, data: [] });

	const { addSuccessToast, addErrorToast } = useToast();

	const start = useRef(0);
	const totalRecords = useRef(0);
	const search = useRef("");
	let tableColumns: { [key: string]: number | string | null } = {};
	const ignoreColumns: string[] = ["countryCode"];

	const filters = useRef<{ enabled?: number | null; country?: string | null }>({ enabled: null, country: null });

	const preferredOrder = {
		countryName: null,
		tooltip: null,
		enabled: null,
		id: null,
	};
	const headerRenderers: HeaderRenderers<CountryExpansionTableColumns> = {
		countryName: () => {
			return (
				<>
					<div className="w-230-px">
						<Select
							options={countries}
							isClearable={true}
							className="react-select"
							value={country}
							placeholder={_("club.non_partner.label.country")}
							onChange={onChangeCountry}
						/>
					</div>
				</>
			);
		},
		tooltip: () => _("label.tooltip_message"),
		enabled: () => {
			return (
				<>
					<div className="w-180-px">
						<Select
							options={[
								{ value: 1, label: "ENABLED" },
								{ value: 0, label: "DISABLED" },
							]}
							isClearable={true}
							className="react-select"
							value={status}
							placeholder={_("label.user.status")}
							onChange={onChangeStatus}
						/>
					</div>
				</>
			);
		},
		id: () => _("label.action"),
	};

	const cellRenderers: Renderers<CountryExpansionTableColumns> = {
		countryName: ({ cell }) => {
			return (
				<>
					<CountryFlag code={cell.row.original.countryCode?.toLowerCase() as CountryCode} />
					<span className="ml-1">{cell.value} ({cell.row.original.countryCode})</span>
				</>
			);
		},
		tooltip: ({ cell }) => _(cell.value),
		enabled: ({ cell }) => {
			return (
				<>
					<div className="switch">
						<input
							id={`switch-${cell.row.original.id}`}
							type="checkbox"
							className="switch-input"
							checked={cell.row?.original.enabled ? true : false}
							onChange={(e) => {
								onStatusUpdate(cell.row.original.id, e.target.checked ? 1 : 0);
								e.target.checked = !e.target.checked;
							}}
						/>
						<label htmlFor={`switch-${cell.row.original.id}`} className="switch-label"></label>
					</div>
				</>
			);
		},
		id: ({ cell }) => {
			return (
				<>
					<div className="action-icons">
						<label
							htmlFor="modal_edit-country"
							className="tableCellControls__button tableCellControls__button--svg js-form-addPlayerInTeam-openModal modal_video_edit_match_1387"
							data-form-action=""
							data-modal-title="Edit game for"
							onClick={() => {
								setShowAddCountryModal(true);
								setSelectedRow(cell.row.original);
							}}
						>
							<Icon icon="crayon" size={16} color="dark-grey" />
						</label>
						<label
							htmlFor="modal_delete-country"
							className="tableCellControls__button tableCellControls__button--svg syc-icon"
							data-form-action=""
							data-modal-title=""
							onClick={() => {
								setSelectedRow(cell.row.original);
								setShowDeleteModal(true);
							}}
						>
							<Icon icon="trash-new" size={16} color="dark-grey" />
						</label>
					</div>
				</>
			);
		},
	};

	const onChangeCountry = (e: SingleValue<{ value: string; label: string }>) => {
		setShowLoader(true);
		setCountry(e);
		start.current = 0;
		if (e) {
			filters.current.country = e.value;
		} else {
			filters.current.country = null;
		}
		fetchData();
	};

	const onChangeStatus = (e: SingleValue<{ value: number; label: string }>) => {
		//setShowLoader(true);
		setStatus(e);
		start.current = 0;

		if (e) {
			filters.current.enabled = e.value;
		} else {
			filters.current.enabled = null;
		}
		fetchData();
	};

	const onStatusUpdate = async (countryId: number | undefined, enabled: number) => {
		if (!countryId) { return; }

		const response = await client.enableDisableCountry.mutation({
			id: countryId,
			body: {
				enabled: enabled === 1 ? true : false
			}
		});

		if (response?.data.status === APIResponse.SUCCESS) {
			if (response?.data.message) { addSuccessToast(response?.data.message) };
			start.current = 0;
			fetchData();
		} else {
			if (response?.data.message) { addErrorToast(response?.data.message) };
		}
	};
	const onSearchSubmit = (values: { search: string }) => {
		search.current = values.search;
		start.current = 0;
		fetchData();
	};


	const fetchData = async (loadMore: boolean = false) => {

		let param = {
			start: start.current,
			length: 10,
			search: search.current,
			country: filters.current.country ? filters.current.country : "",
			enabled: filters.current.enabled !== null ? `${filters.current.enabled}` : "",
		};

		client.getAllCountriesTooltip.query(param).then((res) => {
			if (res) {
				const response = res.data;
				if (response.status === APIResponse.SUCCESS) {
					response.data.forEach((row: { [s: string]: unknown } | ArrayLike<unknown>) => {
						Object.keys(row).forEach((key) => {
							if (!ignoreColumns.includes(key as never)) {
								tableColumns[key] = key;
							}
						});
					});

					tableColumns = { ...preferredOrder, ...tableColumns };
					totalRecords.current = response.recordsFiltered;
					setShowLoader(false);

					setTableData((prevData) => {
						if (search.current && start.current === 0) {
							return { columns: tableColumns, data: response.data };
						}
						if (R.equals(prevData.data, response.data) === false && loadMore) {
							const newData = [...new Set([...prevData.data, ...response.data])];
							return { columns: tableColumns, data: newData };
						}
						return { columns: tableColumns, data: response.data };
					});
				}
			}
		});


	};
	const onAddCountry = async (values: CountryExpansionTableColumnsData) => {
		const response = await client.addUpdateCountry.mutation({ body: { countryTooltip: values } });
		if (response?.data.status === APIResponse.SUCCESS) {
			if (response?.data.message) { addSuccessToast(response?.data.message) };
			setShowAddCountryModal(false);
			start.current = 0;
			fetchData();
		} else {
			if (response?.data.message) { addErrorToast(response?.data.message) };
		}
	};
	const onDeleteRow = async () => {
		if (selectedRow) {
			const response = await client.deleteCountry.mutation({ id: selectedRow.id! });
			if (response?.data.status === APIResponse.SUCCESS) {
				if (response?.data.message) { addSuccessToast(response?.data.message) };
			} else {
				if (response?.data.message) { addErrorToast(response?.data.message) };
			}
			start.current = 0;
			fetchData();
		}
	};

	useEffect(() => {
		if (activeMenu === "country_expansion") {
			window.scrollTo({ top: 0, left: 0 });
			start.current = 0;
			fetchData();
		}
	}, [activeMenu]);

	const containerRef = useRef(null);
	const options = { root: null, rootMargin: "0px", threshold: 1.0 };

	useEffect(() => {
		const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
			handleIntersectionObserver(entries, start, totalRecords, fetchData, setHasMoreData);
		}, options);

		if (containerRef.current) observer.observe(containerRef.current);

		return () => {
			if (containerRef.current) observer.unobserve(containerRef.current);
		};
	}, [containerRef, options]);

	return (
		<>
			<div className="product-management-heading-container">
				<div className="product-management-heading">{_("product.management.country.expansion")}</div>
				<div className="top-form-container">
					<div className="top-search">
						<Form
							onSubmit={onSearchSubmit}
							render={({ handleSubmit, submitting }) => (
								<>
									<form onSubmit={handleSubmit}>
										<div className="form-row">
											<Field name="search" type="text">
												{({ input, meta }) => (
													<>
														<input {...input} placeholder={_("label.search")} />
														{meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
													</>
												)}
											</Field>
										</div>
									</form>
								</>
							)}
						/>
					</div>
					<button
						className="btn btn-light-blue"
						onClick={() => {
							setShowAddCountryModal(true);
						}}
					>
						+ {_("label.add")}
					</button>
				</div>
			</div>

			<div className="datatable-container country-expansion-container">
				<Datagrid
					columns={tableData.columns}
					data={tableData.data}
					className="table"
					override={true}
					cellRenderers={cellRenderers}
					headerRenderers={headerRenderers}
					showLoader={showLoader}
				/>
				<div ref={containerRef}>{hasMoreData && <Loader />}</div>
			</div>
			<Modal
				show={showAddCountryModal}
				title={_("club.non_partner.label.country")}
				size={"sm"}
				onClose={() => {
					setSelectedRow(null);
					setShowAddCountryModal(false);
				}}
			>
				<AddCountryModal onAddCountry={onAddCountry} row={selectedRow!} countriesList={countries!} countriesWithContinents={countriesWithContinents} />
			</Modal>

			<Modal
				show={showDeleteModal}
				title={null}
				size={"sm"}
				onClose={() => {
					setSelectedRow(null);
					setShowDeleteModal(false);
				}}
			>
				<DeleteConfirmationModal
					title={`${selectedRow?.countryName}`}
					show={showDeleteModal}
					onClose={() => {
						setShowDeleteModal(false);
						setSelectedRow(null);
					}}
					onDelete={() => {
						onDeleteRow();
						setShowDeleteModal(false);
						setSelectedRow(null);
					}}
				/>
			</Modal>
		</>
	);
};

export default CountryExpansion;
