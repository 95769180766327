import { _, _any } from "i18n";
import { Continent, ContinentsPath } from "./types";

interface ContinentProps {
	continent: Continent;
	isSelected: boolean;
	onSelect: (continent: Continent) => void;
	onDeselect: (continent: Continent) => void;
}

export default function ContinentSelector({ continent, isSelected = false, onSelect, onDeselect }: ContinentProps) {
	return (
		<div
			className={`continent-thumb${isSelected ? " selected" : ""}`}
			onClick={() => {
				if (isSelected) {
					onDeselect(continent);
				} else {
					onSelect(continent);
				}
			}}
		>
			<span> {_any(`continents.${continent}`)} </span>

			<img src={ContinentsPath[continent]} />
			{isSelected ? (
				<div
					className="remove"
					onClick={() => {
						onDeselect(continent);
					}}
				>
					<svg className="icon icon-cross">
						<title>cross</title>
						<use xlinkHref="#icon-cross" />
					</svg>
				</div>
			) : null}
		</div>
	);
}
