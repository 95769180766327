import "admin/src/style/videoMetadataPage.css";
import { _ } from "i18n";
import client from "libs/client";
import { useToast } from "ui-kit";

interface ApprovalModalProps {
	videoId: number;
	approved: boolean;
	close: () => void;
}

export function ApprovalModal({ videoId, approved, close }: ApprovalModalProps) {
	const { addSuccessToast, addErrorToast } = useToast();
	const approveMutation = client.approveVideoMetadata.useMutation({
		onSuccess: () => {
			addSuccessToast(_("message.metadata_approval.success"));
			close();
			client.queryClient.invalidateQueries({
				predicate: (query) => query.queryKey.includes("metadataVideos"),
			});
		},
		onError: (res) => addErrorToast(res.message),
	});

	const revokeApprovalMutation = client.removeApproveVideoMetadata.useMutation({
		onSuccess: () => {
			addSuccessToast(_("message.metadata_approval.success"));
			close();
			client.queryClient.invalidateQueries({
				predicate: (query) => query.queryKey.includes("metadataVideos"),
			});
		},
		onError: (res) => addErrorToast(res.message),
	});

	const onSubmit = () => {
		if (approved) {
			revokeApprovalMutation.mutate({ id: videoId });
		} else {
			approveMutation.mutate({ id: videoId });
		}
	};

	return (
		<div className="approveVideoModal-container">
			<div className="approveMessage">
				{approved ? <>Are you sure you want to revoke approval for this video?</> : <>Are you sure you want to approve this video?</>}
			</div>
			<div className="buttons">
				<div className="submit" onClick={() => onSubmit()}>
					{approved ? <>Revoke</> : <>Approve</>}
				</div>
				<div className="cancel" onClick={() => close()}>
					Cancel
				</div>
			</div>
		</div>
	);
}
