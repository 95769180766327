import client from "libs/client";
import { useToast } from "ui-kit";
import "./activateDeactivateModal.css"
interface ActivateDeactivateModal {
    id: number;
    isActive: boolean;
    close: () => void;
}

export function ActivateDeactivateModal({ id, isActive, close }: ActivateDeactivateModal) {
    const { addSuccessToast, addErrorToast } = useToast();
    const mutation = client.patchPlayerUser.useMutation({
        onSuccess: () => {
            addSuccessToast(isActive ? "User Deactivated" : "User Activated");
            client.queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes("playerUserDetail"), })
            close();
        },
        onError: (error) => {
            client.queryClient.resetQueries({ predicate: (query) => query.queryKey.includes("playerUserDetail"), })
            addErrorToast(error.message)
        }
    });

    const onSubmit = () => {
        mutation.mutate({ id: id, body: { id: id, isActive: !isActive } })
    }

    return (
        <div className="activateDeactivateModal-container">
            <div className="approveMessage">
                {isActive ? <>Are you sure you want to deactivate the user?</> : <>Are you sure you want activate the user?</>}
            </div>
            <div className="buttons">
                <div className="submit" onClick={() => onSubmit()}>
                    {isActive ? <>Deactivate</> : <>Activate</>}
                </div>
                <div className="cancel" onClick={() => close()}>
                    Cancel
                </div>
            </div>
        </div>
    )
}