import { _ } from "i18n";
import { Form, Field } from "react-final-form";
import { VFC, continentCountries } from "../types";
import { SetStateAction, useState } from "react";
import Select from "react-select";
import { getContinentByCountry } from "../functions";
import { CountryExpansionTableColumns, CountryExpansionTableColumnsData } from "../types";
import { useToast } from "ui-kit";
interface CountryModalProps {
	onAddCountry: (values: CountryExpansionTableColumnsData) => void;
	row: CountryExpansionTableColumns | null;
	countriesList: {}[];
	countriesWithContinents: continentCountries[];
}

const AddCountryModal: VFC<CountryModalProps> = ({ onAddCountry, row, countriesList, countriesWithContinents }) => {
	const [countries, setCountries] = useState(row?.countryName ? [{ value: row?.countryCode, label: row?.countryName, continent: row?.continent }] : []);
	const [enabledcheck, setEnabledCheck] = useState<string | boolean | undefined>(row ? row.enabled : false);
	const { addErrorToast } = useToast();

	const formData = {
		countries: null,
		tooltip: row ? row.tooltip : null,
		enabled: row ? row.enabled : null,
	};

	const onFormSubmit = (values: CountryExpansionTableColumnsData) => {
		if (countries.length === 0 || countries === null) {
			addErrorToast(_("label.Please_select_country"));
			return;
		}
		values.id = row ? row.id : null;

		if(!countriesWithContinents) { return; }

		countries.forEach((country) => {
			country.continent = getContinentByCountry(country.value!, countriesWithContinents);
		});

		values.countries = countries;
		values.enabled = enabledcheck;

		onAddCountry(values);
	};

	const SelectAdapter = ({ input, ...rest }: { [x: string]: {}; input: {} }) => {
		return <Select {...input} {...rest} value={countries} />;
	};

	return (
		<Form
			onSubmit={onFormSubmit}
			initialValues={formData}
			render={({ handleSubmit }) => (
				<>
					<form onSubmit={handleSubmit}>
						<div className="tableSection__head">
							<div className="modal__body">
								<div className="form-row">
									<div className="field-group w-100">
										<label className="required">{_("app.coach.squad.add_player_nationality_2_hint")}</label>
										<Field
											name="countries"
											className={row ? "react-select disabled" : "react-select"}
											component={SelectAdapter}
											isMulti={true}
											options={countriesList}
											onChange={(e: SetStateAction<{ value: string | undefined; label: string; continent: string | undefined; }[]>) => {
												setCountries(e);
											}}
											defaultValue={countries}
										/>
									</div>

									<div className="field-group w-100">
										<label>{_("label.tooltip_message")}</label>

										<Field name="tooltip">
											{({ input }) => (
												<div className="form-group">
													<textarea {...input} maxLength={256} className="form-control textarea" required={true} />
												</div>
											)}
										</Field>
									</div>
									{row?.id == undefined && (
										<div className="field-group w-100">
											<Field
												name="enabled"
												component="input"
												type="checkbox"
												className="custom-control-input"
												checked={enabledcheck}
												onChange={() => {
													setEnabledCheck(!enabledcheck);
												}}
											/>
											{_("label.enable")}
										</div>
									)}
									<div className="field-group w-100 text-center space-around">
										<button className="btn btn-light-blue btn-dark-blue" type="submit">
											{row?.id == undefined ? _("label.add_country") : _("label.update_country")}
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</>
			)}
		/>
	);
};

export default AddCountryModal;
