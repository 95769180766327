import { _ } from "i18n";
import { FC } from "react";
import Icon from "components/Icon";

interface DeleteConfirmationModalProps {
	show: true | false;
	title: string;
	onClose: () => void;
	onDelete: () => void;
}

const DeleteConfirmationModal: FC<DeleteConfirmationModalProps> = ({ show, title, onClose, onDelete }) => {
	return (
		<>
			<div className={show ? "modal open" : "modal"}>
				<span className="modal__backdrop"></span>
				<div className="modal__container modal__container--sm">
					<span className="modal__close" onClick={() => onClose()}>
						<Icon icon="cross" />
					</span>
					<div className="modal__body text-center">
						<div className="modal-msg">
							<p>
								<Icon className="modal-icon" icon="warning-new" size={50} />
							</p>
							<h6>{_("delete.confirmation.are_you_sure")}</h6>
							<p>{_("delete.confirmation.are_you_sure_want_to_delete", { title } )}</p>
							<p>
								<button className="btn btn--red" onClick={() => onDelete()}>
									{_("delete.confirmation.yes_delete_it")}
								</button>
								<button className="btn btn--cancel" onClick={() => onClose()}>
									{_("btn.cancel")}
								</button>
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DeleteConfirmationModal;
