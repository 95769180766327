import { _ } from "i18n";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import type { NonPartnerClubData, continentCountries } from "../../types";
import { useDropzone } from "react-dropzone";
import { type FC, type MutableRefObject, type SetStateAction, useCallback, useState } from "react";
import { APIResponse } from "types";
import clubPlaceholder from "assets/images/logo_fixtures.png";
import Icon from "components/Icon";
import Select from "react-select";
import client from "libs/client";
import { useToast } from "ui-kit";
import { getBase64 } from "../../functions";

interface ClubModalProps {
	onClose: () => void;
	onUpdateClub: (values: any) => Promise<void>;
	club: NonPartnerClubData | null;
	geographicalAreaList: {
		value: string;
		label: string;
	}[];
	countries: { [key: string]: string };
	countriesWithContinents: continentCountries[];
	isSubmitted: MutableRefObject<boolean>;
}

const defaultGeographicalArea = { value: "", label: "" };

const createInitialValues = (
	club: NonPartnerClubData | null,
	countries: { [key: string]: string },
	geographicalAreaList: ClubModalProps["geographicalAreaList"]
) => {
	return {
		id: club ? club.id : null,
		name: club ? club.name : null,
		country: club ? (club?.country ? { value: club?.country, label: countries[club.country] } : null) : null,
		description: club ? club?.description : null,
		contactName: club ? club?.contactName : null,
		contactPhoneNumber: club ? club?.contactPhoneNumber : null,
		contactEmail: club ? club?.contactEmail : null,
		city: club ? club?.city : null,
		zipcode: club ? club?.zipcode : null,
		address: club ? club?.address : null,
		showCase: club ? club?.showCase : false,
		region: club ? club?.region : null,
		geographicalArea: club
			? club?.geographicalArea
				? { value: club?.geographicalArea, label: geographicalAreaList.find((element) => element.value === club?.geographicalArea)?.label }
				: defaultGeographicalArea
			: defaultGeographicalArea,
		clubExternalLinks: club?.clubExternalLinks && Array.isArray(club?.clubExternalLinks)
			? club.clubExternalLinks.map(link => ({
				link: link.link || '',
				type: link.type || '',
			}))
			: [],
	};
};

const linkTypeOptions = [
	{ value: "transfermarkt", label: "Transfermarkt" },
	{ value: "federation", label: "Federation" },
];

const ClubModal: FC<ClubModalProps> = ({ onClose, onUpdateClub, club, geographicalAreaList, countries, countriesWithContinents, isSubmitted }) => {

	const [country, setCountry] = useState<{ value: string; label: string | undefined } | null>(club ? (club?.country ? { value: club?.country, label: countries[club?.country] } : null) : null);

	const [clubLogo, SetClubLogo] = useState(club?.logo ? club.logo : "");

	const { addSuccessToast, addErrorToast } = useToast();

	const publicUrl = new URL(`public`, import.meta.url);

	const onFormSubmit = (values: any) => {
		const formValues = {...values}
		if (clubLogo !== "") {
			formValues.logo = clubLogo;
		}

		if (country?.value === undefined) {
			addErrorToast(_("msg.select_country"));
			return;
		}

		formValues.country = country.value;

		if (formValues.geographicalArea) {
			formValues.geographicalArea = formValues.geographicalArea.value;
		}

		if (formValues.zipcode) {
			formValues.zipcode = Number.parseInt(formValues.zipcode);
		}

		onUpdateClub(formValues);
	};


	const onDrop = useCallback(async (acceptedFiles: File[]) => {
		const id = club ? club.id : null;

		const file = await getBase64(acceptedFiles[0]) as string;
		const data = { id: id, file: file };
		const response = await client.uploadNPClubPhoto.mutation({ body: data });
		if (response?.data.status === APIResponse.SUCCESS) {
			if (response?.data.message) { addSuccessToast(response?.data.message) };
			if(response?.data.url) { SetClubLogo(response.data.url); }
		} else {
			if (response?.data.message) { addErrorToast(response?.data.message) };
		}
	}, []);

	const { getInputProps } = useDropzone({
		onDrop,
		accept: { "image/jpeg": [".jpg", ".jpeg"], "image/png": [".png"] },
		multiple: false,
		maxFiles: 1,
	});

	const SelectAdapter = ({ input, ...rest }: { [x: string]: any; input: any }) => {
		if (input.name === "country") {
			return <Select {...input} {...rest} value={country} />;
		}
		return <Select {...input} {...rest} />;
	};

	return (
		<>
			<div className="modal open">
				<span className="modal__backdrop" />
				<div className="modal__container modal__container--lg">
					<span className="modal__close" onClick={() => onClose()}>
						<Icon icon="cross" />
					</span>
					<div className="modal__title">
						{_("club.non-partner")} {_("label.club")}
					</div>
					<div className="modal__body">
						<div className="admin-creation-container">
							<div className="admin-creation-form">
								<div className="user-row">
									<div className="admin-user-pic">
										<img src={clubLogo !== "" ? (clubLogo.includes("http") ? clubLogo : `${publicUrl}/${clubLogo}`) : clubPlaceholder} />
										<label className="tableCellControls__button tableCellControls__button--svg">
											<Icon icon="crayon" color="white" />
											<input
												id="player_team_photoFile"
												name="player_team[photoFile]"
												className="js-input-file js-disable-tab custom-file-input"
												{...getInputProps()}
											/>
										</label>
									</div>
								</div>
								<Form
									onSubmit={onFormSubmit}
									mutators={{
										...arrayMutators,
									}}
									initialValues={createInitialValues(club, countries, geographicalAreaList)}
									render={({ handleSubmit }) => (
										<>
											<form onSubmit={handleSubmit}>
												<div className="form-row">
													<div className="field-group">
														<label className="required"> {_("label.club_name")} </label>
														<Field name="name" type="text">
															{({ input, meta }) => (
																<div className="form-group">
																	<input {...input} className="form-control" required={true} />
																	{meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
																</div>
															)}
														</Field>
													</div>

													<div className="field-group">
														<label className="required"> {_("club.non_partner.label.country")} </label>
														<Field
															name="country"
															className="react-select"
															component={SelectAdapter}
															options={countriesWithContinents}
															defaultValue={country}
															onChange={(e: SetStateAction<{ value: string; label: string | undefined } | null>) => {
																setCountry(e);
															}}
														/>
													</div>

													<div className="field-group w-100">
														<label> {_("match.placeholder.description")} </label>
														<Field name="description">
															{({ input, meta }) => (
																<div className="form-group">
																	<textarea {...input} maxLength={512} className="form-control textarea" />
																</div>
															)}
														</Field>
													</div>

													<div className="field-group">
														<label> {_("label.contact_name")} </label>
														<Field name="contactName" type="text">
															{({ input, meta }) => (
																<div className="form-group">
																	<input {...input} maxLength={64} className="form-control" />
																</div>
															)}
														</Field>
													</div>

													<div className="field-group">
														<label> {_("label.contact_phone")} </label>
														<Field name="contactPhoneNumber" type="number">
															{({ input, meta }) => (
																<div className="form-group">
																	<input {...input} maxLength={16} className="form-control" />
																</div>
															)}
														</Field>
													</div>

													<div className="field-group">
														<label> {_("label.contact_email")} </label>
														<Field name="contactEmail" type="email">
															{({ input, meta }) => (
																<div className="form-group">
																	<input {...input} maxLength={64} className="form-control" />
																</div>
															)}
														</Field>
													</div>

													<div className="field-group">
														<label> {_("form_add_club_amateur.label.address")} </label>
														<Field name="address" type="text">
															{({ input, meta }) => (
																<div className="form-group">
																	<input {...input} maxLength={128} className="form-control" />
																</div>
															)}
														</Field>
													</div>

													<div className="field-group">
														<label> {_("label.user.city")} </label>
														<Field name="city" type="text">
															{({ input, meta }) => (
																<div className="form-group">
																	<input {...input} maxLength={128} className="form-control" />
																</div>
															)}
														</Field>
													</div>

													<div className="field-group">
														<label> {_("label.user.postal_code")} </label>
														<Field name="zipcode" type="number">
															{({ input, meta }) => (
																<div className="form-group">
																	<input {...input} className="form-control" />
																</div>
															)}
														</Field>
													</div>

													<div className="field-group">
														<label> {_("label.user.region")} </label>
														<Field name="region" type="text">
															{({ input, meta }) => (
																<div className="form-group">
																	<input {...input} maxLength={128} className="form-control" />
																</div>
															)}
														</Field>
													</div>

													<div className="field-group">
														<label> {_("label.player.geographicalArea")} </label>
														<Field
															name="geographicalArea"
															className="react-select"
															component={SelectAdapter}
															options={geographicalAreaList}
														/>
													</div>

													<div className="field-group w-100">
														<Field name="showCase" component="input" type="checkbox" className="custom-control-input" />
														<span>{_("label.showcase")}</span>
													</div>

													<div className="form-group w-100">
														<label>{_("form_add_club_amateur.label.links")}</label>
													</div>
													<div className="form-group w-100">
														<div className="row">
															<FieldArray name="clubExternalLinks">
																{({ fields }) => (
																	<>
																		{fields.map((name, index) => (
																			<div key={index} className="form-row">
																				<div className="form-group">
																					<div className="field-group w-100">
																						<label className="required">{_("link")}</label>
																						<Field
																							name={`${name}.link`}
																							component="input"
																							className="form-control"
																							required={true}
																						/>
																					</div>

																					<div className="field-group w-100">
																						<label className="required">{_("type")}</label>
																						<Field
																							name={`${name}.type`}
																							render={({ input }) => (
																								<Select
																									{...input}
																									value={linkTypeOptions.find(
																										(option) => option.value === input.value
																									)}
																									onChange={(e) => input.onChange(e?.value)}
																									options={linkTypeOptions}
																									className="react-select"
																									required={true}
																								/>
																							)}
																						/>
																					</div>

																					<div className="form-row">
																						<button
																							type="button"
																							onClick={() => fields.remove(index)}
																							className="formAddExternalLink__btn-remove"
																						>
																							{_("Remove")}
																						</button>
																					</div>
																				</div>
																			</div>
																		))}
																		<div>
																			<button
																				type="button"
																				onClick={() => fields.push({ link: "", type: "" })}
																				className="btn btn-primary"
																			>
																				{_("form_add_club_amateur.label.link.add")}
																			</button>
																		</div>
																	</>
																)}
															</FieldArray>
														</div>
													</div>

												</div>

												<div className="form-footer">
													<button type="submit" 
														className={`btn btn-light-blue ${isSubmitted.current ? "disabled" : ""}`} 
														disabled={isSubmitted.current}>
														{_("btn.save")}
													</button>
												</div>
											</form>
										</>
									)}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ClubModal;
