import { NavLink } from "react-router-dom";

export function PlayerUsersSubheader() {
    return (
        <div className="playerUserSubheader">
            <div className="subheader-inner">
                <div className="subheader-container">
                    <div className="subheader-options">
                        <NavLink to="/player-users/signup-requests" className="tab">
                            Sign-up requests
                        </NavLink>

                        <NavLink to="/player-users/player-user-accounts" className="tab">
                            Player Users
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
}
