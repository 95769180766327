import { _ } from "i18n";
import { MigrationStatusData, MigrationStatusGamesTable, MigrationStatusPlayersTable, MigrationType } from "../../types";
import { Datagrid, HeaderRenderers, Renderers } from "components/Datagrid";
import { useState } from "react";
import noteIcon from "assets/images/outline-info.svg";
import Select, { GroupBase, SingleValue } from "react-select";
import { APIResponse, VFC } from "types";
import { useToast } from "ui-kit";
import client from "libs/client";
interface MigrationStatusProps {
	data: MigrationStatusData;
	type: string;
	targetClubteamOptions?: GroupBase<{ label: string; value: number }>[];
	target: { value: string; label: string; type: string };
}

enum TableColumns {
	FIRST_NAME = "First Name",
	LAST_NAME = "Last Name",
	TEAM = "team",
	STATUS = "Status",
	ID = "ID",
	CATEGORY = "Category",
	DATE = "Date",
	HOMECLUB = "homeClub",
	HOMETEAM = "homeTeam",
	AWAYCLUB = "awayClub",
	AWAYTEAM = "awayTeam",
}

const MigrationStatus: VFC<MigrationStatusProps> = ({ data, type, target, targetClubteamOptions }) => {
	const [showSuccessTable, setShowSuccessTable] = useState(false);
	const [showFailedTable, setShowFailedTable] = useState(false);
	const [tableData, setTableData] = useState<MigrationStatusGamesTable[]>(data?.success.migratedGames);
	const { addSuccessToast, addErrorToast } = useToast();

	const playerTableColumns = {
		firstName: TableColumns.FIRST_NAME,
		lastName: TableColumns.LAST_NAME,
		team: TableColumns.TEAM,
		status: TableColumns.STATUS,
	};

	const gameTableColumns = {
		id: TableColumns.ID,
		category: TableColumns.CATEGORY,
		homeClub: TableColumns.HOMECLUB,
		homeTeam: TableColumns.HOMETEAM,
		awayClub: TableColumns.AWAYCLUB,
		awayTeam: TableColumns.AWAYTEAM,
		date: TableColumns.DATE,
	};

	const handleAssignTeam = async (e: SingleValue<{ label: string; value: number | string | null }>, id: number) => {
		if (e === null) {
			e = { value: "", label: "" };
		}

		const response = await client.updateGameTeam.mutation({
			body: {
				teamId: (e?.value !== "" && e?.value !== null) ? parseInt(e.value as unknown as string) : null,
				targetClubId: parseInt(target?.value),
				gameId: id
			}
		});

		if (response?.data.status === APIResponse.SUCCESS) {
			setTableData((prevData) => {
				const Data = prevData.map((data) => {
					if (data.id === response.data.data?.id) {
						return response.data.data;
					}
					return data;
				});
				return Data as MigrationStatusGamesTable[];
			});

			if (response?.data.message) { addSuccessToast(response?.data.message) };
		} else {
			if (response?.data.message) { addErrorToast(response?.data.message) };
		}
	};

	const playerHeaderRenderers: HeaderRenderers<MigrationStatusPlayersTable> = {
		firstName: () => _("label.user.first_name"),
		lastName: () => _("label.user.last_name"),
		team: () => _("label.player.team"),
		status: () => _("label.user.status"),
	};

	const gameHeaderRenderers: HeaderRenderers<MigrationStatusGamesTable> = {
		id: () => _("label.user.id"),
		category: () => _("videos.table.th.category"),
		homeTeam: () => _("label.match.homeTeam"),
		homeClub: () => _("label.match.homeClub"),
		awayTeam: () => _("label.match.awayTeam"),
		awayClub: () => _("label.match.awayClub"),
		date: () => _("label.date_time"),
	};

	const playerCellRenderers: Renderers<MigrationStatusPlayersTable> = {
		status: ({ cell }) => {
			return <strong> {cell.value}</strong>;
		},
	};

	const gameCellRenderers: Renderers<MigrationStatusGamesTable> = {
		id: ({ cell }) => {
			return (
				<>
					<a href={`videos/match/${cell.value}`}>{cell.value}</a>
				</>
			);
		},
		homeTeam: ({ cell }) => {
			return (
				<>
					{(target.type === MigrationType.PARTNER && cell.row.original.migratedClub === "home") ? (
						<div className="field-group">
							<Select
								className="react-select width-300"
								options={targetClubteamOptions}
								placeholder={_("migration.placeholder.team")}
								value={cell.row.original.homeTeam.value ? cell.row.original.homeTeam : null}
								onChange={(e: SingleValue<{ label: string; value: number | string | null }>) => {
									handleAssignTeam(e, cell.row.original.id);
								}}
								isClearable
							/>
						</div>
					) : (
						<a>{cell.value.label ? cell.value.label : "-"}</a>
					)}
				</>
			);
		},
		awayTeam: ({ cell }) => {
			return (
				<>
					{(target.type === MigrationType.PARTNER && cell.row.original.migratedClub === "away") ? (
						<div className="field-group">
							<Select
								className="react-select width-300"
								options={targetClubteamOptions}
								placeholder={_("migration.placeholder.team")}
								value={cell.row.original.awayTeam.value ? cell.row.original.awayTeam : null}
								onChange={(e: SingleValue<{ label: string; value: number | string | null }>) => {
									handleAssignTeam(e, cell.row.original.id as unknown as number);
								}}
								isClearable
							/>
						</div>
					) : (
						<a>{cell.value.label ? cell.value.label : "-"}</a>
					)}
				</>
			);
		},
	};

	return (
		<>
			{(data?.success.migratedGamesCount > 0 &&
				target.type === MigrationType.PARTNER) && (
					<div className="migration-counts-container">
						<div className="migrate-info-container">
							<div className="migrate-info">
								<img src={noteIcon} />
								{_("migration.game-edit-status-note")}
							</div>
						</div>
					</div>
				)}

			<div className="migration-counts-container" id="migration-status">
				<div className="migrate-success-container">
					<div className="migrate-success-count">
						<div className="migrate-counts">
							<div className="migrate-count"> {data?.success.migratedPlayersCount}</div>
							<span>{_("migration.players_successfully_migrated")}</span>
						</div>
						<div className="migrate-counts">
							<div className="migrate-count"> {data?.success.migratedGames.length}</div>
							<span>{_("migration.games_successfully_migrated")}</span>
						</div>
					</div>
					{data?.success.migratedPlayersCount > 0 && (
						<div
							className="click-here"
							onClick={() => {
								setShowSuccessTable(!showSuccessTable);
								setShowFailedTable(false);
							}}
						>
							{_("migration.btn.more_details")}
						</div>
					)}
				</div>
				<div className="migrate-failed-container">
					<div className="migrate-success-count migrate-failed-count">
						<div className="migrate-counts">
							<div className="migrate-count"> {data?.failed.skippedPlayersCount}</div>
							<span>{_("migration.players_failed_while_migration")}</span>
						</div>
						{data?.failed.skippedGames && (
							<div className="migrate-counts">
								<div className="migrate-count"> 10</div>
								<span>{_("migration.games_failed_while_migration")}</span>
							</div>
						)}
					</div>
					{data?.failed.skippedPlayersCount > 0 && (
						<div
							className="click-here"
							onClick={() => {
								setShowFailedTable(!showFailedTable);
								setShowSuccessTable(false);
							}}
						>
							{_("migration.btn.more_details")}
						</div>
					)}
				</div>
			</div>
			<div className="counts-table">
				{showSuccessTable && (
					<>
						<div className="table-container">
							{data?.success.migratedPlayersCount > 0 && (
								<>
									<div className="inner-section-heading">{_("label.migrated_players")}</div>
									<Datagrid
										columns={playerTableColumns}
										data={data?.success.migratedPlayers}
										className="table"
										override={true}
										headerRenderers={playerHeaderRenderers}
										cellRenderers={playerCellRenderers}
										lengthFilterEnabled={false}
										manualPaginationEnabled={false}
									/>
								</>
							)}
						</div>
						<div className="table-container overflow-unset">
							{data?.success.migratedGamesCount > 0 && (
								<>
									<div className="inner-section-heading">{_("label.migrated_games")}</div>
									<Datagrid
										columns={gameTableColumns}
										data={tableData}
										className="table"
										override={true}
										headerRenderers={gameHeaderRenderers}
										cellRenderers={gameCellRenderers}
										lengthFilterEnabled={false}
										manualPaginationEnabled={false}
									/>
								</>
							)}
						</div>
					</>
				)}
				{showFailedTable && (
					<div className="table-container">
						{data?.failed.skippedPlayersCount > 0 && (
							<Datagrid
								columns={playerTableColumns}
								data={data?.failed.skippedPlayers}
								className="table"
								override={true}
								headerRenderers={playerHeaderRenderers}
								cellRenderers={playerCellRenderers}
								lengthFilterEnabled={false}
								manualPaginationEnabled={false}
							/>
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default MigrationStatus;
