import { type Notification, useVideoNotifications, useVideoNotificationsActions } from "app/notificationsStore";
import client from "libs/client";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import type { SimpleColumn } from "ui-kit/src/Datagrid/DatagridV2";
import { getStatusClass } from "../functions";
import "style/videoNotifications.css";
import { usePrevious } from "@uidotdev/usehooks";
import clsx from "clsx";
import { mercure } from "libs/mercure";
import { DialogPopover } from "ui-kit/src/DialogPopover/DialogPopover";
import { VideoUpdateMessageSchema } from "./schemas";

type EventLogRow = Notification & {
	link: string;
	message: string;
};

const columns: SimpleColumn<EventLogRow>[] = [
	{
		id: "timestamp",
		key: "timestamp",
		header: "time",
		cell: (value, row) => (value ? new Date(value).toLocaleString() : null),
	},
	{
		id: "name",
		key: "name",
		header: "name",
		cell: (value, row) => value,
	},
	{
		id: "status",
		key: "status",
		header: "status",
		cell: (value, row) => (
			<div className={getStatusClass(row.status)}>
				{row.status === "PROCESSING_PROGRESS" ? "PROGRESS" : row?.status?.replace(/_/g, " ") ?? "-"}
			</div>
		),
	},
	{
		id: "message",
		key: "message",
		header: "message",
		cell: (value, row) => (
			<div
				className="message"
				onAnimationEnd={(e) => {
					console.log("animation end");
				}}
			>
				{row.status === "PROCESSING_PROGRESS"
					? `[${row.stage}] f:${row.progress.frame} fps:${row.progress.fps} s:${row.progress.speed}`
					: value}
			</div>
		),
	},
	{
		id: "link",
		key: "link",
		header: "actions",
		cell: (value, row) => (
			<Link className="actionsButton" to={`/video-uploads/${row.id}/status`}>
				status
			</Link>
		),
	},
];

interface NotificationsTableRowProps {
	row: Notification;
}
function NotificationsTableRow({ row }: NotificationsTableRowProps) {
	const previous = usePrevious(row.timestamp);
	const [animate, setAnimate] = useState(false);

	useEffect(() => {
		if (previous !== null) {
			setAnimate(true);
		}
	}, [previous]);

	return (
		<tr className={clsx("datagrifd2Table-row", { animate })} key={row.timestamp} id={`video-notification-${row.id}`}>
			{columns.map((column) => {
				const key = column.key;
				const value = key !== "link" ? row[key] : undefined;
				return (
					<td className="datagrifd2Table-cell" key={key}>
						{column.cell ? column.cell(value ?? "", row) : ""}
					</td>
				);
			})}
		</tr>
	);
}

interface NotificationsTableProps {
	notifications: Notification[];
}
function NotificationsTable({ notifications }: NotificationsTableProps) {
	return (
		<table className="datagrifd2Table">
			<thead className="datagrifd2Table-header">
				<tr>
					{columns.map((column) => {
						const header = column.header as string;
						return (
							<th className="datagrifd2Table-headerCell" key={header}>
								{header}
							</th>
						);
					})}
				</tr>
			</thead>
			<tbody className="datagrifd2Table-body">
				{notifications.map((row) => {
					return <NotificationsTableRow row={row} key={row.id} />;
				})}
			</tbody>
		</table>
	);
}

export function VideoNotifications() {
	const notifications = useVideoNotifications();
	const { setNotifications, addMessage } = useVideoNotificationsActions();
	const { data: videoUploadsNotifications } = client.getVideoUploadsNotifications.useQuery({});

	useEffect(() => {
		if (videoUploadsNotifications?.data) {
			setNotifications(videoUploadsNotifications.data);
		}

		const es = mercure({
			topics: ["video-processing"],
			onMessage: (ev) => {
				const parsed = VideoUpdateMessageSchema.safeParse(JSON.parse(ev.data));
				if (parsed.success) {
					addMessage(parsed.data);
				} else {
					console.error(parsed.error);
				}
			},
		});

		return () => {
			es.close();
		};
	}, [videoUploadsNotifications?.data, setNotifications, addMessage]);

	return (
		<DialogPopover
			popoverOptions={{
				placement: "bottom right",
				className: "videoNotifications-popover",
				offset: 15,
			}}
			buttonOptions={{
				className: "videoNotifications-button-trigger",
			}}
			triggerSlot={<span key="even-log" className="icon-new icon-notification-bell-outline icon-blue icon-22" />}
			content={
				<div className="videoMetadataEvents-eventContainer">
					{/* <DatagridV2
						data={notifications}
						noResults="no logs"
						pageCount={1}
						pagination={pagination}
						setPagination={setPagination}
						//@ts-ignore it's ok :)
						simpleColumns={columns}
					/> */}
					<NotificationsTable notifications={notifications} />
				</div>
			}
		/>
	);
}
