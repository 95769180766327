import type { GameData, VideoUpload } from "libs/apiClient";
import * as z from "zod";

export const PeriodName = {
	firstHalf: "firstHalf",
	secondHalf: "secondHalf",
	firstOvertime: "firstOvertime",
	secondOvertime: "secondOvertime",
	penaltyShootout: "penaltyShootout",
} as const;

export enum TimeField {
	FirstHalfStart = "firstHalfStart",
	FirstHalfEnd = "firstHalfEnd",

	SecondHalfStart = "secondHalfStart",
	SecondHalfEnd = "secondHalfEnd",

	FirstOvertimeStart = "firstOvertimeStart",
	FirstOvertimeEnd = "firstOvertimeEnd",

	SecondOvertimeStart = "secondOvertimeStart",
	SecondOvertimeEnd = "secondOvertimeEnd",

	PenaltyShootoutStart = "penaltyShootoutStart",
	PenaltyShootoutEnd = "penaltyShootoutEnd",
}

export const timeFieldLabels: Record<TimeField, string> = {
	[TimeField.FirstHalfStart]: "1st half start",
	[TimeField.FirstHalfEnd]: "1st half end",
	[TimeField.SecondHalfStart]: "2nd half start",
	[TimeField.SecondHalfEnd]: "2nd half end",
	[TimeField.FirstOvertimeStart]: "1st overtime start",
	[TimeField.FirstOvertimeEnd]: "1st overtime end",
	[TimeField.SecondOvertimeStart]: "2nd overtime start",
	[TimeField.SecondOvertimeEnd]: "2nd overtime end",
	[TimeField.PenaltyShootoutStart]: "penalty shootout start",
	[TimeField.PenaltyShootoutEnd]: "penalty shootout end",
} as const;

export interface Time {
	hours: number;
	minutes: number;
	seconds: number;
}

export interface FormValues {
	[TimeField.FirstHalfStart]: string;
	[TimeField.FirstHalfEnd]: string;
	[TimeField.SecondHalfStart]: string;
	[TimeField.SecondHalfEnd]: string;
	[TimeField.FirstOvertimeStart]: string;
	[TimeField.FirstOvertimeEnd]: string;
	[TimeField.SecondOvertimeStart]: string;
	[TimeField.SecondOvertimeEnd]: string;
	[TimeField.PenaltyShootoutStart]: string;
	[TimeField.PenaltyShootoutEnd]: string;

	posterTime: string;
}

export interface TimelineValues {
	[x: string]: {
		start: number;
		end: number;
	};
}

export interface DefaultFormValues {
	[TimeField.FirstHalfStart]?: string;
	[TimeField.FirstHalfEnd]?: string;
	[TimeField.SecondHalfStart]?: string;
	[TimeField.SecondHalfEnd]?: string;
	[TimeField.FirstOvertimeStart]?: string;
	[TimeField.FirstOvertimeEnd]?: string;
	[TimeField.SecondOvertimeStart]?: string;
	[TimeField.SecondOvertimeEnd]?: string;
	[TimeField.PenaltyShootoutStart]?: string;
	[TimeField.PenaltyShootoutEnd]?: string;

	posterTime: string;
}

export const PeriodSchema = z.object({
	end: z.number(),
	start: z.number(),
	period: z.string(),
});
export type Period = z.infer<typeof PeriodSchema>;

export const MetadataSchema = z.object({
	time: z.coerce.date(),
	gameId: z.number(),
	periods: z.array(PeriodSchema).nullable(),
	clubName: z.string(),
	posterTime: z.number(),
});
export type Metadata = z.infer<typeof MetadataSchema>;

export type Club = {
	value: string;
	label: string;
	games: GameData[];
};

export interface VideoTable extends VideoUpload {
	actions?: boolean;
}

export enum VideoStatus {
	Uploaded = "UPLOADED",
	IngestStarted = "INGEST_STARTED",
	IngestOk = "INGEST_OK",
	IngestError = "INGEST_ERROR",
	ProcessingStarted = "PROCESSING_STARTED",
	ProcessingError = "PROCESSING_ERROR",
	ProcessingFinished = "PROCESSING_FINISHED",
}
