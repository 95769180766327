import { PropsWithChildren, ReactNode } from "react";
import { HeaderProps, CellProps, Renderer, FilterProps } from "react-table";

export type CellRenderer<T extends object> = (props: PropsWithChildren<CellProps<T>>) => string | ReactNode;
export type HeaderRenderer<T extends object> = (props: PropsWithChildren<HeaderProps<T>>, headerName: string) => string | ReactNode;
export type ActionRenderer<T extends object> = (row: T) => ReactNode;
export type ActionFunction<T extends object> = (row: T) => ActionRenderer<T> | ReactNode;
export type FilterRenderer<T extends object> = Renderer<FilterProps<T>>;
export type Renderers<T extends object> = {
	[K in keyof T]?: CellRenderer<T>;
};
export type HeaderRenderers<T extends object> = {
	[K in keyof T]?: HeaderRenderer<T>;
};
export type FilterRenderers<T extends object> = {
	[K in keyof T]?: FilterRenderer<T>;
};
export enum TableSortableColumns {
	SORT_ALL_COLUMNS = "sortAllColumns",
}
