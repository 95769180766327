import { VideosWithoutApprovalTable } from "features/videoUploads/tables/VideosWithoutApprovalTable";
import { VideosApprovedTable } from "features/videoUploads/tables/VideosApprovedTable";
import { _ } from "i18n";
import "style/videoMetadataPage.css";
import { useEffect } from "react";
import { setScrollBehavior } from "features/scrollPageBehaviorProvider/scrollPageBehaviorProvider";

export function VideoUploadsWithoutApprovalPage() {
	useEffect(() => {
		setScrollBehavior();
	}, []);

	return (
		<div className="videoMetadataList-container">
			<div className="listHeadline">{_("label.videos.needApproval")}:</div>
			<VideosWithoutApprovalTable />
			<div className="listHeadline">{_("label.videos.approved")}:</div>
			<VideosApprovedTable />
		</div>
	);
}
