import { _ } from "i18n";
import { FC } from "react";
import clubPlaceholder from "../../../../../../../assets/images/logo_fixtures.png";
import { SingleValue } from "react-select";

interface ClubCardProps {
	club: SingleValue<{ value: string; label: string; country: string; logo: string }>;
	countriesList: { [key: string]: string };
}

const ClubCard: FC<ClubCardProps> = ({ club, countriesList }) => {
	return (
		<div className="selected-team">
			<div className="playerCardBlock__infoClub">
				<div className="playerCardBlock__clubLogo">
					<div className="playerCardBlock__clubLogo-cropper">
						<img src={club?.logo ? club?.logo : clubPlaceholder} />
					</div>
				</div>
				<div className="">
					<div className="playerCardBlock__clubName">{club?.label}</div>
					<div className="playerCardBlock__geo">{countriesList[club?.country as keyof {}]}</div>
				</div>
			</div>
		</div>
	);
};

export default ClubCard;
