import clubPlaceholder from "assets/images/logo_fixtures.png";
import { secondsToTime } from "features/videoUploads/metadata/functions";
import { MetadataSchema, VideoStatus } from "features/videoUploads/metadata/types";
import { _any } from "i18n";
import type { VideoUpload } from "libs/apiClient";
import client from "libs/client";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "ui-kit/src/Modal";
import SkeletonBox from "ui-kit/src/Skeleton/SkeletonBox";
import { DeleteModal } from "./DeleteModal";
import { ResetModal } from "./ResetModal";
import { RetryModal } from "./RetryModal";
import { formatDateTime, roundSizeString } from "./functions";
import type { VideoProgressUpdate } from "./tables/schemas";

interface MetadataStatusInfoProps {
	video: VideoUpload;
	progress: (VideoProgressUpdate["progress"] & { stage: string }) | undefined;
}

export function MetadataStatusInfo({ video, progress }: MetadataStatusInfoProps) {
	const metadata = MetadataSchema.nullable().parse(JSON.parse(video.metadata || "null"));
	const navigate = useNavigate();
	const [showRetryModal, setShowRetryModal] = useState(false);
	const [showResetModal, setShowResetModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const { data, isLoading } = client.getVideoGameDetail.useQuery({ id: metadata?.gameId ?? 0 }, { enabled: metadata?.gameId !== undefined });

	return (
		<>
			<div className="VideostatusHeader">
				<div>
					<h1> {video.name}</h1>
					{video.uploadedBy ? <span className="videoMetadataUploadedBy">uploaded by: {video.uploadedBy}</span> : null}
				</div>
				<div className="BacktoVideoMetadata">
					<span className="icon-new icon-24 icon-dark-lavendar icon-admin-arrow-new rotate-180" />
					<div className="" onClick={() => navigate(-1)}>
						Back
					</div>
				</div>
			</div>
			<div className="videoStatusPagedetails-container">
				<div className="videoStatusmetadata-row">
					<div className="VideometaDetails">
						<span>File size</span>
						{roundSizeString(video.size) ?? "-"}
					</div>

					{video.duration && (
						<div className="VideometaDetails">
							<span>Duration</span>
							{video.duration ?? "-"}
						</div>
					)}

					{video.bitRate && (
						<div className="VideometaDetails">
							<span>Bitrate</span>
							{video.bitRate ?? "-"}
						</div>
					)}

					{video.width && video.height && (
						<div className="VideometaDetails">
							<span>Resolution</span>
							{`${video.width}x${video.height}`}
						</div>
					)}

					<div className="VideometaDetails">
						<span>Date & Time Added</span>
						{formatDateTime(video.createdAt)}
					</div>
					{video.uploadStartedAt || video.uploadFinishedAt ? (
						<>
							<div className="VideometaDetails">
								<span>Full Upload Started At</span>
								{formatDateTime(video.uploadStartedAt) ?? "-"}
							</div>
							<div className="VideometaDetails">
								<span>Full Upload Finished At</span>
								{formatDateTime(video.uploadFinishedAt) ?? "-"}
							</div>
						</>
					) : (
						(video.partialUploadStartedAt || video.partialUploadFinishedAt) && (
							<>
								<div className="VideometaDetails">
									<span>Partial Upload Started At</span>
									{formatDateTime(video.partialUploadStartedAt) ?? "-"}
								</div>
								<div className="VideometaDetails">
									<span>Partial Upload Finished At</span>
									{formatDateTime(video.partialUploadFinishedAt) ?? "-"}
								</div>
							</>
						)
					)}
					<div className="VideometaDetails">
						<span>Backed up Date & Time</span>
						{formatDateTime(video.backupFinishedAt) ?? "-"}
					</div>
					{video.gpuId ? (
						<div className="VideometaDetails">
							<span>Gpu Id</span>
							{video.gpuId}
						</div>
					) : null}
				</div>
				<div className="videoStatusmetadata-row2">
					<div className="videoGamedetails-container">
						{metadata?.gameId && (
							<div className="videoGamedetails">
								<div className="detailsContainer">
									<span>Assigned Game</span>
									{isLoading ? (
										<SkeletonBox style={{ height: "80px", margin: "5px" }} />
									) : (
										<Link to={`/admin/videos/match/${data?.data.id}`} className="Assigamethumb">
											<div className="team-logo">
												<img src={data?.data.homeTeamLogo ?? clubPlaceholder} alt="logo" />
											</div>
											<div className="game-score">{`${data?.data.homeTeamsScore ?? "-"}:${data?.data.awayTeamsScore ?? "-"}`}</div>
											<div className="team-logo">
												<img src={data?.data.awayTeamLogo ?? clubPlaceholder} alt="logo" />
											</div>
											<div className="game-details">
												{`${data?.data.category} ${data?.data.homeTeamName}`}
												<span>{`${_any(`enum.gametype.${data?.data.type}`)} ${data?.data.date}`}</span>
											</div>
										</Link>
									)}
								</div>
							</div>
						)}
						<div className="videoGamedetails">
							<div>
								<span>Current status</span>
								{video.status?.replace(/_/g, " ")}
							</div>

							<div className="video-actions">
								{video.status === VideoStatus.IngestError ? (
									<button className="btn delete" type="button" onClick={() => setShowDeleteModal(true)}>
										Delete
									</button>
								) : video.status === VideoStatus.ProcessingError ? (
									<>
										<button className="btn try" type="button" onClick={() => setShowRetryModal(true)}>
											Try again
										</button>
										<button className="btn reset" type="button" onClick={() => setShowResetModal(true)}>
											Reset
										</button>
									</>
								) : null}
							</div>
						</div>
						<div className="videoGamedetails">
							<div>
								<span>Last event</span>
								{video.lastEvent?.replace(/_/g, " ") ?? "-"}
							</div>
						</div>
					</div>
					<div className="videoSelected-times">
						{metadata?.periods?.map((period) => (
							<div key={period.period} className="videoSelected-time">
								<div className="video-time">
									<span>{period.period} start</span>
									{secondsToTime(period.start)}
								</div>
								<div className="video-time">
									<span>{period.period} end</span>
									{secondsToTime(period.end)}
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="progress-container">
					{Object.entries(progress ?? {}).map(([key, value]) => {
						return (
							<div className="videoGamedetails" key={key}>
								<div>
									<span>{key}</span>
									{value}
								</div>
							</div>
						);
					})}
				</div>
			</div>
			<Modal show={showResetModal} onClose={() => setShowResetModal(false)} size="sm" title="Reset">
				<ResetModal videoId={video.id ?? 0} close={() => setShowResetModal(false)} />
			</Modal>
			<Modal show={showRetryModal} onClose={() => setShowRetryModal(false)} size="sm" title="Retry">
				<RetryModal videoId={video.id ?? 0} close={() => setShowRetryModal(false)} />
			</Modal>
			<Modal show={showDeleteModal} onClose={() => setShowDeleteModal(false)} size="sm" title="Delete">
				<DeleteModal videoId={video.id ?? 0} close={() => setShowDeleteModal(false)} />
			</Modal>
		</>
	);
}
