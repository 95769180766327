import { Link, useParams } from "react-router-dom";
import EyeballLogo from "assets/images/logo-eyeball.svg";
import "../style/resetPasswordPage.scss";
import LangSwitch from "ui-kit/src/Header/LangSwitch/LangSwitch";
import { useI18n } from "features/translation/context";
import { useToast } from "ui-kit";
import { _ } from "i18n";
import { Field, Form } from "react-final-form";
import type { ValidationErrors } from "final-form";
import { useState } from "react";
import client from "libs/client";
import loader from "assets-new/loaders/loader3.gif";

enum InputType {
	Text = "text",
	Password = "password",
}

export default function ResetPasswordPage() {
	const { changeLocale, locale } = useI18n();
	const { addErrorToast } = useToast();
	const [showForm, setShowForm] = useState(true);
	const [showNewPass, setShowNewPass] = useState(false);
	const [showRepeatPass, setShowRepeatPass] = useState(false);
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const { token } = useParams();

	const { data, isLoading } = client.validateUserPasswordReset.useQuery({ token: token ?? "" }, {retry: false});

	const mutation = client.resetPassword.useMutation({
		onSuccess: () => {
			setShowForm(false);
		},
		onError: (res) => {
			setButtonDisabled(false);
			addErrorToast(res.message);
		},
	});

	const onSubmit = (values: { newPass: string; repeatNewPass: string }) => {
		setButtonDisabled(true);
		mutation.mutate({ token: token ?? "", body: { newPassword: values.newPass } });
	};

	const validate = (values: { newPass: string; repeatNewPass: string }): ValidationErrors => {
		const errors: ValidationErrors = {};
		if (!/(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Za-z]).*$/.test(values.newPass)) {
			errors.newPass = _("label.valitadion.password");
		}
		if (values.newPass !== values.repeatNewPass) {
			errors.repeatNewPass = _("error.password.not_matching");
		}
		return errors;
	};

	return (
		<>
			<div className="resetPasswordPage-headerContainer">
				<div className="resetPasswordPage-header">
					<img className="resetPasswordPage-logo" src={EyeballLogo} />
					<div className="resetPasswordPage-headerRight">
						<Link to="https://www.eyeball.club/" className="resetPasswordPage-contact">
							{_("header.not_loged.contact")}
						</Link>
						<Link to="/login" className="resetPasswordPage-contact">
							{_("header.not_loged.login")}
						</Link>
						<LangSwitch onChange={changeLocale} selectedLocale={locale} />
					</div>
				</div>
			</div>
			<div className="resetPasswordPage-container">
				<div className="resetPasswordPage-bar" />

				<div className="resetPasswordForm-container">
					<div className="resetPasswordForm-logoSmall">
						<span className="icon icon-logo-eyeball-small-new icon-70" />
					</div>
					{data ? (
						showForm ? (
							<>
								<span className="resetPasswordForm-title">{_("account.signup.new.password")}</span>
								<span className="resetPasswordForm-subtitle">{_("account.signup.new.password.instruction")}</span>
								<Form
									onSubmit={onSubmit}
									validate={validate}
									render={({ handleSubmit }) => (
										<form onSubmit={handleSubmit}>
											<div className="resetPasswordForm-formContent">
												<Field name="newPass" type={showNewPass ? InputType.Text : InputType.Password}>
													{({ input, meta }) => (
														<div className="resetPasswordForm-contentItem">
															<div className="resetPasswordForm-passwordContainer">
																<input
																	className="resetPasswordForm-itemInput"
																	{...input}
																	placeholder={_("label.user.plain_password.first")}
																/>
																<span
																	className={
																		showNewPass ? "svg icon-20 icon-visible-on" : "svg icon-20 icon-visible-off"
																	}
																	onClick={() => setShowNewPass(!showNewPass)}
																/>
															</div>
															{meta.touched && meta.error && <span className="passwordValidation">{meta.error}</span>}
														</div>
													)}
												</Field>
												<Field name="repeatNewPass" type={showRepeatPass ? InputType.Text : InputType.Password}>
													{({ input, meta }) => (
														<div className="resetPasswordForm-contentItem">
															<div className="resetPasswordForm-passwordContainer">
																<input
																	className="resetPasswordForm-itemInput"
																	{...input}
																	placeholder={_("label.user.plain_password.second")}
																/>
																<span
																	className={
																		showRepeatPass
																			? "svg icon-20 icon-visible-on"
																			: "svg icon-20 icon-visible-off"
																	}
																	onClick={() => setShowRepeatPass(!showRepeatPass)}
																/>
															</div>
															{meta.touched && meta.error && <span className="passwordValidation">{meta.error}</span>}
														</div>
													)}
												</Field>
												<button
													className={`resetPasswordForm-buttonSubmit ${buttonDisabled ? "disabled" : ""}`}
													disabled={buttonDisabled}
													type="submit"
												>
													{_("btn.save")}
												</button>
											</div>
										</form>
									)}
								/>
							</>
						) : (
							<>
								<span className="resetPasswordForm-title">{_("message.reset_password.success")}</span>
								<Link to="/login" className="resetPasswordForm-link">
									{_("message.reset_password.back_to_login")}
								</Link>
							</>
						)
					) : isLoading ? (
						<div className="iframe-loader">
							<img src={loader} />
						</div>
					) : (
						<span className="resetPasswordForm-title">{_("app.user.reset_password.invalid_token")}</span>
					)}
				</div>
			</div>
			<div className="resetPasswordPage-footer">
				<Link className="resetPasswordPage-footer-link" to="/homepage_terms-of-use">
					<span>{_("terms.conditions")}</span>
				</Link>
				<span>{_("footer.copyright")}</span>
			</div>
		</>
	);
}
