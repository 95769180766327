import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";
import { useEffect, useState } from "react";
import "style/videoTestPage.css";
import client from "libs/client";
import {VidstackPlayer} from "components/PlayerTestPage/VidstackPlayer";
import {PlyrPlayer} from "components/PlayerTestPage/PlyrPlayer";
import { setScrollBehavior } from "features/scrollPageBehaviorProvider/scrollPageBehaviorProvider";
import Select, { type SingleValue } from "react-select";
import SkeletonBox from "ui-kit/src/Skeleton/SkeletonBox";

export default function VideoUploadsTestPage() {
	useEffect(() => {
		setScrollBehavior();
	}, []);

	const { data, isLoading, refetch } = client.getTestVideos.useQuery();

	const videos = data?.data?.map((video) => ({
		value: `${video.host}/${encodeURIComponent(video.path)}`,
		label: decodeURIComponent(video.path),
	}));

	const [activeVideo, setActiveVideo] = useState<SingleValue<{ value: string; label: string }>>();

	// biome-ignore lint/correctness/useExhaustiveDependencies: adding all dependencies is not desirable, causes loop
	useEffect(() => {
		if (videos) {
			setActiveVideo(videos[0]);
		}
	}, [data?.data]);

	return (
		<div className="videoApp-container">
			<div className="videoApp-controls">
				<button className="videoApp-loadButton" type="button" onClick={() => refetch()}>
					load videos
				</button>
				<Select
					isLoading={isLoading}
					className="videoApp-select"
					value={activeVideo}
					options={videos}
					onChange={(video) => setActiveVideo(video)}
				/>
			</div>
			{isLoading ? (
				<div className="videoApp-players">
					<SkeletonBox className="videoAppSkeleton-video" />
					<SkeletonBox className="videoAppSkeleton-video" />
				</div>
			) : (
				<div className="videoApp-players">
					<>
						<VidstackPlayer key={activeVideo?.value} baseURL={activeVideo?.value} />
					</>
					<>
						<PlyrPlayer key={activeVideo?.value} baseURL={activeVideo?.value} />
					</>
				</div>
			)}
		</div>
	);
}
