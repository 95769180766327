import { useEffect } from "react";
import { Link } from "react-router-dom";
import "../style/forgottenPasswordPage.css";
import EyeballLogo from "assets/images/logo-eyeball.svg";
import { _ } from "i18n";
import LangSwitch from "ui-kit/src/Header/LangSwitch/LangSwitch";
import { useI18n } from "features/translation/context";
import { Form, Field } from "react-final-form";
import client from "libs/client";
import { useToast } from "ui-kit";
import isEmail from "validator/lib/isEmail";
import { useState } from "react";
import { setScrollBehavior } from "features/scrollPageBehaviorProvider/scrollPageBehaviorProvider";

interface ValidationErrors {
	email?: string;
}

export default function ForgottenPasswordPage() {
	const { changeLocale, locale } = useI18n();
	const { addErrorToast, addSuccessToast } = useToast();
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const requestPasswordMutation = client.forgotPassword.useMutation({
		onSuccess: () => {
			addSuccessToast(_("message.request_password.success.msg"));
			setButtonDisabled(false);
		},
		onError: () => {
			addErrorToast(_("message.request_password.fail.notfound.msg"));
			setButtonDisabled(false);
		},
	});

	const onSubmit = (values: { email: string }) => {
		setButtonDisabled(true);
		requestPasswordMutation.mutate({ body: { email: values.email } });
	};

	const validate = (e: { email: string }): ValidationErrors => {
		const errors: ValidationErrors = {};
		if (!e.email) errors.email = _("email.invalid.validation.message");
		if (e.email && !isEmail(e.email)) errors.email = _("email.invalid.validation.message");
		return errors;
	};

	useEffect(() => {
		setScrollBehavior();
	}, []);

	return (
		<div className="forgottenPasswordPage-container">
			<div className="forgottenPasswordPage-headerContainer">
				<div className="forgottenPasswordPage-header">
					<img className="forgottenPasswordPage-logo" src={EyeballLogo} />
					<div className="forgottenPasswordPage-headerRight">
						<Link to="https://www.eyeball.club/" className="forgottenPasswordPage-contact">
							{_("header.not_loged.contact")}
						</Link>
						<Link to="/login" className="forgottenPasswordPage-contact">
							{_("header.not_loged.login")}
						</Link>
						<LangSwitch onChange={changeLocale} selectedLocale={locale} />
					</div>
				</div>
			</div>
			<div className="forgottenPasswordPage-content">
				<div className="forgottenPasswordPage-bar" />
				<div className="forgottenPasswordPage-formContainer">
					<div className="forgottenPasswordPage-logoSmall">
						<span className="icon icon-logo-eyeball-small-new icon-70" />
					</div>
					<span className="forgottenPasswordPage-title">{_("forgot_password.title")}</span>
					<span className="forgottenPasswordPage-subtitle">{_("forgot_password.subtitle")}</span>
					<Form
						onSubmit={onSubmit}
						validate={validate}
						render={({ handleSubmit }) => (
							<form onSubmit={handleSubmit}>
								<div className="forgottenPasswordPage-formContent">
									<Field name="email" component="input" type="text" required>
										{({ input, meta }) => (
											<div>
												<input placeholder="Email" type="text" {...input} className="forgottenPasswordPage-input" />
												{meta.touched && meta.error && <span className="forgottenPasswordPage-validation"> {meta.error} </span>}
											</div>
										)}
									</Field>
									<button type="submit" className={`forgottenPasswordPage-button ${buttonDisabled ? "disabled" : ""}`} disabled={buttonDisabled}>
										{_("forgot_password.sent")}
									</button>
								</div>
							</form>
						)}
					/>
				</div>
			</div>
			<div className="forgottenPasswordPage-footer">
				<Link className="forgottenPasswordPage-footer-link" to="/homepage_terms-of-use">
					<span>{_("terms.conditions")}</span>
				</Link>
				<span>{_("footer.copyright")}</span>
			</div>
		</div>
	);
}
