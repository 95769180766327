import "style/videoMetadataPage.css";
import { useEffect } from "react";
import { setScrollBehavior } from "features/scrollPageBehaviorProvider/scrollPageBehaviorProvider";
import { VideosFinishedTable } from "features/videoUploads/tables/VideosFinishedTable";

export function VideoUploadsFinishedPage() {
	useEffect(() => {
		setScrollBehavior();
	}, []);

	return (
		<div className="videoMetadataList-container">
			<div className="listHeadline">Finished videos:</div>
			<VideosFinishedTable />
		</div>
	);
}
