import { SelectAdapter } from "components/FormComponents/formComponents";
import type { ValidationErrors } from "final-form";
import { _ } from "i18n";
import type { ClubTeamInfo } from "libs/apiClient";
import client from "libs/client";
import { gameTeamAgeGroup } from "libs/constants";
import { Field, Form } from "react-final-form";
import { useToast } from "ui-kit";
interface AddEditTeamModalProps {
	close: () => void;
	teamData?: ClubTeamInfo;
	clubId: number;
}

export function AddEditTeamModal({ close, teamData, clubId }: AddEditTeamModalProps) {
	const { addSuccessToast, addErrorToast } = useToast();

	const oldPlayers = { value: "OLD", label: "OLD PLAYERS" };
	const categories = gameTeamAgeGroup;

	if (!categories.some((obj) => obj.value === oldPlayers.value) && teamData) {
		categories.push(oldPlayers);
	}

	const initialData = {
		name: teamData?.name,
		category: categories.find((category) => category.value === teamData?.category),
	};

	const add = client.addUpdateTeam.useMutation({
		onSuccess: (res) => {
			if (!teamData) {
				client.queryClient.invalidateQueries({
					predicate: (query) => query.queryKey.includes("squadTeams"),
				});
			}
			client.queryClient.invalidateQueries({
				predicate: (query) => query.queryKey.includes("squadTeams") || query.queryKey.includes("squadPlayers"),
			});
			addSuccessToast(res?.data.message ?? "");
		},
		onError: (res) => {
			addErrorToast(res.message);
		},
	});

	const validate = (values: typeof initialData) => {
		const errors: ValidationErrors = {};
		if (!values.name) {
			errors.name = _("form.required.validation.message");
		}
		if (!values.category) {
			errors.category = _("form.required.validation.message");
		}

		return errors;
	};

	const onSubmit = (values: typeof initialData) => {
		add.mutate({
			clubId: clubId,
			body: {
				id: teamData?.id ?? null,
				name: values.name ?? "",
				category: values.category?.value ?? "",
			},
		});
		close();
	};

	return (
		<div className="addEditModal-container">
			<Form
				initialValues={initialData}
				validate={validate}
				onSubmit={onSubmit}
				render={({ handleSubmit }) => {
					return (
						<form onSubmit={handleSubmit}>
							<div className="addEditModal-form">
								<Field name="name">
									{({ input, meta }) => (
										<div className="addEditModal-field team">
											<label>{_("app.coach.match.team_sheet_player_name_header")}</label>
											<input className="itemInput" {...input} type="text" required />
											{meta.touched && meta.error && <span className="form-error">{meta.error}</span>}
										</div>
									)}
								</Field>

								<div className="addEditModal-field team">
									<label>{_("form_search_player_game.category")}</label>
									<Field name="category" component={SelectAdapter} options={categories} />
								</div>
							</div>
							<div className="formButtons">
								<button className="buttonSubmit" type="submit">
									{_("btn.save")}
								</button>
							</div>
						</form>
					);
				}}
			/>
		</div>
	);
}
