import type { PartialSignUpRequestResponse } from "libs/apiClient";
import { ContentCell } from "./ContentCell";
import { formatDate } from "pages/PlayerUserDetailPage";

interface SignupRequestDetailProps {
    signUpData: PartialSignUpRequestResponse;
    editable?: boolean;
    nationalities?: {
        label: string;
        value: string | number;
    }[];
}



export function SignupRequestDetail({ signUpData, editable, nationalities }: SignupRequestDetailProps) {

    const renderContent = <K extends keyof PartialSignUpRequestResponse>(
        key: K,
        value: PartialSignUpRequestResponse[K]
    ) => {
        switch (key) {
            case "id":
                return <ContentCell key={key} title={"Sign-up Id"} content={(typeof value !== "object" && value) ? value : "-"} />
            case "player":
                return <ContentCell key={key} title="Coach Assigned eb_player.id" content={typeof value === "object" && value?.id ? value.id : "-"} />
            case "clubId":
                return <ContentCell key={key} title="Club Id" content={(typeof value !== "object" && value) ? value : "-"} id={signUpData.id} patchMethod={"signup"} field={editable ? key : undefined} number />
            case "previousSeasonClubId":
                return <ContentCell key={key} title="Previous Season Club Id" content={(typeof value !== "object" && value) ? value : "-"} id={signUpData.id} patchMethod={"signup"} field={editable ? key : undefined} number />
            case "clubName":
                return <ContentCell key={key} title="Manually Entered Club Name" content={(typeof value !== "object" && value) ? value : "-"} id={signUpData.id} patchMethod={"signup"} field={editable ? key : undefined} />
            case "birthDate":
                return <ContentCell key={key} title="Date of Birth" date content={formatDate(typeof value === "string" ? value : null, true)} id={signUpData.id} patchMethod={"signup"} field={editable ? key : undefined} />
            case "email":
                return <ContentCell key={key} title="Email" content={(typeof value !== "object" && value) ? value : "-"} id={signUpData.id} patchMethod={"signup"} field={editable ? key : undefined} />
            case "phoneNumber":
                return <ContentCell key={key} title="PhoneNumber" content={(typeof value !== "object" && value) ? value : "-"} id={signUpData.id} patchMethod={"signup"} field={editable ? key : undefined} phoneNumber />
            case "countryOfResidence":
                return <ContentCell key={key} title="Country of Residence" content={(typeof value !== "object" && value) ? value : "-"} id={signUpData.id} patchMethod={"signup"} field={editable ? key : undefined} selectItems={nationalities} />
            case "firstName":
                return <ContentCell key={key} title="First Name" content={(typeof value !== "object" && value) ? value : "-"} id={signUpData.id} patchMethod={"signup"} field={editable ? key : undefined} />
            case "middleName":
                return <ContentCell key={key} title="Middle Name" content={(typeof value !== "object" && value) ? value : "-"} id={signUpData.id} patchMethod={"signup"} field={editable ? key : undefined} />
            case "lastName":
                return <ContentCell key={key} title="Last Name" content={(typeof value !== "object" && value) ? value : "-"} id={signUpData.id} patchMethod={"signup"} field={editable ? key : undefined} />
            case "createdAt":
                return <ContentCell key={key} title="Account Created" content={formatDate(typeof value === "string" ? value : null)} />
            case "updatedAt":
                return <ContentCell key={key} title="Last Updated" content={formatDate(typeof value === "string" ? value : null)} />
            case "otpVerified":
                return <ContentCell key={key} title="OTP Verified" content={value ? "Yes" : "No"} boolean booleanValue={typeof value === "boolean" ? value : undefined} />
            case "termsOfConditions":
                return <ContentCell key={key} title="Terms and Conditions" content={value ? "Accepted" : "Not Accepted"} boolean booleanValue={typeof value === "boolean" ? value : undefined} />
            case "internalNote":
                return <ContentCell key={key} title="Internal Note" content={(typeof value !== "object" && value) ? value : "-"} id={signUpData.id} patchMethod={"signup"} field={editable ? key : undefined} />
            case "countryOfResidenceName": return null
            default: {
                const fieldLabel = key
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, (str) => str.toUpperCase());
                return <ContentCell key={key} title={fieldLabel} content={(typeof value !== "object" && value) ? value : "-"} />
            }
        }
    };

    return (
        <div className="userDetails-content">
            {nationalities && Object.entries(signUpData).map(([key, value]) => {
                const typedKey = key as keyof PartialSignUpRequestResponse;
                return renderContent(typedKey, value);
            })}
        </div>
    );
}
