import { _ } from "i18n";
import "./pageControls.scss";
import type { RowData, Table } from "@tanstack/react-table";

interface TablePageControlsProps<T extends RowData> {
	table: Table<T>;
}

export default function TablePageControls<T extends RowData>({ table }: TablePageControlsProps<T>) {
	const handleInputPageChange = (newPage: number) => {
		if (newPage > 0 && newPage <= table.getPageCount()) {
			setTimeout(() => {
				table.setPageIndex(newPage - 1);
			}, 500);
		}
	};

	return (
		<>
			{table.getPageCount() > 1 && (
				<div className="tablePageControls-container">
					<div
						className="tablePageControls-goToStartEnd"
						onClick={() => {
							if (table.getCanPreviousPage()) table.firstPage();
						}}
					>
						<span className={`icon-new icon-chevron-left-grey ${table.getCanPreviousPage() ? "icon-blue" : ""} icon-24`} />
						<span className={`icon-new icon-chevron-left-grey ${table.getCanPreviousPage() ? "icon-blue" : ""} icon-24`} />
					</div>
					<div
						className="tablePageControls-prevNext"
						onClick={() => {
							if (table.getCanPreviousPage()) table.previousPage();
						}}
					>
						<span className={`icon-new icon-chevron-left-grey ${table.getCanPreviousPage() ? "icon-blue" : ""} icon-24`} />
					</div>

					<div
						className="tablePageControls-prevNext"
						onClick={() => {
							if (table.getCanNextPage()) table.nextPage();
						}}
					>
						<span className={`icon-new icon-chevron-right-grey ${table.getCanNextPage() ? "icon-blue" : ""} icon-24`} />
					</div>
					<div
						className="tablePageControls-goToStartEnd"
						onClick={() => {
							if (table.getCanNextPage()) table.lastPage();
						}}
					>
						<span className={`icon-new icon-chevron-right-grey ${table.getCanNextPage() ? "icon-blue" : ""} icon-24`} />
						<span className={`icon-new icon-chevron-right-grey ${table.getCanNextPage() ? "icon-blue" : ""} icon-24`} />
					</div>
					<div className="tablePageControls-count">{`${_("label.pagination.page")} ${table.getState().pagination.pageIndex + 1} ${_(
						"label.pagination.of",
					)} ${table.getPageCount()} | ${_("label.pagination.go.to.page")}`}</div>
					<div>
						<input
							key={table.getState().pagination.pageIndex}
							className="tablePageControls-input"
							defaultValue={table.getState().pagination.pageIndex + 1}
							type="number"
							min={1}
							max={table.getPageCount()}
							onChange={(e) => handleInputPageChange(Number(e.target.value))}
						/>
					</div>
				</div>
			)}
		</>
	);
}
