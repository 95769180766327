import client from "libs/client";
import { useParams } from "react-router-dom";
import { VideoMetadataEditor } from "features/videoUploads/metadata/videoMetadataEditor";
import { useEffect } from "react";
import { setScrollBehavior } from "features/scrollPageBehaviorProvider/scrollPageBehaviorProvider";
import { VideoEditorSkeleton } from "components/Skeleton/MetadataPages/VideoEditorSkeleton";

export function VideoUploadMetadataPage() {
	const params = useParams();

	useEffect(() => {
		setScrollBehavior();
	}, []);

	// @ts-ignore
	const id = Number.parseInt(params.id);

	const { data: video, isLoading: videoLoading } = client.getVideo.useQuery({ id }, { queryKey: ["metadataVideos"], enabled: id !== undefined });
	const { data: games, isLoading: gamesLoading, isFetching } = client.getGamesWithoutVideo.useQuery(
		{ page: 1, limit: 1000, order: "ASC" },
		{
			queryKey: ["metadataGamesList"],
			enabled: id !== undefined,
		},
	);

	if (!(video || videoLoading)) {
		return "NOT FOUND";
	}

	return (
		<div className="videoMetadataPage-container">
			{videoLoading || gamesLoading ? (
				<VideoEditorSkeleton />
			) : (
				<VideoMetadataEditor video={video?.data} games={games?.data || []} gamesLoading={gamesLoading} isGamesFetching={isFetching} />
			)}
		</div>

	);
}
