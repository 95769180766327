import { QueryClient as BaseClient } from "@tanstack/react-query";

const qc = new BaseClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			cacheTime: 0,
		},
	},
	logger: {
		log: console.log,
		warn: console.warn,
		error: (err) => {
			if (err.status !== 401 && err !== "Passing a custom logger has been deprecated and will be removed in the next major version.") {
				console.error({ err });
			}
		},
	},
});

export default qc;
export type QueryClient = typeof qc;
