import { FC } from "react";

interface IconProps {
	icon: string;
	size?: 10 |  12 | 14 | 16 | 18 | 20 | 22 | 24 | 25 | 26 | 29 | 30 | 32 | 50;
	color?: 'white' | 'blue' | 'light-blue' | 'grey' | 'dark-grey' | 'success' | 'danger' | 'orange' | 'dark-lavender';
	className?: string;
}

const Icon: FC<IconProps> = ({ icon, size, color, className }) => {
	return (
		<span className={`icon icon-new icon-${icon} ${size ? `icon-${size}` : ""} ${color ? `icon-${color}` : ""} ${className ? className : ""}`}/>
	);
};

export default Icon;
