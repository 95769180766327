import type { PropsWithChildren } from "react";
import { AdminHeader } from "./AdminHeader";

export default function Layout({ children }: PropsWithChildren) {
	return (
		<>
			<AdminHeader user={{ name: "Admin EYEBALL", email: "admin@testing.eyeball.club", photo: null }} />
			<div className="container">{children}</div>
		</>
	);
}
