import { PlayerUsersSubheader } from "features/playerUser/PlayerUsersSubheader";
import { Outlet } from "react-router-dom";
import "style/signupRequestsPage.css";

export function PlayerUserLayoutPage() {
    return (
        <div className="playerUsers-container">
            <PlayerUsersSubheader />
            <Outlet />
        </div>
    );
}
