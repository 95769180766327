import { _ } from "i18n";
import client from "libs/client";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import NotFoundPage from "./errorPages/NotFoundPage";
import { setScrollBehavior } from "features/scrollPageBehaviorProvider/scrollPageBehaviorProvider";
import { useEffect, useState } from "react";
import { SignupRequestDetail } from "features/playerUser/SignupRequestDetail";
import { PlayerUserDetail } from "features/playerUser/PlayerUserDetail";
import { GuardianApprovalDetail } from "features/playerUser/GuardianApprovalDetail";
import Modal from "ui-kit/src/Modal";
import { ActivateDeactivateModal } from "features/playerUser/ActivateDeactivateModal";
import SkeletonBox from "ui-kit/src/Skeleton/SkeletonBox";
import { PlayerUserDetailSkeleton } from "components/Skeleton/PlayerUsersPage/PlayerUserDetailSkeleton";

export function formatDate(dateValue: string | null, birthday = false) {
    return dateValue
        ? new Intl.DateTimeFormat("en-GB", {
            dateStyle: "short",
            ...(birthday ? {} : { timeStyle: "short" }),
        }).format(new Date(dateValue))
        : "-";
};

export function PlayerUserDetailPage() {
    const { hash } = useLocation()
    const { id } = useParams();
    const navigate = useNavigate();
    const urlType = hash ? hash.substring(1) : '';
    const [selectedTab, setSelectedTab] = useState(urlType || "signupRequest")
    const [showModal, setShowModal] = useState(false);

    useEffect(() => { setScrollBehavior() }, [])

    const handleTabClick = (tabName: string) => {
        setSelectedTab(tabName);
        navigate(`#${tabName}`);
    };

    const { data: nationalities } = client.getNationalities.useQuery();
    const { data: { data: userData } = {}, isLoading, error } = client.getSignUpRequestData.useQuery({ idOrUuid: id ?? "" }, { queryKey: ["playerUserDetail"] })



    if (error) {
        const code = error?.response.status;

        if (code === 404) {
            return (
                <div>
                    <NotFoundPage />
                </div>
            );
        }
    }

    return (
        <div className="userDetails-container">
            <div className="pageHeading">
                {isLoading ? <SkeletonBox style={{ width: "300px", height: "32px", margin: "35px 0 35px 0" }} /> : <div className="userName">
                    {`${userData?.playerUser?.firstName ?? userData?.signUpRequest?.firstName ?? ""} ${userData?.signUpRequest?.middleName ?? ""} ${userData?.playerUser?.lastName ?? userData?.signUpRequest?.lastName ?? ""}`}
                </div>}
                {userData?.playerUser && <div className="activateButton" onClick={() => setShowModal(true)}>{userData?.playerUser?.isActive ? "Deactivate" : "Activate"}</div>}
            </div>
            <div className="details-container">
                <div className="userDetails-tabs">
                    <div className={`tab ${userData?.signUpRequest ? "" : "disabled"} ${selectedTab === "signupRequest" ? "active" : ""}`} onClick={() => handleTabClick("signupRequest")}>
                        {_("label.signup_request")}
                    </div>
                    <div className={`tab ${(userData?.playerUser || userData?.playerProfile) ? "" : "disabled"} ${selectedTab === "playerDetails" ? "active" : ""}`} onClick={() => handleTabClick("playerDetails")}>
                        {_("label.player_details")}
                    </div>
                    <div className={`tab ${userData?.guardianApprovals && userData?.guardianApprovals?.length > 0 ? "" : "disabled"} ${selectedTab === "guardianApprovals" ? "active" : ""}`} onClick={() => handleTabClick("guardianApprovals")}>
                        {_("label.guardian_approval_requests")}
                    </div>
                </div>
                {isLoading ? <PlayerUserDetailSkeleton /> :
                    <div>
                        {selectedTab === "signupRequest" && userData?.signUpRequest && <SignupRequestDetail nationalities={nationalities?.data} signUpData={userData.signUpRequest} editable={!(userData.playerUser)} />}
                        {selectedTab === "playerDetails" && (userData?.playerUser || userData?.playerProfile) && <PlayerUserDetail nationalities={nationalities?.data} userData={userData.playerUser} profileData={userData.playerProfile} />}
                        {selectedTab === "guardianApprovals" && userData?.guardianApprovals && <GuardianApprovalDetail approvalData={userData.guardianApprovals} />}
                    </div>}
            </div>
            <Modal show={showModal} onClose={() => setShowModal(false)} size="sm" title={userData?.playerUser?.isActive ? "Deactivate" : "Activate"}>
                {userData?.playerUser?.isActive !== undefined && userData?.playerUser?.id && <ActivateDeactivateModal id={userData?.playerUser?.id} isActive={userData?.playerUser?.isActive} close={() => setShowModal(false)} />}
            </Modal>
        </div>
    )
}
