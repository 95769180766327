import { VFC } from "types";
import { NotificationManagementData } from "../types";
interface LanguageListProps {
	language: { value?: string; label?: string };
	selectedLanguage?: string;
	row?: NotificationManagementData;
	onDeleteLanguage: (data: { value?: string; label?: string }) => void;
	setSelectedLanguage: (data: string) => void;
}

const LanguageList: VFC<LanguageListProps> = ({ language, selectedLanguage, row, onDeleteLanguage, setSelectedLanguage }) => {
	return (
		<li
			className={language?.value === selectedLanguage ? "active" : ""}
			onClick={() => {
				setSelectedLanguage(language?.value!);
			}}
		>
			{language?.label}
			<svg
				className={row?.messages ? "icon icon-cross disabled" : "icon icon-cross"}
				onClick={(e) => {
					e.stopPropagation();
					if (!row?.messages) {
						onDeleteLanguage(language);
					}
				}}
			>
				<use xlinkHref="#icon-cross"></use>
			</svg>
		</li>
	);
};
export default LanguageList;
