import type { VideoUploadLog } from "libs/apiClient";
import DatagridV2, { type SimpleColumn } from "ui-kit/src/Datagrid/DatagridV2";
import { formatDateTime } from "./functions";

interface MetadataLogTableProps {
	logs?: VideoUploadLog[];
}

export function MetadataLogTable({ logs }: MetadataLogTableProps) {
	const columns: SimpleColumn<VideoUploadLog>[] = [
		{
			id: "timestamp",
			key: "timestamp",
			header: () => "Date & time",
			cell: (value, row) => <div className="dateTimeCell">{formatDateTime(row.timestamp) ?? "-"}</div>,
		},
		{
			id: "status",
			key: "status",
			header: () => "Event name",
			cell: (value, row) => <div className="statusCell">{row.status?.replace(/_/g, " ") ?? "-"}</div>,
		},
		{
			id: "message",
			key: "message",
			header: () => "message",
			cell: (value, row) => <div className="messageCell">{row.message ?? "-"}</div>,
		},
	];
	return (
		<div className="log-container">
			<h2> Log of events </h2>
			<div className="logtable-container">
				<DatagridV2
					simpleColumns={columns}
					data={logs ?? []}
					noResults="no logs available"
					pageCount={1}
					pagination={{ pageIndex: 0, pageSize: 10 }}
					setPagination={() => {}}
				/>
			</div>
		</div>
	);
}
