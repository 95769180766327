import "style/videoMetadataPage.css";
import { useEffect } from "react";
import { setScrollBehavior } from "features/scrollPageBehaviorProvider/scrollPageBehaviorProvider";
import { VideosErroredTable } from "features/videoUploads/tables/VideosErroredTable";

export function VideoUploadsErroredPage() {
	useEffect(() => {
		setScrollBehavior();
	}, []);

	return (
		<div className="videoMetadataList-container">
			<div className="listHeadline">Errored videos:</div>
			<VideosErroredTable />
		</div>
	);
}
