import { _ } from "i18n";
import client from "libs/client";
import { useToast } from "ui-kit";
import type { Player } from "./PlayersTable";

interface DeletePlayerModalProps {
	deletePlayerInfo?: Player;
	close: () => void;
}
export default function DeletePlayerModal({ deletePlayerInfo, close }: DeletePlayerModalProps) {
	const { addSuccessToast, addErrorToast } = useToast();

	const deletePlayerMutation = client.deletePlayer.useMutation({
		onSuccess: (res) => {
			addSuccessToast(res?.data.message ?? "");
			client.queryClient.invalidateQueries({
				predicate: (query) => query.queryKey.includes("squadPlayers"),
			});
			close();
		},
		onError: (res) => {
			if (res.status !== 403) {
				addErrorToast(res.message);
			}
		},
	});

	return (
		<>
			{deletePlayerInfo && (
				<div className="deletePlayerModal-container">
					<span className="icon-new icon-blue icon-warning-new" />
					<div className="deletePlayer-title">{_("delete.confirmation.are_you_sure")}</div>
					<div className="deletePlayer-text">
						{_("delete.confirmation.are_you_sure_want_to_delete", {
							title: `${deletePlayerInfo.firstName} ${deletePlayerInfo.lastName}`,
						})}
					</div>
					<div className="deletePlayer-buttons">
						<button className="deletePlayer-deleteButton" onClick={() => deletePlayerMutation.mutate({ id: deletePlayerInfo?.id })}>
							{_("match.highlight.delete.yes")}
						</button>
						<button className="deletePlayer-cancelButton" onClick={() => close()}>
							{_("label.remove_player.cancel")}
						</button>
					</div>
				</div>
			)}
		</>
	);
}
