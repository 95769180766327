import { VFC } from "./types";
import AdminManagement from "./adminManagement/AdminManagement";
import PoleManagement from "./poleManagement/PoleManagement";

import RolesAndPermission from "./adminManagement/RolesAndPermission";
import Loader from "components/Loader";
import SubscriptionManagement from "./subscriptionManagement/SubscriptionManagement";
import NonPartnerClubManagement from "./nonPartnerManagement/nonPartnerClub/nonPartnerClubManagement";
import NonPartnerPlayerManagement from "./nonPartnerManagement/nonPartnerPlayers/nonPartnerPlayerManagement";
import CountrtyExpansion from "./countryExpansion/CountryExpansion";
import { useEffect, useState } from "react";
import NotificationManagement from "./notificationManagement/NotificationManagement";
import client from "libs/client";
import { continentCountries } from "./types";
import Migrate from "./nonPartnerManagement/migrate/Migrate";

interface HomeSectionProps {
	activeMenu: string;
	onMenuClick: (id: string) => void;
}

const Section: VFC<HomeSectionProps> = ({ activeMenu, onMenuClick }) => {
	const userRolePermissions: {
		id: number;
		permission: string;
		resource: string;
	}[] = [];

	const isSuperAdmin = true;

	
	const [activeClub, setActiveClub] = useState(null);
	const [countries, setCountries] = useState<{ [key: string]: string }>({});
	const [countriesWithContinents, setCountriesWithContinents] = useState<continentCountries[]>([]);
	const [subscriptionProducts, setSubscriptionProducts] = useState<{ label: string; value: string }[]>([]);


	const onPlayerCountClick = (club: any) => {
		onMenuClick("non_partner_player");
		setActiveClub(club);
	};

	const getCountriesData = async () => {
		const response = await client.allCountries.query().then((res) => { return res?.data as { [key: string]: string }});
		setCountries(response);
		const continentResponse = await client.getContinentCountry.query().then((res) => { return res?.data });
		setCountriesWithContinents(continentResponse);
	};
	const getSubscriptionProducts = async () => {
		const response = await client.getAllSubscriptionProducts.query().then((res) => { return res?.data as {
			label: string; value: string
		}[]});
		setSubscriptionProducts(response);
	};

	useEffect(() => {
		if (activeMenu === "non_partner_club") setActiveClub(null);
	}, [activeMenu]);

	useEffect(() => {
		if(Object.keys(countries).length === 0){
			getCountriesData();
		}
		if (subscriptionProducts.length === 0) {
			getSubscriptionProducts();
		}
	},[]);

	return (
		<>
			<section className="home-section" style={{ top: "75px" }}>
				<div className="home-content product-management-right">
					{activeMenu === "" && <Loader />}
					<section className={activeMenu === "non_partner_player" ? "show" : "hide"}>
						<NonPartnerPlayerManagement activeMenu={activeMenu} activeClub={activeClub} countries={countries}/>
					</section>
					<section className={activeMenu === "non_partner_club" ? "show" : "hide"}>
						<NonPartnerClubManagement activeMenu={activeMenu} countries={countries} countriesWithContinents={countriesWithContinents} onPlayerCountClick={onPlayerCountClick} />
					</section>
					<section className={activeMenu === "migrations" ? "show" : "hide"}>
						<Migrate type="any" activeMenu={activeMenu} countries={countries}/>
					</section>
					<section className={activeMenu === "poles" ? "show" : "hide"}>
						<PoleManagement activeMenu={activeMenu} />
					</section>
					<section className={activeMenu === "subscriptions" ? "show" : "hide"}>
						<SubscriptionManagement activeMenu={activeMenu} countriesWithContinents={countriesWithContinents} subscriptionProducts={subscriptionProducts}/>
					</section>
					<section className={activeMenu === "admin_creation" ? "show" : "hide"}>
						<AdminManagement activeMenu={activeMenu} />
					</section>
					<section className={activeMenu === "roles_permissions_management" ? "show" : "hide"}>
						{isSuperAdmin ? (
							<RolesAndPermission activeMenu={activeMenu}/>
						) : (
							userRolePermissions &&
							userRolePermissions.filter((permission) => permission.resource === "roles_permissions_management").length > 0 && (
								<RolesAndPermission activeMenu={activeMenu} />
							)
						)}
					</section>
					<section className={activeMenu === "specific_notifications" ? "show" : "hide"}>
						<NotificationManagement activeMenu={activeMenu} />
					</section>

					<section className={activeMenu === "country_expansion" ? "show" : "hide"}>
						<CountrtyExpansion activeMenu={activeMenu} countries={Object.entries(countries).map(([code, label]) => ({ label, value: code }))} countriesWithContinents={countriesWithContinents} />
					</section>
				</div>
			</section>
		</>
	);
};

export default Section;
