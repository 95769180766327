interface VoidFunctionComponent<P = {}> {
	(props: P, context?: any): React.ReactElement<any, any> | null;
	propTypes?: React.WeakValidationMap<P> | undefined;
	contextTypes?: React.ValidationMap<any> | undefined;
	defaultProps?: Partial<P> | undefined;
	displayName?: string | undefined;
}

export type VFC<P = {}> = VoidFunctionComponent<P>;

export enum APIResponse {
	SUCCESS = "success",
	FAILED = "failed",
	ERROR = "error",
}

export enum MenuLink {
	PRODUCT_MANAGEMENT = "product-management",
	NON_PARTNER_CLUB = "non_partner_club"
}

export enum UserRole {
	Recruiter = "ROLE_RECRUITER",
	Admin = "ROLE_ADMIN",
	ClubManager = "ROLE_CLUB_MANAGER",
	Coach = "ROLE_COACH",
}

export enum PlayerPosition {
	ALL = "ALL",
	G = "G",
	DD = "DD",
	DC = "DC",
	DG = "DG",
	MDC = "MDC",
	MC = "MC",
	MOC = "MOC",
	MG = "MG",
	MD = "MD",
	ATT = "ATT",
	AG = "AG",
	AD = "AD",
}