import clsx from "clsx";
import Select, { type SingleValue } from "react-select";
import clubPlaceholder from "assets/images/logo_fixtures.png";
import type { Club, Metadata } from "features/videoUploads/metadata/types";
import type { GameData } from "libs/apiClient";
import { _any } from "i18n";
import { useState } from "react";
import client from "libs/client";

interface GameSelectComponentProps {
	clubsArray: Club[];
	metadata: Metadata | null;
	isFetching: boolean;
	onGameSelect: (game: number) => void;
}

export function GameSelectComponent({ isFetching, clubsArray, metadata, onGameSelect }: GameSelectComponentProps) {
	const [selectedClub, setSelectedClub] = useState<SingleValue<Club> | undefined>(clubsArray.find((club) => club.value === metadata?.clubName));
	const [selectedGame, setSelectedGame] = useState<GameData | undefined>(selectedClub?.games.find((game) => game.id === metadata?.gameId));

	const handleListRefresh = () => {
		client.queryClient.invalidateQueries({

			predicate: (query) =>
				query.queryKey.includes("metadataGamesList"),
		});

	}

	return (
		<div className="Assignvideo-container">
			<div className="Assignvideo-heading">
				<h2> Assign Video to Game </h2>
				<span className="icon-new icon-admin-syc icon-blue icon-24" onClick={handleListRefresh} />
			</div>
			<div className="Gamelisting-container">
				<div> Select team </div>
				<Select isLoading={isFetching} options={clubsArray} onChange={setSelectedClub} value={selectedClub} />
				<div className="AssigameList-Container">
					{selectedClub?.games.map((game) => {
						return (
							<div
								key={game.id}
								className={clsx("Assigamethumb", { selected: game.id === selectedGame?.id })}
								onClick={() => {
									setSelectedGame(game);
									onGameSelect(game.id);
								}}
							>
								{game.videoUploadId &&
									<div className="assignedTag">
										<span className="icon-new icon-game-video-play icon-white icon-12" />
										<div>Assigned</div>
									</div>}
								<div className="team-logo">
									<img src={game.homeClubLogo ?? clubPlaceholder} alt="logo" />
								</div>
								<div className="game-score">{game.homeTeamsScore ? `${game.homeTeamsScore}:${game.awayTeamsScore}` : "-"}</div>
								<div className="team-logo">
									<img src={game.awayClubLogo ?? clubPlaceholder} alt="logo" />
								</div>
								<div className="game-details">
									{`${game.category ?? ""} ${game.homeTeamName ?? ""}`}
									<span>{`${_any(`enum.gametype.${game.type}`)} ${game.date}`} </span>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}
