import { useConstants } from "features/constantsProvider/context";
import { _ } from "i18n";
import type { ClubPlayersInfo } from "libs/apiClient";
import client from "libs/client";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { type CountryCode, CountryFlag, useToast } from "ui-kit";
import { SelectAdapter } from "components/FormComponents/formComponents";
import { Field, type FieldRenderProps, Form } from "react-final-form";
import leftFoot from "assets/images/left.png";
import rightFoot from "assets/images/right.png";
import type { ValidationErrors } from "final-form";
import clsx from "clsx";
import UserPlaceholder from "assets/images/user-placeholder.png";
import { getBase64 } from "libs/globalFunctions";

interface AddEditPlayerModalProps {
	clubId: number;
	teams?: { value: string | number; label: string }[];
	positions: { value: string; label: string }[];
	nationalities?: { value: string | number; label: string }[];
	playerData?: ClubPlayersInfo;
	close: () => void;
}

export default function AddEditPlayerModal({ clubId, teams, positions, nationalities, playerData, close }: AddEditPlayerModalProps) {
	const { addSuccessToast, addErrorToast } = useToast();
	const { apiURL } = useConstants();
	const [photo, setPhoto] = useState<string | null>(playerData?.photo ?? null);
	const [duplicateCheck, setDuplicateCheck] = useState(false);
	const [existingPlayerData, setExistingPlayerData] = useState<ClubPlayersInfo[]>();
	const [playersToMigrate, setPlayersToMigrate] = useState<number[]>([]);

	const today = new Date();
	const fourYearsAgo = new Date(today.setFullYear(today.getFullYear() - 4)).toISOString().split("T")[0];

	const initialData = {
		birthday: playerData?.birthdayPure,
		firstName: playerData?.firstName,
		lastName: playerData?.lastName,
		height: playerData?.height?.toString(),
		weight: playerData?.weight?.toString(),
		photo: playerData?.photo,
		strongestFoot: playerData?.strongestFoot,
		team: teams?.find((team) => team.value === playerData?.teamId),
		playedInNationalTeam: playerData?.playedInNationalTeam,
		nationality: nationalities?.find((nationality) => nationality.value === playerData?.nationality),
		nationality2: nationalities?.find((nationality) => nationality.value === playerData?.nationality2),
		position: positions.find((position) => position.value === playerData?.position),
		position2: positions.find((position) => position.value === playerData?.position2),
		notes: playerData?.notes,
		tranLink: playerData?.tranLink,
		federationLink: playerData?.federationLink,
	};

	const uploadPhotoMutation = client.uploadPlayerPhoto.useMutation({
		onSuccess: (res) => {
			let photoUrl = res?.data.url;

			if (photoUrl?.startsWith("/")) {
				photoUrl = photoUrl.slice(1);
			}

			if (!photoUrl?.startsWith("http")) {
				photoUrl = `${apiURL}/${photoUrl}`;
			}

			setPhoto(photoUrl);
			addSuccessToast(_("photo.upload.success"));
		},
		onError: () => {
			addErrorToast(_("photo.upload.fail"));
		},
	});

	const add = client.addUpdatePlayer.useMutation({
		onSuccess: (res) => {
			client.queryClient.invalidateQueries({
				predicate: (query) => query.queryKey.includes("squadTeams") || query.queryKey.includes("squadPlayers"),
			});
			if (res?.data.message) {
				addSuccessToast(res.data.message);
			}
			close();
		},
		onError: (res) => {
			if (res.status === 409) {
				if (res.json.message) {
					addErrorToast(res.json.message);
				}
				setDuplicateCheck(true);
				if ("data" in res.json) {
					setExistingPlayerData(res.json.data);
				}
			} else {
				addErrorToast(res.message);
			}
		},
	});

	const migrate = client.migratePlayers.useMutation({
		onSuccess: (res) => {
			if (res?.data.message) {
				addSuccessToast(res.data.message);
			}
			client.queryClient.invalidateQueries({
				predicate: (query) => query.queryKey.includes("squadTeams") || query.queryKey.includes("squadPlayers"),
			});
			close();
		},
		onError: (res) => {
			addErrorToast(res.message);
		},
	});

	const onDrop = async (acceptedFiles: File[]) => {
		const file = (await getBase64(acceptedFiles[0])) as string;
		uploadPhotoMutation.mutate({ body: { file: file } });
	};

	const { getInputProps } = useDropzone({
		onDrop,
		accept: { "image/jpeg": [".jpg", ".jpeg"], "image/png": [".png"] },
		multiple: false,
		maxFiles: 1,
	});

	const validate = (values: typeof initialData) => {
		const errors: ValidationErrors = {};
		if (!values?.firstName) {
			errors.firstName = _("form.required.validation.message");
		}
		if (!values?.lastName) {
			errors.lastName = _("form.required.validation.message");
		}
		if (!values.birthday) {
			errors.birthday = _("form.required.validation.message");
		}
		return errors;
	};

	const onSubmit = (values: typeof initialData) => {
		add.mutate({
			clubId: clubId,
			body: {
				player: {
					firstName: values.firstName ?? "",
					lastName: values.lastName ?? "",
					strongestFoot: values.strongestFoot ?? "",
					birthday: values.birthday ?? "",
					photo: photo,
					checked: duplicateCheck,
					height: values.height ? Number(values.height) : null,
					weight: values.weight ? Number(values.weight) : null,
					id: playerData?.id ?? null,
					teamId: typeof values?.team?.value === "number" ? values?.team?.value : null,
					//@ts-ignore: temp ignore, needs BE fix
					playedInNationalTeam: values.playedInNationalTeam,
					position: values?.position?.value ? values?.position?.value : "",
					position2: values?.position2?.value ?? null,
					nationality: typeof values?.nationality?.value === "string" ? values?.nationality?.value : "",
					nationality2: typeof values?.nationality2?.value === "string" ? values?.nationality2?.value : "",
					notes: values.notes ?? null,
					tranLink: values.tranLink ?? null,
					federationLink: values.federationLink ?? null,
				},
			},
		});
	};

	const StrongestFootComponent = ({ input, meta }: FieldRenderProps<string, HTMLElement>) => {
		const handleSelect = (value: string) => {
			input.onChange(value);
		};
		return (
			<>
				<div>
					<div>
						<img
							src={leftFoot}
							alt="leftFoot"
							onClick={() => handleSelect("left")}
							className={clsx("strongFoot", { selected: input.value === "left" })}
						/>
						<img
							src={rightFoot}
							alt="rightFoot"
							onClick={() => handleSelect("right")}
							className={clsx("strongFoot", { selected: input.value === "right" })}
						/>
					</div>
					{meta.touched && meta.error && <span className="form-error">{meta.error}</span>}
				</div>
			</>
		);
	};

	const selectPlayer = (playerId: number) => {
		if (playersToMigrate.includes(playerId)) {
			setPlayersToMigrate(playersToMigrate.filter((id) => id !== playerId));
		} else {
			setPlayersToMigrate([...playersToMigrate, playerId]);
		}
	};

	const migratePlayers = (teamId?: number | string) => {
		migrate.mutate({ body: { playerIds: playersToMigrate, teamId: typeof teamId === "number" ? teamId : undefined }, id: clubId });
	};

	return (
		<div className="addEditModal-container">
			<>
				{!duplicateCheck && (
					<div className="addEditModal-photo">
						<img src={photo ?? UserPlaceholder} />
						<label className="photo-uploadButton">
							<input {...getInputProps()} />
							<span className="icon-new icon-white icon icon-crayon icon-12" />
						</label>
					</div>
				)}
				<Form
					initialValues={initialData}
					validate={validate}
					onSubmit={onSubmit}
					render={({ handleSubmit, values }) => {
						return (
							<form onSubmit={handleSubmit}>
								{!duplicateCheck ? (
									<>
										<div className="addEditModal-form">
											<Field name="firstName">
												{({input, meta}) => (
													<div className="addEditModal-field">
														<label>{_("label.user.first_name")}*</label>
														<input className="itemInput" {...input} type="text" required/>
														{meta.touched && meta.error &&
															<span className="form-error">{meta.error}</span>}
													</div>
												)}
											</Field>
											<Field name="lastName">
												{({input, meta}) => (
													<div className="addEditModal-field">
														<label>{_("label.user.last_name")}*</label>
														<input className="itemInput" {...input} type="text" required/>
														{meta.touched && meta.error &&
															<span className="form-error">{meta.error}</span>}
													</div>
												)}
											</Field>
											<Field name="birthday">
												{({input, meta}) => (
													<div className="addEditModal-field">
														<label>{_("label.player.birthday")}*</label>
														<input type="date" max={fourYearsAgo}
															   className="itemInput" {...input} />
														{meta.touched && meta.error &&
															<span className="form-error">{meta.error}</span>}
													</div>
												)}
											</Field>
											<div className="addEditModal-field">
												<label>{_("label.player.strongest_foot")}</label>
												<Field name="strongestFoot" component={StrongestFootComponent}/>
											</div>
											<Field name="height">
												{({input, meta}) => (
													<div className="addEditModal-field">
														<label>{`${_("label.player.height")} (cm)`}</label>
														<input
															placeholder="60-220"
															className="itemInput"
															{...input}
															type="number"
															min={60}
															max={220}
														/>
														{meta.touched && meta.error &&
															<span className="form-error">{meta.error}</span>}
													</div>
												)}
											</Field>
											<Field name="weight">
												{({input, meta}) => (
													<div className="addEditModal-field">
														<label>{`${_("label.player.weight")} (kg)`}</label>
														<input
															placeholder="30-120"
															className="itemInput"
															{...input}
															type="number"
															min={30}
															max={120}
														/>
														{meta.touched && meta.error &&
															<span className="form-error">{meta.error}</span>}
													</div>
												)}
											</Field>
											<div className="addEditModal-field">
												<label>{_("label.player.team")}</label>
												<Field name="team" isClearable component={SelectAdapter}
													   options={teams}/>
											</div>
											<Field name="playedInNationalTeam" type="checkbox">
												{({input, meta}) => (
													<div className="addEditModal-field">
														<div className="national">
															<input type="checkbox"
																   className="itemCheckbox" {...input} />
															{_("player.playedInNationalTeam")}
														</div>
														{meta.touched && meta.error &&
															<span className="form-error">{meta.error}</span>}
													</div>
												)}
											</Field>
											<div className="addEditModal-field">
												<label>{_("label.player.position")}</label>
												<Field name="position" isClearable component={SelectAdapter}
													   options={positions}/>
											</div>
											<div className="addEditModal-field">
												<label>{_("label.player.position2")}</label>
												<Field name="position2" isClearable component={SelectAdapter}
													   options={positions}/>
											</div>
											<div className="addEditModal-field">
												<label>{_("label.player.nationality")}</label>
												<Field name="nationality" isClearable component={SelectAdapter}
													   options={nationalities}/>
											</div>
											<div className="addEditModal-field">
												<label>{_("label.player.nationality2")}</label>
												<Field name="nationality2" isClearable component={SelectAdapter}
													   options={nationalities}/>
											</div>
										</div>
										<div className="addEditModal-notes">
											<Field name="notes">
												{({input, meta}) => (
													<div>
														<label>{_("label.player.notes")}</label>
														<textarea {...input} />
														{meta.touched && meta.error &&
															<span className="form-error">{meta.error}</span>}
													</div>
												)}
											</Field>
										</div>

										<div className="addEditModal-form">
											<Field name="tranLink">
												{({input, meta}) => (
													<div className="addEditModal-field-link">
														<label>{_("label.user.tran_link")}</label>
														<input className="itemInput" {...input} type="text"/>
														{meta.touched && meta.error &&
															<span className="form-error">{meta.error}</span>}
													</div>
												)}
											</Field>
										</div>

										<div className="addEditModal-form">
											<Field name="federationLink">
												{({input, meta}) => (
													<div className="addEditModal-field-link">
														<label>{_("label.user.federation_link")}</label>
														<input className="itemInput" {...input} type="text"/>
														{meta.touched && meta.error &&
															<span className="form-error">{meta.error}</span>}
													</div>
												)}
											</Field>
										</div>

										<div className="formButtons">
											<button className="buttonSubmit" type="submit">
												{_("btn.save")}
											</button>
										</div>
									</>
								) : (
									<div className="addEditModal-existingPlayers">
										<div className="existingPlayers-text">{`${existingPlayerData?.length} ${_(
											"label.similar_player_already_exists",
										)}`}</div>
										<div className="existingPlayers-list">
											{existingPlayerData?.map((player) => (
												<div
													key={player.id}
													className={clsx("existingPlayers-player", {selected: playersToMigrate.includes(player.id) })}
													onClick={() => selectPlayer(player.id)}
												>
													<CountryFlag code={player.nationality as CountryCode} />
													{`${player.firstName} ${player.lastName} - ${player.birthday}`}
												</div>
											))}
										</div>
										<div className="existingPlayers-buttons">
											{playersToMigrate.length > 0 && values.team && (
												<div
													className="existingPlayers-button migrate"
													onClick={() => {
														migratePlayers(values.team?.value);
													}}
												>
													{_("label.migrate")}
												</div>
											)}
											<button className="existingPlayers-button">{_("label.not_my_player")}</button>
										</div>
									</div>
								)}
							</form>
						);
					}}
				/>
			</>
		</div>
	);
}
