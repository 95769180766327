import client from "libs/client";
import { type KeyboardEvent, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import playerPlaceholder from "assets/images/default-player.jpg";
import type { SingleValue } from "react-select";
import { useToast } from "ui-kit";
import SelectComponent from "ui-kit/src/SelectComponent/SelectComponent";
import { useConstants } from "features/constantsProvider/context";

interface ContentCellProps {
    id?: number | string;
    title: string;
    content: string | number | boolean;
    boolean?: boolean;
    booleanValue?: boolean;
    date?: boolean;
    number?: boolean;
    phoneNumber?: boolean;
    field?: string;
    patchMethod?: "signup" | "user" | "profile";
    isImage?: boolean;
    selectItems?: {
        label: string;
        value: string | number;
    }[];
}

export function ContentCell({ id, field, title, content, boolean, booleanValue, date, phoneNumber, number, selectItems, patchMethod, isImage }: ContentCellProps) {
    const [edit, setEdit] = useState(false);

    const formatDate = (date: string) => {
        if (date.includes('/')) {
            const parts = date.split('/');
            return `${parts[2]}-${parts[1]}-${parts[0]}`;
        }
        return date;
    }

    const [inputValue, setInputValue] = useState(
        typeof content !== "boolean" ? (date && typeof content === "string" ? formatDate(content) : content) : ""
    )
    const { addSuccessToast, addErrorToast } = useToast();
    const inputRef = useRef<HTMLInputElement>(null);
    const { apiURL } = useConstants();

    const getInitSelectValue = (value: string | number) => {
        return selectItems?.find((item) => item.value === value);
    }

    const [selectValue, setSelectValue] = useState(selectItems && typeof content !== "boolean" ? getInitSelectValue(content) : undefined)

    const signupSubmit = client.patchSignUpRequest.useMutation({
        onSuccess: () => {
            setEdit(false)
            client.queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes("playerUserDetail"), })
            addSuccessToast("Field updated")
        },
        onError: (error) => {
            addErrorToast(error.message)
        }
    });

    const userSubmit = client.patchPlayerUser.useMutation({
        onSuccess: () => {
            setEdit(false)
            client.queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes("playerUserDetail"), })
            addSuccessToast("Field updated")
        },
        onError: (error) => {
            addErrorToast(error.message)
        }
    });

    const profileSubmit = client.patchPlayerProfile.useMutation({
        onSuccess: () => {
            setEdit(false)
            client.queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes("playerUserDetail"), })
            addSuccessToast("Field updated")
        },
        onError: (error) => {
            addErrorToast(error.message)
        }
    });

    const onSubmit = () => {
        const newValue = selectItems ? selectValue?.value : inputValue
        if (field) {
            if (patchMethod === "signup") {
                signupSubmit.mutate({ id: String(id) ?? "", body: { [field]: newValue ? newValue : null, id: String(id) ?? "" } })
            }
            if (patchMethod === "user") {
                userSubmit.mutate({ id: Number(id) ?? "", body: { [field]: newValue ? newValue : null, id: Number(id) ?? "" } })
            }
            if (patchMethod === "profile") {
                profileSubmit.mutate({ id: Number(id) ?? "", body: { [field]: newValue ? newValue : null, id: Number(id) ?? "" } })
            }
        }
    }

    useEffect(() => {
        if (edit && inputRef.current) {
            inputRef.current.focus();
        }
    }, [edit]);

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            onSubmit()
        }
        if (e.key === "Escape") {
            setEdit(false)
            setInputValue(typeof content !== "boolean" ? content : "")
        }
    }

    const onInputChange = (value: string) => {
        const newPhoneValue = value.replace(/[^0-9+]/g, '');
        const newNumericValue = value.replace(/[^0-9]/g, '');

        if (phoneNumber) {
            setInputValue(newPhoneValue);
        }
        else if (number) {
            setInputValue(newNumericValue);
        }
        else {
            setInputValue(value);
        }
    }

    const onSelectChange = (value: SingleValue<{ label: string; value: string | number; }>) => {
        setSelectValue((value ? value : undefined))
    }

    const handleCancel = () => {
        if (selectItems && typeof content !== "boolean") {
            setSelectValue(getInitSelectValue(content));
        } else {
            setInputValue(typeof content !== "boolean" ? content : "");
        }
        setEdit(false);
    };

    const renderEditableContent = () => {
        if (edit && !selectItems) {
            return (
                <input
                    ref={inputRef}
                    className="cellInput"
                    type={date ? "date" : "text"}
                    onKeyDown={onKeyDown}
                    value={inputValue}
                    onChange={(e) => onInputChange(e.target.value)}
                />
            );
        } if (edit && selectItems && typeof content !== "boolean") {
            return (
                <SelectComponent
                    isCleareable
                    value={selectValue}
                    handleChange={onSelectChange}
                    items={selectItems}
                />
            );
        }
        return null;
    };

    const renderContent = () => {
        if (boolean) {
            return (
                <div className={booleanValue ? "true" : "false"}>
                    {content}
                </div>
            );
        }
        if (isImage) {
            if (typeof content === "string") {
                return (
                    <div className="cellImage-container">
                        <img src={content.includes("http") ? content : `${apiURL}/${content}`} alt={`${title}`} className="cellImage" />
                        <Link to={content.includes("http") ? content : `${apiURL}/${content}`}>Photo Link</Link>
                    </div>
                );
            }
            return (
                <div className="cellImage-container" >
                    <img src={playerPlaceholder} alt={`${title}`} className="cellImage" />
                </div >
            )
        }
        if (!(edit || boolean || selectItems)) {
            return content;
        } if (!edit && selectItems && typeof content !== "boolean") {
            return getInitSelectValue(content)?.label ?? "-";
        }
        return null;
    };

    return (
        <div className="detailCell">
            <div className="cellTitle">
                {title}: {field && !edit && (
                    <span onClick={() => setEdit(true)} className="icon-new icon-crayon icon-grey" />
                )}
            </div>
            <div className="cellContent">
                {renderContent()}
                {renderEditableContent()}
            </div>
            {edit && (
                <div className="buttons">
                    <button className="submitButton" type="button" onClick={onSubmit}>Submit</button>
                    <button className="cancelButton" type="button" onClick={handleCancel}>Cancel</button>
                </div>
            )}
        </div>
    );
}