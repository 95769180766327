import { SelectAdapter } from "components/FormComponents/formComponents";
import { Field, type FieldRenderProps, Form } from "react-final-form";
import leftFoot from "assets/images/left.png";
import rightFoot from "assets/images/right.png";
import noFoot from "assets/images/noFoot.png";
import clsx from "clsx";
import { useToast } from "ui-kit";
import { _ } from "i18n";
import type { ValidationErrors } from "final-form";
import type { Player } from "./PlayersTable";
import { useState } from "react";
import client from "libs/client";

interface BatchUpdateModalProps {
	clubId: number;
	teams?: { value: string | number; label: string }[];
	positions: { value: string; label: string }[];
	nationalities?: { value: string | number; label: string }[];
	selectedPlayers: Player[];
	clearSelecterdPlayers: () => void;
	close: () => void;
}

interface FormValues {
	team?: { value: number; label: string };
	dob?: string;
	strongestFoot?: string | null;
	playedInNationalTeam?: boolean;
	position1?: { value: string; label: string };
	position2?: { value: string; label: string };
	nationality1?: { value: string; label: string };
	nationality2?: { value: string; label: string };
}

export default function BatchUpdateModal({ selectedPlayers, teams, positions, nationalities, close, clearSelecterdPlayers }: BatchUpdateModalProps) {
	const { addSuccessToast, addErrorToast } = useToast();
	const [showMore, setShowMore] = useState(false);

	const today = new Date();
	const fourYearsAgo = new Date(today.setFullYear(today.getFullYear() - 4)).toISOString().split("T")[0];

	const update = client.batchUpdate.useMutation({
		onSuccess: (res) => {
			client.queryClient.invalidateQueries({
				predicate: (query) => query.queryKey.includes("squadTeams") || query.queryKey.includes("squadPlayers"),
			});
			if (res) {
				addSuccessToast("success");
			}
			clearSelecterdPlayers();
			close();
		},
		onError: (res) => {
			addErrorToast(res.message);
		},
	});

	const StrongestFootComponent = ({ input, meta }: FieldRenderProps<string, HTMLElement>) => {
		const handleSelect = (value: string | null) => {
			input.onChange(value);
		};
		return (
			<>
				<div>
					<div>
						<img
							src={leftFoot}
							alt="leftFoot"
							onClick={() => handleSelect("left")}
							className={clsx("strongFoot", { selected: input.value === "left" })}
						/>
						<img
							src={rightFoot}
							alt="rightFoot"
							onClick={() => handleSelect("right")}
							className={clsx("strongFoot", { selected: input.value === "right" })}
						/>
						<img
							src={noFoot}
							alt="noFoot"
							onClick={() => handleSelect("none")}
							className={clsx("strongFoot", { selected: input.value === "none" })}
						/>
					</div>
					{meta.touched && meta.error && <span className="form-error">{meta.error}</span>}
				</div>
			</>
		);
	};

	const validate = (values: FormValues) => {
		const errors: ValidationErrors = {};
		if (JSON.stringify(values) === "{}") {
			errors._error = _("fill.fields.form");
		}
		return errors;
	};

	const onSubmit = (values: FormValues) => {
		const playerIds = selectedPlayers.map((player) => player.id);
		update.mutate({
			body: {
				playerIds: playerIds,
				dob: values.dob,
				nationality: values.nationality1?.value,
				nationality2: values.nationality2?.value,
				position: values.position1?.value,
				position2: values.position2?.value,
				teamId: values.team?.value,
				playedInNationalTeam: values.playedInNationalTeam,
				strongestFoot: values.strongestFoot === "none" ? null : values.strongestFoot,
			},
		});
	};
	return (
		<div className="addEditModal-container">
			<>
				<Form
					validate={validate}
					onSubmit={onSubmit}
					render={({ handleSubmit, errors, submitFailed }) => {
						return (
							<form onSubmit={handleSubmit}>
								<>
									<div className="addEditModal-batchPlayers">
										<div className="addEditModal-batchCount" onClick={() => setShowMore(!showMore)}>
											<span className="icon-info-outline icon-new icon-blue icon-16" />
											{_("selected.players.batch.update", { noOfPlayers: selectedPlayers.length })}
											<div className="batchCount-showMore">
												{showMore ? _("label.nationalLeagues.less") : _("label.showMore")}
											</div>
										</div>
										{showMore && (
											<div className="addEditModal-playerList">
												{selectedPlayers.map((player) => (
													<div className="selectedPlayer">{`${player.firstName} ${player.lastName}`}</div>
												))}
											</div>
										)}
									</div>

									<div className="addEditModal-form">
										<Field name="dob">
											{({ input, meta }) => (
												<div className="addEditModal-field">
													<label>{_("label.player.birthday")}</label>
													<input type="date" max={fourYearsAgo} className="itemInput" {...input} />
													{meta.touched && meta.error && <span className="form-error">{meta.error}</span>}
												</div>
											)}
										</Field>
										<div className="addEditModal-field">
											<label>{_("label.player.strongest_foot")}</label>
											<Field name="strongestFoot" component={StrongestFootComponent} />
										</div>
										<Field name="playedInNationalTeam" type="checkbox">
											{({ input, meta }) => (
												<div className="addEditModal-field">
													<div className="national">
														<input type="checkbox" className="itemCheckbox" {...input} />
														{_("player.playedInNationalTeam")}
													</div>
													{meta.touched && meta.error && <span className="form-error">{meta.error}</span>}
												</div>
											)}
										</Field>
										<div className="addEditModal-field">
											<label>{_("label.player.team")}</label>
											<Field name="team" isClearable component={SelectAdapter} options={teams} />
										</div>
										<div className="addEditModal-field">
											<label>{_("label.player.position")}</label>
											<Field name="position1" isClearable component={SelectAdapter} options={positions} />
										</div>
										<div className="addEditModal-field">
											<label>{_("label.player.position2")}</label>
											<Field name="position2" isClearable component={SelectAdapter} options={positions} />
										</div>
										<div className="addEditModal-field">
											<label>{_("label.player.nationality")}</label>
											<Field name="nationality1" isClearable component={SelectAdapter} options={nationalities} />
										</div>
										<div className="addEditModal-field">
											<label>{_("label.player.nationality2")}</label>
											<Field name="nationality2" isClearable component={SelectAdapter} options={nationalities} />
										</div>
									</div>
									{errors?._error && submitFailed && <div className="form-error">{errors._error}</div>}
									<div className="formButtons">
										<button className="buttonSubmit" type="submit">
											{_("label.update")}
										</button>
									</div>
								</>
							</form>
						);
					}}
				/>
			</>
		</div>
	);
}
