import { useActions, useCountriesStore } from "app/store";
import { useI18n } from "features/translation/context";
import { useUserData } from "features/userData/context";
import { _ } from "i18n";
import { hasPermissionToResource } from "libs/authorization";
import client from "libs/client";
import { isGranted } from "pages/productManagement/functions";
import { Permission, Resource } from "pages/productManagement/types";
import { Fragment } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { MenuLink } from "types";
import { Header, SelectedCountry, useToast } from "ui-kit";
import AccountOptions from "ui-kit/src/Header/AccountOptions/AccountOptions";
import LangSwitch from "ui-kit/src/Header/LangSwitch/LangSwitch";
import Person from "ui-kit/src/icons/Person";
import Planing from "ui-kit/src/icons/Planing";
import Play from "ui-kit/src/icons/Play";
import Shield from "ui-kit/src/icons/Shield";
import { VideoNotifications } from "../features/videoUploads/tables/VideoNotifications";

interface AdminHeaderProps {
	user: { name: string; email: string; photo: string | null };
}

export function AdminHeader({ user }: AdminHeaderProps) {
	const { changeLocale, locale } = useI18n();
	const navigate = useNavigate();
	const { addSuccessToast, addErrorToast } = useToast();
	const { selection, countryData } = useCountriesStore();
	const { openCountrySelect } = useActions();
	const { productManagementOptions } = useUserData();

	const userData = useUserData();
	const isUserCM = hasPermissionToResource(userData, "game_uploads", "read");

	const subMenuOptions = {
		subMenuName: _("resource.product_management"),
		options: Object.entries(productManagementOptions ?? []).map(([key, value]) => {
			return {
				label: value.name,
				id: value.subMenuItems.length > 0 ? value.subMenuItems[0].id : value.id,
				link: MenuLink.PRODUCT_MANAGEMENT,
			};
		}),
	};

	const getNavItems = () => {
		const navItems = [
			{
				resource: Resource.CLUBS_AMATEUR,
				permission: Permission.LIST,
				label: _("header.admin.club_amat"),
				link: "/admin/clubs-amateur",
				icon: <Shield />,
			},
			{
				resource: Resource.GAME_UPLOADS,
				permission: Permission.READ,
				label: _("header.admin.upload_video"),
				link: "/admin/videos",
				icon: <Play />,
			},
			{
				resource: Resource.CLUBS_PRO,
				permission: Permission.LIST,
				label: _("header.admin.club_pro"),
				link: "/admin/clubs-pro",
				icon: <Person />,
			},
			{
				resource: Resource.PRODUCT_MANAGEMENT,
				permission: Permission.READ,
				label: _("header.admin.product_management"),
				link: "product-management",
				icon: <Planing />,
			},
		];

		return navItems.filter((config) => isGranted(config.resource, config.permission));
	};

	const mutation = client.adminJsonLogout.useMutation({
		onSuccess: () => {
			addSuccessToast(_("logoff.success"));
			navigate("/login");
		},
		onError: () => {
			addErrorToast(_("logoff.fail"));
		},
	});

	const onLogout = () => {
		mutation.mutate();
		navigate("/login");
	};

	return (
		<>
			<Header
				logo={(logo) => (
					<Link to="/">
						<img className="header__logo-img" src={logo} alt="logo" />
					</Link>
				)}
				mainItems={getNavItems().map((item) => (
					<NavLink to={item.link} className="header__link" key={item.label}>
						{/* <svg className="header__link-icon icon icon-shield">
						<use xlinkHref={item.icon} />
					</svg> */}
						<>
							{item.icon}
							{item.label}
						</>
					</NavLink>
				))}
				rightItems={[
					<SelectedCountry onClick={openCountrySelect} selection={selection} countryData={countryData} key="country-selection" />,
					isUserCM ? <VideoNotifications key="video-notifications" /> : <Fragment key="video-notifications" />,
					<LangSwitch onChange={changeLocale} selectedLocale={locale} key="lang-switch" />,
					<AccountOptions
						navItems={getNavItems()}
						subMenuOptions={subMenuOptions}
						user={user}
						onLogout={onLogout}
						key="account-options"
						apiUrl=""
					/>,
				]}
			/>
		</>
	);
}
