import { useEffect, useRef, useState } from "react";
import { APIResponse, VFC } from "types";
import { _ } from "i18n";
import Select, { SingleValue } from "react-select";
import ClubCard from "./ClubCard";
import PlayerList from "./PlayerList";
import { NonPartnerMigrationPlayer, MigrationStatusData, ClubData, PlayerData, MigrationType, ClubMigrationType} from "pages/productManagement/types";
import MigrationModal from "./MigrationModal";
import MigrationStatus from "./MigrationStatus";
import Modal from "ui-kit/src/Modal";
import Icon from "components/Icon";
import MigrationClubStatus from "./MigrationClubStatus";
import { addMigrationIdInLocalStorage } from "pages/productManagement/functions";
import client from "libs/client";
import { useToast } from "ui-kit";

export enum ClubType {
	SOURCE = "source",
	TARGET = "target",
}

interface MigrateProps {
	activeMenu: string;
	club: ClubData;
	countries: { [key: string]: string };
	player: PlayerData;
	type: string;
	onClose: () => void;
}

const Migrate: VFC<MigrateProps> = ({ club, countries, player, type, onClose, activeMenu }) => {
	const emptyClub: SingleValue<{ value: string; label: string; country: string; logo: string; type: string }> = {
		value: "",
		label: "",
		country: "",
		logo: "",
		type: "",
	};
	const [clubNonPartnerSourceList, setClubNonPartnerSourceList] = useState<{ value: string; label: string; type: string }[]>([]);
	const [clubNonPartnerTargetList, setClubNonPartnerTargetList] = useState<{ value: string; label: string; type: string }[]>([]);

	const [source, setSource] = useState<SingleValue<{ value: string; label: string; country: string; logo: string; type: string }>>(emptyClub);
	const [target, setTarget] = useState<SingleValue<{ value: string; label: string; country: string; logo: string; type: string }>>(emptyClub);

	const [showSourceCard, setShowSourceCard] = useState<boolean>(false);
	const [showSourcePlayers, setShowSourcePlayers] = useState<boolean>(false);
	const [showTargetCard, setShowTargetCard] = useState<boolean>(false);
	const [showTargetPlayers, setShowTargetPlayers] = useState<boolean>(false);

	const [sourcePlayers, setSourcePlayers] = useState<NonPartnerMigrationPlayer[]>([]);
	const [targetPlayers, setTargetPlayers] = useState<NonPartnerMigrationPlayer[]>([]);
	const [migrationStatus, setMigrationStatus] = useState<MigrationStatusData | null>(null);

	const [showMigrationModal, setShowMigrationModal] = useState(false);
	const [isButtonDisabled, setButtonDisabled] = useState(false);
	const [showMigrationStatus, setShowMigrationStatus] = useState(false);

	const [isPlayerListDisabled, setPlayerListDisabled] = useState(false);
	const [isMigrateClubDisabled, setMigrateClubDisabled] = useState(false);

	const [clubStatCount, setClubStatCount] = useState({ playersCount: 0, gamesCount: 0 });
	const [showDeleteClubConfirmationModal, setShowDeleteClubConfirmationModal] = useState(false);

	const [migrationClubStatus, setMigrationClubStatus] = useState<{ date: string; migratedClub: string; category: string; awayClub: string; homeClub: string; awayTeam: { value: string | number; label: string | number; }; homeTeam: { value: string | number; label: string | number; }; }[] | null>(null);
	const [showMigrationClubStatus, setShowMigrationClubStatus] = useState(false);
	const targetId = useRef({ value: "", label: "" });
	const [targetClubteamOptions, setTargetClubteamOptions] = useState<{
		label: string | number;
		value: string | number;
	}[]>();

	const { addSuccessToast, addErrorToast } = useToast();

	const migrateClub = useRef<HTMLInputElement>();
	const isDataLoaded = useRef<boolean>(false);

	const getClubPlayers = async (id: number, event: string, selectedClubType: string) => {
		const response = await client.getClubPlayers.query({ clubId: id, type: selectedClubType}).then((res) => { return res?.data as NonPartnerMigrationPlayer[]});;

		if (event === ClubType.SOURCE) {
			response.forEach((row: { id: number }, index: number) => {
				if (player && row.id === player.id) {
					response[index].selected = true;
				}

				if (club !== null) {
					response[index].selected = true;
				}
			});
			setSourcePlayers(response);
		} else {
			setTargetPlayers(response);
		}
	};

	const onPlayerSelect = (player: NonPartnerMigrationPlayer, i: number) => {
		if (player.selected === undefined || player.selected === false) {
			setSourcePlayers((prev) => {
				if (prev) {
					prev[i].selected = true;
					return [...prev];
				}
				return prev;
			});
		} else {
			setSourcePlayers((prev) => {
				if (prev) {
					prev[i].selected = false;
					return [...prev];
				}
				return prev;
			});
		}
	};

	const onSelectDeselectPlayers = () => {
		const selectPlayers = sourcePlayers.filter((p) => p.selected);
		if (selectPlayers.length > 0) {
			setSourcePlayers((prev) => {
				return prev.map((p) => {
					return { ...p, selected: false };
				});
			});
		} else {
			setSourcePlayers((prev) => {
				return prev.map((p) => {
					return { ...p, selected: true };
				});
			});
		}
	};

	const getMigrationReportData = async () => {
		let migrateAllPlayers = false;

		if (sourcePlayers.length === sourcePlayers.filter((player) => player.selected === true).length) {
			migrateAllPlayers = true;
		}

		if (source && target && target.value && source.type && target.type  && migrateClub.current) {
			const migrationIds = JSON.parse(localStorage.getItem("migrationIds") as string);
			const response = await client.completeMigration.mutation({
				body: {
					sourceClubId: parseInt(source.value),
					targetClubId: parseInt(target.value),
					sourceClubType: source.type,
					targetClubType: target.type,
					migrationIds: migrationIds,
					isClubMigration: migrateClub.current.checked
				}
			}).then((res) => { return res?.data as MigrationStatusData});;

			document.getElementById("migration-status")?.scrollIntoView({ behavior: "smooth" });
			setShowMigrationModal(false);
			setButtonDisabled(false);
			setMigrationStatus(response);
			setShowMigrationStatus(true);

			getBothClubs(true);

			targetId.current = target;

			setSource(emptyClub);
			setShowSourceCard(false);
			setShowSourcePlayers(false);

			setTarget(emptyClub);
			setShowTargetCard(false);
			setShowTargetPlayers(false);
		}
	};

	const executeMigration = async () => {
		setMigrationStatus(null);
		setMigrationClubStatus(null);
		setButtonDisabled(true);

		if (localStorage.getItem("migrationIds")) {
			localStorage.removeItem("migrationIds");
		}
		localStorage.setItem("migrationIds", JSON.stringify([]));

		if (source && target && source.value !== "" && target.value !== "" && source.type && target.type && migrateClub.current) {
			
			const checkMigrationExists = await client.beginMigration.mutation({ body : {
				sourceClubId: parseInt(source.value),
				targetClubId: parseInt(target.value),
				sourceClubType: source.type,
				targetClubType: target.type,
				isClubMigration: migrateClub.current?.checked,
			}}).then((res) => { return res?.data });

			if (!checkMigrationExists.status && checkMigrationExists.message) {
				addErrorToast(checkMigrationExists.message);
				return;
			}

			if (checkMigrationExists.deleteClub && checkMigrationExists.count) {
				setClubStatCount(checkMigrationExists.count);
				setShowDeleteClubConfirmationModal(true);

				if (checkMigrationExists.count.gamesCount > 0) {
					const TeamResponse = await client.getTeamsByTargetClub.query({ clubId: parseInt(target.value) }).then((res) => { return res?.data });
					setTargetClubteamOptions(TeamResponse);
				}
				return;
			} else if (sourcePlayers.filter((player) => player.selected === true).length === 0) {
				addErrorToast("Please Select Player");
				return;
			}
			if (checkMigrationExists.status) {
				setShowMigrationModal(true);

				const TeamResponse = await client.getTeamsByTargetClub.query({ clubId: parseInt(target.value) }).then((res) => { return res?.data });
				setTargetClubteamOptions(TeamResponse);

				for (let i = 0; i < sourcePlayers.length; i++) {
					if (sourcePlayers[i].selected) {
						setSourcePlayers((prev) => {
							if (prev) {
								prev[i].percentage = 25;
								return [...prev];
							}
							return prev;
						});
						
						const initialResponse = await client.beginMigration.mutation({ body : {
							sourceClubId: parseInt(source.value),
							targetClubId: parseInt(target.value),
							sourceClubType: source.type,
							targetClubType: target.type,
							sourcePlayerId: sourcePlayers[i].id
						}}).then((res) => { return res?.data });
					
						if (initialResponse.percentage === 50) {
							setSourcePlayers((prev) => {
								if (prev) {
									prev[i].percentage = initialResponse.percentage;
									prev[i].migrationId = initialResponse.id;
									prev[i].targetPlayerId = initialResponse.targetPlayer.id;
									return [...prev];
								}
								return prev;
							});
						}
						
						if (initialResponse.percentage === 80) {
							setSourcePlayers((prev) => {
								if (prev) {
									prev[i].percentage = initialResponse.percentage;
									prev[i].migrationId = initialResponse.id;
									return [...prev];
								}
								return prev;
							});
						} else if (initialResponse.percentage === 100) {
							setSourcePlayers((prev) => {
								if (prev) {
									prev[i].percentage = initialResponse.percentage;
									prev[i].migrationId = initialResponse.id;
									prev[i].status = initialResponse.status;
									return [...prev];
								}
								return prev;
							});

							addMigrationIdInLocalStorage(initialResponse.id);
						}
					}
				}
			}
		}
	};

	const onMigrateClub = async () => {
		if (source && target && source.value !== "" && target.value !== "" && source.type && target.type) {
		
			const response = await client.clubMigrationWithZeroPlayers.mutation({ body : {
				sourceClubId: parseInt(source.value),
				targetClubId: parseInt(target.value),
				sourceClubType: source.type,
				targetClubType: target.type,
			}}).then((res) => { return res?.data });

			if (response.status === APIResponse.SUCCESS) {
				if(response.message){ addSuccessToast(response.message) };

				document.getElementById("migration-status")?.scrollIntoView({ behavior: "smooth" });
				setMigrationClubStatus(response.data);
				setShowMigrationClubStatus(true);


				getBothClubs();
				if (target.type === MigrationType.PARTNER) {
					const TeamResponse = await client.getTeamsByTargetClub.query({ clubId: parseInt(target.value) }).then((res) => { return res?.data });
					setTargetClubteamOptions(TeamResponse);
				}

				targetId.current = target;
				setSource(emptyClub);
				setShowSourceCard(false);
				setShowSourcePlayers(false);

				setTarget(emptyClub);
				setShowTargetCard(false);
				setShowTargetPlayers(false);
			} else {
				if (response.message) { addErrorToast(response.message) };
			}
		}
	};

	const getBothClubs = async (isMigrationCompleted: boolean = false) => {
		let nonPartnerData = await client.getAllNPClubs.query().then((res) => { return res?.data });
		let partnerData = await client.getClubList.query().then((res) => { return res?.data });

		nonPartnerData = nonPartnerData.map((np) => {
			return { ...np, label: `${np.label} [Non-Partner, ${np.value}]`, type: "nonpartner" };
		});

		partnerData = partnerData.map((np) => {
			return { ...np, label: `${np.label} [Partner, ${np.value}]`, type: "partner" };
		});

		const bothClubData = [...nonPartnerData, ...partnerData] as {
			value: string;
			label: string;
			type: string;
			country: string;
			logo: string;
		}[];

		if (player != null) {
			const sourceClub = bothClubData.find((item: { value: string }) => item.value === player.clubNonpartnerId);
			if (sourceClub) {
				setSource(sourceClub);
				setShowSourceCard(true);
			}

			getClubPlayers(parseInt(player.clubNonpartnerId), ClubType.SOURCE, ClubMigrationType.NON_PARTNER);
			setShowSourcePlayers(true);
		} else if (club?.id) {
			const sourceClub = bothClubData.find((item: { value: string }) => item.value === club.id);
			if (sourceClub) {
				setSource(sourceClub);
				setShowSourceCard(true);
			}
			setPlayerListDisabled(true);

			if(!isMigrationCompleted){
				getClubPlayers(parseInt(club.id), ClubType.SOURCE, ClubMigrationType.NON_PARTNER);
				setShowSourcePlayers(true);
			}
			
			if (migrateClub.current) {
				migrateClub.current.checked = true;
			}
		}

		setClubNonPartnerSourceList(bothClubData);
		setClubNonPartnerTargetList(bothClubData);
	};

	useEffect(() => {
		if (activeMenu === "migrations") {
			setShowMigrationStatus(false);
		}
	}, [activeMenu]);

	useEffect(() => {
		if (!isDataLoaded.current || player || club) {
			window.scrollTo({ top: 0, left: 0 });
			getBothClubs();
			isDataLoaded.current = true;
		}
	}, []);

	useEffect(() => {
		const isAllPlayerMigrationCompleted = () => {
			const selectedPlayers = sourcePlayers.filter((player) => player.selected === true);
			if (selectedPlayers.length > 0 && selectedPlayers.filter((player) => player.percentage === 100).length === selectedPlayers.length) {
				getMigrationReportData();
			}
		};
		if (sourcePlayers.length > 0) {
			isAllPlayerMigrationCompleted();
		}
	}, [sourcePlayers]);

	const migrateClubCheckboxChange = (e) => {
		setMigrateClubDisabled(e.target.checked);
		if (e.target.checked) {
			setSourcePlayers((prev) => {
				return prev.map((p) => {
					return { ...p, selected: true };
				});
			});
		} else {
			setSourcePlayers((prev) => {
				return prev.map((p) => {
					return { ...p, selected: false };
				});
			});
		}
	}

	return (
		<>
			<div className="migration-container">
				<div className="product-management-heading-container">
					<div className="product-management-heading">
						{type === MigrationType.ANY
							? _("product.management.migrations")
							: `${_("label.migrate.non_partner")} ${player !== null ? _("label.player_to") : _("label.club_to")} ${
									type === MigrationType.NON_PARTNER ? _("club.non-partner") : _("label.partner")
							  } ${_("label.club")}`}
					</div>
					{activeMenu !== "migrations" && (
						<button className="btn btn-primary" onClick={() => onClose()}>
							{_("datepicker.closeText")}
						</button>
					)}
				</div>

				<div className="migration-side-container">
					<div className="migration-section-container">
						<div className="migration-top-section">
							<div className="select-container">
								<Select
									className="react-select"
									isClearable
									options={clubNonPartnerSourceList}
									placeholder={_("migration.validation.message.select_source_club")}
									value={
										source as SingleValue<{
											type: string;
											value: string;
											label: string;
										}>
									}
									onChange={(e) => {
										if (e === null) {
											setSource(emptyClub);
											setShowSourceCard(false);
											setShowSourcePlayers(false);

										} else {
											setSource(
												e as SingleValue<{ value: string; label: string; country: string; logo: string; type: string }>
											);
											setShowSourceCard(true);
											setSourcePlayers([]);
											setShowSourcePlayers(true);

											getClubPlayers(parseInt(e.value), "source", e.type);

											if (type === MigrationType.NON_PARTNER) {
												setClubNonPartnerTargetList(clubNonPartnerSourceList.filter((club) => club.value !== e.value));
											}
										}
									}}
								/>
							</div>
							{showSourceCard && <ClubCard club={source} countriesList={countries}/>}
						</div>
						{showSourcePlayers && sourcePlayers.length > 0 && (
							<>
								<div className="migration-all-player-selection">
									<button className="btn btn-primary" onClick={() => onSelectDeselectPlayers()} disabled={isMigrateClubDisabled}>
										{_("migration.label.select_deselect_all_players")}
									</button>
								</div>
								<PlayerList players={sourcePlayers} countriesList={countries} onPlayerSelect={onPlayerSelect} disabled={isPlayerListDisabled} />
							</>
						)}
					</div>

					<div className="migration-option">
						<p>
							<input type="checkbox" ref={migrateClub} name="migrate_club" onChange={(e) => migrateClubCheckboxChange(e)}/> {_("label.migrate_club")}
						</p>
						<p>
							<input type="checkbox" id="" name="" value="" checked disabled /> {_("label.migration")}
						</p>
						<p>
							<a href="#" className="trans-right">
								<span className="icon-new icon-32 icon-white icon icon-chevron-right-new" />
							</a>
						</p>
					</div>

					<div className="migration-section-container">
						<div className="migration-top-section">
							<div className="select-container">
								<Select
									className="react-select"
									isClearable
									options={clubNonPartnerTargetList}
									placeholder={_("migration.validation.message.select_target_club")}
									value={
										target as SingleValue<{
											type: string;
											value: string;
											label: string;
										}>
									}
									onChange={(e) => {
										if (e === null) {
											setTarget(emptyClub);
											setShowTargetCard(false);
											setShowTargetPlayers(false);
										} else {
											setTarget(
												e as SingleValue<{ value: string; label: string; country: string; logo: string; type: string }>
											);
											setShowTargetCard(true);
											setTargetPlayers([]);
											setShowTargetPlayers(true);

											getClubPlayers(parseInt(e.value), ClubType.TARGET, e.type);
										}
									}}
								/>
							</div>
							{showTargetCard && <ClubCard club={target} countriesList={countries}/>}
						</div>
						{showTargetPlayers && targetPlayers.length > 0 && (
							<PlayerList players={targetPlayers} countriesList={countries} onPlayerSelect={onPlayerSelect} disabled={true} />
						)}
					</div>
					<div className="migrate-bottom-section">
						<button
							className="btn btn-light-blue"
							disabled={isButtonDisabled}
							onClick={() => {
								if (!source || source?.value === "") {
									addErrorToast(_("migration.validation.message.select_source_club"))
								} else if (!target || target?.value === "") {
									addErrorToast(_("migration.validation.message.select_target_club"))
								} else if (source?.value === target?.value && source?.type === target?.type && migrateClub.current?.checked) {
									addErrorToast(_("migration.validation.message.select_both_source_target_club_same"))
								} else {
									executeMigration();
								}
							}}
						>
							{_("btn.execute_migration")}
						</button>
					</div>
				</div>
				{showMigrationStatus && migrationStatus !== null && (
					<MigrationStatus data={migrationStatus} type={type} target={targetId.current} targetClubteamOptions={targetClubteamOptions} />
				)}
				{showMigrationClubStatus && migrationClubStatus !== null && (
					<MigrationClubStatus
						data={migrationClubStatus}
						type={type}
						target={targetId.current}
						targetClubteamOptions={targetClubteamOptions}
					/>
				)}
			</div>
			{showMigrationModal && (
				<MigrationModal
					onClose={() => {
						if (sourcePlayers.filter((player) => player.percentage === 100).length !== sourcePlayers.length) {
							addErrorToast(_("migration.error.close_modal"))
						} else {
							setShowMigrationModal(false);
							setButtonDisabled(false);
						}
					}}
					sourcePlayers={sourcePlayers}
					setSourcePlayers={setSourcePlayers}
					targetClub={target}
					migrationType={type}
					countriesList={countries}
				/>
			)}
			<Modal
				show={showDeleteClubConfirmationModal}
				title={null}
				size={"sm"}
				onClose={() => {
					setShowDeleteClubConfirmationModal(false);
					setButtonDisabled(false);
				}}
			>
				<div className="modal-msg">
					<p>
						<span className="icon-new icon-blue icon-warning-new icon-50" />
					</p>
					<h6>{_("migration.are_you_sure")} ?</h6>
					<p>
						{_("migration.note.1")} {clubStatCount.playersCount} {_("migration.note.2")} {clubStatCount.gamesCount}
						{_("migration.note.3")}
					</p>
					<p>
						<button
							className="btn"
							onClick={() => {
								onMigrateClub();
								setShowDeleteClubConfirmationModal(false);
								setButtonDisabled(false);
							}}
						>
							{_("button.migration.confirm")}
						</button>
						<button
							className="btn btn--cancel"
							onClick={() => {
								setButtonDisabled(false);
								setShowDeleteClubConfirmationModal(false);
							}}
						>
							{_("btn.cancel")}
						</button>
					</p>
				</div>
			</Modal>
		</>
	);
};

export default Migrate;
