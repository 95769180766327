import { useEffect, useRef, useState } from "react";
import { VFC, APIResponse } from "types";
import { Datagrid, Renderers, HeaderRenderers } from "components/Datagrid";
import { AdminManagementTableColumns } from "../types";
import DeleteConfirmationModal from "../components/DeleteConfirmationModal";
import UserModal from "./UserModal";
import { Form, Field } from "react-final-form";
import Loader from "components/Loader";
import { handleIntersectionObserver } from "../functions";
import { _ } from "i18n";
import Icon from "components/Icon";
import * as R from "ramda";
import Modal from "ui-kit/src/Modal";
import client from "libs/client";
import { useToast } from "ui-kit";
import { toApiError } from "libs/apiClient";

export enum TableColumns {
	ROLE_ID = "roleId",
	PHOTO = "photo",
}

interface AdminManagementProps {
	activeMenu: string;
}

const AdminManagement: VFC<AdminManagementProps> = ({ activeMenu }) => {
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showUserModal, setShowUserModal] = useState(false);
	const [hasMoreData, setHasMoreData] = useState(true);

	const start = useRef(0);
	const totalRecords = useRef(0);
	const search = useRef("");

	const [selectedRow, setSelectedRow] = useState<AdminManagementTableColumns | null>(null);
	const [tableData, setTableData] = useState<{
		columns: {};
		data: [];
	}>({ columns: {}, data: [] });

	const [adminRoles, SetAdminRoles] = useState<{ id: number; name: string; }[]>();

	const { addSuccessToast, addErrorToast } = useToast();

	const onDeleteRow = async () => {
		if (!selectedRow?.id) { return; }

		const response = await client.deleteAdminUser.mutation({ id: parseInt(selectedRow.id) })
		if (response?.data.status === APIResponse.SUCCESS) {
			if (response?.data.message) { addSuccessToast(response?.data.message) };
		} else {
			if (response?.data.message) { addErrorToast(response?.data.message) };
		}
		start.current = 0;
		fetchData();
	};

	let tableColumns: { [key: string]: number | string | null } = {};
	const ignoreColumns: string[] = [TableColumns.ROLE_ID, TableColumns.PHOTO];
	const preferredOrder = {
		firstName: null,
		lastName: null,
		phoneNumber: null,
		email: null,
		roles: null,
		isActive: null,
		id: null,
	};

	const fetchData = async (loadMore: boolean = false) => {

		const params = {
			start: start.current,
			length: 10,
			search: search.current
		};

		const response = await client.getAllAdminUsers.query(params).then((res) => { return res?.data });
		if (response.status === APIResponse.SUCCESS) {
			response.data.forEach((row: { [s: string]: unknown } | ArrayLike<unknown>) => {
				Object.keys(row).forEach((key) => {
					if (!ignoreColumns.includes(key as never)) {
						tableColumns[key] = key;
					}
				});
			});

			tableColumns = { ...preferredOrder, ...tableColumns };
			totalRecords.current = response.recordsFiltered;

			setTableData((prevData) => {
				if (search.current && start.current === 0) {
					return { columns: tableColumns, data: response.data };
				}
				if (R.equals(prevData.data, response.data) === false && loadMore) {
					const newData = [...new Set([...prevData.data, ...response.data])];
					return { columns: tableColumns, data: newData };
				}
				return { columns: tableColumns, data: response.data };
			});
		}
	};

	const addUpdateAdminUsers = client.addUpdateAdminUsers.useMutation({
		onSuccess: (response) => {
			setShowUserModal(false);
			start.current = 0;
			fetchData();
			if (response?.data.message) {
				addSuccessToast(response?.data.message)
			};
		},
		onError: (error) => {
			const response = toApiError(error);
			addErrorToast(response.json.message);
		}
	})

	const onUpdateUser = async (values: any) => {
		values.roles = [parseInt(values.roles)];
		if ("id" in values === false) {
			values.id = null;
		}

		addUpdateAdminUsers.mutate({ body: { user: values } });
	};

	const getAllRoles = async () => {
		const response = await client.getRoles.query().then((res) => { return res?.data });
		SetAdminRoles(response);
	};

	const headerRenderers: HeaderRenderers<AdminManagementTableColumns> = {
		firstName: () => _("label.user.first_name"),
		lastName: () => _("label.user.last_name"),
		phoneNumber: () => _("label.user.phone"),
		email: () => _("label.user.email"),
		isActive: () => _("label.user.status"),
		roles: () => _("label.user.role"),
		id: () => _("label.action"),
	};

	const cellRenderers: Renderers<AdminManagementTableColumns> = {
		isActive: ({ cell }) => {
			if (cell.value) {
				return <span className="active">{_("form_add_club_pro.label.is_active.true")}</span>;
			}
			return <span className="active in-active">{_("form_add_club_pro.label.is_active.false")}</span>;
		},
		id: ({ cell }) => {
			return (
				<>
					<div className="action-icons">
						<button
							className="tableCellControls__button tableCellControls__button--svg"
							onClick={() => {
								setSelectedRow(cell.row.original);
								setShowUserModal(true);
							}}
						>
							<Icon icon="crayon" size={16} color="dark-grey" />
						</button>
						<button
							className="tableCellControls__button tableCellControls__button--svg"
							onClick={() => {
								setSelectedRow(cell.row.original);
								setShowDeleteModal(true);
							}}
						>
							<Icon icon="trash-new" size={16} color="dark-grey" />
						</button>
					</div>
				</>
			);
		},
	};

	const onSearchSubmit = (values: any) => {
		search.current = values.search;
		start.current = 0;
		fetchData();
	};

	const containerRef = useRef(null);
	const options = { root: null, rootMargin: "0px", threshold: 1.0 };

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0 });
		getAllRoles();
	}, [activeMenu]);

	useEffect(() => {
		const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
			handleIntersectionObserver(entries, start, totalRecords, fetchData, setHasMoreData);
		}, options);

		if (containerRef.current) observer.observe(containerRef.current);

		return () => {
			if (containerRef.current) observer.unobserve(containerRef.current);
		};
	}, [containerRef, options]);

	return (
		<>
			<div className="product-management-heading-container">
				<div className="product-management-heading">{_("label.admin.users")}</div>
				<div className="top-form-container">
					<div className="top-search">
						<Form
							onSubmit={onSearchSubmit}
							render={({ handleSubmit, submitting }) => (
								<>
									<form onSubmit={handleSubmit}>
										<div className="form-row">
											<Field name="search" type="text">
												{({ input, meta }) => (
													<>
														<input {...input} placeholder="Search" />
														{meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
													</>
												)}
											</Field>
										</div>
									</form>
								</>
							)}
						/>
					</div>
					<button
						className="btn btn-light-blue"
						onClick={() => {
							setSelectedRow(null);
							setShowUserModal(true);
						}}
					>
						+ {_("label.user.add")}
					</button>
				</div>
			</div>
			<div className="adminlist-table-container">
				<Datagrid
					columns={tableData.columns as AdminManagementTableColumns}
					data={tableData.data}
					className="table"
					override={true}
					cellRenderers={cellRenderers}
					headerRenderers={headerRenderers}
					lengthFilterEnabled={false}
					manualPaginationEnabled={false}
				/>
				<div ref={containerRef}>{hasMoreData && <Loader />}</div>
			</div>
			<Modal
				show={showDeleteModal}
				title={null}
				size={"sm"}
				onClose={() => {
					setShowDeleteModal(false);
					setSelectedRow(null);
				}}
			>
				<DeleteConfirmationModal
					show={showDeleteModal}
					title={`${selectedRow?.firstName} ${selectedRow?.lastName}`}
					onClose={() => {
						setShowDeleteModal(false);
						setSelectedRow(null);
					}}
					onDelete={() => {
						onDeleteRow();
						setShowDeleteModal(false);
					}}
				/>
			</Modal>
			<Modal
				show={showUserModal}
				title={_("label.user.account")}
				size={"xl"}
				onClose={() => {
					setShowUserModal(false);
					setSelectedRow(null);
				}}
			>
				<UserModal onUpdateUser={onUpdateUser} row={selectedRow} adminRoles={adminRoles!} />
			</Modal>
		</>
	);
};

export default AdminManagement;
