import { VFC } from "types";

interface CircularProgressBarProps {
	strokeWidth: number;
	percentage: number;
	squareSize: number;
}

export const CircularProgressBar: VFC<CircularProgressBarProps> = ({ strokeWidth, percentage, squareSize }) => {
	const radius = (squareSize - strokeWidth) / 2;
	const viewBox = `0 0 ${squareSize} ${squareSize}`;
	const dashArray = radius * Math.PI * 2;
	const dashOffset = dashArray - (dashArray * percentage) / 100;

	return (
		<svg width={squareSize} height={squareSize} viewBox={viewBox}>
			<circle className="circle-background" cx={squareSize / 2} cy={squareSize / 2} r={radius} strokeWidth={`${strokeWidth}px`} />
			<circle
				className="circle-progress"
				cx={squareSize / 2}
				cy={squareSize / 2}
				r={radius}
				strokeWidth={`${strokeWidth}px`}
				transform={`rotate(-90 ${squareSize / 2} ${squareSize / 2})`}
				style={{
					strokeDasharray: dashArray,
					strokeDashoffset: dashOffset,
				}}
			/>
			<text className="circle-text" x="50%" y="50%" dy=".3em" textAnchor="middle">
				{`${percentage}%`}
			</text>
		</svg>
	);
};
