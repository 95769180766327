import { Locale, TranslationFunction, Translator } from "i18n";
import { createContext, useContext } from "react";

interface ContextValues {
	translator: Translator;
	locale: Locale;
	_: TranslationFunction;
	changeLocale: (lang: Locale) => Promise<void>;
}

const I18nContext = createContext(null as unknown as ContextValues);

export const useI18n = () => useContext(I18nContext);

export default I18nContext;
