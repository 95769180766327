import { useToastState as _useToastState, ToastState, ToastStateProps } from "@react-stately/toast";
import type { AriaToastRegionProps, AriaToastProps } from "@react-aria/toast";
import { ToastQueue, useToastQueue } from "@react-stately/toast";
import { useToastRegion, useToast as useAriaToast } from "@react-aria/toast";
import { ReactElement, useRef } from "react";
import { Button } from "react-aria-components";
import "./toast.css";
import { createPortal } from "react-dom";
import { createContext, useContext } from "react";

export interface EyeballToast {
	type: "success" | "error";
	message: string;
}

interface ToastRegionProps<T> extends AriaToastRegionProps {
	state: ToastState<EyeballToast>;
}

function ToastRegion<T>({ state, ...props }: ToastRegionProps<T>) {
	const ref = useRef(null);
	const { regionProps } = useToastRegion(props, state, ref);

	return (
		<div {...regionProps} ref={ref} className="toast-region">
			{state.visibleToasts.map((toast) => (
				<Toast key={toast.key} toast={toast} state={state} />
			))}
		</div>
	);
}

interface ToastProps<T> extends AriaToastProps<T> {
	state: ToastState<EyeballToast>;
}

function Toast({ state, ...props }: ToastProps<EyeballToast>) {
	const ref = useRef(null);
	const { toastProps, titleProps, descriptionProps, closeButtonProps } = useAriaToast(props, state, ref);
	const toastType = props.toast.content.type;

	return (
		<div
			{...toastProps}
			ref={ref}
			className="toast"
			data-animation={props.toast.animation}
			onAnimationEnd={() => {
				if (props.toast.animation === "exiting") {
					state.remove(props.toast.key);
				}
			}}
		>
			<div className={`toast__item--${toastType}`}>
				<div className={`toast__icon--${toastType}`}>
					{toastType === "success" ? (
						<svg {...titleProps} className="toastMessage__icon icon icon-check">
							<title>messageIconCheck</title>
							<use xlinkHref="#icon-check" />
						</svg>
					) : (
						<svg {...titleProps} className="toastMessage__icon icon icon-cross">
							<title>messageIconCross</title>
							<use xlinkHref="#icon-cross" />
						</svg>
					)}
				</div>
				<div className="toastMessage__message" {...descriptionProps}>
					{props.toast.content.message}
				</div>
				<Button {...closeButtonProps} type="button" className={`toast__close--${toastType}`}>
					x
				</Button>
			</div>
		</div>
	);
}

export function createToastQueue(options: Partial<ToastStateProps>) {
	return new ToastQueue<EyeballToast>(options);
}

interface ContextValues {
	queue: ToastQueue<EyeballToast>;
	addSuccessToast: (message: string) => void;
	addErrorToast: (message: string) => void;
}

const ToastContext = createContext(null as unknown as ContextValues);

export const useToast = () => useContext(ToastContext);

interface ToastProviderProps {
	children: ReactElement;
	queue: ToastQueue<EyeballToast>;
}

export function ToastProvider({ children, queue }: ToastProviderProps) {
	const state = useToastQueue(queue);

	const addSuccessToast = (message: string) => {
		state.add({ message, type: "success" }, { timeout: 2500 });
	};

	const addErrorToast = (message: string) => {
		state.add({ message, type: "error" }, { timeout: 2500 });
	};

	return (
		<>
			<ToastContext.Provider value={{ queue, addSuccessToast, addErrorToast }}>{children}</ToastContext.Provider>
			{state.visibleToasts.length > 0 ? createPortal(<ToastRegion state={state} />, document.body) : null}
		</>
	);
}
