import { ReactElement, createContext, useContext } from "react";

interface ConstantsValues {
	apiURL: string;
}

const ConstantsContext = createContext(null as unknown as ConstantsValues);

export const useConstants = () => useContext(ConstantsContext);

interface ConstantsProviderProps extends ConstantsValues {
	children: ReactElement | ReactElement[];
}

export default function ConstantsProvider({ children, apiURL }: ConstantsProviderProps) {
	return <ConstantsContext.Provider value={{ apiURL }}>{children}</ConstantsContext.Provider>;
}
