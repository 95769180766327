import { type GameData, type PeriodRequest, VideoStatusType, VideoEventLogStatusType, VideoProviderName } from "libs/apiClient";
import { TimeField, type FormValues, type Time, type Metadata, timeFieldLabels, type TimelineValues, PeriodName, type Club } from "./types";
import type { ValidationErrors } from "final-form";

export function timeToSeconds({ hours, minutes, seconds }: Time): number {
	return hours * 3600 + minutes * 60 + seconds;
}

export function secondsToTime(seconds: number): string {
	const hrs = Math.floor(seconds / 3600);
	const mins = Math.floor((seconds % 3600) / 60);
	const secs = (seconds % 60).toFixed(0);

	// Format the time string
	let formattedTime = "";

	if (hrs > 0) {
		formattedTime += `${hrs}:${mins.toString().padStart(2, "0")}:${secs.padStart(2, "0")}`;
	} else {
		formattedTime += `${mins}:${secs.padStart(2, "0")}`;
	}

	return formattedTime;
}

export function parseTime(time: string): Time {
	const parts = time.split(":").map(Number);
	let hours = 0;
	let minutes = 0;
	let seconds = 0;

	if (parts.length === 3) {
		[hours, minutes, seconds] = parts;
	} else if (parts.length === 2) {
		[minutes, seconds] = parts;
		hours = 0;
	} else {
		throw new Error("Invalid time format");
	}

	return { hours, minutes, seconds };
}

export function isFirstTimeSooner(time1: string, time2: string): boolean {
	const parsedTime1 = parseTime(time1);
	const parsedTime2 = parseTime(time2);

	return timeToSeconds(parsedTime1) < timeToSeconds(parsedTime2);
}

export function convertValuesToPeriods(values: FormValues): PeriodRequest[] {
	const periods: PeriodRequest[] = [
		{
			period: "1st half",
			start: values.firstHalfStart,
			end: values.firstHalfEnd,
		},
		{
			period: "2nd half",
			start: values.secondHalfStart,
			end: values.secondHalfEnd,
		},
		{
			period: "1st overtime",
			start: values.firstOvertimeStart,
			end: values.firstOvertimeEnd,
		},
		{
			period: "2nd overtime",
			start: values.secondOvertimeStart,
			end: values.secondOvertimeEnd,
		},
		{
			period: "penalty shootout",
			start: values.penaltyShootoutStart,
			end: values.penaltyShootoutEnd,
		},
	];

	return periods.filter((period) => period.start && period.end);
}

export function convertMetadataToValues(metadata: Metadata): Partial<FormValues> {
	const periods = metadata?.periods;

	const values: Partial<FormValues> = {
		posterTime: secondsToTime(metadata.posterTime),
	};

	const timeFields = Object.values(TimeField);
	if (periods) {
		for (let i = 0; i < periods.length; i++) {
			const period = periods[i];

			const startIndex = i * 2;
			const startField = timeFields[startIndex];
			const endField = timeFields[startIndex + 1];

			values[startField] = secondsToTime(period.start);
			values[endField] = secondsToTime(period.end);
		}
	}
	return values;
}

const timeTest = /^(?:(\d+):)?([0-5]?\d):([0-5]?\d)$/;

export function validate(values: FormValues): ValidationErrors {
	const errors: ValidationErrors = {};

	if (values.posterTime && !timeTest.test(values.posterTime)) {
		errors.posterTime = "Please use HH:MM:SS or MM:SS format";
	}

	const timeFields = Object.values(TimeField);
	for (const field of timeFields) {
		if (values[field] && !timeTest.test(values[field])) {
			errors[field] = "Please use HH:MM:SS or MM:SS format";
		}
	}

	for (let i = 1; i < timeFields.length; i++) {
		const prevField = timeFields[i - 1];
		const currField = timeFields[i];

		if (values[prevField] && values[currField] && !errors[prevField] && !errors[currField]) {
			if (!isFirstTimeSooner(values[prevField], values[currField])) {
				errors[currField] = `${timeFieldLabels[currField]} should be later than ${timeFieldLabels[prevField]}`;
			}
		}
	}
	return errors;
}

export function convertFormValuesToTimelineValues(values: Omit<Partial<FormValues>, "posterTime">): TimelineValues | false {
	const result: TimelineValues = {};

	for (const period of Object.values(PeriodName)) {
		const startName = `${period}Start` as TimeField;
		const endName = `${period}End` as TimeField;
		if (values[startName] || values[endName]) {
			if (values[startName] && values[endName]) {
				result[period] = {
					//@ts-ignore
					start: timeToSeconds(parseTime(values[startName])),
					//@ts-ignore
					end: timeToSeconds(parseTime(values[endName])),
				};
			} else {
				return false;
			}
		}
	}

	return result;
}

export function transformGamesData(dataArray: GameData[]) {
	const clubs: Record<string, Club> = {};

	function addGameToClub(clubName: GameData["homeClubName"], game: GameData) {
		if (clubName) {
			if (!clubs[clubName]) {
				clubs[clubName] = {
					value: clubName,
					label: clubName,
					games: [],
				};
			}

			clubs[clubName].games.push(game);
		}
	}

	for (const data of dataArray) {
		if (data.homeClubName) {
			addGameToClub(data.homeClubName, data);
		}
	}

	return Object.values(clubs);
}

export const statusDropdownOptions = Object.values(VideoStatusType.Values).map((status) => ({
	value: status,
	label: status.replace(/_/g, " "),
}));

export const eventsDropdownOptions = Object.values(VideoEventLogStatusType.Values).map((status) => ({
	value: status,
	label: status.replace(/_/g, " "),
}));

export const providersDropdownOptions = Object.values(VideoProviderName.Values).map((status) => ({
	value: status,
	label: status.replace(/_/g, " "),
}));
