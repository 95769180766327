import { _ } from "i18n";
import { MigrationStatusGamesTable, MigrationType } from "../../types";
import { Datagrid, HeaderRenderers, Renderers } from "../../../../../../../assets/js/components/Datagrid";
import { useState, FC } from "react";
import noteIcon from "../../../../../../../assets/images/outline-info.svg";
import Select, { GroupBase, SingleValue } from "react-select";
import { APIResponse } from "types";
import { useToast } from "ui-kit";
import client from "libs/client";

interface MigrationStatusProps {
	data: MigrationStatusGamesTable[];
	type: string;
	target: { value: string; label: string };
	targetClubteamOptions?: GroupBase<{ label: string; value: number }>[];
}

enum TableColumns {
	Id = "ID",
	Category = "Category",
	HomeTeam = "Home Team",
	AwayTeam = "Away Team",
	Date = "Date",
	Team = "Team",
	HomeClub = "homeClub",
	AwayClub = "awayClub",
}

const MigrationClubStatus: FC<MigrationStatusProps> = ({ data, type, target, targetClubteamOptions }) => {
	const [showSuccessTable, setShowSuccessTable] = useState(false);
	const [tableData, setTableData] = useState<MigrationStatusGamesTable[]>(data);
	const [team, setTeam] = useState<any>({});
	const { addSuccessToast, addErrorToast } = useToast();

	const gamesTableColumns = {
		id: TableColumns.Id,
		category: TableColumns.Category,
		homeClub: TableColumns.HomeClub,
		homeTeam: TableColumns.HomeTeam,
		awayClub: TableColumns.AwayClub,
		awayTeam: TableColumns.AwayTeam,
		date: TableColumns.Date,
	};
	const handleAssignTeam = async (e: SingleValue<{ label: string; value: number | string | null }>, id: number) => {
		if (e === null) {
			e = { value: "", label: "" };
		}

		const response = await client.updateGameTeam.mutation({
			body: {
				teamId: (e?.value !== "" && e?.value !== null) ? parseInt(e.value as unknown as string) : null,
				targetClubId: parseInt(target?.value),
				gameId: id
			}
		});
		
		if (response?.data.status === APIResponse.SUCCESS) {
			setTableData((prevData) => {
				const Data = prevData.map((data) => {
					if (data.id === response.data.data?.id) {
						return response.data;
					}
					return data;
				});
				return Data as MigrationStatusGamesTable[];
			});
			addSuccessToast(response?.data.message!);
		} else {
			addErrorToast(response?.data.message!);
		}
	};

	const gamesHeaderRenderers: HeaderRenderers<MigrationStatusGamesTable> = {
		id: () => _("label.user.id"),
		category: () => _("videos.table.th.category"),
		homeTeam: () => _("label.match.homeTeam"),
		homeClub: () => _("label.match.homeClub"),
		awayTeam: () => _("label.match.awayTeam"),
		awayClub: () => _("label.match.awayClub"),
		date: () => _("label.date_time"),
	};

	const gamesCellRenderers: Renderers<MigrationStatusGamesTable> = {
		id: ({ cell }) => {
			return (
				<>
					<a href={`videos/match/${cell.value}`}>{cell.value}</a>
				</>
			);
		},
		homeTeam: ({ cell }) => {
			if (type === MigrationType.PARTNER && cell.row.original.migratedClub === "home") {
				return (
					<div className="field-group">
						<Select
							className="react-select width-300"
							options={targetClubteamOptions}
							placeholder={_("migration.placeholder.team")}
							value={cell.row.original.homeTeam.value ? cell.row.original.homeTeam : null}
							onChange={(e: SingleValue<{ label: string; value: number | string | null }>) => {
								handleAssignTeam(e, cell.row.original.id);
							}}
							isClearable
						/>
					</div>
				);
			} else {
				return <a>{cell.value.label ? cell.value.label : "-"}</a>;
			}
		},
		awayTeam: ({ cell }) => {
			if (type === MigrationType.PARTNER && cell.row.original.migratedClub === "away") {
				return (
					<div className="field-group">
						<Select
							className="react-select width-300"
							options={targetClubteamOptions}
							placeholder={_("migration.placeholder.team")}
							value={cell.row.original.awayTeam.value ? cell.row.original.awayTeam : null}
							onChange={(e: SingleValue<{ label: string; value: number | string | null }>) => {
								handleAssignTeam(e, cell.row.original.id);
							}}
							isClearable
						/>
					</div>
				);
			} else {
				return <a>{cell.value.label ? cell.value.label : "-"}</a>;
			}
		},
	};

	return (
		<>
			{tableData?.length > 0
				? type === MigrationType.PARTNER ?? (
						<div className="migration-counts-container">
							<div className="migrate-info-container">
								<div className="migrate-info">
									<img src={noteIcon} />
									{_("migration.game-edit-status-note")}
								</div>
							</div>
						</div>
				  )
				: null}
			<div className="migration-counts-container" id="migration-status">
				<div className="migrate-success-container">
					<div className="migrate-success-count">
						<div className="migrate-counts">
							<div className="migrate-count"> {tableData?.length}</div>
							<span>{_("migration.games_successfully_migrated")}</span>
						</div>
					</div>
					{tableData?.length > 0 ? (
						<div
							className="click-here"
							onClick={() => {
								setShowSuccessTable(!showSuccessTable);
							}}
						>
							{_("migration.btn.more_details")}
						</div>
					) : null}
				</div>
			</div>
			<div className="counts-table">
				{showSuccessTable ? (
					<div className="table-container overflow-unset">
						{tableData?.length > 0 ? (
							<>
								<div className="inner-section-heading">{_("label.migrated_games")}</div>
								<Datagrid
									columns={gamesTableColumns}
									data={tableData}
									className="table"
									override={true}
									headerRenderers={gamesHeaderRenderers}
									cellRenderers={gamesCellRenderers}
									lengthFilterEnabled={false}
									manualPaginationEnabled={false}
								/>
							</>
						) : null}
					</div>
				) : null}
			</div>
		</>
	);
};

export default MigrationClubStatus;
