import SkeletonBox from "ui-kit/src/Skeleton/SkeletonBox";
import "./playerUserDetailSkeleton.css"

export function PlayerUserDetailSkeleton() {
    return (
        <>
            <div className="playerUserDetailSkeleton-container">
                <SkeletonBox className="cellSkeleton" />
                <SkeletonBox className="cellSkeleton" />
                <SkeletonBox className="cellSkeleton" />
                <SkeletonBox className="cellSkeleton" />
                <SkeletonBox className="cellSkeleton" />
                <SkeletonBox className="cellSkeleton" />
                <SkeletonBox className="cellSkeleton" />
                <SkeletonBox className="cellSkeleton" />
                <SkeletonBox className="cellSkeleton" />
            </div>
        </>
    )
}