import clsx from "clsx";

export function formatDate(dateString?: string | null) {
	if (dateString) {
		const date = new Date(dateString);

		return new Intl.DateTimeFormat("en-UK", {
			year: "numeric",
			day: "numeric",
			month: "numeric",
		}).format(date);
	}
}

export function formatDateTime(dateString?: string | null) {
	if (dateString) {
		const date = new Date(dateString);

		return new Intl.DateTimeFormat("en-UK", {
			year: "numeric",
			day: "numeric",
			month: "numeric",
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
		}).format(date);
	}
}

export function formatDuration(durationString?: string | null) {
	if (durationString) {
		const [hours, minutes, seconds] = durationString.split(":");
		const roundedSeconds = Math.round(Number.parseFloat(seconds));

		return `${hours}:${minutes}:${roundedSeconds}`;
	}
}

export function roundSizeString(input?: string | null) {
	if (input) {
		const numericPart = Number.parseFloat(input);
		const roundedNumericPart = numericPart.toFixed(2);
		const result = input.replace(/[\d.]+/, roundedNumericPart);

		return result;
	}
}

export function getStatusClass(status?: string) {
	if (status) {
		return clsx("statusCell", {
			red: status.includes("ERROR"),
			green: status.includes("OK") || status.includes("FINISHED"),
			gray: status === "UPLOADED",
		});
	}
	return "-";
}
