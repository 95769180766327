import Router from "app/router";
import I18nContext from "features/translation/context";
import { Locale, TranslationFunction, Translator } from "i18n";
import client from "libs/client";
import http from "libs/http";
import toastQueue from "libs/toastQueue";
import { useEffect, useState } from "react";
import { ToastProvider } from "ui-kit/src";

interface AppProps {
	translator: Translator;
	locale: Locale;
	_: TranslationFunction;
	changeLocale: (lang: Locale) => Promise<void>;
}

function App({ translator, locale: defaultLocale, _, changeLocale: baseChangeLocale }: AppProps) {
	const [locale, setLocale] = useState(defaultLocale);

	const changeLocale = async (locale: Locale) => {
		await baseChangeLocale(locale);
		setLocale(locale);
	};

	// client.getRepositoriesByOwner.useQuery(
	// 	{ username: "jozi" },
	// 	{
	// 		onSuccess: (data) => {
	// 			console.log(data);
	// 			console.log("query success");
	// 		},
	// 	},
	// );

	return (
		<I18nContext.Provider value={{ translator, locale: locale, _, changeLocale }}>
			<ToastProvider queue={toastQueue}>
				<Router />
			</ToastProvider>
		</I18nContext.Provider>
	);
}

export default App;
