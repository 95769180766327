import type { ReactNode } from "react";
import { Modal as AriaModal, Button, Dialog, Heading } from "react-aria-components";
import "./modal.css";

interface ModalProps {
	title?: string | undefined;
	onClose: () => void;
	show: boolean;
	children: ReactNode;
	size: "sm" | "lg" | "xl" | "xxl" | "xxxl";
}

export default function Modal({ title = undefined, onClose, show = false, children, size }: ModalProps) {
	return (
		<AriaModal isDismissable isOpen={show} className={`modal ${show ? "open" : ""}`}>
			<Dialog className={`ariaModal__container ariaModal__container--${size}`} aria-label={title}>
				{title && <Heading className="ariaModal__title">{title}</Heading>}
				<Button className="ariaModal-closeButton" onPress={onClose}>
					X
				</Button>
				<div className="modal__body">{children}</div>
			</Dialog>
		</AriaModal>
	);
}
