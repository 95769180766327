type Redirects = [RegExp, string | ((request: Request, match: RegExpExecArray) => string)][];

const redirects: Redirects = [
	[
		new RegExp(/\/admin\/club-amateur\/(?<id>\d+)\/teams$/),
		(request, match) => {
			return `/club/${match.groups?.id}/teams`;
		},
	],
];

export function getRedirect(request: Request): string | null {
	const url = new URL(request.url);

	for (const [regex, redirect] of redirects) {
		const match = regex.exec(url.pathname);
		if (match !== null) {
			if (typeof redirect === "string") {
				return redirect;
			}

			return redirect(request, match);
		}
	}

	return null;
}
