//@ts-ignore
import africa from "assets/images/africa.png";
//@ts-ignore
import northAmerica from "assets/images/america.png";
//@ts-ignore
import asia from "assets/images/asia.png";
//@ts-ignore
import europe from "assets/images/europe.png";
//@ts-ignore
import oceania from "assets/images/pacific.png";
//@ts-ignore
import southAmerica from "assets/images/south-america.png";

export interface SingleCountryData {
	code: string;
	enabled: boolean;
	tooltip: string | null;
	name: string;
}

export type ContinentData = SingleCountryData[];

export enum Continent {
	AFRICA = "africa",
	ASIA = "asia",
	EUROPE = "europe",
	NORTH_AMERICA = "northAmerica",
	OCEANIA = "oceania",
	SOUTH_AMERICA = "southAmerica",
}

export interface CountryData {
	[Continent.AFRICA]?: ContinentData;
	[Continent.ASIA]?: ContinentData;
	[Continent.EUROPE]?: ContinentData;
	[Continent.NORTH_AMERICA]?: ContinentData;
	[Continent.OCEANIA]?: ContinentData;
	[Continent.SOUTH_AMERICA]?: ContinentData;
}

export type CountrySelection = {
	[continent in Continent]: string[];
};

export const emptySelection = {
	[Continent.AFRICA]: [],
	[Continent.ASIA]: [],
	[Continent.EUROPE]: [],
	[Continent.NORTH_AMERICA]: [],
	[Continent.SOUTH_AMERICA]: [],
	[Continent.OCEANIA]: [],
};

export interface UserCountrySelection {
	countries: CountrySelection;
	count: number;
}

export const ContinentsPath = {
	[Continent.AFRICA]: africa,
	[Continent.ASIA]: asia,
	[Continent.EUROPE]: europe,
	[Continent.NORTH_AMERICA]: northAmerica,
	[Continent.SOUTH_AMERICA]: southAmerica,
	[Continent.OCEANIA]: oceania,
};
