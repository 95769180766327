import { SetStateAction } from "react";
import { _ } from "i18n";
import { VFC } from "../types";
import Icon from "components/Icon";
import { Keys } from "i18n/locales/types";

interface CheckboxTreeProps {
	data: {
		label: string;
		permission: {
			id: number;
			permission: string;
		}[];
	}[];
	checked: number[];
	setChecked: React.Dispatch<SetStateAction<number[]>>;
}

const CheckboxTree: VFC<CheckboxTreeProps> = ({ data, checked, setChecked }) => {
	return (
		<>
			{data &&
				data.map((root) => {
					return (
						<ul key={root.label} className="checkbox-tree">
							<li className="open">
								<span
									className="tree-label"
									onClick={(e) => {
										if (e.currentTarget.parentElement?.classList.contains("open")) {
											e.currentTarget.parentElement?.classList.remove("open");
											e.currentTarget.parentElement?.classList.add("close");
										} else {
											e.currentTarget.parentElement?.classList.remove("close");
											e.currentTarget.parentElement?.classList.add("open");
										}
									}}
								>
									<Icon icon="admin-arrow" />
									<span> {_(`resource.${root.label}` as keyof Keys)} </span>
								</span>
								{root.permission?.length! > 0 && (
									<ul>
										{root.permission?.map((parent) => {
											return (
												<li key={parent.id} className="children">
													<span className="tree-label">
														<input
															type="checkbox"
															name=""
															checked={checked.includes(parent.id) ? true : false}
															onChange={(e) => {
																if (e.target.checked) {
																	setChecked((prevChecked) => {
																		return [...prevChecked, parseInt(e.target.id)];
																	});
																} else {
																	setChecked((prevChecked) => {
																		return prevChecked.filter((val) => val !== parseInt(e.target.id));
																	});
																}
															}}
															id={`${parent.id}`}
														/>
														<span> {_(`permission.${parent.permission}` as keyof Keys)} </span>
													</span>
												</li>
											);
										})}
									</ul>
								)}
							</li>
						</ul>
					);
				})}
		</>
	);
};

export default CheckboxTree;
