import { _ } from "i18n";
import { VFC } from "types";

interface UnAssignSubscriptionModalProps {
	onClose: () => void;
	onUnAssign: () => void;
}

const UnAssignSubscriptionModal: VFC<UnAssignSubscriptionModalProps> = ({ onClose, onUnAssign }) => {
	return (
		<div className="text-center">
			<button className="btn btn--grey" onClick={() => onClose()}>
				{_("btn.cancel")}
			</button>
			<button type="button" className="btn btn--white-red" onClick={() => onUnAssign()}>
				{_("label.unassign")}
			</button>
		</div>
	);
};

export default UnAssignSubscriptionModal;
