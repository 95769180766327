import { _ } from "i18n";
import { Form, Field } from "react-final-form";
import { VFC } from "../types";
import { AssignPoleData, PoleManagementTableData } from "../types";
import { useState } from "react";
import { getClubPoles } from "../api";
import Select from "react-select";
import client from "libs/client";
interface AssignPoleModalProps {
	onClose: () => void;
	onAssignPole: (values: AssignPoleData) => void;
	clubList: { label?: string; value: string }[];
	row: PoleManagementTableData | null;
}

const AssignPoleModal: VFC<AssignPoleModalProps> = ({ onClose, onAssignPole, clubList, row }) => {
	const [poleList, setPoleList] = useState<{ label?: string; value: string }[]>([]);

	const poleSelectBoxLink = ""
	const [selectedClub, setSelectedClub] = useState<{ value: number | undefined; label: number | undefined } | undefined>();
	const [selectedPole, setSelectedPole] = useState<{ value?: string; label?: string } | undefined>();

	const formData = {
		club_id: null,
		assign_to: null,
		pole_id: row ? row.id : null,
	};

	const onFormSubmit = (values: AssignPoleData) => {
		values.club_id = selectedClub?.value;
		values.assign_to = selectedPole?.value;
		onAssignPole(values);
	};

	const SelectAdapter = ({ input, ...rest }: { [x: string]: {}; input: { name: string } }) => {
		if (input.name === "club_id") {
			return <Select {...input} {...rest} value={selectedClub} />;
		}
		return <Select {...input} {...rest} value={selectedPole} />;
	};

	const getPoleList = async (id: number) => {
		const response = await client.getSelectedPoleByClub.query({clubId: id}).then((res) => { return res?.data });
		setPoleList(response);
		if (response.length > 0) {
			setSelectedPole(response[0]);
		}
	};

	return (
		<Form
			onSubmit={onFormSubmit}
			initialValues={formData}
			render={({ handleSubmit, submitting }) => (
				<>
					<form onSubmit={handleSubmit}>
						<div className="form-group flex-column">
							<label className="required"> {_("videos.table.th.club")} </label>
							<Field
								name="club_id"
								className="react-select"
								component={SelectAdapter}
								options={clubList}
								onChange={(e: { value: number | undefined; label: number | undefined } | undefined) => {
									setSelectedClub(e);
									getPoleList(e?.value!);
								}}
							/>
						</div>
						<div className="form-group flex-column">
							<label className="required"> {_("label.assign_into")}</label>
							<Field
								name="assign_to"
								className="react-select"
								component={SelectAdapter}
								options={poleList}
								onChange={(e: { value?: string; label?: string }) => {
									setSelectedPole(e);
								}}
							/>
						</div>
						<div className="form__footer">
							<button type="submit" disabled={submitting} className="btn-primary btn">
								{_("btn.save")}
							</button>
							<label className="btn--cancel" onClick={() => onClose()}>
								{_("btn.cancel")}
							</label>
						</div>
					</form>
				</>
			)}
		/>
	);
};

export default AssignPoleModal;
