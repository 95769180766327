import React from "react";
import "./pageControls.css";

interface PageControlsProps {
	totalPages: number;
	currentPage: number;
	changePage: (newPage: number) => void;
}

export default function PageControls({ totalPages, currentPage, changePage }: PageControlsProps) {
	const displayedPages = calculateDisplayedPages();

	function calculateDisplayedPages(): (number | string)[] {
		if (totalPages <= 6) {
			return Array.from({ length: totalPages }, (_, i) => i + 1);
		}

		if (currentPage <= 3) {
			return [1, 2, 3, 4, 5, "...", totalPages];
		}

		if (currentPage >= totalPages - 2) {
			return [1, "...", totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
		}

		return [1, "...", currentPage - 1, currentPage, currentPage + 1, "...", totalPages];
	}

	const handlePageChange = (newPage: number | string) => {
		if (typeof newPage === "number") {
			changePage(newPage);
			window.scrollTo(0, 0);
		}
	};

	return (
		<>
			{totalPages > 1 && (
				<div className="pageControls-pageControls">
					{currentPage > 1 && (
						<div className="pageControls-previousPage" onClick={() => handlePageChange(currentPage - 1)}>
							<span className="icon-new icon-chevron-left-grey icon-blue icon-24" />
						</div>
					)}
					{displayedPages.map((page, index) => (
						<React.Fragment key={`${index}${page}`}>
							{page === "..." ? (
								<span className="pageControls-pageSeparator">...</span>
							) : (
								<div
									className={page === currentPage ? "pageControls-currentPage" : "pageControls-page"}
									onClick={() => handlePageChange(page as number)}
								>
									{page}
								</div>
							)}
						</React.Fragment>
					))}
					{currentPage < totalPages && (
						<div className="pageControls-nextPage" onClick={() => handlePageChange(currentPage + 1)}>
							<span className="icon-new icon-chevron-right-grey icon-blue icon-24" />
						</div>
					)}
				</div>
			)}
		</>
	);
}
