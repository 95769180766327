import { FC } from "react";
import { MigrationType, NonPartnerClubData } from "../../types";
import Icon from "components/Icon";
import clubPlaceholder from "assets/images/logo_fixtures.png";
import { Menu } from "@headlessui/react";
import { _ } from "i18n";
import { CountryCode, CountryFlag } from "ui-kit/src/flags";

interface NonPartnerClubCardProps {
	club: NonPartnerClubData;
	countriesList: { [key: string]: string };
	onDeleteActionClick: (club: NonPartnerClubData) => void;
	onEditActionClick: (club: NonPartnerClubData) => void;
	onMigrateActionClick: (club: NonPartnerClubData) => void;
	onPlayerCountClick: (club: NonPartnerClubData) => void;
}

const NonPartnerClubCard: FC<NonPartnerClubCardProps> = ({
	club,
	countriesList,
	onDeleteActionClick,
	onEditActionClick,
	onMigrateActionClick,
	onPlayerCountClick,
}) => {
	const publicUrl = new URL(`public`, import.meta.url);

	return (
		<>
			<div className="club-card club-card-space">
				<div className="card-row1">
					<div className="card-logo card-space">
						<img src={club.logo ? (club.logo.includes("http") ? club.logo : `${publicUrl}/${club.logo}`) : clubPlaceholder} />
					</div>
					<div className="card-details">
						<h6>{club.name}</h6>
						{club.country && (
							<div className="playerCardBlock__geo">
								<CountryFlag code={club.country as CountryCode} />
								<span>{countriesList[club.country]}</span>
							</div>
						)}
						<div className="card-row3">
							<div className="clubNonPartner-numbers">
								<span className="icon-new icon-dark-grey icon-12 icon-ball" />
								<a href={`admin/club-amateur-nonpartner/${club.id}/games`} target="_blank">
									<b> {club.gamesCount}</b><span className="ml-1">{_("admin.npc.games")}</span>
								</a>
							</div>
							<div className="clubNonPartner-numbers" onClick={() => onPlayerCountClick(club)}>
								<span className="icon-new icon-dark-grey icon-12 icon-person" />
								<b> {club.playersCount}</b><span className="ml-1">{_("admin.npc.players")}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="card-row2">
					<button
						className="link"
						onClick={() => {
							onEditActionClick(club);
						}}
					>
						<Icon icon="crayon" size={12} color="dark-grey"/>
						{_("permission.edit")}
					</button>
					<button
						className="link"
						onClick={() => {
							onDeleteActionClick(club);
						}}
					>
						<Icon icon="trash-new" size={16}/> {_("permission.delete")}
					</button>
					<button
						className="link"
						onClick={() => {
							onMigrateActionClick(club);
						}}
					>
						<Icon icon="migrate-new" /> {_("label.migrate")}
					</button>
				</div>
			</div>
		</>
	);
};

export default NonPartnerClubCard;
