import { SetStateAction, useEffect, useRef, useState } from "react";
import { VFC, APIResponse } from "types";
import { Datagrid, Renderers, HeaderRenderers } from "components/Datagrid";
import { NotificationManagementData, NotificationManagementTableColumns } from "../types";
import { handleIntersectionObserver, methodFormatter, numFormatter } from "../functions";
import Loader from "components/Loader";
import { _ } from "i18n";
import * as R from "ramda";
import Modal from "ui-kit/src/Modal";
import NotificationModal from "./NotificationModal";
import React from "react";
import JobDetail from "./JobDetail";
import { Field, Form } from "react-final-form";
import { CircularProgressBar } from "components/CircularProgressBar";
import { useToast } from "ui-kit";
import client from "libs/client";
import Icon from "components/Icon";

export enum TableColumns {
	PHONE_NUMBER = "phoneNumber",
	LANGUAGE = "language",
	SUCCESSFUL = "successful",
	EMAIL = "email",
	MESSAGES = "messages",
	METHODS = "methods",
	RECEIVERS = "receivers",
}
interface SubscriptionManagementProps {
	activeMenu: string;
}

const NotificationManagement: VFC<SubscriptionManagementProps> = ({ activeMenu }) => {
	const [showNotificationModal, setShowNotificationModal] = useState(false);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [showLoader, setShowLoader] = useState(false);

	const [selectedRow, setSelectedRow] = useState<NotificationManagementData | undefined | {}>();
	const [tableData, setTableData] = useState<{
		columns: {
			[K in keyof NotificationManagementTableColumns]: string;
		};
		data: [];
	}>({ columns: {}, data: [] });

	const start = useRef(0);
	const totalRecords = useRef(0);
	const search = useRef("");
	const activeStatus = useRef<string>("");

	const { addSuccessToast, addErrorToast } = useToast();

	let tableColumns: { [key: string]: number | string | null } = {};
	const sortableColumns: string[] = [];
	const ignoreColumns: string[] = ["phoneNumber", "language", "successful", "email", "messages", "methods", "receivers", "percentage"];
	const preferredOrder = {
		id: null,
		title: null,
		date: null,
		authorName: null,
		methods: null,
		userCount: null,
		failed: null,
		retry: null,
		detail: null,
		refresh: null,
	};

	const fetchData = async (loadMore: boolean = false) => {

		const params = {
			start: start.current,
			length: 10,
			search: search.current
		};

		const response = await client.getAllSpecificGenericNotifications.query(params).then((res) => { return res?.data });
		if (response.status === APIResponse.SUCCESS) {
			response.data.forEach((row: { [s: string]: unknown } | ArrayLike<unknown>) => {
				Object.keys(row).forEach((key) => {
					if (!ignoreColumns.includes(key as never)) {
						tableColumns[key] = key;
					}
				});
			});

			tableColumns = { ...preferredOrder, ...tableColumns };
			totalRecords.current = response.recordsFiltered;
			setShowLoader(false);

			setTableData((prevData) => {
				if (search.current && start.current === 0) {
					return { columns: tableColumns, data: response.data };
				}
				if (R.equals(prevData.data, response.data) === false && loadMore) {
					const newData = [...new Set([...prevData.data, ...response.data])];
					return { columns: tableColumns, data: newData };
				}
				return { columns: tableColumns, data: response.data } as {
					columns: { [key: string]: string | number | null };
					data: any | NotificationManagementData;
				};
			});
		}
	};

	const handleNotication = async (values: NotificationManagementData, isTest: boolean) => {
		let response;
		if (isTest) {
			response = await client.testSpecificGenericNotification.mutation({ body: { notifications: values } });
		} else {
			response = await client.addSpecificGenericNotification.mutation({ body: { notifications: values } });
		}

		if (response?.data.status === APIResponse.SUCCESS) {
			if (response?.data.message) { addSuccessToast(response?.data.message) };
			if (!isTest) {
				setShowNotificationModal(false);
			}
			start.current = 0;
			fetchData();
		} else {
			if (response?.data.message) { addErrorToast(response?.data.message) };
		}
	};

	const onSearchSubmit = (values: { search: string }) => {
		search.current = values.search;
		start.current = 0;
		fetchData();
	};

	const retryFunction = async (jobId: number | undefined) => {
		if (jobId) {
			const response = await client.retryFailedNotification.query({ jobId }).then((res) => { return res?.data });
			if (response.status === APIResponse.SUCCESS) {
				addSuccessToast(response.message);
			} else {
				addErrorToast(response.message);
			}
		}
	};

	const refreshJobStatus = async (jobId: number | undefined) => {
		if (jobId) {
			const response = await client.refreshJobNotification.query({ jobId }).then((res) => { return res?.data });
			if (response.status === APIResponse.SUCCESS) {
				Object.keys(response.data).forEach((key) => {
					if (!ignoreColumns.includes(key as never)) {
						tableColumns[key] = key;
					}
				});

				tableColumns = { ...preferredOrder, ...tableColumns };

				await setTableData((prevData: { columns: { [key: string]: string | number | null }; data: any }) => {
					const data = prevData.data.map((job: { id: number }) => {
						if (job.id === jobId) {
							return response.data;
						}
						return job;
					});

					return { columns: tableColumns, data: data };
				});
				addSuccessToast(response.message);
			} else {
				addErrorToast(response.message);
			}
		}
	};

	const headerRenderers: HeaderRenderers<NotificationManagementTableColumns> = {
		id: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {
			return (
				<>
					<span {...getToggleAllRowsExpandedProps()}>{isAllRowsExpanded ? "" : ""}</span>
				</>
			);
		},
		title: () => _("form_add_club_amateur.label.contactPosition"),
		authorName: () => _("label.author"),
		date: () => _("label.date/time"),
		methods: () => _("label.methods"),
		userCount: () => _("breadcrumb.casual_club_users"),
		failed: () => _("label.user.status"),
		retry: () => _("label.retry"),
		detail: () => _("label.detail"),
		refresh: () => _("label.refresh"),
	};

	const cellRenderers: Renderers<NotificationManagementTableColumns> = {
		id: ({ row }) => {
			return (
				<span {...row.getToggleRowExpandedProps()}>
					{row.isExpanded ? <span className="plus-toggle"> - </span> : <span className="plus-toggle"> + </span>}
				</span>
			);
		},
		authorName: ({ cell }) => cell.value,
		methods: ({ cell }) => methodFormatter(cell.value),
		userCount: ({ cell }) => <span className="user-count"> {numFormatter(cell.value)} </span>,
		failed: ({ cell }) => {
			if (cell.row.original.percentage! < 100) {
				return (
					<>
						<div className="migration-status" aria-label=" ">
							<CircularProgressBar strokeWidth={5} percentage={cell.row.original.percentage!} squareSize={50} />
						</div>
					</>
				);
			} else if (cell.row.original.failed! > 0) {
				return (
					<span className="green red">
						{_("label.failed")}({cell.row.original.failed})
					</span>
				);
			} else if (cell.row.original.failed == 0) {
				return <span className="green">{_("label.success")}</span>;
			}
		},
		date: ({ cell }) => {
			return (
				<div>
					{cell.value.split(" ")[0]} <br />
					<span>{cell.value.split(" ")[1]}</span>
				</div>
			);
		},
		retry: ({ cell }) => {
			if (cell.row.original.percentage === 100 && cell.row.original.failed! > 0) {
				return (
					<a
						href="#"
						onClick={() => {
							retryFunction(cell.row.original.id);
						}}
					>
						<Icon icon="retry" size={16} color="dark-lavender" />
					</a>
				);
			}
		},
		detail: (cell) => (
			<a
				href="#"
				onClick={() => {
					setSelectedRow(cell.row.original);
					setShowNotificationModal(true);
				}}
			>
				<Icon icon="notify-new" size={22} color="dark-grey" />
			</a>
		),
		refresh: ({ cell }) => {
			return (
				<button
					onClick={() => {
						refreshJobStatus(cell.row.original.id);
					}}
					className="tableCellControls__button tableCellControls__button--svg syc-icon"
				>
					<Icon icon="admin-syc-new" size={16} color="light-blue" />
				</button>
			);
		},
	};

	const containerRef = useRef(null);
	const options = { root: null, rootMargin: "0px", threshold: 1.0 };

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0 });
	}, [activeMenu]);

	useEffect(() => {
		const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
			handleIntersectionObserver(entries, start, totalRecords, fetchData, setHasMoreData);
		}, options);

		if (containerRef.current) observer.observe(containerRef.current);

		return () => {
			if (containerRef.current) observer.unobserve(containerRef.current);
		};
	}, [containerRef, options]);

	const renderRowSubComponent = React.useCallback((row: { row: { original: { id: number } } }) => <JobDetail data={row} />, []);

	return (
		<>
			<div className="product-management-heading-container">
				<div className="product-management-heading">{_("label.notification_management")} </div>
				<div className="top-form-container">
					<div className="top-search">
						<Form
							onSubmit={onSearchSubmit}
							render={({ handleSubmit, submitting }) => (
								<>
									<form onSubmit={handleSubmit}>
										<div className="form-row">
											<Field name="search" type="text">
												{({ input, meta }) => (
													<>
														<input {...input} placeholder={_("label.search")} />
														{meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
													</>
												)}
											</Field>
										</div>
									</form>
								</>
							)}
						/>
					</div>
					<button
						className="btn btn-light-blue"
						onClick={() => {
							setSelectedRow("");
							setShowNotificationModal(true);
						}}
					>
						+ {_("label.add_notification")}
					</button>
				</div>
			</div>
			<div className="notifications-table-container">
				<Datagrid
					columns={tableData?.columns}
					data={tableData?.data}
					className="table"
					override={true}
					cellRenderers={cellRenderers}
					headerRenderers={headerRenderers}
					sortableColumns={sortableColumns}
					lengthFilterEnabled={false}
					manualPaginationEnabled={false}
					showLoader={showLoader}
					renderRowSubComponent={renderRowSubComponent}
				/>
				<div ref={containerRef}>{hasMoreData && <Loader />}</div>
			</div>
			<Modal
				show={showNotificationModal}
				title={_("label.notification")}
				size={"lg"}
				onClose={() => {
					setShowNotificationModal(false);
					setSelectedRow("");
				}}
			>
				<NotificationModal
					onClose={() => {
						setShowNotificationModal(false);
						setSelectedRow("");
					}}
					handleNotication={handleNotication}
					row={selectedRow!}
				/>
			</Modal>
		</>
	);
};

export default NotificationManagement;
