import { VFC } from "../types";

const Loader: VFC = () => {
	return (
		<div className="text-center">
			<svg>
				<use href="#loading"></use>
			</svg>
		</div>
	);
};

export default Loader;
