import { VideoUploadLog } from "libs/apiClient";
import { z } from "zod";

const ProgressJsonSchema = z.object({
	frame: z.string(),
	fps: z.string(),
	q: z.string(),
	size: z.string(),
	time: z.string(),
	bitrate: z.string(),
	speed: z.string(),
});

export const VideoProgressUpdateSchema = z.object({
	id: z.number(),
	name: z.string(),
	status: z.literal("PROCESSING_PROGRESS"),
	stage: z.string(),
	progress: z
		.string()
		.transform((progressStr) => JSON.parse(progressStr))
		.pipe(ProgressJsonSchema),
	timestamp: z.string(),
});

// To use the schema:
export type VideoProgressUpdate = z.infer<typeof VideoProgressUpdateSchema>;

export const VideoUpdateMessageSchema = z.discriminatedUnion("status", [VideoUploadLog, VideoProgressUpdateSchema]);
export type VideoUpdate = z.infer<typeof VideoUpdateMessageSchema>;
