import { _ } from "i18n";
import { Form, Field } from "react-final-form";
import { VFC, APIResponse } from "types";
import { AdminManagementTableColumns } from "../types";
import { useDropzone } from "react-dropzone";
import { useCallback, useState } from "react";
import user from "assets/images/user-placeholder.png";
import Icon from "components/Icon";
import { useToast } from "ui-kit";
import client from "libs/client";
import { getBase64 } from "../functions";

interface UserModalProps {
	onUpdateUser: (values: any) => void;
	row: AdminManagementTableColumns | null;
	adminRoles: { id: number; name: string }[];
}

const UserModal: VFC<UserModalProps> = ({ onUpdateUser, row, adminRoles }) => {
	const [adminPhoto, SetAdminPhoto] = useState(row?.photo ? row.photo : "");
	const publicUrl = new URL(`public`, import.meta.url);

	const { addSuccessToast, addErrorToast } = useToast();

	const formData = {
		id: row ? row.id : null,
		firstName: row ? row.firstName : null,
		lastName: row ? row.lastName : null,
		phoneNumber: row !== null ? row.phoneNumber : null,
		email: row ? row.email : null,
		roles: row ? row.roleId : null,
		status: row !== null ? row.isActive : false,
		photo: row ? row.photo : null,
	};

	const onFormSubmit = (values: any) => {
		if (adminPhoto !== "") {
			values.photo = adminPhoto;
		}
		onUpdateUser(values);
	};

	const onDrop = useCallback(async (acceptedFiles: File[]) => {
		const id = row ? parseInt(row.id) : null;

		const file = await getBase64(acceptedFiles[0]) as string;

		const response = await client.uploadProfilePhoto.mutation({ body: { id, file } });
		if (response?.data.status === APIResponse.SUCCESS) {
			if (response?.data.message) { addSuccessToast(response?.data.message) };
			if(response?.data.url) { SetAdminPhoto(response.data.url); }
		} else {
			if (response?.data.message) { addErrorToast(response?.data.message) };
		}
	}, []);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: { "image/jpeg": [".jpg", ".jpeg"], "image/png": [".png"] },
		multiple: false,
		maxFiles: 1,
	});

	return (
		<div className="admin-creation-container">
			<div className="admin-creation-form">
				<div className="user-row">
					<div className="admin-user-pic">
						<img src={adminPhoto !== "" ? (adminPhoto.includes("http") ? adminPhoto : `${publicUrl}/${adminPhoto}`) : user} />
						<label className="tableCellControls__button tableCellControls__button--svg">
							<Icon icon="crayon" color="white" />
							<input
								id="player_team_photoFile"
								name="player_team[photoFile]"
								className="js-input-file js-disable-tab custom-file-input"
								{...getInputProps()}
							/>
						</label>
					</div>
				</div>
				<Form
					onSubmit={onFormSubmit}
					initialValues={formData}
					render={({ handleSubmit, submitting }) => (
						<>
							<form onSubmit={handleSubmit}>
								<div className="form-row">
									<div className="field-group">
										<label className="required"> {_("label.user.first_name")} </label>
										<Field name="firstName" type="text">
											{({ input, meta }) => (
												<div className="form-group">
													<input {...input} className="form-control" required={true} />
													{meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
												</div>
											)}
										</Field>
									</div>

									<div className="field-group">
										<label className="required"> {_("label.user.last_name")} </label>
										<Field name="lastName" type="text">
											{({ input, meta }) => (
												<div className="form-group">
													<input {...input} className="form-control" required={true} />
													{meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
												</div>
											)}
										</Field>
									</div>

									<div className="field-group">
										<label> {_("label.user.phone")} </label>
										<Field name="phoneNumber" type="text">
											{({ input, meta }) => (
												<div className="form-group">
													<input {...input} className="form-control"/>
													{meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
												</div>
											)}
										</Field>
									</div>

									<div className="field-group">
										<label className="required"> {_("label.user.email")} </label>
										<Field name="email" type="email">
											{({ input, meta }) => (
												<div className="form-group">
													<input {...input} className="form-control" required={true} />
													{meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
												</div>
											)}
										</Field>
									</div>

									<div className="field-group">
										<label className="required"> {_("label.user.role")} </label>
										<Field name="roles" component="select" className="form-control" required={true}>
											<option />
											{adminRoles &&
												adminRoles.map((role) => {
													return (
														<option key={role.id} value={role.id}>
															{role.name}
														</option>
													);
												})}
										</Field>
									</div>

									<div className="field-group">
										<div className="status">
											<label> {_("label.user.status")} </label>
											<div className="switch">
												<Field name="status" type="checkbox">
													{({ input, meta }) => (
														<>
															<input {...input} id="status-switch" className="switch-input" />
															<label htmlFor="status-switch" className="switch-label">
																{_("label.switch")}
															</label>
															{meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
														</>
													)}
												</Field>
											</div>
										</div>
									</div>
								</div>

								<div className="form-footer">
									<button type="submit" className="btn btn-light-blue" disabled={submitting}>
										{_("btn.save")}
									</button>
								</div>
							</form>
						</>
					)}
				/>
			</div>
		</div>
	);
};

export default UserModal;
