import { useState } from "react";
import PlayersTable from "./PlayersTable";
import { _, _any } from "i18n";
import Modal from "ui-kit/src/Modal";
import { AddEditTeamModal } from "./AddEditTeamModal";
import type { ClubTeamInfo } from "libs/apiClient";
import type { PlayerPosition } from "types";
import client from "libs/client";
import { useToast } from "ui-kit";
import type { SelectValue } from "ui-kit/src/MultipleSelect/types";
import Select, { type MultiValue } from "react-select";
import { teamLevels } from "libs/constants";

interface SquadCardProps {
	clubId: number;
	teams?: { value: string | number; label: string }[];
	teamData?: ClubTeamInfo;
	nationalities?: { value: string | number; label: string }[];
	coaches?: { value: string | number; label: string }[];
	playerPositions: {
		value: PlayerPosition;
		label: string;
	}[];
}

export default function SquadCard({ teams, nationalities, teamData, coaches, playerPositions, clubId }: SquadCardProps) {
	const { addSuccessToast, addErrorToast } = useToast();
	const [assignedCoaches, setAssignedCoaches] = useState<MultiValue<SelectValue> | undefined>(
		teamData?.coaches.map((coach) => {
			return { value: coach.id, label: `${coach.firstName} ${coach.lastName} ${coach.email}` };
		}),
	);
	const [showTable, setShowTable] = useState(false);
	const [showAddTeamModal, setShowAddTeamModal] = useState(false);
	const [teamLevel, setTeamLevel] = useState<SelectValue | undefined>(
		teamData?.level ? { value: teamData?.level, label: teamData?.level.toString() } : undefined,
	);

	const coachAssignMutation = client.assignCoachesToTeam.useMutation({
		onSuccess: (res) => addSuccessToast(_any(res?.data.message ?? "")),
		onError: (res) => addErrorToast(res.message),
	});

	const updateLevelMutation = client.updateLevel.useMutation({
		onSuccess: (res) => addSuccessToast(_any(res?.data.message ?? "")),
		onError: (res) => addErrorToast(res.message),
	});

	const onCoachSelect = (values: MultiValue<SelectValue>) => {
		coachAssignMutation.mutate(
			{
				id: teamData?.id ?? 0,
				body: {
					coachIds: values
						.map((coach) => {
							if (typeof coach?.value === "number") return coach.value;
						})
						.filter((value): value is number => typeof value === "number"),
				},
			},
			{ onSuccess: () => setAssignedCoaches(values) },
		);
	};

	const onLevelSelect = (value: SelectValue) => {
		updateLevelMutation.mutate(
			{ id: teamData?.id ?? 0, level: typeof value?.value === "number" ? value.value : 0 },
			{ onSuccess: () => setTeamLevel(value) },
		);
	};

	return (
		<div className="squadsPage-playerTable">
			<div className="squadsPage-teamTab">
				<div className="teamTab-desc">
					<div className="teamTab-left">
						<div>
							<div className="teamTab-name">{`${teamData?.category} ${teamData?.name}`}</div>
							<div className="teamTab-players">{`${teamData?.playerCount} ${_("app.coach.home.teams.info_players")}`}</div>
						</div>
						<div className="teamTab-select">
							<div className="teamTab-selectLabel">{_("btn.assign_coach_to_team")}</div>
							<Select
								value={assignedCoaches}
								menuPortalTarget={document.body}
								placeholder={_("label.coach.empty_select")}
								options={coaches}
								isMulti
								onChange={onCoachSelect}
							/>
						</div>
						<div className="teamSelect-select">
							<div className="teamTab-selectLabel">{_("label.level")}</div>
							<Select value={teamLevel} menuPortalTarget={document.body} options={teamLevels} onChange={onLevelSelect} />
						</div>
					</div>
					<div className="teamTab-right">
						<div className="teamTab-buttonContainer edit">
							<div className="teamTab-button" onClick={() => setShowAddTeamModal(true)}>
								<span className="icon-new icon-blue icon-crayon icon-12" />
								{_("app.player.profile.edit")}
							</div>
						</div>
						<div className="teamTab-buttonContainer">
							<div className="teamTab-button" onClick={() => setShowTable(!showTable)}>
								<span className="icon-new icon-group icon-white" />
								{_("home.club_pro.show_players")}
							</div>
						</div>
					</div>
				</div>
				{showTable && (
					<PlayersTable
						clubId={clubId}
						playerPositions={playerPositions}
						teamId={teamData?.id}
						teams={teams}
						nationalities={nationalities}
						oldPlayers={false}
					/>
				)}
			</div>
			<Modal show={showAddTeamModal} title={_("btn.edit_team").toUpperCase()} size="sm" onClose={() => setShowAddTeamModal(false)}>
				<AddEditTeamModal clubId={clubId} teamData={teamData} close={() => setShowAddTeamModal(false)} />
			</Modal>
		</div>
	);
}
